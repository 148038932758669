import React, { useEffect, useState } from "react";
import styles from './main.module.css';
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "../../../../../design-system/lib/Typography";
import { useTranslation } from "react-i18next";
import Switch from "../../../../../design-system/lib/Switch";
import { useGoogleLogin } from '@react-oauth/google';
import Button from "../../../../../design-system/lib/Button";
import google from "../../../../../static/google.svg";
import { ARROW_LEFT, Email } from "../../../../../static";
import apple from "../../../../../static/apple.svg";
import Facebook from "../../../../../static/facebook.svg";
import Input from "../../../../../design-system/lib/Input";
import PhoneInput from "react-phone-number-input";
import FacebookLogin from "../../../../../innerLibrary/facebookLogin2/facebook";
import AppleLogin from "react-apple-login";
import loadDynamicScript from "../../../../../helper/module/loadDynamicScript";
import { CodeVerification, ResendCode } from "../../../../../Redux/actions/actions";
import { CustomerCodeVerificationApi } from "../../../../../helper/api";


function BusinessLogin_Verification({handleback , data , changeState}) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const [timer, setTimer] = useState(20);
  const [vCode, setVCode] = useState("");
  const [captcha, setCaptcha] = useState(null);
  const [ts, setTs] = useState(null);
  const [captchaResend, setCaptchaResend] = useState(null);
  const [tsResend, setTsResend] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const { t, i18n } = useTranslation();


  const handleAutoManual = (inde) => {
  };

    useEffect(() => {
      requestcaptcha()
    }, [])
  
    const requestcaptcha = React.useCallback(() => {
      setCaptcha(null)
      setTs(null)
      setCaptchaResend(null)
      setTsResend(null)
      // this.setState({
      //   Captcha: null ,
      //   ts:null
      // },()=>{
         loadDynamicScript(
        `https://www.google.com/recaptcha/enterprise.js?render=${global.config.grsk}`,
        "recaptchaEnterprise",
        () => {
          try {
          window.grecaptcha.enterprise.ready(() => {
            const ts = Number(String(Number(Date.now())))
  
            window.grecaptcha.enterprise
              .execute(global.config.grsk, {
                action: `custom_customer_login_${ts}`
              })
              .then((token) => {
                setCaptcha(token)
                setCaptchaResend(token)
                setTs(ts)
                setTsResend(ts)
   
              })
              .catch((error) => {
                console.log(error);
              });
          });
        } catch (error) {
          
        }
        }
      );
      // })
  
  
     
    }, []);
  

  useEffect(() => {
      const intervalId = setInterval(() => {
      timer > 0 && setTimer(timer - 1)
    }, 1000);


    return () => clearInterval(intervalId); //This is important

  }, [timer])


  const resendCodeHandler = React.useCallback(() => {
    if (timer == 0) {
      dispatch(ResendCode(dispatch, { username: data.email_username }, (res) => {
        setTimeout(() => {
          setTimer(20)
          requestcaptcha()
        }, 200);
      }, { Captcha: captchaResend, ts: tsResend }))
    }
  }, [timer, data, captchaResend, tsResend]);


  
    const getMinSec = React.useCallback(() => {
      return `${str_pad_left(
        Math.floor(timer / 60),
        "0",
        2
      )}:${str_pad_left(timer % 60, "0", 2)}`;
    }, [timer]);

    const  verificationcodeOnclick = React.useCallback(() => {
      setLoading(true)
      setMessage(null)
        if (vCode.length > 3) {
            CustomerCodeVerificationApi(
               dispatch,
              {
                username: data.email_username ,
                verificationCode: vCode, 
              },{
                "x-captcha-token" :  captcha,
                "x-request-ts" :  ts
              },
              (res) => {
                setLoading(false)
                if(res.error){
                  setMessage(res.error)

                }

                requestcaptcha()
               }
            )
        }
      }, [vCode , data , captcha , ts]);

    
  
  
    const str_pad_left = React.useCallback((string, pad, length) => {
      return (new Array(length + 1).join(pad) + string).slice(-length);

    }, [timer]);



  let resendCode =
   timer == 0
    ? t("resendCode")
    : t("resendCodeWithTime").replace("xxx", getMinSec);


    return <div
      className={styles.Container}
    >

     <div className={styles.header}>
      <Button size="Medium" type="Secondary" icon={ARROW_LEFT} onClick={handleback} />
      <Typography weight="Heading_Small_Bold" text={<>{t("Verificationcode")}  </>} style={{color:"#000000"}} />
     </div>
    

     <Typography className={styles.numberPhoneSentContainer} weight="Body_Middle_Regular" text={data.errorText} style={{color:"#484848"}} />

   <div className={styles.InputContainers}>

   <Input  error={message}  size="Large" type={"text"} title={t("Code")} onChange={(e)=>{setVCode(e.target.value || "")}} value={vCode} placeHolder={t("TypeHere")}  />

   </div>

      <Typography onClick={() => timer == 0 && resendCodeHandler()} weight="Body_Middle_Bold" text={resendCode} style={{ color: "#1080FF", alignSelf: "start", marginTop: "16px", opacity: timer == 0 ? "1" : ".4", cursor: timer == 0 ? "pointer" : "not-allowed" }} /> 

   {/* <Typography weight="Body_Small_Regular" text={<>{t("Wrong Code")}  </>} style={{color:"#D01400",alignSelf :"start" ,marginTop:"16px"}}   /> */}


  <Button isLoading={loading} size="Large" type="Primary" disabled={vCode.length < 4} onClick={verificationcodeOnclick} text={t("Verify")} style={{marginTop:"24px" , flex:"1" , padding:"14px 16px" , width:"calc(100% - 32px)"}} />

        </div>

}

export default BusinessLogin_Verification