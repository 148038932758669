import * as React from 'react'
import styles from './style.module.css';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RemoveAddressBookFromList, Set_Pickup_Inputs, SetNewAddressBookInNewOrderDetails, SetopenAddressBookInNewOrder, SetopenNewAddressBookInNewOrder } from '../../../../../../../Redux/actions/actionNames';
import { Address_details_grey, CLOSE, Email, email_color, notes, PERSON, PICKUP, WARNING } from '../../../../../../../static';
import Typography from '../../../../../../../design-system/lib/Typography';
import Input from '../../../../../../../design-system/lib/Input';
import Button from '../../../../../../../design-system/lib/Button';
import Schedule from '../../../Schedule/Schedule';
import IconProviderSvgModifier from '../../../../../../../design-system/lib/IconProviderSvgModifier';
import { ReactComponent as BookmarkInActive } from '../../../../../../../static/IconProvider/bookmarkInActive.svg'
import { ReactComponent as Bookmarkactive } from '../../../../../../../static/IconProvider/Bookmarkactive.svg'
import Modal from '../../../../../../../design-system/lib/Modal';
import { get_customerPannel_type, Get_Permisssion } from '../../../../../../../helper/UserData/userdate';
import { Api_Add_Addressbook_AGW, Api_Delete_Addressbook_AGW, Api_Get_Addressbook_list_AGW } from '../../../../../../../helper/api/ApiGateway';
import IconProvider from '../../../../../../../design-system/lib/IconProvider';
import { isEmpty, transform } from 'lodash';
import { SucceessFul_i18 } from '../../../../../../../helper/Notification/sendNotification';
import PhoneInput from '@onro/react-phone-input-2'
import '@onro/react-phone-input-2/lib/style.css'
import { ReactComponent as Address_details_Icon } from '../../../../../../../static/IconProvider/address_details.svg'
import ArrowDown from '../../../../../../../static/IconProvider/Arrow---Down-2.svg'





function PickupInfoNewOrder({handleShowInfo , pickupRequiredCheck}) {
  const addressDetail = useSelector((state) => state.NewOrder.newOrder.pickup.addressDetail)
  const block = useSelector((state) => state.NewOrder.newOrder.pickup.block)
  const floor = useSelector((state) => state.NewOrder.newOrder.pickup.floor)
  const unit = useSelector((state) => state.NewOrder.newOrder.pickup.unit)
  const email = useSelector((state) => state.NewOrder.newOrder.pickup.email)
  const senderFullName = useSelector((state) => state.NewOrder.newOrder.pickup.senderFullName)
  const senderPhoneNumber = useSelector((state) => state.NewOrder.newOrder.pickup.senderPhoneNumber)
  const noteForDriver = useSelector((state) => state.NewOrder.newOrder.pickup.noteForDriver)
  const ActivescheduleSetting = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType ? state.NewOrder.newOrder.service.activeVehicleType.scheduleSetting.isActive : false)
  const scheduleDateBefore = useSelector((state) => state.NewOrder.newOrder.pickup.scheduleDateBefore)
  const scheduleDateAfter = useSelector((state) => state.NewOrder.newOrder.pickup.scheduleDateAfter)
  const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
  const serviceactiveVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const Addressbooklist = useSelector((state) => state.Addressbook.list)
  const address = useSelector((state) => state.NewOrder.newOrder.pickup.address)
  const [modalAddActive, setModalAddActive] = React.useState(false);
  const [modalDelActive, setModalDelActive] = React.useState(false);
  const adresss_loc = useSelector((state) => state.NewOrder.newOrder.pickup.adresss_loc)
  const [modalAddData, setModalAddData] = React.useState({title: null , code : null});
  const [collapsed, setCollapsed] = React.useState(true);

  const { t, i18n } = useTranslation();


  const dispatch = useDispatch();


  const closeDialoge = () => {
    handleShowInfo()
  };


  const detectOrderType = React.useCallback(() => {

    if (serviceactiveVehicleType && serviceactiveVehicleType.orderTypes?.indexOf("PickupDelivery") > -1) {
      return "PickupDelivery"
    } else {
      return "Ondemand"
    }

  },[serviceactiveVehicleType])

  const Emailvalidate = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  const handlePickUpInputs = (e, name) => {
    dispatch(
      Set_Pickup_Inputs({
        name: name,
        value: e.target.value ? e.target.value : "",
      })
    );
  };

  const phoneNumberOnChange = (res, id) => {
    dispatch(
      Set_Pickup_Inputs({
        name: id,
        value: res,
      })
    );
  };


  const checkInputFilledByAB = React.useCallback(() => {
    let status = false;
      Addressbooklist.forEach((element) => {
        if (element.address == address) {
          status = true;
        }
      });
      return status;
   
  }, [Addressbooklist,address ]);

  const getAddressbookId = React.useCallback(() => {
    let status = null;

    console.log("dwdfe343 ", Addressbooklist , address)
      Addressbooklist.forEach((element) => {
        if (element.address == address) {
          status = element.id;
        }
      });
      return status;
   
  }, [Addressbooklist,address ]);

  const RemoveAddressBook = React.useCallback(() => {
    setModalDelActive(false)
    let xxx = getAddressbookId()
    Api_Delete_Addressbook_AGW(
      dispatch,
      xxx,
      (call) => {
        call &&
          dispatch(
            RemoveAddressBookFromList(xxx)
          );
      }
    );
  }, [Addressbooklist,address ]);

  const GetDetailsInputtoText = React.useCallback(() => {

    let xxx = ""

    if(addressDetail && String(addressDetail).trim().length > 0 && (serviceactiveVehicleType?.setting?.isAddressDetailsActive && detectOrderType() == "PickupDelivery")){
      String(xxx).length > 0 ?  xxx+=`, ${addressDetail}` : xxx+=`${addressDetail}`;
    }
    
    if(block && String(block).trim().length > 0){
      String(xxx).length > 0 ?  xxx+=`, ${block}` : xxx+=`${block}`
    }

    if(floor && String(floor).trim().length > 0){
      String(xxx).length > 0 ?  xxx+=`, ${floor}` : xxx+=`${floor}`
    }

    if(unit && String(unit).trim().length > 0){
      String(xxx).length > 0 ?  xxx+=`, ${unit}` : xxx+=`${unit}`
    }

    if(email && String(email).trim().length > 0){
      String(xxx).length > 0 ?  xxx+=`, ${email}` : xxx+=`${email}`
    }


    if(senderFullName && String(senderFullName).trim().length > 0){
      String(xxx).length > 0 ?  xxx+=`, ${senderFullName}` : xxx+=`${senderFullName}`
    }


    if(senderPhoneNumber && String(senderPhoneNumber).trim().length > 0){
      String(xxx).length > 0 ?  xxx+=`, ${senderPhoneNumber}` : xxx+=`${senderPhoneNumber}`
    }



    if(noteForDriver && String(noteForDriver).trim().length > 0){
      String(xxx).length > 0 ?  xxx+=`, ${noteForDriver}` : xxx+=`${noteForDriver}`
    }

    return xxx

  },[addressDetail,block,floor,unit,email,senderFullName,senderPhoneNumber,noteForDriver , serviceactiveVehicleType])

  const UserHavePermission = React.useCallback(() => {

    if (get_customerPannel_type() == "business") {
      if (Get_Permisssion()?.isDefaultPickupAddressEditable) {
        return true
      } else if (adresss_loc.lat == '') {
        return true
      } else {
        return false
      }


    } else {
      return true
    }

  },[adresss_loc])
  
  const handleNewBookmark = React.useCallback(() => {

        Api_Add_Addressbook_AGW(dispatch, {
          "title": modalAddData.title,
          "address": address,
          // addressDetail : addressDetail ,
            "coordinates": [
                adresss_loc.lng, adresss_loc.lat
            ], 
            "buildingBlock": block,
            "floor": floor,
            "fullName": senderFullName,
            "phone": senderPhoneNumber,
            "placeId": "",
            "room": unit,
            "code": modalAddData.code ? modalAddData.code  : "" ,
            email:email ,
            description:noteForDriver ,
        },(call)=>{
          if(call.loading){
            setModalAddActive(false)
          }else {
            setModalAddActive(false)
            setModalAddData({title: null , code : null})
            if(call.ok){
              SucceessFul_i18(
                "Createdsuccessfully",
                "networkError",
                "close",
                () => {
                  dispatch({
                    type: "removeNetworkErrorSnack",
                    key: "networkError",
                  });
                },
                new Date().getTime() + Math.random()
              );
              Api_Get_Addressbook_list_AGW(dispatch, {page:1 , perpage:20 , searchRegex:""}, false);
  
            }else{
              
            }
          }
        });
  

    //    setModalAddActive(false)


  },[senderPhoneNumber , addressDetail , address, adresss_loc,block ,floor , unit,senderFullName,noteForDriver ,email ,modalAddData ])


  return (
    <div className={styles.NewCustomerRegisterContainer}>

      {/* <div className={styles.largeHeader}>
        <Button icon={CLOSE} size="Medium" type="Secondary" onClick={closeDialoge} />
        <div className={styles.largeHeaderTitles}>
          <Typography text={t("Pickup")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
        </div>

        {checkInputFilledByAB() ?
          <Button disabled={!UserHavePermission()} onClick={() => { setModalDelActive(true) }} style={{ marginInlineStart: "auto" }} size='Medium' type='Secondary' iconComponentChildren={<IconProviderSvgModifier Icon={Bookmarkactive} size='16' />} >  </Button>
          : <Button onClick={() => { setModalAddActive(true) }} style={{ marginInlineStart: "auto" }} size='Medium' type='Secondary' iconComponentChildren={<IconProviderSvgModifier Icon={BookmarkInActive} size='16' />} >  </Button>
        }



      </div> */}

      <div className={styles.collapseHeaderContainer} onClick={()=>{setCollapsed(!collapsed)}}>

        <IconProviderSvgModifier Icon={Address_details_Icon} size='14' style={{marginInlineStart:"11.6px"}} />
        <Typography text={<>{t("Details")}{(String(GetDetailsInputtoText()).length > 0 && !collapsed) ? ":" :""} {!pickupRequiredCheck && <span className={styles.starRequired} style={{color:"red"}}>*</span>} </>} weight="Body_Middle_Medium"  style={{marginInlineStart:"4px"}}  />
        {!collapsed && <Typography text={GetDetailsInputtoText()} weight="Body_Tiny_Regular"  style={{marginInlineStart:"4px" , textDecoration: 'none',textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',width: 'auto',flex: '1' }}  />}

        
        <IconProvider Icon={ArrowDown} size='10' style={{marginInlineStart:"auto" ,marginInlineEnd :"10px" , transform : collapsed ? "" : "rotate(180deg)"}} />
      </div>
      {/* <div className={styles.largeHeader}>
        <Button icon={CLOSE} size="Medium" type="Secondary" onClick={closeDialoge} />
        <div className={styles.largeHeaderTitles}>
          <Typography text={t("Pickup")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
        </div>

        {checkInputFilledByAB() ?
            <Button disabled={!UserHavePermission()} onClick={()=>{setModalDelActive(true)}} style={{marginInlineStart:"auto"}}  size='Medium' type='Secondary' iconComponentChildren={<IconProviderSvgModifier Icon={Bookmarkactive} size='16' />} >  </Button>
            :  <Button  onClick={()=>{setModalAddActive(true)}} style={{marginInlineStart:"auto"}}  size='Medium' type='Secondary' iconComponentChildren={<IconProviderSvgModifier Icon={BookmarkInActive} size='16' />} >  </Button>
          }

       
        
      </div> */}

     {(  !pickupRequiredCheck && !collapsed ) && <Typography text={t("Complete * Feileds")} weight='Body_Small_Regular' style={{
          width: "345px", overflow: 'hidden',
          textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: "1" ,marginTop:"4px"
        }} textColor='Red' />} 

      {collapsed && <>
            <div className={styles.body}>
        <div className={styles.inputsConatiner}>
          {(serviceactiveVehicleType?.setting?.isPickupAddressRoomFloorBlockActive && detectOrderType() != "PickupDelivery") && <div className={styles.threeCOLUMNS}>
            <Input type={"text"} placeHolder={t("block")} value={block} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "block") }} />
            <Input type={"text"} placeHolder={t("Floor")} value={floor} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "floor") }} />
            <Input type={"text"} placeHolder={t("Unit")} value={unit} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "unit") }} />
          </div>}

          {(serviceactiveVehicleType?.setting?.isAddressDetailsActive && detectOrderType() == "PickupDelivery") &&
            <Input   IconStyle={{width:"16px" , height :"16px"}}  iconStart={Address_details_grey}  type={"text"} placeHolder={t("addressDetail")} value={addressDetail} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "addressDetail") }} />
           }

<div className={styles.twoCOLUMNS}>

            {serviceactiveVehicleType?.setting?.isPickupAddressNameActive && <Input IconStyle={{width:"16px" , height :"16px"}} iconStart={PERSON} isRequired={serviceactiveVehicleType?.setting?.isPickupAddressNameRequired} isRequiredText={t("*")} type={"text"} placeHolder={t("Fullname")}  value={senderFullName} onChange={(e) => { handlePickUpInputs(e, "senderFullName") }}  ContainerclassName={styles.flexInput}/>}


          {serviceactiveVehicleType?.setting?.isPickupAddressPhoneActive && 
     
            <div className={styles.PhoneInput} style={{width:"100%" ,flex:"1"}}>
                  <PhoneInput
                  countryCodeEditable ={false}
                    country={String(global.config.countryCca2).toLowerCase()}
                    className={clsx(styles.inputContainer ,  styles.PhoneInputxc )}
                    value={senderPhoneNumber}
                    onChange={(e1 , e2 , e3 , e4) => { phoneNumberOnChange(e4, "senderPhoneNumber")}}
                    enableLongNumbers={true}
                    inputProps={{type:"text"}}
                    copyNumbersOnly={false}
                    autoFormat={false}
                    placeHolder={t("Phonenumber")}
                    style={{width:"100% !important"}}
                  />
              {serviceactiveVehicleType &&
                serviceactiveVehicleType.setting &&
                serviceactiveVehicleType.setting.isPickupAddressPhoneRequired && String(senderPhoneNumber).length < 3 && (
                  <span className={`inputRequired ${styles.PhoneInputRequired}`}>
                    {t("*")}
                  </span>
                )}
            </div>
          }
</div>


<div className={styles.twoCOLUMNS}>
       {(serviceactiveVehicleType?.setting?.isPickupAddressEmailActive ) && <Input isRequired={serviceactiveVehicleType?.setting?.isPickupAddressEmailRequired} isRequiredText={t("*")} error={Emailvalidate(email) ? "" : t("emailInvalid")} type={"text"} value={email} placeHolder={t("email")} onChange={(e) => { handlePickUpInputs(e, "email") }}  IconStyle={{width:"16px" , height :"16px"}} iconStart={Email}  />}
         {detectOrderType() != "PickupDelivery" && <Input  IconStyle={{width:"16px" , height :"16px"}} iconStart={notes}  type={"text"} value={noteForDriver} placeHolder={t("notesfordriver")} onChange={(e) => { handlePickUpInputs(e, "noteForDriver") }} />}

</div>

{checkInputFilledByAB() ?
          <Button text={t("Added to address book")} disabled={!UserHavePermission()} onClick={() => { setModalDelActive(true) }} size='Medium' type='Secondary' iconComponentChildren={<IconProviderSvgModifier Icon={Bookmarkactive} size='16' />} >  </Button>
          : <Button text={t("Add to address book")} onClick={() => { setModalAddActive(true) }} size='Medium' type='Secondary' iconComponentChildren={<IconProviderSvgModifier Icon={BookmarkInActive} size='16' />} >  </Button>
        }

     
        </div>

      </div>

    

      {/* {ActivescheduleSetting && <div className={styles.body}>
        <Typography text={t("SchedulePickup")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />

        <Schedule orderType={detectOrderType()}
          scheduleDateBefore={scheduleDateBefore}
          scheduleDateAfter={scheduleDateAfter}
        />
      </div>}  */}

      { modalAddActive && <Modal  onclose={()=>{setModalAddActive(false)}} 
      
      body={<div className={styles.addAddressbookContainer}>
          <div className={styles.addAddressbookinnerContainer} style={{paddingBottom : "16px" ,borderBottom:"1px solid #F4F4F7"}}> 
                <Input title={t("title")} isRequired={true} isRequiredText='*'  type={"text"} placeHolder={t('ExHome')} value={modalAddData.title} onChange={(e)=>{setModalAddData({title : e.target.value , code : modalAddData.code})}}/>
                <Input title={t('Code')}  type={"text"} placeHolder={t('Ex123')} value={modalAddData.code}  onChange={(e)=>{setModalAddData({title : modalAddData.title , code : e.target.value})}}/>

          </div>
          <div className={styles.addAddressbookinnerContainer} style={{paddingTop:"16px" }}> 
              
              <div className={styles.addAddressnamephoneContainer}>
                <IconProvider Icon={PICKUP} size='16' />
                <div className={styles.addAddressnamephoneInnerContainer}>
                  <Typography weight='Body_Middle_Medium' text={address} style={{lineHeight : "16.71px"}} />
                  <Typography weight='Body_Tiny_Regular' text={isEmpty(addressDetail) ? "-" : addressDetail} />
                </div>
              </div>

      
             {(!isEmpty(senderFullName) || !isEmpty(senderPhoneNumber)) && <div className={styles.addAddressnamephoneContainer} style={{borderTop:"1px solid rgb(244, 244, 247)",paddingTop:"12px"}}>
                <IconProvider Icon={PERSON} size='16' style={{alignSelf :"center"}} />
                <div className={styles.addAddressnamephoneInnerContainer}>
                  <Typography weight='Body_Middle_Medium' text={isEmpty(senderFullName) ? "--" : senderFullName} style={{lineHeight : "17.3px"}} />
                  <Typography weight='Body_Tiny_Regular' text={isEmpty(senderPhoneNumber) ? "-" : senderPhoneNumber}  />
                </div>
               {!isEmpty(email) && <Typography style={{alignSelf :"end" , marginInlineStart :"auto"}} weight='Subtitle_Tiny_Regular' text={email}  />}

              </div>}

          </div>
      </div>} ConfirmationDisabled={isEmpty(modalAddData.title)} size='Large' CancelText={t("Cancel")} CancelOnClick={()=>{setModalAddActive(false)}} ConfirmationOnClick={handleNewBookmark} ConfirmationText={t("Add")} icon={WARNING} title={t('Addtoaddressbook')} description='This address with entered details will be address to your address book.'  />  }
      { modalDelActive && <Modal size='Small' ConfirmationStyle={{color:"white",backgroundColor:"red"}} CancelText={t("Cancel")} CancelOnClick={()=>{setModalDelActive(false)}} ConfirmationOnClick={RemoveAddressBook} ConfirmationText={t("YesRemove")} icon={WARNING} title={t('Removefromaddressbook')} description={t("RemovefromaddressbookDesc")}  />}

      </>}

    </div>
  )
}

export default PickupInfoNewOrder