import React, { useEffect, useState } from "react";
import styles from './main.module.css';
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "../../../../../design-system/lib/Typography";
import { useTranslation } from "react-i18next";
import Switch from "../../../../../design-system/lib/Switch";
import { useGoogleLogin } from '@react-oauth/google';
import Button from "../../../../../design-system/lib/Button";
import google from "../../../../../static/google.svg";
import { ARROW_LEFT, Email } from "../../../../../static";
import apple from "../../../../../static/apple.svg";
import Facebook from "../../../../../static/facebook.svg";
import Input from "../../../../../design-system/lib/Input";
import PhoneInput from "react-phone-number-input";
import FacebookLogin from "../../../../../innerLibrary/facebookLogin2/facebook";
import AppleLogin from "react-apple-login";


function RegisterBusiness_Manual_start({ handler , data , changeState , handleback}) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const [timer, setTimer] = useState(20);

  const { t, i18n } = useTranslation();


  const handleAutoManual = (inde) => {
  };


  useEffect(() => {

    const intervalId = setInterval(() => {
      timer > 0 && setTimer(timer - 1)
    }, 1000);


    return () => clearInterval(intervalId); //This is important
  }, [timer])



  
    const getMinSec = React.useCallback(() => {
      return `${str_pad_left(
        Math.floor(timer / 60),
        "0",
        2
      )}:${str_pad_left(timer % 60, "0", 2)}`;
    }, [timer]);
  
  
    const str_pad_left = React.useCallback((string, pad, length) => {
      return (new Array(length + 1).join(pad) + string).slice(-length);

    }, [timer]);



const emailValidate=(email)=> {
  try {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  } catch (error) {
    return false
  }
}


  let resendCode =
   timer == 0
    ? t("resendCode")
    : t("resendCodeWithTime").replace("xxx", getMinSec);


    return <div
      className={styles.Container}
    >

     <div className={styles.header}>
      <Button size="Medium" type="Secondary" icon={ARROW_LEFT} onClick={handleback} />
      <Typography weight="Heading_Small_Bold" text={<>{t("Business account")}  </>} style={{color:"#000000"}} />
     </div>
    

    {/* <div className={styles.thirdConnected}>
      <Typography weight="Body_Middle_Bold" style={{color:"#009B62"}} text={t("Google connected")} />
      <Typography weight="Body_Small_Regular" style={{color:"#484848"}} text={"Example@example.com"} />

    </div> */}

    <div className={styles.InputContainers}>

        <Input error={!emailValidate(data.email)} size="Large" type={"text"} value={data.email} isRequiredText={"*"} isRequired={true} title={t("Business Email")} onChange={(e)=>{handler("email",e.target.value || "")}} placeHolder={t("Ex. example@example.com")}  />
        <div
            className={styles.phoneTitleContainer}
        >

            <Typography weight="Body_Small_Regular" text={<>{t("Phonenumber")} <Typography weight="Body_Small_Regular" text={"*"} style={{ color: "#D01400" ,float:"inline-end" ,marginInlineStart:"4px" }} /></>} style={{ color: "#484848" }} />

            <div className={`${styles.phoneContainer} ${styles.phoneContainer_error}`}>
              <PhoneInput
            defaultCountry={global.config.countryCca2}
                placeholder={t("plsenterphonnumber")}
                value={data.phone} 
            onChange={(e)=>{handler("phone",e || "")}}
                className={styles.loginpersonPhoneInput}
              />  
            </div>


        </div>

        <Input  size="Large" type={"text"} isRequiredText={"*"} isRequired={true} title={t("Contact name")} value={data.contactName}  onChange={(e)=>{handler("contactName",e.target.value || "")}} placeHolder={t("Ex. John Leo")}  />

    </div> 

     {/* <Typography className={styles.numberPhoneSentContainer} weight="Body_Middle_Regular" text={<>{t("Enter code sent to your")} <Typography weight="Body_Middle_Regular" text={"+1234567890"} style={{color:"#242424" , float:"inline-end" , marginInlineStart:"5px" , direction:"initial" ,}} /> </>} style={{color:"#808080"}} /> */}

   {/* <div className={styles.InputContainers}>

   <Input type={"text"} title={t("Code")} onChange={()=>{}} placeHolder={t("TypeHere")}  />

   </div> */}

    {/* <Typography onClick={()=>timer > 0 && null} weight="Body_Middle_Bold" text={resendCode} style={{color:"#1080FF",alignSelf :"start" ,marginTop:"16px",opacity: timer == 0 ? "1" : ".4" , cursor : timer == 0 ? "pointer" :"not-allowed"}}   />  */}

   {/* <Typography weight="Body_Small_Regular" text={<>{t("Wrong Code")}  </>} style={{color:"#D01400",alignSelf :"start" ,marginTop:"16px"}}   /> */}


  <Button disabled={String(data.contactName).length < 2 || String(data.email).length < 2 || !emailValidate(data.email) || String(data.phone).length < 2} size="Large" type="Primary" text={t("Continue")} style={{marginTop:"24px" , flex:"1" , padding:"14px 16px" , width:"calc(100% - 32px)"}} onClick={()=>{changeState("registerBusiness_end")}} />

  {/* <Typography className={styles.termsContainer} weight="Body_Small_Regular" style={{color:"#808080"}} text={<>{t("By continuing, you agree to Onro’s ")} <Typography weight="Body_Small_Regular" style={{color:"#808080" , textDecoration :"underline" ,cursor:"pointer"}} text={<>{t("Terms of Use")}</>} /> <Typography weight="Body_Small_Regular" style={{color:"#808080"}} text={<>{t("and")}</>} /> <Typography weight="Body_Small_Regular" style={{color:"#808080", textDecoration :"underline",cursor:"pointer"}} text={<>{t("Privacy Policy.")}</>} /></>} /> */}

        </div>

}

export default RegisterBusiness_Manual_start