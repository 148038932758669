import React, { useEffect, useState } from "react";
import styles from './main.module.css';
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "../../../../../design-system/lib/Typography";
import { useTranslation } from "react-i18next";
import Switch from "../../../../../design-system/lib/Switch";
import { useGoogleLogin } from '@react-oauth/google';
import Button from "../../../../../design-system/lib/Button";
import google from "../../../../../static/google.svg";
import { ARROW_LEFT, Email } from "../../../../../static";
import apple from "../../../../../static/apple.svg";
import Facebook from "../../../../../static/facebook.svg";
import Input from "../../../../../design-system/lib/Input";
import PhoneInput from "react-phone-number-input";
import FacebookLogin from "../../../../../innerLibrary/facebookLogin2/facebook";
import AppleLogin from "react-apple-login";
import loadDynamicScript from "../../../../../helper/module/loadDynamicScript";
import { LoginBusiness } from "../../../../../Redux/actions/actions";


function BusinessLogin({data , handler , handleback , changeState}) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [ts, setTs] = useState(null);
  const [errorText, seterrorText] = useState(null);

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const login = React.useCallback(() => {
    if (!loading) {
      seterrorText(null)

      setLoading(true)
      dispatch(LoginBusiness(dispatch, {
        "username": data.email_username ,
        "password": data.password
      },{
        "x-captcha-token" : captcha,
        "x-request-ts" : ts
      }, (xx) => {
        console.log(xx)
        if(xx.error){
          seterrorText(xx.error)
        }
        setLoading(xx.loading)

        if(String(xx.code) == "-22"){
          handler("errorText" ,xx.error )
          changeState("BusinessLogin_Verification")
        }
        requestcaptcha()
      }))
    }
  }, [captcha , ts , data]);

  useEffect(() => {
    requestcaptcha()
  }, [])


  const handleAutoManual = (inde) => {
  };



  const requestcaptcha = React.useCallback(() => {
    setCaptcha(null)
    setTs(null)
    // this.setState({
    //   Captcha: null ,
    //   ts:null
    // },()=>{
       loadDynamicScript(
      `https://www.google.com/recaptcha/enterprise.js?render=${global.config.grsk}`,
      "recaptchaEnterprise",
      () => {
        try {
        window.grecaptcha.enterprise.ready(() => {
          const ts = Number(String(Number(Date.now())))

          window.grecaptcha.enterprise
            .execute(global.config.grsk, {
              action: `custom_customer_login_${ts}`
            })
            .then((token) => {
              setCaptcha(token)
              setTs(ts)
 
            })
            .catch((error) => {
              console.log(error);
            });
        });
      } catch (error) {
        
      }
      }
    );
    // })


   
  }, []);

    return <div
      className={styles.Container}
    >

     <div className={styles.header}>
      <Button size="Medium" type="Secondary" icon={ARROW_LEFT} onClick={handleback} />
      <Typography weight="Heading_Small_Bold" text={<>{t("Business account")}  </>} style={{color:"#000000"}} />
     </div>
    

   <div className={styles.InputContainers}>

   <Input  size="Large" inputStyle={{height:"95%"}} value={data.email_username} onChange={(e)=>{handler("email_username" , e.target.value || "")}} type={"text"} title={t("Email/Username")} placeHolder={t("TypeHere")} />
   <Input size="Large"  inputStyle={{height:"95%"}} value={data.password}  onChange={(e)=>{handler("password" , e.target.value || "")}}  type={"password"} title={t("Password")} placeHolder={t("TypeHere")}  />

   </div>

  {errorText && <Typography weight="Body_Small_Regular" text={<>{errorText}  </>} style={{color:"#D01400",alignSelf :"start" ,marginTop:"16px"}}   />}


  <Button isLoading={loading} onClick={login} disabled={String(data.email_username).length <= 1 || String(data.password).length <= 1} size="Large" type="Primary" text={t("login")} style={{marginTop:"24px" , flex:"1" , padding:"14px 16px" , width:"calc(100% - 32px)"}} />

        <div className={styles.hr} />


        <Typography className={styles.registerLink} onClick={()=>{changeState("forgetBusinessLogin")}} weight="Body_Middle_Medium" text={<>{t("Forgot your password?")}   </>} textColor="TextLight" style={{direction:"initial" , color:"#242424"}} />

        </div>

}

export default BusinessLogin