import axios from "axios";
import { store } from "../../Redux/store/index";
import { Service } from "axios-middleware";
import {
  UnSucceessFul_i18,
  SucceessFul_i18,
  Warning_NOi18,
  SucceessFul,
} from "../Notification/sendNotification";
import {
  SetAboutUs,
  SetOrderHistoryListData,
  SetOrderListData,
  SetTransactions,
  set_access_token,
  Set_Service_VehicleTypes,
  Set_SearchAutoComplete,
  Set_SearchAutoCompleteLoading,
  VerificationCodeSetKeyValue,
  Set_Driver_auto_VehicleTypes,
  Set_PromoCode,
  Set_Map_Geocoding,
  SetPriceNewOrder,
  Set_Customer_Balance,
  SetCanSaveTravel,
  AddNewOrderToList,
  ResetNewOrder,
  ChangeNewTravelDialoge,
  SetWebSettings,
  LoadingOrderInfo,
  SetOrderInfoError,
  SetOrderInfo,
  SetEstimated,
  SETQUICKESSAGE,
  SETMESSAGETOCHATS,
  SETMESSAGEErrorLoading,
  Set_latlng,
  SetAddressBookListData,
  Set_viewOrder_events,
  SetSupportData,
  SetSelectedSupportData,
  SetMessageBoxData,
  SetBulkImportList,
  SetActiveImportData,
  Create_new_BulkImport_List,
  SetActiveImport,
  Remove_draft_bulkImport,
  SetActiveImportDataList,
  Set_Order_Info_Api_PD_Update,
} from "../../Redux/actions/actionNames";
import { ResetOrderListAfterCreateOrder } from "../module";
import {
  get_accessToken,
  get_currencyCode,
  get_customerPannel_type,
  get_defaultProjectDev,
  get_Id,
  get_lang,
  get_refreshToken,
  set_accessToken,
  Set_Bussiness_Account_Info,
  Set_Bussiness_Info,
  set_calendarType,
  set_currencyCode,
  set_customerPannel_type,
  set_Id,
  Set_Permisssion,
  Set_Person_Account_Info,
  Set_Person_Info,
  set_refreshToken,
} from "../UserData/userdate";
import _, { get, isEmpty } from "lodash";
import {
  Api_GetVehicleTypeByService_AGW,
  Api_get_estimated_distance_AGW,
  Api_get_Order_Info_AGW,
  Api_Get_Web_Setting_AGW,
  Api_ondemand_promotion_set_AGW,
  Api_schedule_promotion_set_AGW,
  Api_Get_AcountInformation_AGW,
} from "./ApiGateway";
import packageJson from "../../../package.json";
import { emitCustomEvent } from "react-custom-events";
import history from "../../service/history";
import { snack } from "../module/SnackbarUtilsConfigurator";
const FileDownload = require('js-file-download');


const httpCustomerUpload = axios.create({
  baseURL: global.config.restServerUrl,
  timeout: 100000000,
});


const getBaseApiByUrl = () => {
  switch (get_defaultProjectDev() || window.location.hostname) {
    case "restaurant.catrin-go.com":
      return "https://rest.catrin-go.com";
    case "deliver.loco.direct":
      return "https://rest.loco.direct";

    case "onro.test":
      return "https://rest.onro.org";
      break;
    case "app.onro.io":
      return "https://rest.onro.io";
      break;
    case "app.pickup.global":
      return "https://webservice.pickup.global";
    case "app.deliro.ir":
      return "https://rest.deliro.ir";
    case "app.yoni-app.sn":
      return "https://rest.yoni-app.sn";
    case "app.emrazgo.com":
      return "https://rest.emrazgo.com";
    case "order.hellocourier.co.ke":
      return "https://rest.hellocourier.co.ke";
    case "app.otokh.com":
      return "https://rest.otokh.com";
    case "now.veedelivery.com":
      return "https://rest.veedelivery.com";
    case "app.onro.org":
      return "https://rest.onro.org";
    case "app.hio.ro":
      return "https://rest.hio.ro";
    case "app.fab.com.np":
      return "https://rest.fab.com.np";
      break;
    case "app.geenstress.amsterdam":
      return "https://rest.geenstress.amsterdam";
      break;

    case "business.trcs.pk":
      return "https://rest.trcs.pk";
      break;

    case "dash.droppx.com":
      return "https://rest.droppx.com";
      break;

    case "deliver.pickngosg.com":
      return "https://rest.pickngosg.com";
      break;
    case "my.wadelivery.com":
      return "https://rest.wadelivery.com";
      break;

    default:
      return "https://rest.onro.io";
    // return "https://rest.onro.org";
  }
};

console.log("httpCustomer ",global.config.restServerUrl)
const httpCustomer = axios.create({
  baseURL: global?.config?.restServerUrl,
  timeout: 10000,
});
//const service = new Service(httpCustomer);
const CancelToken = axios.CancelToken;
let cancelQueryTransaction;
let cancelQuerySearch;
let cancelcalculatetravelcost;
let cancelgetlistAddressbook;

httpCustomer.defaults.headers.common["retry"] = 2;
httpCustomer.defaults.headers.common["retryDelay"] = 3000;
httpCustomer.defaults.headers.common["retryCount"] = 0;
httpCustomer.defaults.headers.common["X-Api-Source"] = "web";
httpCustomer.defaults.headers.common["X-App-Version-Code"] =
  packageJson.version;
httpCustomer.defaults.headers.common["X-App-Version-Name"] =
  packageJson.version;

// fase 1 = include ApiName and ApiParam

// fase 1 done
export const Person_TokenExChange = async (func) => {
  httpCustomer
    .post(
      `/api/v1/customer/individual/auth/token`,
      {
        customerId: get_Id(),
        refreshToken: get_refreshToken(),
      },
      {
        headers: {
          "Accept-Language": get_lang(),
        },
      }
    )
    .then((res) => {
      if (res.data.data.accessToken) {
        set_accessToken(res.data.data.accessToken);
        setTimeout(() => {
          func();
        }, 500);
      }
    })
    .catch((error) => {
      localStorage.clear();
      window.location.reload();
    });
};

export const Bussiness_TokenExChange = async (func) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/auth/token`,
      {
        refreshToken: get_refreshToken(),
      },
      {
        headers: {
          "Accept-Language": "en",
        },
      }
    )
    .then((res) => {
      if (res.data.data.accessToken) {
        set_accessToken(res.data.data.accessToken);
        setTimeout(() => {
          func();
        }, 500);
      }
    })
    .catch((error) => {
      localStorage.clear();
      window.location.reload();
    });
};

// fase 1 done
export const RefreshToken = async (func) => {
  if (get_customerPannel_type() == "individual") {
    Person_TokenExChange(func);
  } else {
    Bussiness_TokenExChange(func);
  }
};

export const GoogleTokenInfoApi = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/customer/individual/auth/google/tokeninfo`, {
      params: {
        idToken: data,
      },
    })
    .then((res) => {
  
      call({ res : res.data.data, loading: false, error: false });
    })
    .catch((error) => {
      call({ error, loading: false, error: false });

      if (error.response) {
        if (error.response.status === 400 && error.response.data.code == "-2") {
          UnSucceessFul_i18(
            "googletokenerror",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const BusinessGoogleTokenInfoApi = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/customer/business/auth/google/tokeninfo`, {
      params: {
        idToken: data,
      },
    })
    .then((res) => {
  
      call({ res : res.data.data, loading: false, error: false });
    })
    .catch((error) => {
      call({ error, loading: false, error: false });

      if (error.response) {
        if (error.response.status === 400 && error.response.data.code == "-2") {
          UnSucceessFul_i18(
            "googletokenerror",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const FacebookTokenInfoApi = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/customer/individual/auth/facebook/tokeninfo`, {
      params: {
        accessToken: data,
      },
    })
    .then((res) => {
   
      call({ res : res.data.data, loading: false, error: false });
    })
    .catch((error) => {
      call({ error, loading: false, error: false });

      if (error.response) {
        if (error.response.status === 400 && error.response.data.code == "-2") {
          UnSucceessFul_i18(
            "facebooktokenerror",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const AppleTokenInfoApi = async (dispatch, data, call) => {
  call({ loading: true, error: false });

  httpCustomer
    .get(`/api/v1/customer/individual/auth/apple/tokeninfo`, {
      params: {
        idToken: data,
      },
    })
    .then((res) => {
 
      call({ res : res.data.data, loading: false, error: false });
    })
    .catch((error) => {
      call({ error, loading: false, error: false });

      if (error.response) {
        if (error.response.status === 400 && error.response.data.code == "-2") {
          UnSucceessFul_i18(
            "appletokenerror",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const RegisterBussinessPureApi = async (dispatch, data, call) => {

  httpCustomer
    .post(`/api/v1/customer/business/auth/register/web`, data.state, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
        "x-captcha-token": data.header.Captcha,
        "x-request-ts": data.header.ts
      },
    })
    .then((res) => {
      call({ res, loading: false, error: false });
 
   
    })
    .catch((error) => {

      call({ error:error.response?.data, loading: false });

    });
};

export const RegisterPureApi = async (dispatch, data, call) => {

  httpCustomer
    .post(`/api/v1/customer/individual/auth/register/web/`, data.state, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
        "x-captcha-token": data.header.Captcha,
        "x-request-ts": data.header.ts
      },
    })
    .then((res) => {
      call({ res : res.data.message });
 
    })
    .catch((error) => {

      if (error.response) {
        call({ error : error.response.data.message });
    
      }else {
        call({ error : true });

      }
    });
};

export const RegisterGoogleApi = async (dispatch, data, call) => {

  httpCustomer
    .post(`/api/v1/customer/individual/auth/google/register`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      call({ res :res.data.message });
 
    })
    .catch((error) => {

      if (error.response) {
        call({  error: error.response.data.message });
      }else{
        call({ error: true });

      }
    });
};

export const RegisterFacebookApi = async (dispatch, data, call) => {
  call({ loading: true, error: false });

  httpCustomer
    .post(`/api/v1/customer/individual/auth/facebook/register`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      call({ res :res.data.message });
 
    })
    .catch((error) => {

      if (error.response) {
        call({  error: error.response.data.message });
      }else{
        call({ error: true });

      }
    });
};

export const RegisterAppleApi = async (dispatch, data, call) => {
  call({ loading: true, error: false });

  httpCustomer
    .post(`/api/v1/customer/individual/auth/apple/register`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      call({ res :res.data.message });
 
    })
    .catch((error) => {

      if (error.response) {
        call({  error: error.response.data.message });
      }else{
        call({ error: true });

      }
    });
};

// fase 1 done
export const LoginPureApi = async (dispatch, data, call) => {

  httpCustomer
    .post(`/api/v1/customer/individual/auth/login/web/`, data.state, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
        "x-captcha-token": data.header.Captcha,
        "x-request-ts": data.header.ts
      },
    })
    .then((res) => {
      call({error: false , res : res.data.message });
 
    
    })
    .catch((error) => {
      call({
        error: error.response.data.message,
      });
    });
};

export const LoginByGooglepi = async (dispatch, data, call) => {
  call({ loading: true, error: false });

  httpCustomer
    .post(`/api/v1/customer/individual/auth/google/login`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      call({ res, loading: false, error: false });
      Set_Person_Info(res.data.data);
      dispatch(set_access_token(res.data.data.accessToken));
      SucceessFul_i18(
        "welcome",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 && error.response.data.code == "-2") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: false,
          });
          UnSucceessFul_i18(
            "googletokenerror",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
        if (error.response.status === 400 && error.response.data.code == "-3") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: true,
          });
          UnSucceessFul_i18(
            "CustomerNotRegistered",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const LoginByFacebookApi = async (dispatch, data, call) => {
  call({ loading: true, error: false });

  httpCustomer
    .post(`/api/v1/customer/individual/auth/facebook/login`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.data.data.accessToken) {
        set_Id(res.data.data.customerId);
        set_accessToken(res.data.data.accessToken);
        set_refreshToken(res.data.data.refreshToken)
        set_customerPannel_type("business")
        setTimeout(() => {
           Api_Get_AcountInformation_AGW( dispatch, {}, (call) => { });
          dispatch(set_access_token(res.data.data.accessToken));
          Api_Get_Web_Setting_AGW(dispatch, {
            customerId: res.data.data.customerId,
            refreshToken: res.data.data.refreshToken,
          });
        }, 500);
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 && error.response.data.code == "-2") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: false,
          });
          UnSucceessFul_i18(
            "facebooktokenerror",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
        if (error.response.status === 400 && error.response.data.code == "-3") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: true,
          });
          UnSucceessFul_i18(
            "CustomerNotRegistered",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const LoginByAppleApi = async (dispatch, data, call) => {
  call({ loading: true, error: false });

  httpCustomer
    .post(`/api/v1/customer/individual/auth/apple/login`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.data.data.accessToken) {
        set_Id(res.data.data.customerId);
        set_accessToken(res.data.data.accessToken);
        set_refreshToken(res.data.data.refreshToken)
        set_customerPannel_type("business")
        setTimeout(() => {
           Api_Get_AcountInformation_AGW( dispatch, {}, (call) => { });
          dispatch(set_access_token(res.data.data.accessToken));
          Api_Get_Web_Setting_AGW(dispatch, {
            customerId: res.data.data.customerId,
            refreshToken: res.data.data.refreshToken,
          });
        }, 500);
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 && error.response.data.code == "-2") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: false,
          });
          UnSucceessFul_i18(
            "appletokenerror",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
        if (error.response.status === 400 && error.response.data.code == "-3") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: true,
          });
        }
      }
    });
};

export const LoginBusinessApi = async (dispatch, data, header, call) => {
  console.log("LoginBusiness 1054")

  httpCustomer
    .post(`/api/v1/customer/business/auth/login`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
        ...header
      },
    })
    .then((res) => {
      call({ res, loading: false, error: false });
      Set_Bussiness_Info(res.data.data);
      dispatch(set_access_token(res.data.data.accessToken));
      SucceessFul_i18(
        "welcome",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      if (error.response) {
     
           call({
            code :error.response.data.code ,
            loading: false,
            error: error.response.data.message
          });
      }else{
        call({
          code :error.response.data.code ,
          error:true,
          loading: false,
        });
      }
    });
};

// fase 1 done
export const CodeVerificationApi = async (dispatch, data, header, call) => {
  call({ loading: true, error: false });

  httpCustomer
    .post(`/api/v1/customer/individual/auth/code-verification`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
        ...header
      },
    })
    .then((res) => {
      call({ res, loading: false, error: false });
      Set_Person_Info(res.data.data);
      dispatch(set_access_token(res.data.data.accessToken));
      Api_Get_Web_Setting_AGW(dispatch, {
        customerId: res.data.data.customerId,
        refreshToken: res.data.data.refreshToken,
      });
      SucceessFul_i18(
        "welcome",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 && error.response.data.code == "-5") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: false,
          });
          UnSucceessFul_i18(
            "VerificationCodeExpired",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
        if (error.response.status === 400 && error.response.data.code == "-4") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: false,
          });
          UnSucceessFul_i18(
            "InvalidVerificationCode",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }

        if (error.response.status === 400 && error.response.data.code == "-3") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: false,
          });
          UnSucceessFul_i18(
            "InvalidVerificationCode",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const ResendCodeApi = async (dispatch, data, call, header) => {
  call({ loading: true, error: false });
  console.log("xcop ts: ", header)

  httpCustomer
    .post(`/api/v1/customer/auth/code-verification/resend`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "application/json",
        "x-captcha-token": header.Captcha,
        "x-request-ts": header.ts
      },
    })
    .then((res) => {
      call({ res, loading: false, error: false });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 && error.response.data.code == "-5") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: false,
          });
          UnSucceessFul_i18(
            "SmsLimitationError",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }

        if (error.response.status === 400 && error.response.data.code == "-3") {
          call({
            error,
            loading: false,
            error: true,
            numberNotRegistered: false,
          });
          UnSucceessFul_i18(
            "CustomerNotRegistered",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_Get_OrderList_Api = async (dispatch, data, call) => {
  dispatch(
    SetOrderListData({
      extra: {
        OrderListLoading: true,
        OrderListError: false,
      },
    })
  );

  httpCustomer
    .get(`/api/v1/customer/business/order`, {
      params: {
        ...data,
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        SetOrderListData({
          list: res.data.data,
          extra: {
            OrderListLoading: false,
            OrderListError: false,
            page: res.data.data.length > 0 ? data.page : data.page - 1,
          },
        })
      );
    })
    .catch((error) => {
      dispatch(
        SetOrderListData({
          extra: {
            OrderListLoading: false,
            OrderListError: true,
          },
        })
      );
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_OrderList_Api(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

// fase 1 done
export const Customer_Get_OrderList_Api = async (dispatch, data, call) => {
  dispatch(
    SetOrderListData({
      extra: {
        OrderListLoading: true,
        OrderListError: false,
      },
    })
  );

  httpCustomer
    .get(`/api/v1/customer/order`, {
      params: {
        ...data,
        type: "Ondemand"
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        SetOrderListData({
          list: res.data.data,
          extra: {
            OrderListLoading: false,
            OrderListError: false,
            // page:res.data.data.length > 0 ? data.page : data.page-1,
          },
        })
      );

      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      dispatch(
        SetOrderListData({
          extra: {
            OrderListLoading: false,
            OrderListError: true,
          },
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_OrderList_Api(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

export const Bussiness_Get_OrderHistoryList_Api = async (
  dispatch,
  data,
  call
) => {
  dispatch(
    SetOrderHistoryListData({
      extra: {
        OrderHistoryListLoading: true,
        OrderHistoryListError: false,
      },
    })
  );

  httpCustomer
    .get(`/api/v1/customer/business/order/history`, {
      params: {
        ...data,
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        SetOrderHistoryListData({
          list: res.data.data,
          extra: {
            OrderHistoryListLoading: false,
            OrderHistoryListError: false,
            page: res.data.data.length > 0 ? data.page : data.page - 1,
          },
        })
      );
    })
    .catch((error) => {
      dispatch(
        SetOrderHistoryListData({
          extra: {
            OrderHistoryListLoading: false,
            OrderHistoryListError: true,
          },
        })
      );
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_OrderHistoryList_Api(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

// fase 1 done
export const Customer_Get_OrderHistoryList_Api = async (
  dispatch,
  data,
  call
) => {
  dispatch(
    SetOrderHistoryListData({
      extra: {
        OrderHistoryListLoading: true,
        OrderHistoryListError: false,
      },
    })
  );

  httpCustomer
    .get(`/api/v1/customer/order/history`, {
      params: {
        ...data,
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        SetOrderHistoryListData({
          list: res.data.data,
          extra: {
            OrderHistoryListLoading: false,
            OrderHistoryListError: false,
            page: res.data.data.length > 0 ? data.page : data.page - 1,
          },
        })
      );

      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      dispatch(
        SetOrderHistoryListData({
          extra: {
            OrderHistoryListLoading: false,
            OrderHistoryListError: true,
          },
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_OrderHistoryList_Api(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

export const Bussiness_Get_AboutUs = async (dispatch, data, call) => {
  dispatch(
    SetAboutUs({
      extra: {
        loading: true,
        error: false,
      },
    })
  );

  httpCustomer
    .get(`/api/v1/customer/business/about-us`, {
      params: {
        ...data,
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        SetAboutUs({
          data: res.data.data,
          extra: {
            loading: false,
            error: false,
          },
        })
      );

      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      dispatch(
        SetAboutUs({
          extra: {
            loading: false,
            error: true,
          },
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_AboutUs(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

// fase 1 done
export const Customer_Get_AboutUs = async (dispatch, data, call) => {
  dispatch(
    SetAboutUs({
      extra: {
        loading: true,
        error: false,
      },
    })
  );

  httpCustomer
    .get(`/api/v1/customer/application/about-us`, {
      params: {
        ...data,
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        SetAboutUs({
          data: res.data.data,
          extra: {
            loading: false,
            error: false,
          },
        })
      );

      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      dispatch(
        SetAboutUs({
          extra: {
            loading: false,
            error: true,
          },
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_AboutUs(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

export const Bussiness_Get_Transactions = async (dispatch, data, call) => {
  cancelQueryTransaction && cancelQueryTransaction("holy ...");

  dispatch(
    SetTransactions({
      extra: {
        OrderListLoading: true,
        OrderListError: false,
      },
    })
  );

  httpCustomer
    .get(`/api/v1/customer/business/turnovers`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelQueryTransaction = c;
      }),
      params: {
        ...data,
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      call(isEmpty(res.data.data));

      dispatch(
        SetTransactions({
          list: res.data.data,
          extra: {
            OrderListLoading: false,
            OrderListError: false,
            page: res.data.data.length > 0 ? data.page : data.page - 1,
          },
        })
      );

      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      call(true);

      dispatch(
        SetTransactions({
          extra: {
            OrderListLoading: false,
            OrderListError: true,
          },
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_Transactions(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

export const Customer_Get_Transactions = async (dispatch, data, call) => {
  cancelQueryTransaction && cancelQueryTransaction("holy ...");

  dispatch(
    SetTransactions({
      extra: {
        OrderListLoading: true,
        OrderListError: false,
      },
    })
  );

  httpCustomer
    .get(`/api/v1/customer/turnovers`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelQueryTransaction = c;
      }),
      params: {
        ...data,
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      call(isEmpty(res.data.data));

      dispatch(
        SetTransactions({
          list: res.data.data,
          extra: {
            OrderListLoading: false,
            OrderListError: false,
            page: res.data.data.length > 0 ? data.page : data.page - 1,
          },
        })
      );

      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      call(true);
      dispatch(
        SetTransactions({
          extra: {
            OrderListLoading: false,
            OrderListError: true,
          },
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_Transactions(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

export const Bussiness_Get_AccountInformation = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .get(`/api/v1/customer/business/account/${get_Id()}`, {
      params: {
        ...data,
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      Set_Permisssion(res.data.data.permissions);
      Set_Bussiness_Account_Info(res.data.data);
      dispatch(
        Set_Customer_Balance({
          balance: res.data.data.balance,
          minimumBalance: res.data.data.minimumBalance,
          currencyCode: res.data.data.currencyCode,
        })
      );
      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_AccountInformation(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

// fase 1 done
export const Customer_Get_AccountInformation = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/customer/account/${get_Id()}`, {
      params: {
        ...data,
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      Set_Person_Account_Info(res.data.data);
      dispatch(
        Set_Customer_Balance({
          balance: res.data.data.balance,
          minimumBalance: res.data.data.minimumBalance,
          currencyCode: res.data.data.currencyCode,
        })
      );

      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_AccountInformation(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};


export const Bussiness_Edit_AccountInformation = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .patch(`/api/v1/customer/business/account/${get_Id()}`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      Set_Bussiness_Account_Info(res.data.data);

      call({ res, loading: false, error: false });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Edit_AccountInformation(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

// fase 1 done
export const Customer_Edit_AccountInformation = async (dispatch, data, call) => {
  httpCustomer
    .patch(`/api/v1/customer/account/${get_Id()}`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      Set_Person_Account_Info(res.data.data);

      call({ res, loading: false, error: false })
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Edit_AccountInformation(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

// fase 1 done
export const Customer_Get_Services = async (dispatch, center) => {
  httpCustomer
    .get(`/api/v1/customer/service`, {
      params: {
        customerId: get_Id()
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(Set_Service_VehicleTypes(res.data.data));
      center &&
        Api_GetVehicleTypeByService_AGW(dispatch, {
          latitude: center.lat,
          longitude: center.lng,
          serviceId: res.data.data[0].id,
        });
      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_Services(dispatch, center);
          });
          //token exchange
        }
      }
    });
};

export const Bussiness_Get_Services = async (dispatch, center) => {
  httpCustomer
    .get(`/api/v1/customer/business/service`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(Set_Service_VehicleTypes(res.data.data));
      center &&
        Api_GetVehicleTypeByService_AGW(dispatch, {
          latitude: center.lat,
          longitude: center.lng,
          serviceId: res.data.data[0].id,
        });

      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_Services(dispatch, center);
          });
          //token exchange
        }
      }
    });
};

export const Customer_SearchAutoComplete_Api = async (dispatch, data, call) => {
  cancelQuerySearch && cancelQuerySearch("holy ...");
  dispatch(Set_SearchAutoCompleteLoading(true));

  httpCustomer
    .get(`/api/v1/customer/geocoding/autocomplete`, {
      params: data,
      cancelToken: new CancelToken(function executor(c) {
        cancelQuerySearch = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      if (call) {
        call(res.data.data);
      } else {
        dispatch(Set_SearchAutoCompleteLoading(false));
        dispatch(Set_SearchAutoComplete(res.data.data));
        // call({res , loading :false ,error :false})
      }
    })
    .catch((error) => {
      dispatch(Set_SearchAutoCompleteLoading(false));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_SearchAutoComplete_Api(dispatch, data);
          });
          //token exchange
        }
      }
    });
};

export const Bussiness_SearchAutoComplete_Api = async (
  dispatch,
  data,
  call
) => {
  cancelQuerySearch && cancelQuerySearch("holy ...");
  dispatch(Set_SearchAutoCompleteLoading(true));

  httpCustomer
    .get(`/api/v1/customer/business/geocoding/autocomplete`, {
      params: data,
      cancelToken: new CancelToken(function executor(c) {
        cancelQuerySearch = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      if (call) {
        call(res.data.data);
      } else {
        dispatch(Set_SearchAutoCompleteLoading(false));
        dispatch(Set_SearchAutoComplete(res.data.data));
      }
      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      dispatch(Set_SearchAutoCompleteLoading(false));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_SearchAutoComplete_Api(dispatch, data);
          });
          //token exchange
        }
      }
    });
};

export const Customer_PlaceIdDetails_Api = async (dispatch, data, call) => {
  dispatch(Set_SearchAutoCompleteLoading(true));

  httpCustomer
    .get(`/api/v1/customer/geocoding/details`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      call(res.data.data);

      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_PlaceIdDetails_Api(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

export const Bussiness_PlaceIdDetails_Api = async (dispatch, data, call) => {
  dispatch(Set_SearchAutoCompleteLoading(true));

  httpCustomer
    .get(`/api/v1/customer/business/geocoding/details`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      call(res.data.data);

      // call({res , loading :false ,error :false})
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_PlaceIdDetails_Api(dispatch, data, call);
          });
          //token exchange
        }
      }
    });
};

// fase 1 done
export const Customer_GetVehicleTypeByService_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .get(`/api/v1/customer/service/vehicle-type`, {
      params: {
        ...data,
        customerId: get_Id()
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      if (!_.isEmpty(res.data.data)) {
        dispatch(Set_Driver_auto_VehicleTypes(res.data.data));
      } else {
        dispatch(Set_Driver_auto_VehicleTypes([]));
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_GetVehicleTypeByService_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_GetVehicleTypeByService_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .get(`/api/v1/customer/business/service/vehicle-type/`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      if (!_.isEmpty(res.data.data)) {
        dispatch(Set_Driver_auto_VehicleTypes(res.data.data));
      } else {
        dispatch(Set_Driver_auto_VehicleTypes([]));
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_GetVehicleTypeByService_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_ondemand_promotion_verify_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/ondemand/verify-promotion`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        Set_PromoCode({
          valid: true,
          data: res.data.data,
          code : data.promoCode  ,
          error : false
        })
      );
      SucceessFul_i18(
        "PromoCodeValid",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(true)

    })
    .catch((error) => {
      call(false)

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_ondemand_promotion_verify_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
          dispatch(
            Set_PromoCode({
              valid: false,
              error : true
            })
          );
          call(false)
        }
      }
    });
};

export const Bussiness_ondemand_promotion_verify_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/business/order/ondemand/verify-promotion`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        Set_PromoCode({
          valid: true,
          data: res.data.data,
        })
      );
      SucceessFul_i18(
        "PromoCodeValid",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(true)

    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_ondemand_promotion_verify_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
          call(false)

        }
      }
    });
};

export const Customer_ondemand_promotion_set_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/ondemand/promotion`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      call(res);
      SucceessFul_i18(
        "PromoCodeValid",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_ondemand_promotion_set_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_ondemand_promotion_set_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/business/order/ondemand/promotion`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      call(res);
      SucceessFul_i18(
        "PromoCodeValid",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_ondemand_promotion_set_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_pickupDelivery_promotion_verify_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(
      `/api/v1/customer/order/pickup-delivery/verify-promotion`,
      data,
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: `Bearer ${get_accessToken()}`,
        },
      }
    )
    .then((res) => {
      dispatch(
        Set_PromoCode({
          valid: true,
          data: res.data.data,
          code : data.promoCode ,
          error : false

        })
      );
      SucceessFul_i18(
        "PromoCodeValid",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(true)

    })
    .catch((error) => {
      call(false)

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_pickupDelivery_promotion_verify_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
          dispatch(
            Set_PromoCode({
              valid: false,
              error : true
            })
          );
          call(false)

        }
      }
    });
};

export const Bussiness_pickupDelivery_promotion_verify_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/order/pickup-delivery/verify-promotion`,
      data,
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: `Bearer ${get_accessToken()}`,
        },
      }
    )
    .then((res) => {
      dispatch(
        Set_PromoCode({
          valid: true,
          data: res.data.data,
        })
      );
      SucceessFul_i18(
        "PromoCodeValid",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_pickupDelivery_promotion_verify_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Customer_pickupDelivery_promotion_set_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/pickup-delivery/promotion`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      call(res);
      SucceessFul_i18(
        "PromoCodeValid",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_pickupDelivery_promotion_set_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_pickupDelivery_promotion_set_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/business/order/pickup-delivery/promotion`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      call(res);

      SucceessFul_i18(
        "PromoCodeValid",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_pickupDelivery_promotion_set_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Customer_map_geocoding_reverse_Api = async (
  dispatch,
  data,
  call
) => {
  dispatch(
    Set_Map_Geocoding({
      loading: true,
    })
  );
  httpCustomer
    .post(`/api/v1/customer/geocoding/reverse`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        Set_Map_Geocoding({
          loading: false,
          ...res.data.data,
        })
      );
    })
    .catch((error) => {
      dispatch(
        Set_Map_Geocoding({
          loading: false,
        })
      );
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_map_geocoding_reverse_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_map_geocoding_reverse_Api = async (
  dispatch,
  data,
  call
) => {
  dispatch(
    Set_Map_Geocoding({
      loading: true,
    })
  );
  httpCustomer
    .post(`/api/v1/customer/business/geocoding/reverse`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        Set_Map_Geocoding({
          loading: false,
          ...res.data.data,
        })
      );
    })
    .catch((error) => {
      dispatch(
        Set_Map_Geocoding({
          loading: false,
        })
      );
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_map_geocoding_reverse_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_calculateOrderCost_Ondemand_Api = async (
  dispatch,
  data,
  call
) => {
  cancelcalculatetravelcost && cancelcalculatetravelcost("holy ...");

  dispatch(
    SetPriceNewOrder(
      { loading: true, price: null, done: false },
      { loading: true, price: 0, done: false }
    )
  );

  httpCustomer
    .post(`/api/v1/customer/order/ondemand/calculate-price`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancelcalculatetravelcost = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      if (res.data.code != 1) {
        dispatch(
          SetPriceNewOrder(
            { loading: false, price: res.data.data, done: true },
            {
              loading: false,
              done: true,
              ...res.data.data,
              operatorSubmit: false,
            }
          )
        );
      } else {
        Warning_NOi18(
          res.data.message,
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    })
    .catch((error) => {
      dispatch(
        SetPriceNewOrder(
          { loading: false, price: null, done: false },
          { loading: false, price: 0, done: false }
        )
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_calculateOrderCost_Ondemand_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_calculateOrderCost_Ondemand_Api = async (
  dispatch,
  data,
  call
) => {
  cancelcalculatetravelcost && cancelcalculatetravelcost("holy ...");

  dispatch(
    SetPriceNewOrder(
      { loading: true, price: null, done: false },
      { loading: true, price: 0, done: false }
    )
  );

  httpCustomer
    .post(`/api/v1/customer/business/order/ondemand/calculate-price`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancelcalculatetravelcost = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      if (res.data.code != 1) {
        dispatch(
          SetPriceNewOrder(
            { loading: false, price: res.data.data, done: true },
            {
              loading: false,
              done: true,
              ...res.data.data,
              operatorSubmit: false,
            }
          )
        );
      } else {
        Warning_NOi18(
          res.data.message,
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    })
    .catch((error) => {
      dispatch(
        SetPriceNewOrder(
          { loading: false, price: null, done: false },
          { loading: false, price: 0, done: false }
        )
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_calculateOrderCost_Ondemand_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_calculateOrderCost_PickupDelivery_Api = async (
  dispatch,
  data,
  call
) => {
  cancelcalculatetravelcost && cancelcalculatetravelcost("holy ...");

  dispatch(
    SetPriceNewOrder(
      { loading: true, price: null, done: false },
      { loading: true, price: 0, done: false }
    )
  );

  httpCustomer
    .post(
      `/api/v1/customer/order/pickup-delivery/calculate-price`,
      data,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelcalculatetravelcost = c;
        }),
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      if (res.data.code != 1) {
        dispatch(
          SetPriceNewOrder(
            { loading: false, price: res.data.data, done: true },
            {
              loading: false,
              done: true,
              ...res.data.data,
              operatorSubmit: false,
            }
          )
        );
      } else {
        Warning_NOi18(
          res.data.message,
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    })
    .catch((error) => {
      dispatch(
        SetPriceNewOrder(
          { loading: false, price: null, done: false },
          { loading: false, price: 0, done: false }
        )
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_calculateOrderCost_PickupDelivery_Api(
              dispatch,
              data,
              call
            );
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_calculateOrderCost_PickupDelivery_Api = async (
  dispatch,
  data,
  call
) => {
  cancelcalculatetravelcost && cancelcalculatetravelcost("holy ...");

  dispatch(
    SetPriceNewOrder(
      { loading: true, price: null, done: false },
      { loading: true, price: 0, done: false }
    )
  );

  httpCustomer
    .post(
      `/api/v1/customer/business/order/pickup-delivery/calculate-price`,
      data,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelcalculatetravelcost = c;
        }),
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      if (res.data.code != 1) {
        dispatch(
          SetPriceNewOrder(
            { loading: false, price: res.data.data, done: true },
            {
              loading: false,
              done: true,
              ...res.data.data,
              operatorSubmit: false,
            }
          )
        );
      } else {
        Warning_NOi18(
          res.data.message,
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    })
    .catch((error) => {
      dispatch(
        SetPriceNewOrder(
          { loading: false, price: null, done: false },
          { loading: false, price: 0, done: false }
        )
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_calculateOrderCost_PickupDelivery_Api(
              dispatch,
              data,
              call
            );
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Customer_get_balance_Api = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/customer/individual/account/${get_Id()}/wallet`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(Set_Customer_Balance(res.data.data));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_get_balance_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_get_balance_Api = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/customer/business/account/${get_Id()}`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      dispatch(
        Set_Customer_Balance({
          balance: res.data.data.balance,
          currencyCode: res.data.data.currencyCode,
        })
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_get_balance_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_Create_Ondemand_Order_Api = async (
  dispatch,
  data,
  extraData,
  call
) => {
  dispatch(SetCanSaveTravel({ loading: true }));

  httpCustomer
    .post(`/api/v1/customer/order/ondemand`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancelcalculatetravelcost = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetCanSaveTravel({ loading: false }));
      SucceessFul_i18(
        "Createdsuccessfully",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      // dispatch(
      //   AddNewOrderToList({
      //     id: res.data.data.orderId,
      //     code: "",
      //     status: "new",
      //     createdAt: new Date(),
      //     service: extraData.service,
      //     vehicleType: extraData.vehicletype,
      //     dropoffs: [
      //       ...data.dropoffs.map((drop) => {
      //         return { address: drop.address };
      //       }),
      //     ],
      //     pickup: {
      //       address: data.pickup.address,
      //     },
      //     type: "Ondemand",
      //   })
      // );
      dispatch(SetCanSaveTravel({ loading: false }));
      dispatch(ChangeNewTravelDialoge());
      dispatch(ResetNewOrder());

      // if (data.promoCode) {
      //   Api_ondemand_promotion_set_AGW(dispatch, {
      //     orderId: res.data.data.orderId,
      //     promoCode: data.promoCode,
      //   });
      // }

      ResetOrderListAfterCreateOrder(dispatch, res.data.data.orderId);

      history.push(`/order/${res.data.data.orderId}`)

    })
    .catch((error) => {
      dispatch(SetCanSaveTravel({ loading: false }));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Create_Ondemand_Order_Api(dispatch, data, extraData, call);
          });
          //token exchange
        } else if (error.response.data.code == "-15") {
          call(error.response.data.message);
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_Create_Ondemand_Order_Api = async (
  dispatch,
  data,
  extraData
) => {
  dispatch(SetCanSaveTravel({ loading: true }));

  httpCustomer
    .post(`/api/v1/customer/business/order/ondemand/`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancelcalculatetravelcost = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetCanSaveTravel({ loading: false }));
      SucceessFul_i18(
        "Createdsuccessfully",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      // dispatch(
      //   AddNewOrderToList({
      //     id: res.data.data.orderId,
      //     code: "",
      //     status :"new",
      //     createdAt: new Date(),
      //     service: extraData.service,
      //     vehicleType: extraData.vehicletype,
      //     dropoffs: [
      //       ...data.dropoffs.map((drop) => {
      //         return { address: drop.address };
      //       }),
      //     ],
      //     pickup: {
      //       address: data.pickup.address,
      //     },
      //     type: "Ondemand",
      //   })
      // );
      dispatch(SetCanSaveTravel({ loading: false }));
      dispatch(ChangeNewTravelDialoge());
      dispatch(ResetNewOrder());

      // if (data.promoCode) {
      //   Api_ondemand_promotion_set_AGW(dispatch, {
      //     orderId: res.data.data.orderId,
      //     promoCode: data.promoCode,
      //   });
      // }

      ResetOrderListAfterCreateOrder(dispatch, res.data.data.orderId);
    })
    .catch((error) => {
      dispatch(SetCanSaveTravel({ loading: false }));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Create_Ondemand_Order_Api(dispatch, data, extraData);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Customer_Create_PickupDelivery_Order_Api = async (
  dispatch,
  data,
  extraData,
  call
) => {
  dispatch(SetCanSaveTravel({ loading: true }));

  httpCustomer
    .post(`/api/v1/customer/order/pickup-delivery`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancelcalculatetravelcost = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetCanSaveTravel({ loading: false }));
      SucceessFul_i18(
        "Createdsuccessfully",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      // dispatch(
      //   AddNewOrderToList({
      //     id: res.data.data.orderId,
      //     code: "",
      //     status :"new",
      //     createdAt: new Date(),
      //     service: extraData.service,
      //     vehicleType: extraData.vehicletype || {} ,
      //     dropoff: data.dropoff,
      //     dropoffs:[],
      //     pickup: {
      //       address: data.pickup.address,
      //     },
      //     type: "PickupDelivery",
      //   })
      // );
      dispatch(SetCanSaveTravel({ loading: false }));
      dispatch(ChangeNewTravelDialoge());
      dispatch(ResetNewOrder());
      // if (data.promoCode) {
      //   Api_schedule_promotion_set_AGW(dispatch, {
      //     orderId: res.data.data.orderId,
      //     promoCode: data.promoCode,
      //   });
      // }

      ResetOrderListAfterCreateOrder(dispatch, res.data.data.orderId);

      history.push(`/order/${res.data.data.orderId}`)
    })
    .catch((error) => {
      dispatch(SetCanSaveTravel({ loading: false }));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Create_PickupDelivery_Order_Api(dispatch, data, extraData);
          });
          //token exchange
        } else if (error.response.data.code == "-15") {
          call(error.response.data.message);
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_Create_PickupDelivery_Order_Api = async (
  dispatch,
  data,
  extraData
) => {
  dispatch(SetCanSaveTravel({ loading: true }));

  httpCustomer
    .post(`/api/v1/customer/business/order/pickup-delivery/`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancelcalculatetravelcost = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetCanSaveTravel({ loading: false }));
      SucceessFul_i18(
        "Createdsuccessfully",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      // dispatch(
      //   AddNewOrderToList({
      //     id: res.data.data.orderId,
      //     code: "",
      //     status :"new",
      //     createdAt: new Date(),
      //     service: extraData.service,
      //     vehicleType: extraData.vehicletype || {} ,
      //     dropoff: data.dropoff,
      //     dropoffs:[],
      //     pickup: {
      //       address: data.pickup.address,
      //     },
      //     type: "PickupDelivery",
      //   })
      // );
      dispatch(SetCanSaveTravel({ loading: false }));
      dispatch(ChangeNewTravelDialoge());
      dispatch(ResetNewOrder());
      // if (data.promoCode) {
      //   Api_schedule_promotion_set_AGW(dispatch, {
      //     orderId: res.data.data.orderId,
      //     promoCode: data.promoCode,
      //   });
      // }
      ResetOrderListAfterCreateOrder(dispatch, res.data.data.orderId);
    })
    .catch((error) => {
      dispatch(SetCanSaveTravel({ loading: false }));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Create_PickupDelivery_Order_Api(
              dispatch,
              data,
              extraData
            );
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_Get_Web_Setting_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(
      `/api/v1/customer/application/web/setting`,
      data
        ? data
        : {
          customerId: get_Id(),
          refreshToken: get_refreshToken(),
        },
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      try {
        global.config = {
          ...global.config,
          maxDropoffCount: res.data.data.maxDropoffCount,
          stripePublicApiKey: res.data.data.stripePublicApiKey,
          applicationMap: res.data.data.applicationMap,
          MapCenter: {
            lng: res.data.data.defaultLocation[0],
            lat: res.data.data.defaultLocation[1],
          },
          // applicationMap : {type:"osm"}
        };

        const myEvent = new CustomEvent("applicationMap", {
          detail: { applicationMap: res.data.data.applicationMap },
        });
        document.dispatchEvent(myEvent);
      } catch (error) { }
      dispatch(SetWebSettings(res.data.data));

      res.data.data.defaultLocation &&
        dispatch(
          Set_latlng({
            lng: res.data.data.defaultLocation[0],
            lat: res.data.data.defaultLocation[1],
          })
        );
      res.data.data.calendarType &&
        set_calendarType(res.data.data.calendarType);
      res.data.data.currencyCode &&
        set_currencyCode(res.data.data.currencyCode);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_Web_Setting_Api(dispatch, data, call);
          });
          //token exchange
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_Get_Web_Setting_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/setting`,
      data
        ? {
          customerId: data.customerId,
          refreshToken: get_refreshToken(),
        }
        : {
          customerId: get_Id(),
          refreshToken: get_refreshToken(),
        },
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      try {
        global.config = {
          ...global.config,
          stripePublicApiKey: res.data.data.stripePublicApiKey,
          applicationMap: res.data.data.applicationMap,
          isInvoiceActive: res.data.data.isInvoiceActive,
          MapCenter: {
            lng: res.data.data.defaultLocation[0],
            lat: res.data.data.defaultLocation[1],
          },
        };

        const myEvent = new CustomEvent("applicationMap", {
          detail: { applicationMap: res.data.data.applicationMap },
        });
        document.dispatchEvent(myEvent);
      } catch (error) { }
      res.data.data.defaultLocation &&
        dispatch(
          Set_latlng({
            lng: res.data.data.defaultLocation[0],
            lat: res.data.data.defaultLocation[1],
          })
        );
      dispatch(SetWebSettings(res.data.data));
      res.data.data.calendarType &&
        set_calendarType(res.data.data.calendarType);
      res.data.data.currencyCode &&
        set_currencyCode(res.data.data.currencyCode);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_Web_Setting_Api(dispatch, data, call);
          });
          //token exchange
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_New_Payment_Order_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(`/api/v1/customer/payment/request`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      window.open(res.data.data.redirectUrl);
    })
    .catch((error) => {
      localStorage.removeItem("draft");

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_New_Payment_Order_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_New_Payment_Order_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(`/api/v1/customer/business/payment/request`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      window.open(res.data.data.redirectUrl);
    })
    .catch((error) => {
      localStorage.removeItem("draft");

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_New_Payment_Order_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_get_Order_Info_Api = async (dispatch, data, call, noLoading = false) => {
  if (!noLoading) {
    dispatch(LoadingOrderInfo(true));
  }

  httpCustomer
    .get(`/api/v1/customer/order/${data}`, {
      params: {
        // orderId: data,
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {

      if (!noLoading) {
        dispatch(LoadingOrderInfo(false));
      }
      try {
        dispatch(SetOrderInfo({ ...res.data.data, ...res.data.data.payload }));
      } catch (error) {
        dispatch(SetOrderInfo(res.data.data));
      }

      try {
        Api_GetVehicleTypeByService_AGW(dispatch, {
          latitude: res.data.data.pickup.coordinates[1],
          longitude: res.data.data.pickup.coordinates[1],
          serviceId: res.data.data.service.id,
        });
      } catch (error) { }

      // Api_get_estimated_distance_AGW(dispatch,{type:res.data.data.type , id: data})
    })
    .catch((error) => {
      if (!noLoading) {
        dispatch(LoadingOrderInfo(false));
      }

      dispatch(SetOrderInfoError(true));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_get_Order_Info_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_get_Order_Info_Api = async (dispatch, data, call) => {
  dispatch(LoadingOrderInfo(true));

  httpCustomer
    .get(`/api/v1/customer/business/order/${data}?customerId=${get_Id()}`, {
      // params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(LoadingOrderInfo(false));
      dispatch(SetOrderInfo(res.data.data));

      try {
        Api_GetVehicleTypeByService_AGW(dispatch, {
          latitude: res.data.data.pickup.coordinates[1],
          longitude: res.data.data.pickup.coordinates[1],
          serviceId: res.data.data.service.id,
        });
      } catch (error) { }

      // Api_get_estimated_distance_AGW(dispatch,{type:res.data.data.type , id: data.orderId})
    })
    .catch((error) => {
      dispatch(LoadingOrderInfo(false));

      dispatch(SetOrderInfoError(true));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_get_Order_Info_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
// there is not in post man
export const Customer_Ondemand_upload_order_service_phooto_Api = async (
  dispatch,
  data,
  orderId,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/ondemand/photo`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(res);
    })
    .catch((error) => {
      UnSucceessFul_i18(
        "errorinServer",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_upload_order_service_phooto_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
// there is not in post man
export const Customer_Schedule_upload_order_service_phooto_Api = async (
  dispatch,
  data,
  orderId,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/schedule/${orderId}/photo`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(res);
    })
    .catch((error) => {
      UnSucceessFul_i18(
        "errorinServer",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_upload_order_service_phooto_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_upload_order_service_phooto_Api = async (
  dispatch,
  data,
  orderId,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/business/order/ondemand/photo`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(res);
    })
    .catch((error) => {
      UnSucceessFul_i18(
        "errorinServer",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_upload_order_service_phooto_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_change_payment_method_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/business/order/ondemand/change-payment`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(res);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_change_payment_method_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      } else {
        UnSucceessFul_i18(
          "errorinServer",
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    });
};

export const Bussiness_PickupDelivery_change_payment_method_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/order/pickup-delivery/change-payment`,
      data,
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(res);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_change_payment_method_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      } else {
        UnSucceessFul_i18(
          "errorinServer",
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    });
};

export const Customer_Ondemand_change_payment_method_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/ondemand/change-payment`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(res);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Ondemand_change_payment_method_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      } else {
        UnSucceessFul_i18(
          "errorinServer",
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    });
};

export const Customer_Schedule_change_payment_method_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(
      `/api/v1/customer/order/pickup-delivery/change-payment`,
      data,
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(res);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Schedule_change_payment_method_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      } else {
        UnSucceessFul_i18(
          "errorinServer",
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    });
};

export const Bussiness_Set_Feedback_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/order/ondemand/rate`,
      {
        orderId: data.orderId,
        customerId: get_Id(),
        rateText: data.rateText,
        rate: data.rate,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(res);
    })
    .catch((error) => {
      UnSucceessFul_i18(
        "errorinServer",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Set_Feedback_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Customer_Set_Feedback_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(
      `/api/v1/customer/order/ondemand/rate`,
      {
        orderId: data.orderId,
        customerId: get_Id(),
        rateText: data.rateText,
        rate: data.rate,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
      call(res);
    })
    .catch((error) => {
      UnSucceessFul_i18(
        "errorinServer",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Set_Feedback_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_hasFeedbackPageSeen_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/order/ondemand/feedback`,
      {
        orderId: data.orderId,
        customerId: get_Id(),
        hasFeedbackPageSeen: true,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(res);
    })
    .catch((error) => {
      UnSucceessFul_i18(
        "errorinServer",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_hasFeedbackPageSeen_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
// there is not in post man
export const Customer_Ondemand_Set_hasFeedbackPageSeen_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(
      `/api/v1/customer/order/ondemand/feedback`,
      {
        orderId: data.orderId,
        customerId: get_Id(),
        hasFeedbackPageSeen: true,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(res);
    })
    .catch((error) => {
      UnSucceessFul_i18(
        "errorinServer",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Ondemand_Set_hasFeedbackPageSeen_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
// there is not in post man
export const Customer_Schedule_Set_hasFeedbackPageSeen_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .patch(
      `/api/v1/customer/individual/order/schedule/${data.orderId}`,
      {
        customerId: get_Id(),
        hasFeedbackPageSeen: true,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(res);
    })
    .catch((error) => {
      UnSucceessFul_i18(
        "errorinServer",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Schedule_Set_hasFeedbackPageSeen_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_get_order_trackDriver_Api = async (
  dispatch,
  orderId,
  call
) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/order/track-driver`,
      {
        customerId: get_Id(),
        orderId: orderId,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      console.log(error);
      UnSucceessFul_i18(
        "errorinServer",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_get_order_trackDriver_Api(dispatch, orderId, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Customer_get_order_trackDriver_Api = async (
  dispatch,
  orderId,
  call
) => {
  httpCustomer
    .post(
      `/api/v1/customer/order/track-driver`,
      {
        customerId: get_Id(),
        orderId: orderId,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      console.log(error);

      UnSucceessFul_i18(
        "errorinServer",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_get_order_trackDriver_Api(dispatch, orderId, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_pickupDelivery_Cancel_Order_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/business/order/pickup-delivery/cancel`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_pickupDelivery_Cancel_Order_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Bussiness_Ondemand_Cancel_Order_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/business/order/ondemand/cancel`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Ondemand_Cancel_Order_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_pickupDelivery_Cancel_Order_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/pickup-delivery/cancel`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_pickupDelivery_Cancel_Order_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};


export const Customer_pickupDelivery_Draft_Order_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/pickup-delivery/draft`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_pickupDelivery_Cancel_Order_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};


export const Customer_pickupDelivery_Confirm_Order_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/pickup-delivery/confirm`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_pickupDelivery_Cancel_Order_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_Ondemand_Cancel_Order_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/ondemand/cancel`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_get_order_trackDriver_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Customer_Schedule_get_estimated_distance_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .get(
      `/api/v1/customer/order/schedule/${data.id}/estimated-arrival`,
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      dispatch(SetEstimated(res.data.data));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Schedule_get_estimated_distance_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Customer_Ondemand_get_estimated_distance_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .get(
      `/api/v1/customer/individual/order/ondemand/${data.id}/estimated-arrival`,
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      dispatch(SetEstimated(res.data.data));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Ondemand_get_estimated_distance_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Business_Schedule_get_estimated_distance_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .get(
      `/api/v1/customer/business/order/schedule/${data.id}/estimated-arrival`,
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      dispatch(SetEstimated(res.data.data));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_Schedule_get_estimated_distance_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Business_Ondemand_get_estimated_distance_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .get(
      `/api/v1/customer/business/order/ondemand/${data.id}/estimated-arrival`,
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      dispatch(SetEstimated(res.data.data));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_Ondemand_get_estimated_distance_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_get_quick_message_Api = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/customer/chat/quick-message/`, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SETQUICKESSAGE(res.data.data));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_get_quick_message_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Business_get_quick_message_Api = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/customer/business/chat/quick-message/`, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SETQUICKESSAGE(res.data.data));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_get_quick_message_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

// fase 1 done
export const Customer_get_chats_Api = async (dispatch, data, call) => {
  dispatch(
    SETMESSAGEErrorLoading({
      loading: true,
      error: false,
    })
  );

  httpCustomer
    .get(`/api/v1/customer/chat/${data}/message/`, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SETMESSAGEErrorLoading({
          loading: false,
          error: false,
        })
      );

      dispatch(SETMESSAGETOCHATS(res.data.data.message));
    })
    .catch((error) => {
      dispatch(
        SETMESSAGEErrorLoading({
          loading: false,
          error: true,
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_get_quick_message_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Business_get_chats_Api = async (dispatch, data, call) => {
  dispatch(
    SETMESSAGEErrorLoading({
      loading: true,
      error: false,
    })
  );

  httpCustomer
    .get(`/api/v1/customer/business/chat/messages/`, {
      params: {
        orderId: data,
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SETMESSAGEErrorLoading({
          loading: false,
          error: false,
        })
      );
      dispatch(SETMESSAGETOCHATS(res.data.data.message));
    })
    .catch((error) => {
      dispatch(
        SETMESSAGEErrorLoading({
          loading: false,
          error: true,
        })
      );
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_get_quick_message_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Business_Send_Message_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(`/api/v1/customer/business/chat/send-message`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data.id);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_Send_Message_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Customer_Send_Message_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(`/api/v1/customer/chat/send-message`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data.message.id);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Send_Message_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

export const Customer_seen_message_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(
      `/api/v1/customer/chat/seen-message`,
      {
        customerId: get_Id(),
        ...data,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => { })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_seen_message_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Business_seen_message_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/chat/seen-message`,
      {
        customerId: get_Id(),
        ...data,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => { })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_seen_message_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Customer_Get_Addressbook_list_Api = async (
  dispatch,
  data,
  update
) => {
  cancelgetlistAddressbook && cancelgetlistAddressbook("holy ...");

  dispatch(
    SetAddressBookListData({
      listLoading: true,
      listError: false,
    })
  );

  httpCustomer
    .get(`/api/v1/customer/${get_Id()}/address-book`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelgetlistAddressbook = c;
      }),
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SetAddressBookListData({
          list: res.data.data,
          listLoading: false,
          listError: false,
          update: update,
          page: data.page + 1
        })
      );
    })
    .catch((error) => {
      dispatch(
        SetAddressBookListData({
          listLoading: false,
          listError: true,
        })
      );
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_Addressbook_list_Api(dispatch, data, update);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Business_Get_Addressbook_list_Api = async (
  dispatch,
  data,
  call
) => {
  cancelgetlistAddressbook && cancelgetlistAddressbook("holy ...");

  dispatch(
    SetAddressBookListData({
      listLoading: true,
      listError: false,
    })
  );

  httpCustomer
    .get(`/api/v1/customer/business/address`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelgetlistAddressbook = c;
      }),
      params: {
        ...data,
        customerId: get_Id(),
        page: data.page + 1,
      },
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SetAddressBookListData({
          list: res.data.data,
          listLoading: false,
          listError: false,
          page: data.page + 1,
          update: data.page + 1 > 1 ? true : false,
        })
      );
    })
    .catch((error) => {
      dispatch(
        SetAddressBookListData({
          listLoading: false,
          listError: true,
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_Get_Addressbook_list_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Customer_Add_Addressbook_Api = async (dispatch, data, call) => {
  call({
    loading: true,
  });

  httpCustomer
    .post(
      `/api/v1/customer/${get_Id()}/address-book`,
      {
        ...data,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call({
        loading: false,
        ok: true,
      });
    })
    .catch((error) => {
      call({
        loading: false,
        ok: false,
      });
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Add_Addressbook_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Business_Add_Addressbook_Api = async (dispatch, data, call) => {
  call({
    loading: true,
  });

  httpCustomer
    .post(
      `/api/v1/customer/business/address`,
      {
        ...data,
        isDefaultPickupAddress: false,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call({
        loading: false,
        ok: true,
      });
    })
    .catch((error) => {
      call({
        loading: false,
        ok: false,
      });
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_Add_Addressbook_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Customer_Delete_Addressbook_Api = async (dispatch, data, call) => {
  httpCustomer
    .delete(
      `/api/v1/customer/${get_Id()}/address-book/${data}`,
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(true);
    })
    .catch((error) => {
      call(false);

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Delete_Addressbook_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Business_Delete_Addressbook_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/address/${data}/delete`,
      {
        customerId: get_Id(),
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(true);
    })
    .catch((error) => {
      call(false);
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_Delete_Addressbook_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Customer_Set_Default_Addressbook_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(
      `/api/v1/customer/${get_Id()}/address-book/${data.id}/default-pickup`,
      {
        isDefaultPickupAddress: data.isDefaultPickupAddress,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(true);
    })
    .catch((error) => {
      call(false);
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_Delete_Addressbook_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Customer_Edit_Addressbook_Api = async (dispatch, data, call) => {
  httpCustomer
    .patch(
      `/api/v1/customer/${get_Id()}/address-book/${data.id}`,
      {
        ...data.pure,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(true);
    })
    .catch((error) => {
      call(false);

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Delete_Addressbook_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Business_Edit_Addressbook_Api = async (dispatch, data, call) => {
  httpCustomer
    .patch(
      `/api/v1/customer/business/address/${data.id}`,
      {
        ...data.pure,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(true);
    })
    .catch((error) => {
      call(false);
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Business_Delete_Addressbook_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          // Warning_NOi18(
          //   error.response.data.message,
          //   "networkError",
          //   "close",
          //   () => {
          //     dispatch({
          //       type: "removeNetworkErrorSnack",
          //       key: "networkError",
          //     });
          //   },
          //   new Date().getTime() + Math.random()
          // );
        }
      }
    });
};

export const Customer_Get_Order_Events_Api = async (dispatch, id) => {
  dispatch(
    Set_viewOrder_events({
      loading: true,
      error: false,
    })
  );

  httpCustomer
    .get(`/api/v1/customer/order/event`, {
      params: {
        orderId: id,
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        Set_viewOrder_events({
          loading: false,
          data: res.data.data,
        })
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_Order_Events_Api(dispatch, id);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};

export const Bussiness_Get_Order_Events_Api = async (dispatch, id) => {
  dispatch(
    Set_viewOrder_events({
      loading: true,
      error: false,
    })
  );

  httpCustomer
    .get(`/api/v1/customer/business/order/event`, {
      params: {
        orderId: id,
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        Set_viewOrder_events({
          loading: false,
          data: res.data.data,
        })
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_Order_Events_Api(dispatch, id);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};

export const Customer_GET_ETA_Api = async (dispatch, id, call) => {
  dispatch(
    Set_viewOrder_events({
      loading: true,
      error: false,
    })
  );

  httpCustomer
    .get(`/api/customer/order/ondemand/${id}/estimated-arrival`, {
      params: {
        orderId: id,
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_GET_ETA_Api(dispatch, id, call);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};

export const Bussiness_GET_ETA_Api = async (dispatch, id, call) => {
  dispatch(
    Set_viewOrder_events({
      loading: true,
      error: false,
    })
  );

  httpCustomer
    .post(
      `/api/v1/customer/business/order/ondemand/estimated-arrival`,
      {
        orderId: id,
        customerId: get_Id(),
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_GET_ETA_Api(dispatch, id, call);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};

export const Customer_add_Payment_Method_Api = async (call, dispatch) => {
  httpCustomer
    .post(
      `/api/v1/customer/payment/stripe/payment-method`,
      {
        customerId: get_Id(),
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: `Bearer ${get_accessToken()}`,
        },
      }
    )
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_add_Payment_Method_Api(call, dispatch);
          });
        } else if (error.response.status === 403) {
          // localStorage.clear()
          // window.location.reload();
        } else {
        }
      }
    });
};

export const Bussiness_add_Payment_Method_Api = async (call, dispatch) => {
  httpCustomer
    .post(
      `/api/customer/business/payment/stripe/payment-method`,
      {
        customerId: get_Id(),
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: `Bearer ${get_accessToken()}`,
        },
      }
    )
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_add_Payment_Method_Api(call, dispatch);
          });
        } else if (error.response.status === 403) {
          // localStorage.clear()
          // window.location.reload();
        } else {
        }
      }
    });
};

export const Customer_get_Payment_Methods_Api = async (dispatch, call) => {
  httpCustomer
    .get(`/api/v1/customer/payment/stripe/payment-method`, {
      params: {
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_get_Payment_Methods_Api(dispatch, call);
          });
        } else if (error.response.status === 403) {
          // localStorage.clear()
          // window.location.reload();
        } else {
        }
      }
    });
};

export const Bussiness_get_Payment_Methods_Api = async (dispatch, call) => {
  httpCustomer
    .get(`/api/customer/business/payment/stripe/payment-method`, {
      params: {
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_get_Payment_Methods_Api(dispatch, call);
          });
        } else if (error.response.status === 403) {
          // localStorage.clear()
          // window.location.reload();
        } else {
        }
      }
    });
};

export const Customer_Delete_Payment_Method_Api = async (
  dispatch,
  paymentMethodId,
  call
) => {
  httpCustomer
    .post(
      `/api/v1/customer/payment/stripe/payment-method/detach`,
      {
        customerId: get_Id(),
        paymentMethodId,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 400) {
          call({}, error.response.status, error.response.data.message);
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Delete_Payment_Method_Api(dispatch, paymentMethodId, call);
          });
        } else if (error.response.status === 403) {
          // localStorage.clear()
          // window.location.reload();
        } else {
        }
      }
    });
};

export const Bussiness_Delete_Payment_Method_Api = async (
  dispatch,
  paymentMethodId,
  call
) => {
  httpCustomer
    .post(
      `/api/customer/business/payment/stripe/payment-method/detach`,
      {
        customerId: get_Id(),
        paymentMethodId,
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 400) {
          call({}, error.response.status, error.response.data.message);
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Delete_Payment_Method_Api(
              dispatch,
              paymentMethodId,
              call
            );
          });
        } else if (error.response.status === 403) {
          // localStorage.clear()
          // window.location.reload();
        } else {
        }
      }
    });
};

export const Customer_Get_Support_Message_Api = async (dispatch) => {
  httpCustomer
    .get(`/api/v1/customer/support/message`, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetSupportData(res.data.data));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 400) {
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_Support_Message_Api(dispatch);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};

export const Bussiness_Get_Support_Message_Api = async (dispatch) => {
  httpCustomer
    .get(`/api/v1/customer/business/support/message`, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetSupportData(res.data.data));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 400) {
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_Support_Message_Api(dispatch);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};

export const Customer_Reply_Support_Message_Api = async (dispatch, data) => {
  httpCustomer
    .post(`/api/v1/customer/support/message/reply`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetSelectedSupportData(null));
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 400) {
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Reply_Support_Message_Api(dispatch, data);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};

export const Bussiness_Reply_Support_Message_Api = async (dispatch, data) => {
  httpCustomer
    .post(`/api/v1/customer/business/support/message/reply`, data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetSelectedSupportData(null));
      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status == 400) {
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Reply_Support_Message_Api(dispatch, data);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};

export const Customer_Get_MessageBox_Api = async (dispatch, data, call) => {
  dispatch(
    SetMessageBoxData({
      extra: {
        ListLoading: true,
        ListError: false,
      },
    })
  );

  httpCustomer
    .get(`/api/customer/individual/account/${get_Id()}/message-box`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(isEmpty(res.data.data));

      dispatch(
        SetMessageBoxData({
          data: res.data.data,
          extra: {
            ListLoading: false,
            ListError: false,
            page: res.data.data.length > 0 ? data.page : data.page - 1,
          },
        })
      );
    })
    .catch((error) => {
      call(true);
      dispatch(
        SetMessageBoxData({
          extra: {
            ListLoading: false,
            ListError: true,
          },
        })
      );
      if (error.response) {
        if (error.response.status == 400) {
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_MessageBox_Api(dispatch, data, call);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};

export const Bussiness_Get_MessageBox_Api = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/customer/business/account/${get_Id()}/message-box`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(isEmpty(res.data.data));

      dispatch(
        SetMessageBoxData({
          data: res.data.data,
          extra: {
            ListLoading: false,
            ListError: false,
            page: res.data.data.length > 0 ? data.page : data.page - 1,
          },
        })
      );
    })
    .catch((error) => {
      call(true);
      dispatch(
        SetMessageBoxData({
          extra: {
            ListLoading: false,
            ListError: true,
          },
        })
      );

      if (error.response) {
        if (error.response.status == 400) {
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_MessageBox_Api(dispatch, data, call);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};

export const Bussiness_change_password = async (dispatch, data, call) => {
  httpCustomer
    .post(
      `/api/customer/business/account/${get_Id()}/change-password`,
      data,
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(true);

      SucceessFul_i18(
        "SucceessFul",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    })
    .catch((error) => {
      call(false);


      if (error.response) {
        if (error.response.status == 400) {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Bussiness_Get_MessageBox_Api(dispatch, data, call);
          });
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else {
        }
      }
    });
};



export const Get_initialSetting_Api = async (call) => {
   httpCustomer
    .post(
      `${/*window.location.href*/""}${"https://onro.dispatcher.onro.app/"}api/dispatcher/initial-setting`,
      {},
      {
        headers: {
          "Accept-Language": get_lang(),
          "Content-type": "application/json",
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      call(res.data.data)
    })
    .catch((error) => {
      call(null)


      if (error.response) {
        if (error.response.status == 400) {

        } else {

        }
      };

    })
  }
      

export const Customer_ondemand_optimize_dropoffs_Api = async (
  dispatch,
  data,
  call
) => {
  httpCustomer
    .post(`/api/v1/customer/order/ondemand/optimize-dropoffs`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      },
    })
    .then((res) => {
      call()
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_ondemand_promotion_verify_Api(dispatch, data, call);
          });
          //token exchange
        } else {
          call()
        }
      }
    });
};





export const BulkImportList = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/customer/order/bulk-import/`, {
      params: {},
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetBulkImportList(res.data.data))
    })
    .catch((error) => {
      call({ loading: false });

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          // dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            BulkImportList(dispatch, data, call);
          });
        }
      }
    });
};





export const BulkImportCsvUpload = async (dispatch, data, id, call) => {

  let percent = 0

  httpCustomerUpload
    .post(`/api/v1/customer/order/bulk-import/csv`, data, {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        percent = Math.floor((loaded * 100) / total)
        // just to see whats happening in the console
        if (percent) {
          dispatch(SetActiveImportData({
            id,
            percentUpload: percent
          }))
        }
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken()
      },
    })
    .then((res) => {

      dispatch(SetActiveImportData({
        id,
        uploading: false,
        uploaded: true,
        status: "processing",
        fromServer: true,
        percentUpload: 100
      }))

      BulkImportGetInfo(dispatch, res.data.data.id, id)
    })
    .catch((error) => {
      dispatch(SetActiveImportData({
        id,
        uploading: false,
        uploaded: false,
        status: "draft"

      }))

      if (error.response.status === 400) {
        // snack.warning(error.response.data.message)
        Warning_NOi18(
          error.response.data.message,
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );

      } else {
        // snack.warning_i18("serverError")
        Warning_NOi18(
          error.response.data.message,
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );

      }


      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          // dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            BulkImportList(dispatch, data, call);
          });
        }
      }
    });
};


export const BulkImportGetInfo = async (dispatch, id, localId) => {


  httpCustomer
    .get(`/api/v1/customer/order/bulk-import/${id}`, {
      params: {},
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      if (localId) {
        dispatch(Create_new_BulkImport_List({
          ...res.data.data,
          info: res.data.data
        }))
        dispatch(SetActiveImport(id, "imported"))
        dispatch(Remove_draft_bulkImport(localId))

      } else {
        dispatch(SetActiveImportDataList({
          id,
          status: res.data.data.status,
          info: res.data.data
        }))
      }



    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          // dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            BulkImportGetInfo(dispatch, id);
          });
        }
      }
    });
};



export const BulkImport_GetFailedRecords = async (dispatch, id, fileName) => {
  httpCustomer
    .get(`/api/v1/customer/order/bulk-import/${id}/failed-records/csv`, {
      params: {},
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      FileDownload(res.data, `${fileName}-errors.csv`)
    })
    .catch((error) => {

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          // dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            BulkImport_GetFailedRecords(dispatch, id, fileName);
          });
        }
      }
    });
};




export const VehicletypeForMonitoring = async (dispatch, data, func) => {
  httpCustomer
    .get(`/api/v1/dispatcher/service/vehicle-type`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      // dispatch(OrderListFilterVehicletypes(res.data.data))

      if (!_.isEmpty(res.data.data)) {
        // dispatch(MonitoringSet_VehicleTypes(res.data.data));
        func(res.data.data)

      } else {
        func(false)
        // dispatch(MonitoringSet_VehicleTypes([]));

      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          // dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            VehicletypeForMonitoring(dispatch, data, func);
          });
        }
      }
    });
};


export const ServicesVehicletype = async (dispatch, center, customerId) => {
  httpCustomer
    .get(`/api/v1/dispatcher/service`, {
      params: {
        customerId: customerId,
      },

      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      // dispatch(Set_Service_VehicleTypes(res.data.data));
      // dispatch(OrderListFilterService(res.data.data))
      // dispatch(
      //   GetVehicletypeByServiceAction(dispatch, {
      //     latitude: center.lat,
      //     longitude: center.lng,
      //     serviceId: res.data.data[0].id,
      //     customerId
      //   }, (vt) => {
      //     if (vt) {
      //       // dispatch(Set_Auto_ActiveVehicleType(vt[0]));
      //     }

      //   })
      // );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          // dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            ServicesVehicletype(dispatch, center, customerId);
          });
        }
      }
    });
};




export const BatchUpdateStatus = async (data) => {

  try {

    const response = await httpCustomer.request({
      method: 'post',
      url: `/api/v1/customer/order/pickup-delivery/batch/update-status`,
      data,
      headers: {
        Authorization: "Bearer " + get_accessToken(),
      },
    });


    return response.data.data

  } catch (error) {
    if (error.response && error.response.status === 403) {
      localStorage.clear()
      window.location.reload();
    } else if (error.response) {
      return Promise.reject({
        code: error.response.data.code,
        message: error.response.data.message,
        status: error.response.status,
      })
    } else {
      return Promise.reject()
    }
  }


};



export const GetCancellationReasonsList = async (call) => {
  try {

    // if (typeof cancelToken != typeof undefined) {
    //   cancelToken.cancel("Operation canceled due to new request.")
    // }
    // cancelToken = Axios.CancelToken.source()


    const response = await httpCustomer.request({
      method: 'get',
      url: `/api/v1/customer/order/pickup-delivery/cancellation-reason`,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    });


    return response.data.data

  } catch (error) {
    if (error.response) {
      if (error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }
  }

};


export const Customer_get_Order_Info_Api_PD_Update = async (dispatch, data) => {
  httpCustomer
    .get(`/api/v1/customer/order/${data}`, {
      params: {
        // orderId: data,
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {

      dispatch(Set_Order_Info_Api_PD_Update(res.data.data))

    })
    .catch((error) => {

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_get_Order_Info_Api(dispatch, data);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};


export const Customer_get_Order_Info_Api_call = async (data, call) => {

  httpCustomer
    .get(`/api/v1/customer/order/${data}`, {
      params: {
        // orderId: data,
        customerId: get_Id(),
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data)
    })
    .catch((error) => {

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_get_Order_Info_Api_call(data, call);
          });
          //token exchange
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {

            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};


export const Customer_Get_receipt = async (id, func) => {
  httpCustomer
    .get(`/api/v1/customer/order/receipt`, {
      params: {
        customerId: get_Id(),
        orderId: id
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      func(res.data.data)
    })
    .catch((error) => {

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          // dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            Customer_Get_receipt(id, func);
          });
        }
      }
    });

  }


  export const CustomerCodeVerificationApi = async (dispatch, data, header, call) => {
  
    httpCustomer
      .post(`/api/v1/customer/auth/code-verification`, data, {
        headers: {
          "Accept-Language": get_lang(),
          "Content-Type": "application/json",
          ...header
        },
      })
      .then((res) => {
        call({ res, loading: false, error: false });
        Set_Person_Info(res.data.data);
        dispatch(set_access_token(res.data.data.accessToken));
        Api_Get_Web_Setting_AGW(dispatch, {
          customerId: res.data.data.customerId,
          refreshToken: res.data.data.refreshToken,
        });
        SucceessFul_i18(
          "welcome",
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      })
      .catch((error) => {
        if (error.response) {
          call({
            error:error.response.data.message,
            loading: false,
            numberNotRegistered: false,
          });
        }
      });
  };
  


  export const Bussiness_Token_Login = async (idToken , dispatch,func) => {
    httpCustomer
      .post(
        `/api/v1/customer/business/auth/google/login`,
        {
          idToken: idToken,
        },
        {
          headers: {
            "Accept-Language": "en",
          },
        }
      )
      .then((res) => {
        if (res.data.data.accessToken) {
          set_Id(res.data.data.customerId);
          set_accessToken(res.data.data.accessToken);
          set_refreshToken(res.data.data.refreshToken)
          set_customerPannel_type("business")
          setTimeout(() => {
            func();
            Api_Get_AcountInformation_AGW( dispatch, {}, (call) => { });
            dispatch(set_access_token(res.data.data.accessToken));
            Api_Get_Web_Setting_AGW(dispatch, {
              customerId: res.data.data.customerId,
              refreshToken: res.data.data.refreshToken,
            });
          }, 500);
        }
      })
      .catch((error) => {
        snack.error(error.response.data.message)

      });
};


export const Bussiness_Login_ForgetPassword = async (idToken, dispatch, func) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/auth/password-reset/request`,
      {
        email: idToken,
      },
      {
        headers: {
          "Accept-Language": "en",
        },
      }
    )
    .then((res) => {
      func({res:res.data})
    })
    .catch((error) => {
      func({error : error})

      snack.error(error.response.data.message)

    });
};




export const Bussiness_register_google = async (data, dispatch, func) => {
  httpCustomer
    .post(
      `/api/v1/customer/business/auth/google/register`,
      {
        ...data
      },
      {
        headers: {
          "Accept-Language": "en",
          },
        }
      )
      .then((res) => {
        if (res.data.data.accessToken) {
          set_Id(res.data.data.customerId);
          set_accessToken(res.data.data.accessToken);
          set_refreshToken(res.data.data.refreshToken)
          set_customerPannel_type("business")
          setTimeout(() => {
            Api_Get_AcountInformation_AGW( dispatch, {}, (call) => { });
            dispatch(set_access_token(res.data.data.accessToken));
            Api_Get_Web_Setting_AGW(dispatch, {
              customerId: res.data.data.customerId,
              refreshToken: res.data.data.refreshToken,
            });
          }, 500);
        }
        func({
          error:false,
          res:res.data
        })
      })
      .catch((error) => {
        snack.error(error.response.data.message)
        func({
          error:true,
          res:error
        })
      });
  };