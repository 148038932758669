import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { SetReferenceID } from "../../../../Redux/actions/actionNames";
import { HASHTAG ,INFO_CIRCLE } from "../../../../static";
import Input from "../../../../design-system/lib/Input";
import TooltipOnro from "../../../../design-system/lib/Tooltip";
import Typography from "../../../../design-system/lib/Typography";
import IconProvider from "../../../../design-system/lib/IconProvider";
import { filter } from "lodash";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.inputOnchange = this.inputOnchange.bind(this)
  }


  inputOnchange(e){
    this.props.dispatch(SetReferenceID(e.target.value))
  }
  


  render() {
    const { t } = this.props;

    if(!(this.props.serviceactiveVehicleType && this.props.serviceactiveVehicleType.setting.isReferenceIdActive) ){
      return null
    }

    return (
      <Input Tooltip={<> <TooltipOnro tooltipClassName={this.props.TooltipOnro} title={<Typography weight="Body_Small_Medium" text={this.props.t("refid_tooltip")} />} placement="top-end" >
        <img src={INFO_CIRCLE} style={{filter:"grayscale(1)" , width:"16px"}} />
      </TooltipOnro></>} ContainerclassName={this.props.inputContainerclassName} value={this.props.referenceID ? this.props.referenceID : ""} IconStyle={{ width: "16px", height: "16px" }} type={"text"} iconStart={HASHTAG} placeHolder={t("ReferenceID")} onChange={this.inputOnchange} />

      // <div className="codContainer" style={{maxWidth:"calc(50% - 10px)"}}>
      //     <span> {t("ReferenceID")} </span>
      //     <input min={0} onChange={this.inputOnchange} placeholder={t("EnterId")} style={{maxWidth:"calc(100% - 46px)"}} />
      // </div>
    
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  referenceID:state.NewOrder.newOrder.referenceID ,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
