import { useState, useEffect, useRef, memo } from "react";
import useScript from "../hook/useScript";
import googlelogo from "./../../static/icon-google.svg";
import { get_lang } from "../UserData/userdate";

const GoogleLogin = memo(({ onSuccess , id , lang}) => {
  console.log("foptbvyu 12");

  const googleSignInButton = useRef(null);
  const [googleLoaded, setGoogleLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://accounts.google.com/gsi/client";
    script.onload = ()=>{
         console.log("foptbvyu 17");
    window.google.accounts.id.initialize({
      client_id: global.config.googleLoginClientId,
      callback: onSuccess,
    });

    setGoogleLoaded(true);
    window.google.accounts.id.renderButton(
      googleSignInButton.current,
      {
        locale: get_lang(),
        type: "standard",
        theme: "outline",
        size: "large",
        logo_alignment: "center",
        width: "300px",
        height: "50px",
        text: "continue_with",
      } // customization attributes
    );
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [id , lang]);

 


  return <div ref={googleSignInButton}></div>;
});

export default GoogleLogin;