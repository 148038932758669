export const CzechLabel = {
   Cutomerpanel: 'Zákaznický portál',
   login: 'Přihlášení',
   Register: 'Registrace',
   en: 'Angličtina',
   fa: 'Perština',
   Person: 'Osoba',
   Business: 'Podnik',
   Phonenumber: 'Telefonní číslo',
   Continue: 'Pokračovat',
   phonenumberhasnotregisterd: 'Telefonní číslo nebylo zaregistrováno',
   Username: 'Uživatelské jméno',
   title: 'Název',
   Password: 'Heslo',
   ExOnro: 'Např.. společnost',
   Exstart: 'Např. ******',
   googleConnected: 'Připojeno ke službě Google',
   facebookConnected: 'Připojeno k Facebooku',
   appleConnected: 'Apple Id připojen',
   plsenterphonnumber: 'Zadejte prosím své telefonní číslo',
   businessNamePlaceholder: 'Např. ABC Company',
   registerBusinessNameLabel: 'Obchodní název',
   firstnnameloginplaceholder: 'Např. Jan',
   lastnameloginplaceholder: 'Např. Novák',
   Firstname: 'Křestní jméno',
   Lastname: 'Příjmení',
   email: 'E-mail',
   emailPlaceholder: '(nepovinné) Např. example@example.com',
   comingsoon: 'Již brzy :',
   comingsoondesc: 'Chcete-li vytvořit obchodní účet, kontaktujte nás',
   CustomerAlreadyRegistered: 'Zákazník se již zaregistroval, přihlaste se prosím',
   registerCompleted: 'Zadejte ověřovací kód',
   googletokenerror: 'Problém s tokenem Google',
   facebooktokenerror: 'Problém s tokenem Facebook',
   appletokenerror: 'Problém s tokenem Apple',
   CustomerNotRegistered: 'Zákazník není registrován',
   fr: 'francouzština',
   de: 'Němčina',
   InvalidVerificationCode: 'Neplatný ověřovací kód',
   a: 'Vaše údaje nebyly nalezeny',
   welcome: 'Vítejte',
   SmsLimitationError: 'Zkuste to prosím za chvíli znovu',
   resendCodeWithTime: 'znovu zaslat kód (xxx)',
   resendCode: 'znovu zaslat kód',
   VerificationcodePlaceHolder: 'Např. 1234',
   Verificationcode: 'Ověřovací kód',
   VerificationcodeText: 'Zadejte kód zaslaný na xxx',
   VerificationCodeExpired: 'Platnost ověřovacího kódu vypršela',
   Pending: 'Ve frontě',
   Unassigned: 'Nepřiřazeno',
   Assigned: 'Probíhá',
   'Em curso': 'Toegewezen',
   PickedUp: 'Probíhá',
   Started: 'Probíhá',
   statusInProgress: 'Probíhá',
   Done: 'Hotovo',
   CustomerCanceled: 'Zrušeno',
   DriverCanceled: 'Zrušeno',
   SupportCanceled: 'Zrušeno',
   Arrived: 'Probíhá',
   new: 'nové',
   Historyofyourorderswillbehere: 'Historie vašich objednávek bude zde',
   otherdropoffs: 'další doručení',
   Orderhistory: 'Historie objednávek',
   Noorder: 'Bez pořadí',
   loadingList: 'Buďte trpěliví při získávání informací',
   loadingError: 'Problém se získáváním informací',
   retry: 'Zkusit znovu',
   Create_your_first_requst: 'Vytvořit novou objednávku',
   List_of_you_active_orders_will_be_here: 'Seznam vašich aktivních objednávek bude zde',
   Aboutus: 'O nás',
   Readour: 'Přečtěte si naše',
   privaypolicy: 'zásady ochrany osobních údajů',
   Transactions: 'Transakce',
   NoTransaction: 'Nemáte žádnou transakci',
   Historyofyourransactionswillbehere: 'Historie vašich transakcí bude zde',
   Settings: 'Nastavení',
   Language: 'Jazyk',
   English: 'Angličtina',
   Gereman: 'Perština',
   French: 'Francoužština',
   Logout: 'Odhlášení',
   logotTitle: 'Odhlásit se z účtu?',
   logoutDescription: 'Budete odhlášeni z účtu a měli byste se přihlásit později.',
   confirmLogout: 'Ano, odhlásit se',
   skipLogout: 'Zrušit',
   savesettings: 'Uložit nastavení',
   emailInvalid: 'e-mail je neplatný',
   Neworder: 'Nová objednávka',
   Service: 'Služba',
   poperLoading: 'Čekejte prosím',
   Pickup: 'Vyzvednutí',
   SetPickup: 'Nastavit místo vyzvednutí',
   ChooseoOnMap: 'Vybrat na mapě',
   Fornow: 'Ihned',
   Scheduled: 'Naplánované',
   before: 'Před :',
   after: 'Po :',
   confirm: 'Potvrdit',
   Settime: 'Nastavit čas',
   fo: 'pro',
   Cancel: 'Zrušit',
   Pleasetryanotherkeyword: 'Zkuste jiné klíčové slovo',
   Noinformationfound: 'Nebyly nalezeny žádné informace',
   address: 'Adresa',
   block: 'Blok',
   Floor: 'Patro',
   Unit: 'Jednotka',
   senderfullname: 'Jméno odesílatele',
   senderphonenumber: 'Číslo odesílatele',
   notesfordriver: 'Poznámka pro řidiče',
   DropOff: 'Doručení',
   SetDropOff: 'Adresa doručení',
   fetchingdata: 'Získávání informací...',
   Recieverfullname: 'Jméno příjemce',
   Recieverphonenumber: 'Číslo příjemce',
   PromotionCode: 'Promo kód',
   Delete: 'Samazat',
   Save: 'Uložit',
   PromoCodeValid: 'Promo kód uložen',
   SenderWallet: 'Peněženka',
   SenderCash: 'Hotovost',
   ReceiverCash: 'Platí příjemce',
   lowBalance: 'Příliš nízký zůstatek kreditu',
   Balance: 'peněženka',
   Methods: 'Metody',
   balanceError: 'Zůstatek kreditu musí být minimálně stejný jako cena za doručení.',
   SendOrder: 'Odeslat objednávku',
   feedback: 'Zpětná vazba',
   Feedback: 'Zpětná vazba',
   Orderagain: 'Objednat znovu',
   Createdsuccessfully: 'Úspěšně vytvořeno',
   chooseAmount: 'Zvolte částku',
   orenterthepreferedamount: 'Preferovaná částka:',
   enteramount: 'požadovaná částka',
   pay: 'Zaplatit',
   selectPaymentGateway: 'Vyberte platební bránu',
   Time_MachineText: 'Chcete obnovit dříve nedokončené informace o objednávce?',
   yes: 'Ano',
   ignore: 'ignorovat',
   Order: 'Objednávka',
   statusPending: 'Ve frontě',
   statusUnassigned: 'Nepřiřazeno',
   statusAssigned: 'Přirazeno',
   statusStarted: 'Zahájeno',
   statusPickedUp: 'Vyzvednuto',
   statusDone: 'Hotovo',
   statusCustomerCanceled: 'Zrušeno zákazníkem',
   statusDriverCanceled: 'Zrušeno řidičem',
   statusArrived: 'Řidič dorazil',
   statusSupportCanceled: 'Zrušeno podporou',
   statusCanceled: 'Zrušeno',
   Phone: 'Telefon',
   additonalservice: 'doplňkové služby',
   CallDriver: 'Zavolat řidiči',
   Message: 'Zpráva',
   orderInfoError: 'Chyba při získávání informací',
   support: 'Podpora',
   map: 'Mapa',
   receipt: 'Doklad',
   photo: 'fotografie',
   ServicePhoto: 'Fotografie služby',
   Addphoto: 'Přidat fotografii',
   Estime: 'Odhadovaný čas',
   Espath: 'Odhadovaná vzdálenost',
   pod: 'Důkaz o doručení',
   Notes: 'Poznámky',
   Photo: 'Fotografie',
   Signature: 'Podpis',
   Nonotes: 'Žádné poznámky',
   Nosignature: 'Žádný podpis',
   Nophoto: 'Žádná fotografie',
   Price: 'Cena',
   Receiver: 'Příjemcem',
   Sender: 'Odesílatelem',
   Cash: 'Hotovost',
   Canceltravel: 'Zrušit žádost',
   CancellText: 'Po zrušení se objednávka pro řidiče zruší.',
   Estimated: 'Odhadovaný',
   Wallet: 'Zůstatek',
   Copylink: 'Kopírovat odkaz',
   repeatOrder: 'Opakovat objednávku',
   Edit: 'Upravit',
   confirmCancel: 'Ano',
   paymentmethod: 'Platební metoda',
   SucceessFul: 'Hotovo',
   errorinServer: 'Server problém',
   Comment: 'Komentář',
   Exfeedbackinput: 'Např. doručil velmi rychle',
   Wallett: 'Peněženka',
   TypeHere: 'Napište zde',
   Loadingmessages: 'Načítání zpráv',
   PoweredBy: 'PoweredBy Delivy',
   newincomemessage: 'Přišla Vám nová zpráva',
   newincomemessageanother: 'Přišla Vám nová zpráva v jiné objednávce',
   youhaveunreadmessage: 'Nepřečtená zpráva',
   Vehicletype: 'Typ vozidla',
   Pickup2: 'Vyzvednutí',
   Dropoff2: 'Doručení',
   Events: 'Události',
   allEvents: 'Všechny události',
   star: 'hvězda',
   Yourrate: 'Vaše hodnocení',
   CopyOrderlink: 'Kopírovat odkaz na objednávku',
   CopyPickuplink: 'Kopírovat odkaz pro vyzvednutí',
   CopyDeliverylink: 'Kopírovat odkaz pro doručení',
   Nointernetconnection: 'Žádné připojení k internetu',
   close: 'zavřít',
   seen: 'Viděno',
   controlThatbeforeLoweEqualAfter: 'Čas „Před :“ nesmí být kratší než „Po :“',
   controlThatbeforeAndAfterMustBiggerthanCurrentDate: 'Časy „před:“ a „po:“ nemohou být kratší než aktuální čas',
   errorInPanelSetting: 'Chyba v nastavení rezervace, kontaktujte prosím podporu',
   Arabic: 'Arabština',
   ar: 'Arabština',
   veeDeliveryLoginText1: 'Odeslat cokoliv',
   veeDeliveryLoginText2: 'Komukoliv, TEĎ!',
   Schedule: 'Naplánovat',
   Date: 'Datum',
   Time: 'Čas',
   noDateToShow: 'Není k dispozici žádné datum k zobrazení',
   noTimeToShow: 'Není k dispozici žádný čas na zobrazení',
   Apply: 'Použít',
   Increaseyourbalance: 'Navyšte zvůj zůstatek',
   AddBalance: 'Přidat kredit',
   Addressbook: 'Adresář',
   AddressbookSearchbar: 'Vyhledávání podle adresy, názvu a kódu',
   Removedefault: 'Vyhledávání podle adresy, názvu a kódu',
   Setdefaultpickup: 'Nastavit výchozí vyszvednutí',
   Remove: 'Odstranit',
   newAddress: 'Nová adresa',
   NewAddressbookInfoTitle: 'Info',
   Addressdetails: 'Údaje o adrese',
   Title: 'Název',
   Code: 'Kód',
   editAddress: 'Upravit adresu',
   AddressbookDropOf: 'Adresář (doručení)',
   AddressbookPickup: 'Adresář (vyzvednutí)',
   hide: 'Skrýt',
   Nosavedaddress: 'Žádné uložené adresy',
   NosavedaddressDesc: 'Můžete si ji vytvořit kliknutím na ikonu záložky vedle adresy nebo přejít na stránku adresáře.',
   Romanian: 'Rumunština',
   ro: 'Rumunština',
   Dutch: 'Nizozemština',
   Shipmentlabel: 'Štítek zásilky',
   Name: 'Jméno',
   CashOnDelivery: 'Platba hotově při doručení',
   EnterAmount: 'Zadejte částku',
   COD: 'Hotovost při doručení',
   Surchargexxx: 'Příplatek xxx',
   Pricedetails: 'Detaily ceny',
   minxxx: 'xxx min',
   toPickup: 'na vyzvednutí',
   toDropOff: 'na doručení',
   AddCreditCard: 'Přidat kreditní/debetní kartu',
   CustomerWallet: 'Zákaznická peněženka',
   Add: 'Přidat',
   Addedsuccessfully: 'Úspěšně přidáno',
   paymentmethods: 'Platební metody',
   NoCards: 'Žádné karty',
   Listofyourcardswillbehere: 'Seznam vašich karet bude zde',
   CardInfo: 'Informace o kartě',
   ExpireDate: 'Datum expirace',
   CVC: 'CVC',
   PostalCode: 'PSČ',
   RemoveCardq: 'Odstranit kartu?',
   RemoveCardA: 'Karta bude odebrána a nebude ji možné dále používat.',
   YesRemove: 'Ano, odstranit',
   Cardisinuse: 'Karta je používána',
   ok: 'Ok',
   Pleasetypeyourwordstosearch: 'Zadejte prosím klíčové slovo pro vyhledávání',
   spanish: 'Španělština',
   es: 'Španělština',
   Invoices: 'Faktury',
   noInvoice: 'Č. xxx',
   germany: 'Němčina',
   nl: 'Holandština',
   downloadClientApplication: 'Stáhnout aplikaci xxx',
   AssignedAt: 'Přiřadit do xxx',
   StartedAt: 'Zahájit do xxx',
   ArrivedAt: 'Dorazit do xxx',
   PickedUpAt: 'Vyzvednout do xxx',
   DoneAt: 'Dokončit do xxx',
   Russian: 'Ruský',
   Azerbaijani: 'Ázerbájdžánština',
   rus: 'Ruský',
   aze: 'Ázerbájdžánština',
   Support: 'Podpora',
   SendRequest: 'Odeslat žádost',
   CallTheSupport: 'Zavolejte na podporu',
   Choosethesubject: 'Vyberte si předmět',
   Seefrequentquestions: 'Podívejte se na časté dotazy',
   Details: 'Podrobnosti',
   Send: 'Odeslat',
   MessageBox: 'Centrum zpráv',
   NoMessages: 'Žádné zprávy',
   Yourmessageswillbehere: 'Vaše zprávu se zobrazí tady',
   Portuguese: 'Portugalština',
   pt: 'Portugalština',
   Greek: 'Řečtina',
   el: 'Řečtina',
   individual: 'individuální',
   comingsoondesc2: 'Chcete-li vytvořit firemní účet, kontaktujte nás',
   contactUs: 'kontaktujte nás',
   changePassword: 'Změna hesla',
   changePasswordp1: 'Všechny relace, s výjimkou této, opustíte, abyste ochránili svůj účet, který se pokouší o přístup.',
   changePasswordp2: 'Vaše heslo by mělo mít alespoň 6 znaků',
   currentPassword: 'Aktuální heslo',
   newpassword: 'Nové heslo',
   Retypenewpassword: 'Zadejte znovu nové heslo',
   account: 'Účet (peněženka)',
   Required: 'Nezbytné',
   registerBussinessUsername: 'Uživatelské jméno (alespoň 5 znaků)',
   registerBussinessPassword: 'Heslo (alespoň 6 znaků)',
   ReferenceID: 'Referenční ID',
   EnterId: 'Zadejte ID',
   Orderid: 'Id objednávky',
   EnterCode: 'Zadejte kód',
   AustrianGerman: 'Rakouská němčina',
   deu: 'Rakouská němčina',
   pop: 'Doklad o vyzvednutí',
   Options: 'Možnosti',
   RequiredText: 'Vyžadováno',
   PleaseSelect: 'Vyberte prosím',
   Optimizedropoffs: 'Optimalizace doručení',
   'Optimizedropoffs?': 'Optimalizovat doručení?',
   OptimizedropoffsDesc: 'Pořadí doručení se změní na optimalizované pořadí a může se změnit cena.',
   Optimize: 'Optimalizovat',
   zh: 'Zjednodušená čínština',
   ChineseSimplified: 'Zjednodušená čínština',
   Bulkimport: 'Hromadný import',
   processing: 'Zpracování',
   done: 'Hotovo',
   failed: 'Neúspěšné',
   Draft: 'Návrh',
   Uploading: 'Nahrávání',
   Draganddropyourfile: 'Přetáhněte svůj soubor',
   Onlycsvformatissupported: 'Podporován je pouze formát .csv',
   ORyoucan: 'NEBO můžete',
   Openfile: 'Otevřít soubor',
   Noimportsyet: 'Zatím žádný import',
   NoimportsyetDesc: 'Zde si můžete prohlédnout seznam svých importů a zkontrolovat jejich stav',
   nosupportdialogeTitle: 'Soubor Formart není podporován',
   nosupportdialogeText: 'Soubor xxx není podporován. můžete nahrát pouze soubor .csv obsahující informace o objednávce.',
   Import: 'Import',
   previewTitle: 'Zobrazte náhled a zvolte možnosti',
   previewDesc: 'Toto je první položka vašich souborů, abyste zkontrolovali, zda jsou vaše informace ve správné struktuře.',
   Upload: 'Nahrát',
   ServiceandOrdertype: 'Typ služby a objednávky',
   Fileisproccessing: 'Soubor se zpracovává ...',
   proccessingDesc: 'Můžete obnovit stránku, abyste zjistili, zda je výsledek připraven.',
   serverError: 'Chyba serveru',
   isRequired: 'Je vyžadováno xxx',
   Result: 'Výsledek',
   ResultText: 'Zde si můžete prohlédnout problémy a stáhnout si tyto řádky k opravě samostatně',
   ErrorDetails: 'Objednávky s chybou',
   ErrorDetailsText: 'Objednávky s chybami jsou uvedeny v souboru, který si můžete stáhnout níže. Obsahuje podrobnosti o chybách pro každý řádek.',
   DownloadErroredRows: 'Stáhnout řádky s chybami',
   importedsuccessfully: 'Objednávky xxx jsou úspěšně importovány',
   ordershaserrors: 'xxx objednávek má chyby',
   refresh: 'Obnovit',
   pleaseSellectParsel: 'Vyberte prosím službu',
   Ondemand: 'Přímé doručení',
   PickupandDelivery: 'Vyzvednutí a doručení',
   PickupDelivery: 'Vyzvednutí a doručení',
   Delivery: 'Doručení',
   tab_Delivery: 'Doručení',
   tab_Ondemand: 'Přímo doručení',
   tab_Pickup: 'Vyzvednutí',
   tab_PickupDelivery: 'Vyzvednutí a doručení',
   Downloadsamplecsv: 'Stáhnout vzorový CSV',
   Distance: 'Vzdálenost',
   Duration: 'Doba trvání',
   driverPhoto: 'Fotografie řidiče',
   yourPhoto: 'Vaše fotografie',
   No: 'Ne',
   confirmCancel2: 'Ano, zrušit objednávku',
   UploadPhoto: 'Nahrát fotografii',
   Photos: 'Fotografie',
   ParcelPhoto: 'Fotografie pozemku',
   ProofofPickup: 'Doklad o vyzvednutí',
   EditPhoto: 'Upravit fotografii',
   TrackLink: 'Odkaz na sledování',
   ShipmentLabel: 'Štítek zásilky',
   Receipt: 'Potvrzení',
   Confirm: 'Potvrdit',
   BulkEditStatusSummary: 'Hromadná úprava přehledu stavu',
   Successful: 'Úspěšně',
   Failed: 'Neúspěšně',
   OrderID: 'ID objednávky',
   New: 'Nový',
   Clear: 'Vymazat',
   Noresultfound: 'Nebyl nalezen žádný výsledek',
   Typeyourkeyword: 'Zadejte klíčové slovo',
   Typeintheinputtostartsearch: 'Zadejte vstup pro zahájení vyhledávání',
   OnDemand: 'Přímé doručení',
   P_Hub_D: 'P_Hub_D',
   AddressDetails: 'Podrobnosti o adrese',
   Map: 'Mapa',
   'BulkEditStatusxxx/yyy': 'Hromadné úpravy Stav xxx/yyy',
   'xxxSuccessfull,yyyFailed': 'xxx úspěšně, yyy neúspěšně',
   xxxfromyyy: 'xxx z yyy',
   xxxrows: 'xxx řádků',
   xxxselected: 'xxx vybráno',
   xxxdropoffs: 'xxx doručení',
   'Status Updated at xxx': 'Stav aktualizován na xxx',
   More: 'Více',
   Summary: 'Souhrn',
   Prev: 'Předchozí',
   Next: 'Další',
   ClearAll: 'Vymazat vše',
   Errorinloadingdata: 'Chyba při načítání dat',
   Therewasaproblemloadingdatapleasetryagainorcontactsupport: 'Při načítání dat došlo k problému. zkuste to prosím znovu nebo kontaktujte podporu',
   Tryagain: 'Zkuste to znovu',
   ConfigTable: 'Konfigurační tabulka',
   ActiveColumns: 'Aktivní sloupce',
   Createyourorder: 'Vytvoření objednávky',
   Yourorderswillbeshownhere: 'Zde se zobrazí vaše objednávky',
   Pleasecheckyourfiltersorkeyword: 'Zkontrolujte prosím své filtry nebo klíčové slovo',
   CustomerPhoto: 'Fotografie zákazníka',
   NoAttachments: 'Žádné přílohy',
   AllAttachmentswillbeshownhere: 'Všechny přílohy se zobrazí zde',
   Scheduleoverlap: 'Čas plánovaného vyzvednutí musí být před časem plánovaného doručení a nesmí se překrývat.',
   SearchAddress: 'Vyhledat adresu',
   NoResultfromLocalDatabaseD: 'Zkontrolujte ji prosím znovu nebo zkuste jiné klíčové slovo',
   NoResultfromMapService: 'Žádný výsledek z mapové služby',
   Senderinfo: 'Informace o odesílateli',
   Receiverinfo: 'Informace o příjemci',
   SchedulePickup: 'Plán vyzvednutí',
   ScheduleDelivery: 'Plán doručení',
   Fullname: 'Celé jméno',
   addressDetail: 'Detail adresy',
   Addtoaddressbook: 'Přidat do adresáře',
   ExHome: 'Např. Home',
   Ex123: 'Např. 123',
   Savechanges: 'Uložit změny',
   Removefromaddressbook: 'Odstranit z adresáře?',
   RemovefromaddressbookDesc: 'Tato adresa se zadanými údaji bude adresována do vašeho adresáře.',
   Clearfield: 'Vymazat pole',
   CreateOrder: 'Vytvořit objednávku',
   Walletbalanceislow: 'Zůstatek v peněžence je nízký',
   Walletbalanceislowd: 'Chcete-li použít peněženku, musí být zůstatek na účtu alespoň stejný jako cena objednávky.',
   'Selected date and time': 'Vybrané datum a čas',
   Vehicle: 'Vozidlo',
   Other: 'Další',
   'Fill in or Select': 'Vyplňte nebo vyberte',
   Fields: 'Pole',
   OrderType: 'Typ objednávky',
   Stage: 'Stage',
   Status: 'Stav',
   CreatedAt: 'Vytvořena v',
   StatusUpdatedAt: 'Status aktualizován v',
   Note: 'Poznámka',
   ReferenceId: 'Referenční ID',
   PickupAddress: 'Adresa vyzvednutí',
   DeliveryAddress: 'Dodací adresa',
   EstimatedDistanceInMeters: 'Odhadovaná vzdálenost v metrech',
   EstimatedDuration: 'Odhadovaná doba trvání',
   'Payment Method': 'Způsob platby',
   PickupAddressDetail: 'VyzvednutíAdresaPodrobné informace',
   PickupCompleteAfter: 'PickupCompleteAfter',
   PickupcompleteBefore: 'Pickup completedBefore',
   PickupFullName: 'PickupFullName',
   PickupPhone: 'PickupPhone',
   PickupEmail: 'PickupEmail',
   PickupPodNote: 'PickupPodNote',
   PickupPodPhoto: 'PickupPodPhoto',
   PickupPodSignature: 'PickupPodSignature',
   DeliveryAddressDetail: 'DeliveryAddressDetail',
   DeliveryCompleteAfter: 'DeliveryCompleteAfter',
   DeliverycompleteBefore: 'DeliveryFullPrevious',
   DeliveryFullName: 'DeliveryFullName',
   DeliveryPhone: 'DeliveryPhone',
   DeliveryEmail: 'DeliveryE-Mail',
   DeliveryPodNote: 'DeliveryPodNote',
   DeliveryPodPhoto: 'DeliveryPodPhoto',
   DeliveryPodSignature: 'DeliveryPodSignature',
   'Customer Email': 'E-mail zákazníka',
   PaymentProvider: 'Poskytovatel platby',
   'Shipment Label': 'Přepravní štítek',
   'Track Order': 'Sledování objednávky',
   Stages: 'Etapy',
   CodAmount: 'Dobírka',
   selected: 'vybrané',
   from: 'z',
   rows: 'Řádky',
   'Select Status': 'Vybrat Stav',
   'Select Reason': 'Vybrat důvod',
   'Edit Status': 'Upravit stav',
   Persian: 'Persian',
   undefined: 'Service type',
   'Pickup Address': 'Pickup Address',
   'You must assign driver to select this route': 'You must assign driver to select this route',
   'You must choose an unassigned route or unassign selected route': 'You must choose an unassigned route or unassign selected route' ,
   "Czech":"Czech",
   "cs":"Czech"
 };
