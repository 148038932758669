export const DutchLabel  = {
  Cutomerpanel: "Dashboard",
  login: "Login",
  Register: "Registreren",
  en: "Engels",
  fa: "Perzisch",
  Person: "Persoon",
  Business: "Bedrijf",
  Phonenumber: "Telefoonnummer",
  Continue: "Verdergaan",
  phonenumberhasnotregisterd: "Telefoonnummer is onbekend",
  Username: "Gebruikersnaam",
  title: "Titel",
  Password: "Wachtwoord",
  ExOnro: "Vb. Bedrijf",
  Exstart: "VB. *****",
  googleConnected: "Login via Google",
  facebookConnected: "Login via Facebook",
  appleConnected: "Login met Apple ID",
  plsenterphonnumber: "Vul je telefoonnummer in",
  firstnnameloginplaceholder: "Vb. Jan",
  "businessNamePlaceholder": "Ex. ABC Company",
  "registerBusinessNameLabel":"Business Name",
  lastnameloginplaceholder: "Vb. Jansen",
  Firstname: "Voornaam",
  Lastname: "Achternaam",
  email: "Email",
  emailPlaceholder: "VB. Jan@voorbeeld.com",
  comingsoon: "Binnenkort beschikbaar",
  comingsoondesc2: "Neem contact op voor een bedrijfsaccount",
  comingsoondesc: "Klik hier om uw bedrijf te registreren",
  contactUs: "Neem contact met ons op",
  CustomerAlreadyRegistered: "Klant bestaat al. Log alsjeblief opnieuw in",
  registerCompleted: "Vul registratiecode in",
  googletokenerror: "Fout opgetreden bij Google Token",
  facebooktokenerror: "Fout opgetreden bij Facebook Token",
  appletokenerror: "Fout opgetreden bij Apple Token",
  CustomerNotRegistered: "Klant niet geregistreerd",
  fr: "Frans",
  InvalidVerificationCode: "Ongeldige verificatiecode ",
  a: "Informatie niet beschikbaar",
  welcome: "Welkom",
  SmsLimitationError: "Probeer later opnieuw",
  resendCodeWithTime: "Stuur code opnieuw (xxx)",
  resendCode: "Stuur code opnieuw",
  VerificationcodePlaceHolder: "Vb. 1234",
  Verificationcode: "Verificatie Code",
  VerificationcodeText: "Vul de code in die is verzonden naar xxx",
  VerificationCodeExpired: "Verificatie Code verlopen",
  Pending: "In behandeling",
  Unassigned: "Niet toegewezen",
  Assigned: "Toegewezen",
  PickedUp: "Opgehaald",
  Started: "Gestart",
  statusInProgress: "In behandeling",
  Done: "Afgerond",
  CustomerCanceled: "Geannuleerd door klant",
  DriverCanceled: "Geannuleerd door koerier",
  SupportCanceled: "Geannuleerd door support",
  Arrived: "Aangekomen",
  new: "Nieuw",
  Historyofyourorderswillbehere: "Hier komt de geschiedenis van je orders",
  otherdropoffs: "Andere afleveringen",
  Orderhistory: "Order geschiedenis",
  Noorder: "Geen order",
  loadingList: "Moment geduld alsjeblieft",
  loadingError: "Er is een fout opgetreden bij het opvragen van informatie",
  retry: "Opnieuw",
  Create_your_first_requst: "Maak een nieuwe bestelling",
  List_of_you_active_orders_will_be_here:
    "Lijst van actieve orders is hier inzichtelijk",
  Aboutus: "Over ons",
  Readour: "Lees onze",
  privaypolicy: "Privacy Verklaring",
  Transactions: "Transacties",
  NoTransaction: "Geen vorige transacties beschikbaar",
  Historyofyourransactionswillbehere: "Overzicht van alle transacties",
  Settings: "Instellingen",
  Language: "Taal",
  English: "Engels",
  Persian: "Perzisch",
  French: "Frans",
  Logout: "Uitloggen",
  logotTitle: "Uitloggen bij je account?",
  logoutDescription:
    "Je wordt uitgelogd bij je account. Probeer. het later opnieuw",
  confirmLogout: "Ja, log uit",
  skipLogout: "Annuleren",
  savesettings: "Instellingen opslaan",
  emailInvalid: "Email adres niet geldig",
  Neworder: "Nieuwe order",
  Service: "Service",
  poperLoading: "Momentje",
  Pickup: "Pickup",
  SetPickup: "Pickup punt",
  ChooseoOnMap: "Kaart",
  Fornow: "Voorlopig",
  Scheduled: "Ingepland",
  before: "Voor",
  after: "Erna",
  confirm: "Bevestigen",
  Settime: "Tijd instellen",
  Cancel: "Annuleren",
  Shipmentlabel:"Zendingslabel",
  Pleasetryanotherkeyword: "Vul een ander woord in",
  Noinformationfound: "Geen informatie gevonden",
  address: "Adres",
  block: "Gebouw",
  Floor: "Verdieping",
  Unit: "Kantoor",
  senderfullname: "Volledige naam afzender",
  senderphonenumber: "Volledig telefoonnumer ",
  notesfordriver: "Opmerkingen voor koerier",
  DropOff: "Aflevering",
  SetDropOff: "Ophaalpunt",
  fetchingdata: "Informatie verwerken...",
  Recieverfullname: "Volledige naam ontvanger",
  Recieverphonenumber: "Telefoonnummer van ontvanger",
  undefined: "Service Type",
  PromotionCode: "Promotiecode",
  Delete: "Verwijderen",
  Save: "Opslaan",
  PromoCodeValid: "Promotiecode Opgeslagen",
  SenderWallet: "Wallet",
  SenderCash: "Cash",
  ReceiverCash: "Betalen door Ontvanger",
  lowBalance: "Laag tegoed",
  Balance: "Tegoed",
  Methods: "Methoden",
  balanceError: "Balans moet minimaal gelijk zijn aan orderwaarde",
  SendOrder: "Verstuur bestelling",
  feedback: "Feedback",
  Feedback: "Feedback",
  Orderagain: "Bestel opnieuw",
  Createdsuccessfully: "Goed afgerond",
  chooseAmount: "Kies aantal:",
  orenterthepreferedamount: "Voorkeur aantal:",
  enteramount: "Gewenste Tarief:",
  pay: "Betalen",
  selectPaymentGateway: "Kies Betaalprovider",
  Time_MachineText: "Vorige niet opgeslagen informatie herstellen?",
  yes: "Ja",
  ignore: "Negeren",
  Order: "Bestellen",
  statusPending: "In behandeling",
  statusUnassigned: "Niet toegewezen",
  statusAssigned: "Toegewezen",
  statusStarted: "Gestart",
  statusPickedUp: "Opgehaald",
  statusDone: "Afgerond",
  statusCustomerCanceled: "Geannuleerd door klant",
  statusDriverCanceled: "Geannuleerd door koerier",
  statusArrived: "Aangekomen",
  statusSupportCanceled: "Geannuleerd door support",
  statusCanceled: "Geannuleerd",
  Phone: "Telefoon",
  additonalservice: "Extra service",
  CallDriver: "Bel Koerier",
  Message: "Bericht",
  orderInfoError: "Fout bij het verwerken van informatie",
  support: "Support",
  map: "Kaart",
  receipt: "Bon",
  photo: "Foto",
  ServicePhoto: "Service foto",
  Addphoto: "Foto toevoegen",
  Estime: "Geschatte tijd",
  Espath: "Geschatte route",
  pod: "Bewijs van levering",
  Notes: "Opmerkingen",
  Photo: "Foto",
  Signature: "Handtekening",
  Nonotes: "Geen opmerkingen",
  Nosignature: "Geen handtekening",
  Nophoto: "Geen foto",
  Price: "Tarief",
  Receiver: "Door ontvanger",
  Sender: "Door verzender",
  Cash: "Cash",
  Canceltravel: "Annuleer verzoek",
  CancellText: "Na annulatie wordt de order voor de koerier gecancelled",
  Estimated: "Geschat",
  Wallet: "Tegoed",
  Copylink: "Kopiëer link",
  repeatOrder: "Herhaal bestelling",
  Edit: "Wijzigen",
  confirmCancel: "Ja",
  paymentmethod: "Betaalmethode",
  SucceessFul: "Goed afgerond",
  errorinServer: "Server probleem",
  Comment: "Opmerking",
  Exfeedbackinput: "Vb. snel geleverd door koerier",
  Wallett: "Tegoed",
  TypeHere: "Typ hier",
  Loadingmessages: "Berichten laden",
  PoweredBy: "Powered by",
  newincomemessage: "Nieuw bericht ontvangen",
  newincomemessageanother: "Nieuw bericht bij andere bestelling ontvangen",
  youhaveunreadmessage: "Ongelezen bericht",
  Nointernetconnection: "Geen internet verbinding",
  close: "Sluiten",
  seen: "Bekeken",
  controlThatbeforeLoweEqualAfter: 'Tijd "voor:" kan niet later zijn dan "na"',
  controlThatbeforeAndAfterMustBiggerthanCurrentDate:
    'De "voor:" en "na:" tijden kunnen niet korter zijn dan de huidige tijd',
  errorInPanelSetting: "Fout in reserverings instellingen, contacteer support",
  Arabic: "Arabisch",
  ar: "Arabisch",
  veeDeliveryLoginText1: "Stuur iets",
  veeDeliveryLoginText2: "Naar iemand, NU!",
  Schedule: "Plannen",
  Date: "Datum",
  Time: "Tijd",
  noDateToShow: "Er is geen data om weer te geven",
  noTimeToShow: "Er is geen tijd om weer te geven",
  Apply: "Toepassen",
  Increaseyourbalance: "Verhoog tegoed",
  AddBalance: "Tegoed toevoegen",
  Addressbook: "Opgeslagen adressen",
  AddressbookSearchbar: "Zoek per adres, titel en code",
  Removedefault: "Zoek per adres, titel en code",
  Setdefaultpickup: "Zet standaard pickup punt",
  Remove: "Verwijderen",
  newAddress: "Nieuw adres",
  NewAddressbookInfoTitle: "Info",
  Addressdetails: "Adresgegevens",
  Title: "Titel",
  Code: "code",
  editAddress: "Adres wijzigen",
  AddressbookDropOf: "Adressen (Drop off)",
  AddressbookPickup: "Adressen (Pick up)",
  hide: "Verbergen",



  Nosavedaddress: "Geen opgeslagen adresadres",
  NosavedaddressDesc:
    "U kunt er een maken, door op het bladwijzerpictogram naast adres te klikken of naar het adresboekpagina te gaan",
  Romanian: "Roemeense",
  ro: "Roemeense",
  Vehicletype:"Type Voertuig" ,
   Pickup2:"Ophalen" ,
    Dropoff2:"Afleveren" ,
    senderfullname: "Naam verzender",
    block: "Blokkeren",
    senderphonenumber: "Telefoonnummer verzender",
    Recieverfullname: "Naam ontvanger",
    Recieverphonenumber: "Telefoonnummer ontvanger",


    Events: "Updates",
    allEvents: "Alle updates",
    star :"Sterren" ,
    Yourrate:"Jouw waardering" ,
    Comment:"Opmerking" ,
    CopyOrderlink:"Kopiëer link bestelling" ,
    CopyPickuplink:"Kopieer link ophalen" ,
    CopyDeliverylink:"Kopieer link afleveren" ,
    Dutch: "Nederlands",
    nl:"Nederlands",

    paymentmethod: "Betalingsmiddel",
    Name :"Naam",
    CashOnDelivery:"Cash bij levering",
    EnterAmount:"Voer een bedrag in",
    COD:"Cash bij levering",
    Surchargexxx:"Toeslag XXX",
    Pricedetails: "Prijsgegevens",
    minxxx :"xxx min",
    toPickup:"Ophalen" ,
    toDropOff: "afzetten",
    AddCreditCard:"Voeg Credit / Debit Card toe" ,
    CustomerWallet:"Klantportefeuille" ,
    Add:"Toevoegen",
    Addedsuccessfully: "Succesvol toegevoegd",
    paymentmethods: "Betalingsmethoden",
    NoCards:"Geen kaarten",
    Listofyourcardswillbehere:"Lijst met uw kaarten zal hier zijn",
    CardInfo:"Kaartinformatie",
    ExpireDate:"Vervaldatum",
    CVC:"Cvc",
    PostalCode:"Postcode",
    RemoveCardq:"Kaart verwijderen?",
    RemoveCardA:"De kaart wordt verwijderd en kan niet meer worden gebruikt",
    YesRemove:"Ja, verwijder",
    Cardisinuse:"Kaart is in gebruik",
    ok:"Oké",
    Pleasetypeyourwordstosearch:"Typ uw trefwoord om te zoeken" ,
    spanish:"Spaans",
    es : "Spaans",
    Invoices:"Facturen",
    noInvoice:"Nee. XXX",
    germany: 'Duitsland',
    de: 'Duitsland',
    downloadClientApplication:"Download xxx -applicatie" ,

    AssignedAt: 'Wijs toe tot xxx',
    StartedAt: 'Begin tot xxx',
    ArrivedAt: 'Aankomen tot xxx',
    PickedUpAt: 'Ophalen op xxx',
    DoneAt: 'Eindig bij xxx',
    Russian:"Russisch",
    Azerbaijani:"Azerbeidzjani",
    rus:"Russisch",
    aze:"Azerbeidzjani",
    Support:"Steun",
    SendRequest:"Verzend verzoek",
    CallTheSupport:"Bel de steun",
    Choosethesubject :"Kies het onderwerp",
    Seefrequentquestions:"Zie frequente vragen",
    Details:"details",
    Send:"Versturen",
    MessageBox:"Berichten box",
    NoMessages:"Geen berichten",
    Yourmessageswillbehere:"Uw berichten zullen hier zijn",
    Portuguese:"Portugees",
    pt:"Portugees",
    Required:"Vereist",
    changePassword : "Verander wachtwoord",
    changePasswordp1 : "U wordt uit alle sessies geregistreerd, behalve deze om uw account te beschermen, iemand probeert toegang te krijgen",
    changePasswordp2 : "Uw wachtwoord moet minimaal 6 tekens zijn",
    currentPassword:"huidig ​​wachtwoord",
    newpassword:"nieuw paswoord",
    Retypenewpassword:"Typ het nieuwe wachtwoord opnieuw",
    account: "Account (portemonnee)",
    "Greek":"Grieks",
    "el":"Grieks",
    "registerBussinessUsername":"Gebruikersnaam (minimaal 5 tekens)",
    "registerBussinessPassword":"Wachtwoord (minimaal 6 tekens)",
    "ReferenceID":"referentie nummer",
    "EnterId":"Geef een identiteitsdocument",
    "Orderid": "orderId",
    "EnterCode":"Voer code in",
    "AustrianGerman":"Oostenrijks Duits",
    "deu":"Oostenrijks Duits",
    "pop":"Bewijs van pick -up",
    "Options":"Opties",
    "RequiredText":"Vereist",
    "PleaseSelect":"Selecteer alstublieft",
    "Optimizedropoffs":"Optimaliseer dropoffs",
    "Optimizedropoffs?":"Dropoffs optimaliseren?",
    "OptimizedropoffsDesc":"De volgorde van dropoffs wordt gewijzigd in geoptimaliseerde volgorde en het kan de prijs veranderen",
    "Optimize":"Optimaliseren"

    ,
    "Bulkimport":"Bulk import",
    "processing":"Verwerken" ,
     "done":"Afgerond" ,
     "failed":"Mislukt",
     "Draft":"Voorlopige versie" ,
     "Uploading":"Het uploaden" ,
     "Draganddropyourfile":"Sleep en laat uw bestand vallen",
     "Onlycsvformatissupported":"Alleen .CSV -indeling wordt ondersteund" ,
     "ORyoucan":"Of je kan",
     "Openfile":"Open bestand" ,
     "Noimportsyet":"Nog geen import" ,
     "NoimportsyetDesc":"U kunt de lijst met uw import hier bekijken en hun status controleren",
     "nosupportdialogeTitle":"Bestands formaat niet ondersteund",
     "nosupportdialogeText":"XXX -bestand wordt niet ondersteund.U kunt het .csv -bestand alleen uploaden, inclusief bestelinformatie" ,
     "Import":"Importeren",
     "previewTitle":"Voorbeeld en kies opties",
     "previewDesc":"Dit is het eerste item van uw bestanden om uw info te controleren, is in de juiste structuur" ,
     "Upload":"Uploaden", 
     "Remove":"Verwijderen" ,
     "ServiceandOrdertype":"Service- en besteltype" ,
     "Fileisproccessing":"Bestand is verwerkt ...",
     "proccessingDesc":"U kunt vernieuwen om te zien of het resultaat klaar is",
     "serverError":"Serverfout",
     "isRequired":"XXX is vereist" ,
     "Result":"Resultaat",
     "ResultText":"U kunt de problemen hier zien en deze rijen downloaden om afzonderlijk op te lossen",
     "ErrorDetails":"Bestellingen met fout",
     "ErrorDetailsText":"Bestellingen met fouten worden vermeld in het bestand dat u hieronder kunt downloaden.Het bevat foutdetails voor elke rij.",
     "DownloadErroredRows":"Download foutrijen rijen" ,
     "importedsuccessfully":"XXX -bestellingen worden met succes geïmporteerd" ,
     "ordershaserrors":"xxx bestellingen hebben fouten",
     "refresh":"Opfrissen",
     "pleaseSellectParsel":"Selecteer Service",
     "Ondemand":"Op aanvraag",
     "PickupandDelivery":"Ophalen en leveren",
     "PickupDelivery":"Ophalen en leveren",
     "Delivery":"Levering",
     "Pickup":"Ophalen",
     "tab_Delivery":"Levering",
     "tab_Ondemand":"Op aanvraag",
     "tab_Pickup":"Ophalen",
     "tab_PickupDelivery":"P&D",
     "Downloadsamplecsv":"Download voorbeeld CSV" ,
     "driverPhoto":"Driver Photo",
     "yourPhoto":"Your Photo",
     "UploadPhoto":"Upload Photo",
     "Photos":"Photos",
   
   
   
     "TrackLink":"Track Link",
     "ShipmentLabel":"Shipment Label",
     "Draft":"Draft",
     "Receipt":"Receipt",
     "Confirm":"Confirm",
     "Cancel":"Cancel",
     "BulkEditStatusSummary":"Bulk Edit Status Summary",
     "Successful":"Successful",
     "Failed":"Failed",
     "OrderID":"Order ID",
     "Details":"Details",
     "Result":"Result",
     "New":"New",
     "Import":"Import",
     "Apply":"Apply",
     "Clear":"Clear",
     "Noresultfound":"No result found",
     "Typeyourkeyword":"Type your keyword",
     "Typeintheinputtostartsearch":"Type in the input to start search",
     "OnDemand":"On Demand",
     "P_Hub_D":"P_Hub_D",
     "AddressDetails":"Address Details",
     "Map":"Map",
     "Support":"Support",
     "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
     "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
     "xxxfromyyy":"xxx from yyy",
     "xxxrows":"xxx rows",
     "xxxselected":"xxx selected",
     "xxxdropoffs":"+xxx dropoffs",
     "Status Updated at xxx":"Status Updated at xxx",
     'More':'More',
     'Summary':'Summary',
     'Prev':'Prev',
     'Next':'Next',
     'Done':'Done',
     "ClearAll":"Clear All",
     'Errorinloadingdata':'Error in loading data',
     'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
     'Tryagain':'Try again',
     'ConfigTable' :'Config Table' ,
     'ActiveColumns':'Active Columns',
     'Createyourorder':'Create your order',
     'Yourorderswillbeshownhere':'Your orders will be shown here',
     'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
   "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
     'CustomerPhoto':'Customer Photo',
     "NoAttachments":"No Attachments",
     "AllAttachmentswillbeshownhere":"All Attachments will be shown here",


     "SearchAddress":"Search Address",
     "NoResultfromLocalDatabaseD": "Please check it again or try another keyword",
     "NoResultfromMapService": "No Result from Map Service",
     "Senderinfo": "Sender info",
     "Receiverinfo": "Receiver info",
     "SchedulePickup": "Schedule Pickup ",
     "ScheduleDelivery": "Schedule Delivery",
     "Fullname": "Full name",
     "addressDetail":"Address Detail",
     "Addtoaddressbook":"Add to addressbook",
     "ExHome":"Ex. Home" ,
     "Ex123":"Ex. 123",
     "Savechanges": "Save changes",
     "Removefromaddressbook":"Remove from addressbook?",
     "RemovefromaddressbookDesc":"This address with entered details will be address to your address book.",
     "Clearfield":"Clear field",
     "CreateOrder":"Create Order" ,
     "Czech":"Czech",
     "cs":"Czech"

};
