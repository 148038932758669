import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import styles from './style.module.css';
import momentJalaali from "moment-jalaali";
import moment from "moment";


import jalali from "jalali-moment";
import { snack } from "../../../../../helper/module/SnackbarUtilsConfigurator";
import { NewTravelDropOffSetReserve, NewTravelPickupSetReserve } from "../../../../../Redux/actions/actionNames";
import { get_lang } from "../../../../../helper/UserData/userdate";
import Button from "../../../../../design-system/lib/Button";
import { CALENDAR_CHECK,     CLOCK_1, CLOSE, TIMER } from "../../../../../static";

import { ReactComponent as Clock } from '../../../../../static/IconProvider/clock.svg'

import Modal from "../../../../../design-system/lib/Modal";
import Typography from "../../../../../design-system/lib/Typography";
import IconProvider from "../../../../../design-system/lib/IconProvider";
import DropDown from "../../../../../design-system/lib/DropDown";
import { ReactComponent as Calendar_time } from '../../../../../static/IconProvider/calendar_time.svg'
import IconProviderSvgModifier from "../../../../../design-system/lib/IconProviderSvgModifier";
import ArrowDown from '../../../../../static/IconProvider/Arrow---Down-2.svg'

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import { transform } from "lodash";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edited:false ,
      TABIndex: (!momentJalaali(this.props.scheduleDateAfter).isValid() ||
        !momentJalaali(this.props.scheduleDateBefore).isValid()) ? 0 : 1,
      showTimePickerDialoge: false,
      TimeSelected: false,
      forNow:
        props.scheduleDateAfter || props.scheduleDateBefore ? true : true,
      Dialoge: false,
      Date: momentJalaali(),
      init: moment(),
      scheduleDateBefore: null,
      scheduleDateAfter: null,
      activeDialoge: null,
      rangeTime: [],
      selectedTime: null,
      min: moment(
        Date.now() +
        props.activeVehicleType.scheduleSetting.scheduleDateRangeAfter
      ),
      max: moment(
        Date.now() +
        props.activeVehicleType.scheduleSetting.scheduleDateRangeBefore
      ),
    };
    this.confirm = this.confirm.bind(this);
    this.timePickerRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    try {
      if (
        nextProps.activeVehicleType &&
        nextProps.activeVehicleType.scheduleSetting
      ) {
        this.setState({
          min: moment(
            Date.now() +
            nextProps.activeVehicleType.scheduleSetting.scheduleDateRangeAfter
          ),
          max: moment(
            Date.now() +
            nextProps.activeVehicleType.scheduleSetting
              .scheduleDateRangeBefore
          ),
        });
      }
    } catch (error) { }

    this.setState({
      TABIndex: (!momentJalaali(nextProps.scheduleDateAfter).isValid() ||
        !momentJalaali(nextProps.scheduleDateBefore).isValid()) ? 0 : 1,
    })
  }

  handleToggle = (b) => {
    if (
      parseInt(
        this.props.activeVehicleType.scheduleSetting.scheduleDateRangeBefore
      ) == 0
    ) {
      snack.warning(this.props.t("errorInPanelSetting"))

    } else {
      this.setState({
        showTimePickerDialoge: b,
      });
    }
  };

  componentDidMount() { }

  handleDialoge = (e) => {
    //   if (this.props.activePickupDropOff.type === "pickup") {

    this.setState({
      Date: moment(),
      ActiveFild: e,
      activeDialoge: e,
      // scheduleDateBefore: momentJalaali(this.props[e]).isValid()
      //   ? this.props[e]
      //   : momentJalaali(),
      // scheduleDateAfter: momentJalaali(this.props[e]).isValid()
      //   ? this.props[e]
      //   : momentJalaali(),
      selectedTime:null,
        scheduleDateBefore: null,
        scheduleDateAfter: null,
      Dialoge: !this.state.Dialoge,
      TimeSelected: false,
    });
    //  }
  };

  handleSwitch = () => {
    if (this.props.type == "pickup") {
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateBefore",
          value: null,
        })
      );
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateAfter",
          value: null,
        })
      );
    } else {
      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateBefore",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );
      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateAfter",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );
    }

    this.setState({
      forNow: !this.state.forNow,
    });
  };

  checkSekectedTime = () => {
    if (this.state.selectedTime) {
      return `${this.state.selectedTime.scheduleDateAfter.hour}:${this.state.selectedTime.scheduleDateAfter.minute} - ${this.state.selectedTime.scheduleDateBefore.hour}:${this.state.selectedTime.scheduleDateBefore.minute}`
    } else {
      return this.props.t("Set time slot")
    }
  }

  SetTime = (date) => {
    //   if (this.props.activePickupDropOff.type === "pickup") {

    let scheduleDateBefore = moment(this.state.selectedDate).set({
      hour: moment(date.data[1]).format("HH"),
      minute: moment(date.data[1]).format("mm"),
    });
    let scheduleDateAfter = moment(this.state.selectedDate).set({
      hour: moment(date.data[0]).format("HH"),
      minute: moment(date.data[0]).format("mm"),
    });

    this.setState(
      {
        scheduleDateBefore: scheduleDateBefore,
        scheduleDateAfter: scheduleDateAfter,
        TimeSelected: true,
        selectedTime: {
          scheduleDateBefore: {
            hour: moment(date.data[1]).format("HH"),
            minute: moment(date.data[1]).format("mm"),
          },
          scheduleDateAfter: {
            hour: moment(date.data[0]).format("HH"),
            minute: moment(date.data[0]).format("mm"),
          },
        },
        edited : true
      },
      () => {
        this.handleToggle(false);
      }
    );
    // }
  };;

  SetDate = (e) => {

    let dateStr = momentJalaali(
                e.day +
                "/" +
                e.month +
                "/" +
                e.year,
                "DD/MM/YYYY"
            ),
      timeStr = momentJalaali(this.state[this.state.ActiveFild]).format(
        "HH:mm"
      ),
      date = moment(dateStr),
      time = moment(timeStr, "HH:mm");

    date.set({
      hour: time.get("hour"),
      minute: time.get("minute"),
      second: time.get("second"),
    });
    this.setState({
      Date: date,
      // scheduleDateBefore: date,
      // scheduleDateAfter: date,
      TimeSelected: false,
      CalendarDate  : e , 
      selectedDate: dateStr,
      selectedTime: null,
      edited : true
    }, () => {
      this.TimesProvider()
    });
    // this.props.dispatch(
    //   NewTravelPickupSetReserve({
    //     name: this.state.ActiveFild,
    //     value: date,
    //   })
    // );
    //  }
  };

  confirm = () => {
    // if(this.controlThatbeforeAndAfterMustBiggerthanCurrentDate()){
    // ok
    // if (this.controlThatbeforeLoweEqualAfter()) {

    if(this.state.TABIndex == 1 && this.state.selectedTime) {
         if (this.props.type === "pickup") {

      if(this.props.firstDropOffScheduleDateAfter || this.props.firstDropOffScheduleDateBefore){
        if(Number(this.props.firstDropOffScheduleDateAfter) <  Number(this.state["scheduleDateBefore"]) ){
          snack.error(this.props.t("Scheduleoverlap"))
        }else{
          this.setState({
            Dialoge: false,
          });
          this.props.dispatch(
            NewTravelPickupSetReserve({
              name: "scheduleDateBefore",
              value: this.state["scheduleDateBefore"],
            })
          );
          this.props.dispatch(
            NewTravelPickupSetReserve({
              name: "scheduleDateAfter",
              value: this.state["scheduleDateAfter"],
            })
          );
        }
      }else{
        this.setState({
          Dialoge: false,
        });
        this.props.dispatch(
          NewTravelPickupSetReserve({
            name: "scheduleDateBefore",
            value: this.state["scheduleDateBefore"],
          })
        );
        this.props.dispatch(
          NewTravelPickupSetReserve({
            name: "scheduleDateAfter",
            value: this.state["scheduleDateAfter"],
          })
        );
      }


    } else {

      
      if(this.props.pickupScheduleDateAfter || this.props.pickupScheduleDateBefore){
        if(Number(this.state["scheduleDateAfter"]) <  Number(this.props.pickupScheduleDateBefore) ){
          snack.error(this.props.t("Scheduleoverlap"))

        }else{
          this.setState({
            Dialoge: false,
          });
          this.props.dispatch(
            NewTravelDropOffSetReserve({
              name: "scheduleDateBefore",
              value: this.state["scheduleDateBefore"],
              firstDropOff: this.props.firstDropOff,
              id: this.props.firstDropOff ? "dropoff" : this.props.id,
            })
          );
          this.props.dispatch(
            NewTravelDropOffSetReserve({
              name: "scheduleDateAfter",
              value: this.state["scheduleDateAfter"],
              firstDropOff: this.props.firstDropOff,
              id: this.props.firstDropOff ? "dropoff" : this.props.id,
            })
          );
        }

      }else{
        this.setState({
          Dialoge: false,
        });
        this.props.dispatch(
          NewTravelDropOffSetReserve({
            name: "scheduleDateBefore",
            value: this.state["scheduleDateBefore"],
            firstDropOff: this.props.firstDropOff,
            id: this.props.firstDropOff ? "dropoff" : this.props.id,
          })
        );
        this.props.dispatch(
          NewTravelDropOffSetReserve({
            name: "scheduleDateAfter",
            value: this.state["scheduleDateAfter"],
            firstDropOff: this.props.firstDropOff,
            id: this.props.firstDropOff ? "dropoff" : this.props.id,
          })
        );
      }



    }
    }else {
      this.cancel()
    }

 

  };

  cancel = () => {
    this.setState({
      [this.state.activeDialoge]: null,
    });
    if (this.props.type === "pickup") {
      this.setState({
        Dialoge: false,
      });
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateBefore",
          value: null,
        })
      );
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateAfter",
          value: null,
        })
      );
    } else {
      this.setState({
        Dialoge: false,
      });
      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateBefore",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );

      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateAfter",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );
    }
  };

  controlThatbeforeLoweEqualAfter = () => {
    if (!this.state.scheduleDateAfter || !this.state.scheduleDateBefore) {
      return true;
    } else if (
      moment(this.state.scheduleDateBefore).isAfter(
        this.state.scheduleDateAfter
      ) ||
      moment(this.state.scheduleDateBefore).isSame(this.state.scheduleDateAfter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  controlThatbeforeAndAfterMustBiggerthanCurrentDate = () => {
    if (
      (this.state.scheduleDateBefore
        ? moment(this.state.scheduleDateBefore).isAfter(new Date())
        : true) &&
      (this.state.scheduleDateAfter
        ? moment(this.state.scheduleDateAfter).isAfter(new Date())
        : true)
    ) {
      return true;
    } else {
      return false;
    }
  };

  diffDays = (IsfirstDay, IslastDay, IsOneDay, start, end, step) => {
    const today = start;
    const endDate = end;
    const minutes = Math.ceil(
      (Math.abs(endDate.unix() - today.unix()) / (1000 * 60)) % 60
    );
    const allSteps = Math.ceil(
      Math.abs(endDate.unix() - today.unix()) / 60 / step
    );
    // )
    // )
    // )
    // )
    // 
    // 
    // .format("HH:mm MM/DD ") , moment(endDate).format("HH:mm MM/DD "))

    let arr = [];
    arr.push([today, moment(today).add(step, "minute")]);

    for (let i = 1; i < allSteps; i++) {
      if (i == allSteps - 1) {
        if (!IsfirstDay && !IslastDay && !IsOneDay) {
          arr.push([
            moment(arr[arr.length - 1][1]),
            moment(endDate).set({ hour: 23, minute: 59 }),
          ]);
        } else if (IsfirstDay) {
          arr.push([moment(arr[arr.length - 1][1]), endDate]);
        }
      } else {
        arr.push([
          moment(arr[arr.length - 1][1]),
          moment(arr[arr.length - 1][1]).add(step, "minute"),
        ]);
      }
    }

    // arr.forEach(element => {
    //   .format("HH:mm MM/DD") , moment(element[1]).format("HH:mm MM/DD"))
    // });

    // return arr;
    this.setState({
      rangeTime: arr
    })
  };

  TimesProvider = () => {
    const step =
      this.props.activeVehicleType.scheduleSetting.scheduleDateStep / 1000 / 60;
    if (
      parseInt(
        this.props.activeVehicleType.scheduleSetting.scheduleDateRangeBefore
      ) == 0
    ) {
      return [];
    } else if (
      moment(this.state.min).format("MM/DD") ==
      moment(this.state.max).format("MM/DD")
    ) {
      //One Day
      return this.diffDays(
        false,
        false,
        true,
        moment(Date.now()).add(step, "minute"),
        moment(this.state.max),
        step
      );
    } else if (
      moment(this.state.init).format("MM/DD") ==
      moment(this.state.Date).format("MM/DD")
    ) {
      //the first
      return this.diffDays(
        true,
        false,
        false,
        moment(Date.now()).add(step, "minute"),
        moment(this.state.min).add(step, "minute").endOf("day"),
        step
      );
    } else if (
      moment(this.state.max).format("MM/DD") ==
      moment(this.state.Date).format("MM/DD")
    ) {
      //the last
      return this.diffDays(
        false,
        true,
        false,
        moment(this.state.max).startOf("day"),
        moment(this.state.max),
        step
      );
    } else {
      //the midd
      return this.diffDays(
        false,
        false,
        false,
        moment(this.state.Date).startOf("day"),
        moment(this.state.Date).endOf("day").add(-3, "minute"),
        step
      );
    }
  };

  getDaysOfDate = () => {
    let diff = moment(this.state.max).diff(moment(this.state.min), "days");

    let diffminute = moment(this.state.max).diff(moment(this.state.min), "minute");


    let arr = [];
    let min = moment(this.state.min).startOf("day");
    let max = moment(this.state.max).startOf("day");

    if (diff > 0) {
      arr.push(min);
      for (let i = 0; i < diff; i++) {
        arr.push(moment(min).add(i + 1, "day"));
      }
      return arr;
    } else {
      let step = this.props.activeVehicleType.scheduleSetting.scheduleDateStep / 1000 / 60;
      if (diffminute = moment(this.state.max).diff(moment(this.state.min), "minute") >= step) {
        arr.push(min);
        if (momentJalaali(this.state.max).format(
          get_lang() == "fa" ? "jMM/jDD" : "MM/DD"
        ) != momentJalaali(this.state.min).format(
          get_lang() == "fa" ? "jMM/jDD" : "MM/DD"
        )) {
          arr.push(max);
          return arr;
        } else {
          return arr;
        }
      } else {
        return arr;
      }

    }
  };

  checkSekectedDate = (day) => {
    if (this.state.selectedDate) {
      if (
        moment(day).format("MM/DD") ==
        moment(this.state.selectedDate).format("MM/DD")
      ) {
        return true
      }
    } else {
      return false;
    }
  };

  getValueDate = () => {

    if(this.state.selectedDate){
      return {
        year: this.state.CalendarDate?.year ,
        month: this.state.CalendarDate?.month,
        day: this.state.CalendarDate?.day
      }
    }else {
      return null
    }

  }

  getMinimumDate = (arr) => {
    console.log("dwdwdwdwdwd ",arr)
  if(arr && arr.length > 0){
    return {
      year: momentJalaali(arr[0]).format(
        get_lang() == "fa" ? "jYYYY" : "YYYY"
      ) ,
      month: momentJalaali(arr[0]).format(
        get_lang() == "fa" ? "jMM" : "MM"
      ),
      day: momentJalaali(arr[0]).format(
        get_lang() == "fa" ? "jDD" : "DD"
      )
    }
  }else{
    return null
  }
  };

  getMaximumDate = (arr) => {
    console.log("dwdwdwdwdwd ",arr)

    if(arr && arr.length > 0){
      return {
        year: momentJalaali(arr[arr.length - 1]).format(
          get_lang() == "fa" ? "jYYYY" : "YYYY"
        ) ,
        month: momentJalaali(arr[arr.length - 1]).format(
          get_lang() == "fa" ? "jMM" : "MM"
        ),
        day: momentJalaali(arr[arr.length - 1]).format(
          get_lang() == "fa" ? "jDD" : "DD"
        )
      }
    }else{
      return null
    }
  };

  render() {
    const { t } = this.props;
    let DaysOfDate = this.getDaysOfDate()

    console.log("45344454 ",this.state)

    return (
      <div className="ScheduleContainer" style={{ margin: "0px" , width:"auto",marginInlineStart:"auto" }}>
 
        <React.Fragment>

          <div  onClick={() => this.handleDialoge("scheduleDateAfter")} style={{display:"flex",flexDirection:"row", gap:"4px" , justifyContent:"center" , alignItems:"center" , cursor:"pointer"}}>
            {(momentJalaali(this.props.scheduleDateAfter).isValid() &&
            momentJalaali(this.props.scheduleDateBefore).isValid()) ? <>
                     <IconProviderSvgModifier Icon={Calendar_time} size='16' />
            <Typography text={`${jalali(this.props.scheduleDateAfter)
              .locale(get_lang())
              .format("D MMM HH:mm")} - ${jalali(
                this.props.scheduleDateBefore
              )
                .locale(get_lang())
                .format("HH:mm")}`} weight='Body_Small_Medium' />
              <IconProvider Icon={ArrowDown} size="10" />
            </> : <>
            <IconProviderSvgModifier Icon={Calendar_time} size='16' />
            <Typography text={this.props.t("ASAP")} weight='Body_Small_Medium' />
              <IconProvider Icon={ArrowDown} size="10" style={{transform :"rotate(180deg)"}} />
            </>}
          </div>

          {/* <Button className={styles.NoMaxWidth} size="Medium" type="Secondary"  iconComponentChildren={<IconProviderSvgModifier Icon={Add_schedule} size='16' />}  text={momentJalaali(this.props.scheduleDateAfter).isValid() &&
            momentJalaali(this.props.scheduleDateBefore).isValid() ? (
            `${jalali(this.props.scheduleDateAfter)
              .locale(get_lang())
              .format("D MMM HH:mm")} - ${jalali(
                this.props.scheduleDateBefore
              )
                .locale(get_lang())
                .format("HH:mm")}`
          ) : (
            <Trans i18nKey={"Settime"}> </Trans>
          )} style={{ width: "calc(100% - 16px)", justifyContent: "start" }} isActive={momentJalaali(this.props.scheduleDateAfter).isValid() && momentJalaali(this.props.scheduleDateBefore).isValid()} /> */}


          {this.state.Dialoge && <Modal  ConfirmationOnClick={this.confirm} CancelOnClick={() => { }} onclose={this.handleDialoge} size='large'
            icon={CLOSE} 
            
            footer={<div style={{ flex: "1", flexDirection: "row" ,display:"flex",alignItems:"center" }} >
              {/* <div className="ScheduleDialogeFooterDateTime"  style={{ flex: "1"}}>
                <Typography text={(momentJalaali(this.state.scheduleDateAfter).isValid() &&
            momentJalaali(this.state.scheduleDateBefore).isValid()) ? (
                  <p className="time">
                    {jalali(this.state.scheduleDateAfter).locale(get_lang()).format("D MMM HH:mm")} -{" "}
                    {jalali(this.state.scheduleDateBefore).locale(get_lang()).format("HH:mm")}
                  </p>
                ) : (
                  "- - - - - - "
                )} weight='Body_Middle_Bold' />

                <Typography text={this.props.t("Selected date and time")} weight='Subtitle_Tiny_Regular' />
              </div> */}

             {/* {(momentJalaali(this.props.scheduleDateAfter).isValid() &&
            momentJalaali(this.props.scheduleDateBefore).isValid()) && <Button className={styles.NoMaxWidth} size="Medium" type="Secondary" onClick={this.cancel} text={this.props.t("clear")} style={{ marginInlineEnd: "16px" }} />} */}
            </div>}

            title={this.props.t(`Schedule${this.props.type}`)} 
            body={
              <>
                <div className={styles.tabContainer}>
                  <div className={`${styles.tabBTN} ${(this.state.TABIndex == 0) && styles.tabBTN_Active}`} onClick={() => { this.setState({ TABIndex: 0 }) }}>{this.props.t("ASAP")}</div>
                  <div className={`${styles.tabBTN} ${((this.state.TABIndex == 1)) && styles.tabBTN_Active}`} onClick={() => { this.setState({ TABIndex: 1 }) }} >{this.props.t("Schedule")}</div>

                </div> 
          { this.state.TABIndex == 1 &&  <div className={styles.twoColumns}>
              
              {/* <div className={styles.Column}>
                <div className={styles.title}> <IconProvider Icon={CALENDAR_CHECK} /> <Typography text={this.props.t("Date")} weight='Body_Middle_Bold' /></div>
                <DropDown placement="bottomStart" size='Medium' data={DaysOfDate.map((dod) => { return { data: dod, title: jalali(dod).locale(get_lang()).format("MMM D") } })} onClick={this.SetDate} text={this.state.selectedDate ? jalali(this.state.selectedDate).locale(get_lang()).format("MMM D") : (DaysOfDate.length > 0 ? this.props.t("PleaseSelect") : this.props.t("noDateToShow"))} />
              </div> */}

               <Calendar
               minimumDate={this.getMinimumDate(DaysOfDate)}
               maximumDate={this.getMaximumDate(DaysOfDate)}
                                  value={this.getValueDate()}
                                  onChange={(e) => {this.SetDate(e)}}
                                  shouldHighlightWeekends
                    colorPrimary={global?.config?.colors?.secondaryColor} // added this
                                  colorPrimaryLight="#148be940" // and this
                                  calendarClassName={styles.customCalendar}
                              />
              <div className={styles.Column} style={{minWidth:"238px"}}>
                <div className={styles.title}>  <Typography text={this.props.t("Time slot")} weight='Body_Small_Regular' /></div>
                <DropDown  isRequired={true} IconComponents={<IconProviderSvgModifier Icon={Clock} size="16" />} placement="topStart" size='Medium' data={this.state.rangeTime.map((dod) => {
                  return {
                    data: dod, title: `${jalali(dod[0]).locale(get_lang()).format("HH:mm")} - ${jalali(
                      dod[1]
                    ).locale(get_lang()).format("HH:mm")}`
                  }
                })} onClick={this.SetTime} text={this.checkSekectedTime()} />
              </div>
            </div>}
             </>
          } 
            
            CancelText={this.props.t("clear")} ConfirmationDisabled={(this.state.TABIndex == 1) ? (!this.state.edited || !this.state.selectedTime || !this.state.selectedDate  ) : false} ConfirmationText={this.props.t("Savechanges")} />}

        
        </React.Fragment>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  pickupScheduleDateBefore : state.NewOrder.newOrder.pickup.scheduleDateBefore,
  pickupScheduleDateAfter : state.NewOrder.newOrder.pickup.scheduleDateAfter,

  firstDropOffScheduleDateBefore : state.NewOrder.newOrder.dropOffs.firstDropOff.scheduleDateBefore,
  firstDropOffScheduleDateAfter : state.NewOrder.newOrder.dropOffs.firstDropOff.scheduleDateAfter
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
