import 'moment/locale/ar'
import 'moment/locale/el'
import 'moment/locale/pt'
import 'moment/locale/fa'
import 'moment/locale/fr'
import 'moment/locale/ro'
import 'moment/locale/nl'
import 'moment/locale/es'
import 'moment/locale/az'
import 'moment/locale/de'
import 'moment/locale/ru'
import './helper/module/trusted-security-policies.js';
import React, { useEffect, useState } from "react";
import "./App.css";
import { Router, Route, Switch } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
// import * as Sentry from '@sentry/browser';
// import { Integrations } from "@sentry/tracing";
import { RtlLtrCssSelector } from "./helper/RtlLtrCssSelector";
import packageJson from "../package.json";
import { CheckNewVersion ,getDefaultDirectionWithGlobal,getDirectionByUrl,getProjectNameByUrl,i18Constructor } from "./helper/module";
import LoginRegister from "./Pages/LoginRegister";
import Main from "./Pages/Main";
import ViewOrder from "./Pages/ViewOrder";
import TableOrders from "./Pages/TableOrders";
import VerificationCode from "./Pages/VerificationCode";
import { withTranslation, Trans, useTranslation } from "react-i18next";
import { setupSocket } from "./socket/Setup_socket";
import { Helmet } from "react-helmet";
import BulkImport from "./Pages/BulkImport";
import { SnackbarProvider } from "notistack3";
import { BatchOperationalSnackbar } from './components/shared/batch-operational-snackbar/index.js';
import { SnackbarUtilsConfigurator } from './helper/module/SnackbarUtilsConfigurator.js';
import history from './service/history/index.js';
import NewOrderPage from './Pages/NewOrder/index.js';
import { Api_Get_Web_Setting_AGW } from './helper/api/ApiGateway.js';
// import LtrLayout from './helper/RtlLtrCssSelector/layout/LtrLayout.js';
// import RtlLayout from './helper/RtlLtrCssSelector/layout/RtlLayout.js';
import loading from "./static/loading.gif";
import { ErrorTitleDescSnackbar } from './components/shared/error-title-desc-snackbar/index.js'
import { ResetPassword } from './Pages/reset-password/index.jsx'

global.appVersion = packageJson.version;
global.local = packageJson.local;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
} else {
  // production code
  // Sentry.init({
  //   dsn: "https://f2bdc4c7ae3049cb8ba64245b933c3d4@sentry.qcompany.org/15",
  //   enableInExpoDevelopment: true,
  //   debug: true, // If `true`, Sentry will try to print out useful debugging
  //   integrations: [new Integrations.BrowserTracing()],
  //   tracesSampleRate: 1.0,
  // });
}



let theme = {
  Secondarycolor: "#FFD700",
  SecondaryDarkColor: "#F6D000",
  SecondaryLightColor: "#FFDF36",
  SecondaryUltraLight: "#FFFBE5",
  SecondaryTextColor: "#11293B",
  Secondaryonlight: "#11293B",
  TextDark: "#242424",
  TextNormal: "#545454",
  TextLight: "#808080",
  icon_light_start:"#FFD700",
  icon_light_end:"#FFDF36",
  icon_dark_start:"#11293B",
  icon_dark_end:"#214158",
}

function App(props) {
  const [i18Init, seti18Init] = React.useState(false);
  const [globalLoaded, setGlobalLoaded] = React.useState(false);

  const orderTypes = useSelector((state) => state.SharedData.WebSettings?.orderTypes)
  const dispatch = useDispatch()
   
  CheckNewVersion();
  !i18Init && i18Constructor(props.dispatch, props.i18n, props.language, seti18Init);

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
 

  useEffect(() => {
    if (getDefaultDirectionWithGlobal(global.config.defaultLanguage)) {
      setGlobalLoaded(true)
    }else{
      const interval = setInterval(() => {
        if (getDefaultDirectionWithGlobal(global.config.defaultLanguage)) {
          setGlobalLoaded(true)
          clearInterval(interval);
        }
      }, 500);
      return () => clearInterval(interval);
    }

  }, []);

  useEffect(() => {
    if (props.authenticated) {
      Api_Get_Web_Setting_AGW(dispatch, null, null)
    }

  }, [props.authenticated]);


  useEffect(() => {
    if (orderTypes && orderTypes.length == 1 && orderTypes.findIndex(element => element == "Ondemand") == -1 && props.authenticated) {
      history.push("/table")
    }
  }, [orderTypes, props.authenticated]);


  console.log()

  if(window.location.pathname == "/password-reset"){
    return <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      Components={{
        BatchOperationalSnackbar: BatchOperationalSnackbar ,
        ErrorTitleDescSnackbar : ErrorTitleDescSnackbar
      }}
    >
      <SnackbarUtilsConfigurator />
      {/* {(props.direction || global.config.Direction) == "Ltr" ? <LtrLayout /> : <RtlLayout /> } */}
      <RtlLtrCssSelector Direcrion={props.direction || getDefaultDirectionWithGlobal(global.config.defaultLanguage)}>
        <Helmet>
          <title> {global.config.customerWebText.title}</title>
        </Helmet>
        <Router history={history}>

        <ResetPassword />   
        </Router>
   
        </RtlLtrCssSelector>

    </SnackbarProvider>
  }

  if (!props.authenticated && globalLoaded) {
    return <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      Components={{
        BatchOperationalSnackbar: BatchOperationalSnackbar ,
        ErrorTitleDescSnackbar : ErrorTitleDescSnackbar
      }}
    >
      <SnackbarUtilsConfigurator />
      {/* {(props.direction || global.config.Direction) == "Ltr" ? <LtrLayout /> : <RtlLayout /> } */}
      <RtlLtrCssSelector Direcrion={props.direction || getDefaultDirectionWithGlobal(global.config.defaultLanguage)}>
      <Helmet>
            <title> {global.config.customerWebText.title}</title>
            <style type="text/css">{`
                     :root {
               --primary-primary : ${global.config.colors.secondaryColor} ;
              --primary-hover : ${global.config.colors.secondaryDarkColor} ;
              --primary-dark : ${global.config.colors.secondaryDarkColor} ;
              --primary-active : ${global.config.colors.secondaryColor}3D;
              --primary-ultra-light : ${global.config.colors.secondaryColor}14;  
              --Secondaryonlight : ${global.config.colors.secondaryonlight};    
              --primary-on-light : ${global.config.colors.secondaryonlight};       
               --primary-text-color : ${global.config.colors.secondaryTextColor};    
                  --icon_light_start : ${global.config.colors.iconLightStart}; 
                --icon_dark_start : ${global.config.colors.iconDarkStart};      
                --secondaryUltraLight :  ${global.config.colors.secondaryUltraLight};  
   
             }

             .OrderListMaperContainer .OrderListMaperNewOrder {
  /* Secondarycolor */
  /* Secondary light color */
  background-image: linear-gradient(to top, ${global.config.colors.iconLightStart}, ${global.config.colors.iconLightStart});
  color: ${global.config.colors.secondaryTextColor}
  /* SecondaryTextColor */

  }
  
  .OrderListMaperContainer .OrderListMaperNewOrder g {

    fill: ${global.config.colors.iconDarkStart};
    /* icon_dark_start */
  }
  
  .NoOrderContainer .lds-grid div {
    background: ${global.config.colors.secondaryColor};
    /* Secondarycolor */
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  * .lds-grid div {
    background: ${global.config.colors.secondaryColor};
    /* Secondarycolor */
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  .loading {
    color: ${global.config.colors.secondaryColor};
    /* Secondarycolor */
  
  }
  
  .SecondSectionHeader .LRHactiveTab {
    color: ${global.config.colors.secondaryColor};
    /* Secondarycolor */
    border-bottom: 4px solid ${global.config.colors.secondaryColor}
      /* Secondarycolor */
  
  }
  
  .loginRegisterSwitchTab .ActivePersonBusiness {
    background-color: ${global.config.colors.secondaryColor}14;
    /* SecondaryUltraLight */
  
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  
  }
  
  
  .loginregistercontinue {
    /* Secondarycolor */
    /* Secondary light color */
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  .vrHeaderEventsTitle {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  
  .EventTimeLine_Header .ETLHTc {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  

  
  // .enterprefredamount .payalowed {
  //   background-color: ${global.config.colors.secondaryColor} !important;
  //   /* Secondarycolor */
  //   color: ${global.config.colors.secondaryTextColor}
  //   /* SecondaryTextColor */
  // }
  
  .cardItempatchAdd span {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  
  orderagain {
    color: ${global.config.colors.secondaryonlight} !important;
    /* Secondaryonlight */
  }
  
  feedback {
      /* Secondarycolor */
    /* Secondary light color */
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  .newAddressbookContainer span {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  
  .bottomSheetSetPick_DropButton {
        /* Secondarycolor */
    /* Secondary light color */
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  .SettingFooterButton {
    background-color: ${global.config.colors.secondaryColor} ;
    /* Secondarycolor */
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  
  .chatWithDriverFooter .container .imgsend {
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
        /* Secondarycolor */
    /* Secondary light color */
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  sendOrder {
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
    /* Secondarycolor */
  /* Secondary light color */
  color: ${global.config.colors.secondaryTextColor}
  /* SecondaryTextColor */
  }
  
  
  .ChooseoOnMapText {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  
  .AddressbookContainerPickup span {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  
  .ChooseoOnMap {
    background-color: ${global.config.colors.secondaryColor}14;
      /* SecondaryUltraLight */
      color: ${global.config.colors.secondaryTextColor}
      /* SecondaryTextColor */
  
  }
  
  .AddressbookContainerPickupActive {
    background-color: ${global.config.colors.secondaryColor}14;
      /* SecondaryUltraLight */
      color: ${global.config.colors.secondaryTextColor}
      /* SecondaryTextColor */
  
  }
  
  
  // .avtiveServiceOptions {
  //   border: 1px solid ${global.config.colors.secondaryColor};
  //     /* Secondarycolor */
  //   color: ${global.config.colors.secondaryColor};
  //     /* Secondarycolor */
  //     background-color: ${global.config.colors.secondaryColor}14;
  //     /* SecondaryUltraLight */
  //   }
  
  
    .MethodsItemActive {
      border: 1px solid ${global.config.colors.secondaryColor};
      /* Secondarycolor */
      color: ${global.config.colors.secondaryColor};
      /* Secondarycolor */
      background-color: ${global.config.colors.secondaryColor}14;
      /* SecondaryUltraLight */
    }
  
    .MethodsItem .AddCreditCardAddStripe {
      color: ${global.config.colors.secondaryonlight} !important;
      /* Secondaryonlight */
    }
  
    .vehicleTypeNewTravelContainer .ActivevehicleTypeItemNewTravel {
      border: 1px solid ${global.config.colors.secondaryColor} !important;
      /* Secondarycolor */
      background-color: ${global.config.colors.secondaryColor}14 !important;
      /* SecondaryUltraLight */
    }
  
  
    // .itemLanguageActive {
    //   border: 1px solid ${global.config.colors.secondaryColor} !important;
    //   /* Secondarycolor */
    //   background-color: ${global.config.colors.secondaryColor}14 !important;
    //   /* SecondaryUltraLight */
    // }
  
    // .confirmLogout {
    //   background-color: ${global.config.colors.secondaryColor} !important;
    //   /* Secondarycolor */
    //   color: ${global.config.colors.secondaryTextColor}
    //   /* SecondaryTextColor */
    // }
  
    // .confirmLogout span{
    //   color: ${global.config.colors.secondaryTextColor}
    //   /* SecondaryTextColor */
    // }

    .skipLogout {
      color: ${global.config.colors.secondaryonlight} !important;
      /* Secondaryonlight */
    }
  
  
    .privaypolicy {
      color: ${global.config.colors.secondaryonlight} !important;
      /* Secondaryonlight */
    }
  
   
  
    .confirmCancellOrder {
      background-color: ${global.config.colors.secondaryColor} !important;
      /* Secondarycolor */
      color: ${global.config.colors.secondaryTextColor}
      /* SecondaryTextColor */
    }

    .confirmCancellOrder span{
      color: ${global.config.colors.secondaryTextColor}
      /* SecondaryTextColor */
    }
  
    .SkipCancellOrder {
      color: ${global.config.colors.secondaryonlight} !important;
      /* Secondaryonlight */
    }
  
    .creditcardaddBtn {
        background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
        /* Secondarycolor */
    /* Secondary light color */

    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */

    }




    .originShow defs linearGradient:last-child  stop:first-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* SecondaryLightColor */
    }

    .originShow defs linearGradient:last-child  stop:last-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* Secondarycolor */
    }


    .destinationShow defs linearGradient:last-child  stop:first-child {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .destinationShow defs linearGradient:last-child  stop:last-child {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    // pinsContainer {
    //   border-color:  ${global.config.colors.secondaryColor};
    //   /*  SecondaryLightColor  */
    // }


    .viewOrderSupportBtn linearGradient:first-child stop:nth-child(1){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .viewOrderSupportBtn linearGradient:first-child stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .viewOrderSupportBtn linearGradient:nth-child(2) stop:nth-child(1){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .viewOrderSupportBtn linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .viewOrderSupportBtn linearGradient:nth-child(3) stop:nth-child(1){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .viewOrderSupportBtn linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .viewOrderSupportBtn linearGradient:nth-child(4) stop:nth-child(1){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .viewOrderSupportBtn linearGradient:nth-child(4) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .ohdowhow .img linearGradient:nth-child(1) stop:nth-child(1){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .ohdowhow .img linearGradient:nth-child(1) stop:nth-child(2){
      stop-color:${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .ohdowhow .img linearGradient:nth-child(2) stop:nth-child(1){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .ohdowhow .img linearGradient:nth-child(2) stop:nth-child(2){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .lineContainer .upper {
      background-color: ${global.config.colors.secondaryColor};
      /*  SecondaryLightColor */
    }


    .lineContainer .loweer {
      background-color: ${global.config.colors.secondaryColor};
      /*  SecondaryLightColor  */
    }

    
    .EventTimeLine_ListItem .circule {
      background-color: ${global.config.colors.secondaryColor};
         /* Secondarycolor */
    }

    .addressbookSetPin defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .addressbookSetPin defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .admcI .add1 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .admcI .add1 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .admcI .add1 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add1 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .admcI .add2 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .admcI .add2 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .admcI .add2 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add2 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

 
    .admcI .add3 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .admcI .add3 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .admcI .add3 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add3 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

 

    .headerItemContainer .wallet defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .wallet defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .wallet defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .wallet defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .headerItemContainer .Card defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .headerItemContainer .Card defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .headerItemContainer .Card defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .headerItemContainer .Card defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    

    .headerItemContainer .Card defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Card defs linearGradient:nth-child(3) stop:nth-child(2) {
       stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }




    .headerItemContainer .Card defs linearGradient:nth-child(4) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Card defs linearGradient:nth-child(4) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }
    



    .headerItemContainer .Orderhistory defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .Orderhistory defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .Orderhistory defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Orderhistory defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .headerItemContainer .Invoices defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .headerItemContainer .Invoices defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .headerItemContainer .Invoices defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_start */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(4) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(4) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_start */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(5) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(5) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_start */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(6) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(6) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_start */
    }




    .headerItemContainer .Transactions defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .Transactions defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .Transactions defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Transactions defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .headerItemContainer .Transactions defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Transactions defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }




    .headerItemContainer .Settings defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .Settings defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .Settings defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Settings defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .headerItemContainer .Aboutus defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .Aboutus defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .Aboutus defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Aboutus defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .headerItemContainer .Aboutus defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Aboutus defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .Sort_up .xxxiconDark path  {
          fill: ${global.config.colors.iconDarkStart};
    }
    
    .Sort_up .xxxiconLight path {
          fill: ${global.config.colors.iconLightStart};
    }

    .BalanceContainer .balanceicon defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .BalanceContainer .balanceicon defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .BalanceContainer .balanceicon defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .BalanceContainer .balanceicon defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }




     
    .admcI .add4 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .admcI .add4 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .admcI .add4 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add4 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .admcI .add4 defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add4 defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .admcI .add5 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .admcI .add5 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .admcI .add5 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add5 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }




    .newAddressbookContainer .img defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.secondaryColor};
      /* Secondarycolor */
    }

    .newAddressbookContainer .img defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.secondaryColor};
      /* Secondarycolor */
    }

         
    cardItem .ofgtwpi defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    cardItem .ofgtwpi  defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    cardItem .ofgtwpi  defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    cardItem .ofgtwpi  defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    cardItem .ofgtwpi  defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    cardItem .ofgtwpi  defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .increaseBalance{
    /* Secondarycolor */
    /* Secondary light color */
      background-image: linear-gradient(to top, ${global.config.colors.secondaryColor},${global.config.colors.secondaryColor});
    }


    .increaseBalance g g{
      fill :${global.config.colors.iconDarkStart} ;
      /* Secondaryonlight */
    }



    .c7921369 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .c7921369 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .c7921369 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .c7921369 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .w9647 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .w9647 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .w9647 defs linearGradient:nth-child(2) stop:nth-child(1) {

      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .w9647 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }



    
    .wr98364 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

      .wr98364 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

      .wr98364 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

      .wr98364 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


     .wr98364 defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

     .wr98364  defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }




    .efo654852 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .efo654852 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .efo654852 defs linearGradient:nth-child(2) stop:nth-child(1) {

      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .efo654852 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }






    .AddressbookContainerPickup .img  defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .AddressbookContainerPickup .img  defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .AddressbookContainerPickup .img  defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .AddressbookContainerPickup .img  defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .AddressbookContainerPickup .img  defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .AddressbookContainerPickup .img   defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .ChooseoOnMapimg  defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .ChooseoOnMapimg defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .ChooseoOnMapimg defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .ChooseoOnMapimg defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .addDropOff defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .addDropOff defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .addDropOff defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .addDropOff defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    #OriginIconSelectedSVG defs linearGradient:last-child  stop:first-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* SecondaryLightColor */
    }

    #OriginIconSelectedSVG defs linearGradient:last-child  stop:last-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* Secondarycolor */
    }


    #destinationIconSVG defs linearGradient:last-child  stop:first-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* icon_dark_end */
    }

    #destinationIconSVG defs linearGradient:last-child  stop:last-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* icon_dark_start */
    }



    .AbookmarkActive   defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .AbookmarkActive  defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .AbookmarkActive  defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .AbookmarkActive  defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .AbookmarkActive  defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .AbookmarkActive  defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .bottomSheetSetPick_Drop .x896364d defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .bottomSheetSetPick_Drop .x896364d defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }





    .NoOrderbox  defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .NoOrderbox  defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .NoOrderbox  defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .NoOrderbox  defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .NoOrderbox defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .NoOrderbox  defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .NoOrderNewOrder {

      /* Secondarycolor */
  /* Secondary light color */
  background-image: linear-gradient(to top, ${global.config.colors.iconLightStart}, ${global.config.colors.iconLightStart});
  color: ${global.config.colors.secondaryTextColor}
  /* SecondaryTextColor */
    }

    .NoOrderNewOrder g g{
      fill :${global.config.colors.iconDarkStart} ;
      /* Secondaryonlight */
    }

    
    extraDetail .ETA path {
      fill: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    extraDetail .Promocode linearGradient:nth-child(1) stop:nth-child(1){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    extraDetail .Promocode linearGradient:nth-child(1) stop:nth-child(2){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    extraDetail .Promocode linearGradient:nth-child(2) stop:nth-child(1){
      stop-color: ${global.config.colors.iconDarkStart} ;
      /* icon_dark_end */
    }

    extraDetail .Promocode linearGradient:nth-child(2) stop:nth-child(2){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    extraDetail .Cod linearGradient:nth-child(1) stop:nth-child(1){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    extraDetail .Cod linearGradient:nth-child(1) stop:nth-child(2){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    extraDetail .Cod linearGradient:nth-child(2) stop:nth-child(1){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    extraDetail .Cod linearGradient:nth-child(2) stop:nth-child(2){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .scheduledOrderTimeConatiner .img defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .scheduledOrderTimeConatiner .img defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .scheduledOrderTimeConatiner .img #opqo5758 {
      fill : ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .scheduledOrderTimeConatiner .img #opqo5760 {
      fill : ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .addphotoService .img defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .addphotoService .img defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .addphotoService .img defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .addphotoService .img defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .AddPhotoConatiner .img defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .AddPhotoConatiner .img defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .AddPhotoConatiner .img defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .AddPhotoConatiner .img defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .AddPhotoConatiner .img defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .AddPhotoConatiner .img defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


        .addphotoService .img defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .addphotoService .img defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


// circleBlue {
//   border: 2px solid ${global.config.colors.iconLightStart};
//   /* icon_light_end */
//   background-color : ${global.config.colors.iconDarkStart} ;
//   /* icon_dark_end */
// }

vrPickupCircle {
  border: 2px solid ${global.config.colors.iconLightStart};
  /* icon_light_end */
  background-color :${global.config.colors.iconDarkStart} ;
  /* icon_dark_end */
}

vrpickuptitle .Dropoff {
  background-color :${global.config.colors.iconDarkStart} !important ;
  /* icon_dark_end */
  border: 2px solid ${global.config.colors.iconDarkStart} !important ;
  /* icon_dark_end */
}
// squre {
//   background-color :${global.config.colors.iconDarkStart} ;
//   /* icon_dark_end */
// }

#remodeCardStripe span1 {
  background-color: ${global.config.colors.secondaryColor} ;
  /* Secondarycolor */

color: ${global.config.colors.secondaryTextColor};
/* SecondaryTextColor */

}

#remodeCardStripe span2 {
  color: ${global.config.colors.secondaryColor};
  /* Secondarycolor */

}

 

driverInformation .img defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

driverInformation .img defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

driverInformation .img defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

driverInformation .img defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

driverInformation .img defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

driverInformation .img defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color:${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

driverInformation .img defs linearGradient:nth-child(4) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

driverInformation .img defs linearGradient:nth-child(4) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

driverInformation .img defs linearGradient:nth-child(5) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

driverInformation .img defs linearGradient:nth-child(5) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

driverInformation .img defs linearGradient:nth-child(6) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

driverInformation .img defs linearGradient:nth-child(6) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

driverInformation .img defs linearGradient:nth-child(7) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

driverInformation .img defs linearGradient:nth-child(7) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


 

// .ProfileMenuIcon defs linearGradient:nth-child(1) stop:nth-child(1) {
//   stop-color: ${global.config.colors.iconLightStart};
//   /* icon_light_end */
// }

// .ProfileMenuIcon defs linearGradient:nth-child(1) stop:nth-child(2) {
//   stop-color:${global.config.colors.iconLightStart};
//   /* icon_light_start */
// }

// .ProfileMenuIcon defs linearGradient:nth-child(2) stop:nth-child(1) {
//   stop-color: ${global.config.colors.iconDarkStart};
//   /* icon_dark_end */
// }

// .ProfileMenuIcon defs linearGradient:nth-child(2) stop:nth-child(2) {
//   stop-color: ${global.config.colors.iconDarkStart};
//   /* icon_dark_start */
// }

// .ProfileMenuIcon defs linearGradient:nth-child(3) stop:nth-child(1) {
//   stop-color: ${global.config.colors.iconLightStart};
//   /* icon_light_end */
// }

// .ProfileMenuIcon defs linearGradient:nth-child(3) stop:nth-child(2) {
//   stop-color: ${global.config.colors.iconLightStart};
//   /* icon_light_start */
// }





.USERProfileiMAGE defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

.USERProfileiMAGE defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

.USERProfileiMAGE defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

.USERProfileiMAGE defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.USERProfileiMAGE defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

.USERProfileiMAGE defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

.USERProfileiMAGE defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}


.AddCreditCard {
    /* Secondarycolor */
    /* Secondary light color */
    background-image: linear-gradient(to top,${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
  color: ${global.config.colors.secondaryTextColor};
  /* SecondaryTextColor */
}

 


.NoCardsIcon defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.NoCardsIcon defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

.NoCardsIcon defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color:${global.config.colors.iconLightStart};
  /* icon_light_end */
}

.NoCardsIcon defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

.NoCardsIcon defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.NoCardsIcon defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

.NoCardsIcon defs linearGradient:nth-child(4) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.NoCardsIcon defs linearGradient:nth-child(4) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


.NoTransactionCard  defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_end */
}

.NoTransactionCard  defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_start */
}

.NoTransactionCard defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color:${global.config.colors.iconLightStart} !important;
  /* icon_light_end */
}


.NoTransactionCard  defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart} !important;
  /* icon_light_start */
}


.NoTransactionCard  defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color:${global.config.colors.iconDarkStart} !important;
  /* icon_dark_end */
}


.NoTransactionCard  defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_start */
}




.NoTransactionCard defs linearGradient:nth-child(4) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_end */
}


.NoTransactionCard  defs linearGradient:nth-child(4) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_start */
}

.AddressbookImgInCo defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

.AddressbookImgInCo defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

.AddressbookImgInCo defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}


.AddressbookImgInCo defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


.AddressbookImgInCo defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}


.AddressbookImgInCo defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


 .w9647 defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.w9647 defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}



 .w9647 defs linearGradient:nth-child(4) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.w9647 defs linearGradient:nth-child(4) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


.efo654852 defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color:${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.efo654852 defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color:${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

.AddphotoBtnSave {
  /* Secondarycolor */
  /* Secondary light color */
  background-image: linear-gradient(to top,${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
  color: ${global.config.colors.secondaryTextColor};
  /* SecondaryTextColor */
}

.DriverpinSass defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color:${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.DriverpinSass defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

.AddressbookCircularProgressmenu {
  color: ${global.config.colors.secondaryColor} !important;
    /* Secondarycolor */
}

thdotItem .spanblue {
  color: ${global.config.colors.secondaryonlight} !important;
    /* Secondaryonlight */
}


.xusoiwwjhq defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

.xusoiwwjhq defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

.xusoiwwjhq defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}


.xusoiwwjhq defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


.ScheduleDialogeButttonContainer .confirmReserve {
  background-color: ${global.config.colors.secondaryColor} !important;
  /* Secondarycolor */
  color: ${global.config.colors.secondaryTextColor}  !important;
  /* SecondaryTextColor */
}


.ScheduleDialogeButttonContainer .CancelReserve {
  background-color: rgb(128 128 128 / 4%) !important;
  color: ${global.config.colors.secondaryonlight} !important;
      /* Secondarycolor */
}

.ScheduleDialogeDateTimeItemActive {
  border: solid 1px  ${global.config.colors.secondaryColor} !important;
  /* Secondarycolor */
  background-color: ${global.config.colors.secondaryColor}14 !important;
  /* SecondaryUltraLight */
  color:  ${global.config.colors.secondaryonlight} !important;
    /* Secondaryonlight */
}


.ScheduleDialogeDateTimeItem:hover {
  border: solid 1px  ${global.config.colors.secondaryColor} !important;
  /* Secondarycolor */
  background-color: ${global.config.colors.secondaryColor}14 !important;
  /* SecondaryUltraLight */
  color:  ${global.config.colors.secondaryonlight} !important;
    /* Secondaryonlight */
}

defs #duhjz5luma stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}
defs #duhjz5luma stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

defs #zs3uzqvaeb stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}
defs #zs3uzqvaeb stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

defs #a963456963 stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}
defs #a963456963 stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

defs #b963456963 stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}
defs #b963456963 stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

#c963456963 {
  fill :${global.config.colors.secondaryonlight} ;
  /* Secondaryonlight */
}


.ChangePasswordicon .ChangePasswordicon1 {
  fill :${global.config.colors.iconDarkStart} ;
}

.ChangePasswordicon .ChangePasswordicon2 {
  fill :${global.config.colors.iconLightStart} ;
}


.changingCssAnimation {
  display: none !important;
}

.orderTypesHeaderTabActive {
    background-color: white !important;
    color: var(--Secondaryonlight) !important;
}
   

// .orderTypesHeaderTabNotActive {
//     color: ${global.config.colors.secondaryTextColor} !important;
// }

  .NewOrderBtnsHeader {
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
    color: ${global.config.colors.secondaryTextColor} !important;
}
   body .NewOrderBtnsHeader span{
    color: ${global.config.colors.secondaryTextColor} !important;
}

  .NewOrderBtnsHeaderIcon {
    fill: ${global.config.colors.secondaryTextColor};
}




.ImportBtnsHeaderIcon  defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_end */
}

.ImportBtnsHeaderIcon  defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_start */
}

.ImportBtnsHeaderIcon defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color:${global.config.colors.iconLightStart} !important;
  /* icon_light_end */
}


.ImportBtnsHeaderIcon  defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart} !important;
  /* icon_light_start */
}


.ImportBtnsHeaderIcon  defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color:${global.config.colors.iconLightStart} !important;
  /* icon_dark_end */
}


.ImportBtnsHeaderIcon  defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart} !important;
  /* icon_dark_start */
}




  .noOrderListIcon defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .noOrderListIcon defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .noOrderListIcon defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .noOrderListIcon defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


      .es_time defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .es_time defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .es_time defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .es_time defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



   .barcode2 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .barcode2 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .barcode2 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_dark_start */
    }

    .barcode2 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_dark_start */
    }

       .barcode2 defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .barcode2 defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .barcode2 defs linearGradient:nth-child(4) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .barcode2 defs linearGradient:nth-child(4) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .inputFilterDesignSystemApply {
        color: var(--Secondaryonlight) !important;

    }

    .BARCODEIcon * {
    fill: ${global.config.colors.iconDarkStart};
    }

    .BARCODEIcon [fill-opacity ="0.7"]{
        fill: ${global.config.colors.iconDarkStart};
    }
  
    .BARCODEIcon [fill-opacity ="0.2"]{
        fill: ${global.config.colors.iconLightStart};
    }

       .BARCODEIcon [fill-opacity ="1.0"]{
        fill: ${global.config.colors.iconDarkStart};
    }
  
    .BARCODEIcon [fill-opacity ="0.99"]{
        fill: ${global.config.colors.iconLightStart};
    }


    .ConfirmationTextModal {
            background-color: ${global.config.colors.secondaryColor} !important;
    color: ${global.config.colors.secondaryTextColor} !important;

    }


    .CheckboxDesignSystemBack {
                background-color: ${global.config.colors.secondaryColor} !important;

    }

    .OrderIDTable {
 color: var(--Secondaryonlight) !important;    }

    .eventDetailsContainer .eventDetailsContaineractiveTab {
        background-color: ${global.config.colors.secondaryColor}14 ;
        color: ${global.config.colors.secondaryonlight};
    }

    .filterClearAll {
    color:${global.config.colors.secondaryColor}
    }

    .Csv defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_dark_start */
    }

    .Csv defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_dark_start */
    }

    
    .Csv defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .Csv defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

       .Csv defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_end */
    }


    .Csv defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_start */
    }

    .Csv defs linearGradient:nth-child(4) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .Csv defs linearGradient:nth-child(4) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

.rolgprtu {
        background-color: unset;
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
}

.rolgprtu span {
    color:  ${global.config.colors.secondaryTextColor};
}

.Open_file path {
        fill: ${global.config.colors.secondaryTextColor};
}
    :root {
      --filterActivebutton: ${global.config.colors.secondaryColor}14;
    }
         `}</style>
            {/* <style type="text/css">{`
            :root {
               --primary-primary : ${global.config.Secondarycolor} ;
              --primary-hover : ${global.config.SecondaryDarkColor} ;
              --primary-dark : ${global.config.SecondaryDarkColor} ;
              --primary-active : ${global.config.Secondarycolor}3D;
              --primary-ultra-light : ${global.config.Secondarycolor}14;  
              --Secondaryonlight : ${global.config.Secondaryonlight};    
              --primary-on-light : ${global.config.Secondaryonlight};       
               --primary-text-color : ${global.config.SecondaryTextColor};    
                  --icon_light_start : #FFD700;
                --icon_dark_start : #11293B;      
   
             {
         `}</style> */}
                   {/* <style type="text/css">{`
            :root {
               --primary-primary :  #FFD700 ;
              --primary-hover : #FFB800 ;
              --primary-dark : #FFB800 ;
              --primary-active : #FFD7003D;
              --primary-ultra-light : #FFD70014;  
              --Secondaryonlight : #11293B;             
              --primary-on-light : #11293B;   
               --primary-text-color : #11293B;          
                --icon_light_start : #FFD700;
                --icon_dark_start : #11293B;
                   }
         `}</style> */}
          </Helmet>
        
        <LoginRegister />      
        </RtlLtrCssSelector>

    </SnackbarProvider>
  }else if ((!orderTypes && props.authenticated) || !globalLoaded ) {
    return <div style={{ backgroundColor: "white", width: '100vw', height: "100vh", position: "absolute", left: "0", top: "0", zIndex: "500" }}>
      <img style={{ width: "110px", marginLeft: "calc(50% - 55px)", float: "left", marginTop: "calc(50vh - 100px)" }} src={loading} />
    </div>
  }else{
    return (
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        Components={{
          BatchOperationalSnackbar: BatchOperationalSnackbar ,
          ErrorTitleDescSnackbar : ErrorTitleDescSnackbar        }}
      >
        <SnackbarUtilsConfigurator />
        {/* {(props.direction || global.config.Direction) == "Ltr" ? <LtrLayout /> : <RtlLayout /> } */}
        <RtlLtrCssSelector Direcrion={props.direction || getDefaultDirectionWithGlobal(global.config.defaultLanguage)}>
          <Helmet>
            <title> {global.config.customerWebText.title}</title>
            <style type="text/css">{`
                     :root {
               --primary-primary : ${global.config.colors.secondaryColor} ;
              --primary-hover : ${global.config.colors.secondaryDarkColor} ;
              --primary-dark : ${global.config.colors.secondaryDarkColor} ;
              --primary-active : ${global.config.colors.secondaryColor}3D;
              --primary-ultra-light : ${global.config.colors.secondaryColor}14;  
              --Secondaryonlight : ${global.config.colors.secondaryonlight};    
              --primary-on-light : ${global.config.colors.secondaryonlight};       
               --primary-text-color : ${global.config.colors.secondaryTextColor};    
                  --icon_light_start : ${global.config.colors.iconLightStart}; 
                --icon_dark_start : ${global.config.colors.iconDarkStart};      
                --secondaryUltraLight :  ${global.config.colors.secondaryUltraLight};  
   
             }

             .OrderListMaperContainer .OrderListMaperNewOrder {
  /* Secondarycolor */
  /* Secondary light color */
  background-image: linear-gradient(to top, ${global.config.colors.iconLightStart}, ${global.config.colors.iconLightStart});
  color: ${global.config.colors.secondaryTextColor}
  /* SecondaryTextColor */

  }
  
  .OrderListMaperContainer .OrderListMaperNewOrder g {

    fill: ${global.config.colors.iconDarkStart};
    /* icon_dark_start */
  }
  
  .NoOrderContainer .lds-grid div {
    background: ${global.config.colors.secondaryColor};
    /* Secondarycolor */
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  * .lds-grid div {
    background: ${global.config.colors.secondaryColor};
    /* Secondarycolor */
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  .loading {
    color: ${global.config.colors.secondaryColor};
    /* Secondarycolor */
  
  }
  
  .SecondSectionHeader .LRHactiveTab {
    color: ${global.config.colors.secondaryColor};
    /* Secondarycolor */
    border-bottom: 4px solid ${global.config.colors.secondaryColor}
      /* Secondarycolor */
  
  }
  
  .loginRegisterSwitchTab .ActivePersonBusiness {
    background-color: ${global.config.colors.secondaryColor}14;
    /* SecondaryUltraLight */
  
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  
  }
  
  
  .loginregistercontinue {
    /* Secondarycolor */
    /* Secondary light color */
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  .vrHeaderEventsTitle {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  
  .EventTimeLine_Header .ETLHTc {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  

  
  // .enterprefredamount .payalowed {
  //   background-color: ${global.config.colors.secondaryColor} !important;
  //   /* Secondarycolor */
  //   color: ${global.config.colors.secondaryTextColor}
  //   /* SecondaryTextColor */
  // }
  
  .cardItempatchAdd span {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  
  orderagain {
    color: ${global.config.colors.secondaryonlight} !important;
    /* Secondaryonlight */
  }
  
  feedback {
      /* Secondarycolor */
    /* Secondary light color */
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  .newAddressbookContainer span {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  
  .bottomSheetSetPick_DropButton {
        /* Secondarycolor */
    /* Secondary light color */
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  .SettingFooterButton {
    background-color: ${global.config.colors.secondaryColor} ;
    /* Secondarycolor */
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  
  .chatWithDriverFooter .container .imgsend {
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
        /* Secondarycolor */
    /* Secondary light color */
    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */
  }
  
  sendOrder {
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
    /* Secondarycolor */
  /* Secondary light color */
  color: ${global.config.colors.secondaryTextColor}
  /* SecondaryTextColor */
  }
  
  
  .ChooseoOnMapText {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  
  .AddressbookContainerPickup span {
    color: ${global.config.colors.secondaryonlight};
    /* Secondaryonlight */
  }
  
  .ChooseoOnMap {
    background-color: ${global.config.colors.secondaryColor}14;
      /* SecondaryUltraLight */
      color: ${global.config.colors.secondaryTextColor}
      /* SecondaryTextColor */
  
  }
  
  .AddressbookContainerPickupActive {
    background-color: ${global.config.colors.secondaryColor}14;
      /* SecondaryUltraLight */
      color: ${global.config.colors.secondaryTextColor}
      /* SecondaryTextColor */
  
  }
  
  
  // .avtiveServiceOptions {
  //   border: 1px solid ${global.config.colors.secondaryColor};
  //     /* Secondarycolor */
  //   color: ${global.config.colors.secondaryColor};
  //     /* Secondarycolor */
  //     background-color: ${global.config.colors.secondaryColor}14;
  //     /* SecondaryUltraLight */
  //   }
  
  
    .MethodsItemActive {
      border: 1px solid ${global.config.colors.secondaryColor};
      /* Secondarycolor */
      color: ${global.config.colors.secondaryColor};
      /* Secondarycolor */
      background-color: ${global.config.colors.secondaryColor}14;
      /* SecondaryUltraLight */
    }
  
    .MethodsItem .AddCreditCardAddStripe {
      color: ${global.config.colors.secondaryonlight} !important;
      /* Secondaryonlight */
    }
  
    .vehicleTypeNewTravelContainer .ActivevehicleTypeItemNewTravel {
      border: 1px solid ${global.config.colors.secondaryColor} !important;
      /* Secondarycolor */
      background-color: ${global.config.colors.secondaryColor}14 !important;
      /* SecondaryUltraLight */
    }
  
  
    // .itemLanguageActive {
    //   border: 1px solid ${global.config.colors.secondaryColor} !important;
    //   /* Secondarycolor */
    //   background-color: ${global.config.colors.secondaryColor}14 !important;
    //   /* SecondaryUltraLight */
    // }
  
    // .confirmLogout {
    //   background-color: ${global.config.colors.secondaryColor} !important;
    //   /* Secondarycolor */
    //   color: ${global.config.colors.secondaryTextColor}
    //   /* SecondaryTextColor */
    // }
  
    // .confirmLogout span{
    //   color: ${global.config.colors.secondaryTextColor}
    //   /* SecondaryTextColor */
    // }

    .skipLogout {
      color: ${global.config.colors.secondaryonlight} !important;
      /* Secondaryonlight */
    }
  
  
    .privaypolicy {
      color: ${global.config.colors.secondaryonlight} !important;
      /* Secondaryonlight */
    }
  
   
  
    .confirmCancellOrder {
      background-color: ${global.config.colors.secondaryColor} !important;
      /* Secondarycolor */
      color: ${global.config.colors.secondaryTextColor}
      /* SecondaryTextColor */
    }

    .confirmCancellOrder span{
      color: ${global.config.colors.secondaryTextColor}
      /* SecondaryTextColor */
    }
  
    .SkipCancellOrder {
      color: ${global.config.colors.secondaryonlight} !important;
      /* Secondaryonlight */
    }
  
    .creditcardaddBtn {
        background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
        /* Secondarycolor */
    /* Secondary light color */

    color: ${global.config.colors.secondaryTextColor}
    /* SecondaryTextColor */

    }




    .originShow defs linearGradient:last-child  stop:first-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* SecondaryLightColor */
    }

    .originShow defs linearGradient:last-child  stop:last-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* Secondarycolor */
    }


    .destinationShow defs linearGradient:last-child  stop:first-child {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .destinationShow defs linearGradient:last-child  stop:last-child {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    // pinsContainer {
    //   border-color:  ${global.config.colors.secondaryColor};
    //   /*  SecondaryLightColor  */
    // }


    .viewOrderSupportBtn linearGradient:first-child stop:nth-child(1){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .viewOrderSupportBtn linearGradient:first-child stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .viewOrderSupportBtn linearGradient:nth-child(2) stop:nth-child(1){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .viewOrderSupportBtn linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .viewOrderSupportBtn linearGradient:nth-child(3) stop:nth-child(1){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .viewOrderSupportBtn linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .viewOrderSupportBtn linearGradient:nth-child(4) stop:nth-child(1){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .viewOrderSupportBtn linearGradient:nth-child(4) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .ohdowhow .img linearGradient:nth-child(1) stop:nth-child(1){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .ohdowhow .img linearGradient:nth-child(1) stop:nth-child(2){
      stop-color:${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .ohdowhow .img linearGradient:nth-child(2) stop:nth-child(1){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .ohdowhow .img linearGradient:nth-child(2) stop:nth-child(2){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .lineContainer .upper {
      background-color: ${global.config.colors.secondaryColor};
      /*  SecondaryLightColor */
    }


    .lineContainer .loweer {
      background-color: ${global.config.colors.secondaryColor};
      /*  SecondaryLightColor  */
    }

    
    .EventTimeLine_ListItem .circule {
      background-color: ${global.config.colors.secondaryColor};
         /* Secondarycolor */
    }

    .addressbookSetPin defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .addressbookSetPin defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .admcI .add1 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .admcI .add1 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .admcI .add1 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add1 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .admcI .add2 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .admcI .add2 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .admcI .add2 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add2 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

 
    .admcI .add3 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .admcI .add3 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .admcI .add3 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add3 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

 

    .headerItemContainer .wallet defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .wallet defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .wallet defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .wallet defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .headerItemContainer .Card defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .headerItemContainer .Card defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .headerItemContainer .Card defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .headerItemContainer .Card defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    

    .headerItemContainer .Card defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Card defs linearGradient:nth-child(3) stop:nth-child(2) {
       stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }




    .headerItemContainer .Card defs linearGradient:nth-child(4) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Card defs linearGradient:nth-child(4) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }
    



    .headerItemContainer .Orderhistory defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .Orderhistory defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .Orderhistory defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Orderhistory defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .headerItemContainer .Invoices defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .headerItemContainer .Invoices defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .headerItemContainer .Invoices defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_start */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(4) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(4) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_start */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(5) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(5) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_start */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(6) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_end */
    }

    .headerItemContainer .Invoices defs linearGradient:nth-child(6) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_start */
    }




    .headerItemContainer .Transactions defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .Transactions defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .Transactions defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Transactions defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .headerItemContainer .Transactions defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Transactions defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .AddressbookImg defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }




    .headerItemContainer .Settings defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .Settings defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .Settings defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Settings defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .headerItemContainer .Aboutus defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .headerItemContainer .Aboutus defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .headerItemContainer .Aboutus defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Aboutus defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .headerItemContainer .Aboutus defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .headerItemContainer .Aboutus defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .Sort_up .xxxiconDark path  {
          fill: ${global.config.colors.iconDarkStart};
    }
    
    .Sort_up .xxxiconLight path {
          fill: ${global.config.colors.iconLightStart};
    }

    .BalanceContainer .balanceicon defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .BalanceContainer .balanceicon defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .BalanceContainer .balanceicon defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .BalanceContainer .balanceicon defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }




     
    .admcI .add4 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .admcI .add4 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .admcI .add4 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add4 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .admcI .add4 defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add4 defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .admcI .add5 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .admcI .add5 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .admcI .add5 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .admcI .add5 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }




    .newAddressbookContainer .img defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.secondaryColor};
      /* Secondarycolor */
    }

    .newAddressbookContainer .img defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.secondaryColor};
      /* Secondarycolor */
    }

         
    cardItem .ofgtwpi defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    cardItem .ofgtwpi  defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    cardItem .ofgtwpi  defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    cardItem .ofgtwpi  defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    cardItem .ofgtwpi  defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    cardItem .ofgtwpi  defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .increaseBalance{
    /* Secondarycolor */
    /* Secondary light color */
      background-image: linear-gradient(to top, ${global.config.colors.secondaryColor},${global.config.colors.secondaryColor});
    }


    .increaseBalance g g{
      fill :${global.config.colors.iconDarkStart} ;
      /* Secondaryonlight */
    }



    .c7921369 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .c7921369 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .c7921369 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .c7921369 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .w9647 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .w9647 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .w9647 defs linearGradient:nth-child(2) stop:nth-child(1) {

      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .w9647 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }



    
    .wr98364 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

      .wr98364 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

      .wr98364 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

      .wr98364 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


     .wr98364 defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

     .wr98364  defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }




    .efo654852 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .efo654852 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .efo654852 defs linearGradient:nth-child(2) stop:nth-child(1) {

      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .efo654852 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }






    .AddressbookContainerPickup .img  defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .AddressbookContainerPickup .img  defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .AddressbookContainerPickup .img  defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .AddressbookContainerPickup .img  defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .AddressbookContainerPickup .img  defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .AddressbookContainerPickup .img   defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .ChooseoOnMapimg  defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .ChooseoOnMapimg defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .ChooseoOnMapimg defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .ChooseoOnMapimg defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .addDropOff defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .addDropOff defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .addDropOff defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .addDropOff defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    #OriginIconSelectedSVG defs linearGradient:last-child  stop:first-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* SecondaryLightColor */
    }

    #OriginIconSelectedSVG defs linearGradient:last-child  stop:last-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* Secondarycolor */
    }


    #destinationIconSVG defs linearGradient:last-child  stop:first-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* icon_dark_end */
    }

    #destinationIconSVG defs linearGradient:last-child  stop:last-child {
      stop-color: ${global.config.colors.secondaryColor};
      /* icon_dark_start */
    }



    .AbookmarkActive   defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .AbookmarkActive  defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .AbookmarkActive  defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .AbookmarkActive  defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    .AbookmarkActive  defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .AbookmarkActive  defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .bottomSheetSetPick_Drop .x896364d defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .bottomSheetSetPick_Drop .x896364d defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }





    .NoOrderbox  defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .NoOrderbox  defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .NoOrderbox  defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .NoOrderbox  defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .NoOrderbox defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .NoOrderbox  defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .NoOrderNewOrder {

      /* Secondarycolor */
  /* Secondary light color */
  background-image: linear-gradient(to top, ${global.config.colors.iconLightStart}, ${global.config.colors.iconLightStart});
  color: ${global.config.colors.secondaryTextColor}
  /* SecondaryTextColor */
    }

    .NoOrderNewOrder g g{
      fill :${global.config.colors.iconDarkStart} ;
      /* Secondaryonlight */
    }

    
    extraDetail .ETA path {
      fill: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    extraDetail .Promocode linearGradient:nth-child(1) stop:nth-child(1){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    extraDetail .Promocode linearGradient:nth-child(1) stop:nth-child(2){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    extraDetail .Promocode linearGradient:nth-child(2) stop:nth-child(1){
      stop-color: ${global.config.colors.iconDarkStart} ;
      /* icon_dark_end */
    }

    extraDetail .Promocode linearGradient:nth-child(2) stop:nth-child(2){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    extraDetail .Cod linearGradient:nth-child(1) stop:nth-child(1){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    extraDetail .Cod linearGradient:nth-child(1) stop:nth-child(2){
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


    extraDetail .Cod linearGradient:nth-child(2) stop:nth-child(1){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    extraDetail .Cod linearGradient:nth-child(2) stop:nth-child(2){
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .scheduledOrderTimeConatiner .img defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .scheduledOrderTimeConatiner .img defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .scheduledOrderTimeConatiner .img #opqo5758 {
      fill : ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .scheduledOrderTimeConatiner .img #opqo5760 {
      fill : ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }


    .addphotoService .img defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .addphotoService .img defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }


    .addphotoService .img defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .addphotoService .img defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



    .AddPhotoConatiner .img defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }

    .AddPhotoConatiner .img defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .AddPhotoConatiner .img defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .AddPhotoConatiner .img defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .AddPhotoConatiner .img defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .AddPhotoConatiner .img defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


        .addphotoService .img defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_end */
    }

    .addphotoService .img defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


// circleBlue {
//   border: 2px solid ${global.config.colors.iconLightStart};
//   /* icon_light_end */
//   background-color : ${global.config.colors.iconDarkStart} ;
//   /* icon_dark_end */
// }

vrPickupCircle {
  border: 2px solid ${global.config.colors.iconLightStart};
  /* icon_light_end */
  background-color :${global.config.colors.iconDarkStart} ;
  /* icon_dark_end */
}

vrpickuptitle .Dropoff {
  background-color :${global.config.colors.iconDarkStart} !important ;
  /* icon_dark_end */
  border: 2px solid ${global.config.colors.iconDarkStart} !important ;
  /* icon_dark_end */
}
// squre {
//   background-color :${global.config.colors.iconDarkStart} ;
//   /* icon_dark_end */
// }

#remodeCardStripe span1 {
  background-color: ${global.config.colors.secondaryColor} ;
  /* Secondarycolor */

color: ${global.config.colors.secondaryTextColor};
/* SecondaryTextColor */

}

#remodeCardStripe span2 {
  color: ${global.config.colors.secondaryColor};
  /* Secondarycolor */

}

 

driverInformation .img defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

driverInformation .img defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

driverInformation .img defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

driverInformation .img defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

driverInformation .img defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

driverInformation .img defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color:${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

driverInformation .img defs linearGradient:nth-child(4) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

driverInformation .img defs linearGradient:nth-child(4) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

driverInformation .img defs linearGradient:nth-child(5) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

driverInformation .img defs linearGradient:nth-child(5) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

driverInformation .img defs linearGradient:nth-child(6) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

driverInformation .img defs linearGradient:nth-child(6) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

driverInformation .img defs linearGradient:nth-child(7) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

driverInformation .img defs linearGradient:nth-child(7) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


 

// .ProfileMenuIcon defs linearGradient:nth-child(1) stop:nth-child(1) {
//   stop-color: ${global.config.colors.iconLightStart};
//   /* icon_light_end */
// }

// .ProfileMenuIcon defs linearGradient:nth-child(1) stop:nth-child(2) {
//   stop-color:${global.config.colors.iconLightStart};
//   /* icon_light_start */
// }

// .ProfileMenuIcon defs linearGradient:nth-child(2) stop:nth-child(1) {
//   stop-color: ${global.config.colors.iconDarkStart};
//   /* icon_dark_end */
// }

// .ProfileMenuIcon defs linearGradient:nth-child(2) stop:nth-child(2) {
//   stop-color: ${global.config.colors.iconDarkStart};
//   /* icon_dark_start */
// }

// .ProfileMenuIcon defs linearGradient:nth-child(3) stop:nth-child(1) {
//   stop-color: ${global.config.colors.iconLightStart};
//   /* icon_light_end */
// }

// .ProfileMenuIcon defs linearGradient:nth-child(3) stop:nth-child(2) {
//   stop-color: ${global.config.colors.iconLightStart};
//   /* icon_light_start */
// }





.USERProfileiMAGE defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

.USERProfileiMAGE defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

.USERProfileiMAGE defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

.USERProfileiMAGE defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.USERProfileiMAGE defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

.USERProfileiMAGE defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

.USERProfileiMAGE defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}


.AddCreditCard {
    /* Secondarycolor */
    /* Secondary light color */
    background-image: linear-gradient(to top,${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
  color: ${global.config.colors.secondaryTextColor};
  /* SecondaryTextColor */
}

 


.NoCardsIcon defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.NoCardsIcon defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

.NoCardsIcon defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color:${global.config.colors.iconLightStart};
  /* icon_light_end */
}

.NoCardsIcon defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

.NoCardsIcon defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.NoCardsIcon defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

.NoCardsIcon defs linearGradient:nth-child(4) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.NoCardsIcon defs linearGradient:nth-child(4) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


.NoTransactionCard  defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_end */
}

.NoTransactionCard  defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_start */
}

.NoTransactionCard defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color:${global.config.colors.iconLightStart} !important;
  /* icon_light_end */
}


.NoTransactionCard  defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart} !important;
  /* icon_light_start */
}


.NoTransactionCard  defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color:${global.config.colors.iconDarkStart} !important;
  /* icon_dark_end */
}


.NoTransactionCard  defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_start */
}




.NoTransactionCard defs linearGradient:nth-child(4) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_end */
}


.NoTransactionCard  defs linearGradient:nth-child(4) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_start */
}

.AddressbookImgInCo defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

.AddressbookImgInCo defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

.AddressbookImgInCo defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}


.AddressbookImgInCo defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


.AddressbookImgInCo defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}


.AddressbookImgInCo defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


 .w9647 defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.w9647 defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}



 .w9647 defs linearGradient:nth-child(4) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.w9647 defs linearGradient:nth-child(4) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


.efo654852 defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color:${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.efo654852 defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color:${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

.AddphotoBtnSave {
  /* Secondarycolor */
  /* Secondary light color */
  background-image: linear-gradient(to top,${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
  color: ${global.config.colors.secondaryTextColor};
  /* SecondaryTextColor */
}

.DriverpinSass defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color:${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

.DriverpinSass defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}

.AddressbookCircularProgressmenu {
  color: ${global.config.colors.secondaryColor} !important;
    /* Secondarycolor */
}

thdotItem .spanblue {
  color: ${global.config.colors.secondaryonlight} !important;
    /* Secondaryonlight */
}


.xusoiwwjhq defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

.xusoiwwjhq defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}

.xusoiwwjhq defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}


.xusoiwwjhq defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}


.ScheduleDialogeButttonContainer .confirmReserve {
  background-color: ${global.config.colors.secondaryColor} !important;
  /* Secondarycolor */
  color: ${global.config.colors.secondaryTextColor}  !important;
  /* SecondaryTextColor */
}


.ScheduleDialogeButttonContainer .CancelReserve {
  background-color: rgb(128 128 128 / 4%) !important;
  color: ${global.config.colors.secondaryonlight} !important;
      /* Secondarycolor */
}

.ScheduleDialogeDateTimeItemActive {
  border: solid 1px  ${global.config.colors.secondaryColor} !important;
  /* Secondarycolor */
  background-color: ${global.config.colors.secondaryColor}14 !important;
  /* SecondaryUltraLight */
  color:  ${global.config.colors.secondaryonlight} !important;
    /* Secondaryonlight */
}


.ScheduleDialogeDateTimeItem:hover {
  border: solid 1px  ${global.config.colors.secondaryColor} !important;
  /* Secondarycolor */
  background-color: ${global.config.colors.secondaryColor}14 !important;
  /* SecondaryUltraLight */
  color:  ${global.config.colors.secondaryonlight} !important;
    /* Secondaryonlight */
}

defs #duhjz5luma stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}
defs #duhjz5luma stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

defs #zs3uzqvaeb stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}
defs #zs3uzqvaeb stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

defs #a963456963 stop:nth-child(1) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_start */
}
defs #a963456963 stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart};
  /* icon_light_end */
}

defs #b963456963 stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_start */
}
defs #b963456963 stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart};
  /* icon_dark_end */
}

#c963456963 {
  fill :${global.config.colors.secondaryonlight} ;
  /* Secondaryonlight */
}


.ChangePasswordicon .ChangePasswordicon1 {
  fill :${global.config.colors.iconDarkStart} ;
}

.ChangePasswordicon .ChangePasswordicon2 {
  fill :${global.config.colors.iconLightStart} ;
}


.changingCssAnimation {
  display: none !important;
}

.orderTypesHeaderTabActive {
    background-color: white !important;
    color: var(--Secondaryonlight) !important;
}
   

// .orderTypesHeaderTabNotActive {
//     color: ${global.config.colors.secondaryTextColor} !important;
// }

  .NewOrderBtnsHeader {
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
    color: ${global.config.colors.secondaryTextColor} !important;
}
   body .NewOrderBtnsHeader span{
    color: ${global.config.colors.secondaryTextColor} !important;
}

  .NewOrderBtnsHeaderIcon {
    fill: ${global.config.colors.secondaryTextColor};
}




.ImportBtnsHeaderIcon  defs linearGradient:nth-child(1) stop:nth-child(1) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_end */
}

.ImportBtnsHeaderIcon  defs linearGradient:nth-child(1) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconDarkStart} !important;
  /* icon_dark_start */
}

.ImportBtnsHeaderIcon defs linearGradient:nth-child(2) stop:nth-child(1) {
  stop-color:${global.config.colors.iconLightStart} !important;
  /* icon_light_end */
}


.ImportBtnsHeaderIcon  defs linearGradient:nth-child(2) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart} !important;
  /* icon_light_start */
}


.ImportBtnsHeaderIcon  defs linearGradient:nth-child(3) stop:nth-child(1) {
  stop-color:${global.config.colors.iconLightStart} !important;
  /* icon_dark_end */
}


.ImportBtnsHeaderIcon  defs linearGradient:nth-child(3) stop:nth-child(2) {
  stop-color: ${global.config.colors.iconLightStart} !important;
  /* icon_dark_start */
}




  .noOrderListIcon defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .noOrderListIcon defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .noOrderListIcon defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .noOrderListIcon defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }


      .es_time defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .es_time defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .es_time defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .es_time defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }



   .barcode2 defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .barcode2 defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .barcode2 defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_dark_start */
    }

    .barcode2 defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_dark_start */
    }

       .barcode2 defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_end */
    }


    .barcode2 defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_light_start */
    }

    .barcode2 defs linearGradient:nth-child(4) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .barcode2 defs linearGradient:nth-child(4) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .inputFilterDesignSystemApply {
        color: var(--Secondaryonlight) !important;

    }

    .BARCODEIcon * {
    fill: ${global.config.colors.iconDarkStart};
    }

    .BARCODEIcon [fill-opacity ="0.7"]{
        fill: ${global.config.colors.iconDarkStart};
    }
  
    .BARCODEIcon [fill-opacity ="0.2"]{
        fill: ${global.config.colors.iconLightStart};
    }

       .BARCODEIcon [fill-opacity ="1.0"]{
        fill: ${global.config.colors.iconDarkStart};
    }
  
    .BARCODEIcon [fill-opacity ="0.99"]{
        fill: ${global.config.colors.iconLightStart};
    }


    .ConfirmationTextModal {
            background-color: ${global.config.colors.secondaryColor} !important;
    color: ${global.config.colors.secondaryTextColor} !important;

    }


    .CheckboxDesignSystemBack {
                background-color: ${global.config.colors.secondaryColor} !important;

    }

    .OrderIDTable {
 color: var(--Secondaryonlight) !important;    }

    .eventDetailsContainer .eventDetailsContaineractiveTab {
        background-color: ${global.config.colors.secondaryColor}14 ;
        color: ${global.config.colors.secondaryonlight};
    }

    .filterClearAll {
    color:${global.config.colors.secondaryColor}
    }

    .Csv defs linearGradient:nth-child(1) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_dark_start */
    }

    .Csv defs linearGradient:nth-child(1) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconLightStart};
      /* icon_dark_start */
    }

    
    .Csv defs linearGradient:nth-child(2) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .Csv defs linearGradient:nth-child(2) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

       .Csv defs linearGradient:nth-child(3) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_end */
    }


    .Csv defs linearGradient:nth-child(3) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_light_start */
    }

    .Csv defs linearGradient:nth-child(4) stop:nth-child(1) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

    .Csv defs linearGradient:nth-child(4) stop:nth-child(2) {
      stop-color: ${global.config.colors.iconDarkStart};
      /* icon_dark_start */
    }

.rolgprtu {
        background-color: unset;
    background-image: linear-gradient(to top, ${global.config.colors.secondaryColor}, ${global.config.colors.secondaryColor});
}

.rolgprtu span {
    color:  ${global.config.colors.secondaryTextColor};
}

.Open_file path {
        fill: ${global.config.colors.secondaryTextColor};
}
    :root {
      --filterActivebutton: ${global.config.colors.secondaryColor}14;
    }
         `}</style>
            {/* <style type="text/css">{`
            :root {
               --primary-primary : ${global.config.Secondarycolor} ;
              --primary-hover : ${global.config.SecondaryDarkColor} ;
              --primary-dark : ${global.config.SecondaryDarkColor} ;
              --primary-active : ${global.config.Secondarycolor}3D;
              --primary-ultra-light : ${global.config.Secondarycolor}14;  
              --Secondaryonlight : ${global.config.Secondaryonlight};    
              --primary-on-light : ${global.config.Secondaryonlight};       
               --primary-text-color : ${global.config.SecondaryTextColor};    
                  --icon_light_start : #FFD700;
                --icon_dark_start : #11293B;      
   
             {
         `}</style> */}
                   {/* <style type="text/css">{`
            :root {
               --primary-primary :  #FFD700 ;
              --primary-hover : #FFB800 ;
              --primary-dark : #FFB800 ;
              --primary-active : #FFD7003D;
              --primary-ultra-light : #FFD70014;  
              --Secondaryonlight : #11293B;             
              --primary-on-light : #11293B;   
               --primary-text-color : #11293B;          
                --icon_light_start : #FFD700;
                --icon_dark_start : #11293B;
                   }
         `}</style> */}
          </Helmet>
          <Router history={history}>
            <Switch>

              <Route path="/password-reset" exact >
                <ResetPassword />
              </Route>   
              <PrivateRoute
                authenticated={props.authenticated}
                VerificationCode_step={props.VerificationCode_step}
                path="/neworder"
                dispatch={props.dispatch}
                name="neworder"
              >
                <NewOrderPage />
                {/* <TableOrders  isTable={false}/> */}

              </PrivateRoute>
              <PrivateRoute
                authenticated={props.authenticated}
                VerificationCode_step={props.VerificationCode_step}
                path="/order/:id"
                dispatch={props.dispatch}
                name="orderDetails"
              >
                <ViewOrder />
                {/* <TableOrders  isTable={false}/> */}

              </PrivateRoute>
              {orderTypes.findIndex(element => element == "PickupDelivery") > -1 && <PrivateRoute
                authenticated={props.authenticated}
                VerificationCode_step={props.VerificationCode_step}
                path="/table"
                dispatch={props.dispatch}
                name="table"
              >
                <TableOrders isTable={true} />
              </PrivateRoute>}
              {orderTypes.findIndex(element => element == "Ondemand") > -1 && <PrivateRoute
                authenticated={props.authenticated}
                VerificationCode_step={props.VerificationCode_step}
                path="/"
                dispatch={props.dispatch}
                name="Home"
              >
                <Main />
                {/* <TableOrders  isTable={false}/> */}
  
              </PrivateRoute>}
  
  
  
            </Switch>
          </Router>
        </RtlLtrCssSelector>
  
      </SnackbarProvider>
  
    );
  }




}

const PrivateRoute = ({
  authenticated,
  VerificationCode_step,
  dispatch,
  component: Component,
  children,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      exact
      render={(props) => {
        if (authenticated) {
          return children;
        } else {
          return /* VerificationCode_step ? <VerificationCode/> : */ <ResetPassword />;
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.SharedData.access_token,
  VerificationCode_step: state.LoginRegister.VerificationCode.VerificationCode_step,
  direction: state.SharedData.direction,
  language: state.SharedData.language
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
