import React, { useEffect, useState } from "react";
import styles from './main.module.css';
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "../../../../../design-system/lib/Typography";
import { useTranslation } from "react-i18next";
import Switch from "../../../../../design-system/lib/Switch";
import { useGoogleLogin } from '@react-oauth/google';
import Button from "../../../../../design-system/lib/Button";
import google from "../../../../../static/google.svg";
import { ARROW_LEFT, Email } from "../../../../../static";
import apple from "../../../../../static/apple.svg";
import Facebook from "../../../../../static/facebook.svg";
import Input from "../../../../../design-system/lib/Input";
import PhoneInput from "react-phone-number-input";
import FacebookLogin from "../../../../../innerLibrary/facebookLogin2/facebook";
import AppleLogin from "react-apple-login";
import { RegisterPure } from "../../../../../Redux/actions/actions";
import loadDynamicScript from "../../../../../helper/module/loadDynamicScript";
import { snack } from "../../../../../helper/module/SnackbarUtilsConfigurator";


function RegisterIndividual_Manual_start({handleback , changeState  ,handler , Individualdata}) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const [timer, setTimer] = useState(20);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [ts, setTs] = useState(null);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();


  useEffect(() => {
    requestcaptcha()
  }, [])

  const requestcaptcha = React.useCallback(() => {
    setCaptcha(null)
    setTs(null)
    // this.setState({
    //   Captcha: null ,
    //   ts:null
    // },()=>{
       loadDynamicScript(
      `https://www.google.com/recaptcha/enterprise.js?render=${global.config.grsk}`,
      "recaptchaEnterprise",
      () => {
        try {
        window.grecaptcha.enterprise.ready(() => {
          const ts = Number(String(Number(Date.now())))
          setTs(ts)

          window.grecaptcha.enterprise
            .execute(global.config.grsk, {
              action: `custom_customer_register_${ts}`
            })
            .then((token) => {
              setCaptcha(token)

            })
            .catch((error) => {
              console.log(error);
            });
        });
      } catch (error) {
        
      }
      }
    );
    // })


   
  }, []);


  useEffect(() => {

    const intervalId = setInterval(() => {
      timer > 0 && setTimer(timer - 1)
    }, 1000);


    return () => clearInterval(intervalId); //This is important
  }, [timer])



  
    const registerIndi = React.useCallback(() => {
      setLoading(true)
  
  
      dispatch(
            RegisterPure(
              dispatch,
              {
                header: {
                  Captcha: captcha,
                  ts: ts
                },
                state: {
                  phone: Individualdata.phone,
                  givenName: Individualdata.firstname,
                  familyName: Individualdata.lastname,
                  email: Individualdata.email 
                }
              },
              (res)=>{
                setLoading(false)

                if(res.res){
                  handler("Individual","login","message" , res.res)
                  handler("Individual","login","phone" , Individualdata.phone)

                  changeState("verifyNumber")
                }else if (res.error){
                  setMessage(res.error)
                }

                requestcaptcha()
              }
            )
          )
    }, [captcha , ts , Individualdata]);
  



    return <div
      className={styles.Container}
    >

     <div className={styles.header}>
      <Button size="Medium" type="Secondary" icon={ARROW_LEFT} onClick={handleback} />
      <Typography weight="Heading_Small_Bold" text={<>{t("Individual account")}  </>} style={{color:"#000000"}} />
     </div>

     {message &&   <div className={styles.thirdConnected} style={{backgroundColor:"#D0140014"}}>
      <Typography weight="Body_Middle_Medium" style={{color:"#D01400"}} text={message} />
    </div> }
    

    <div className={styles.InputContainers}>

    <Input  size="Large" value={Individualdata.email} onChange={(e)=>{handler("Individual","register","email" , e.target.value || "")}} type={"text"} isRequiredText={"*"} isRequired={true} title={t("email")}   placeHolder={t("Ex. example@example.com")}  />


        <div
            className={styles.phoneTitleContainer}
        >

            <Typography weight="Body_Small_Regular" text={<>{t("Phonenumber")} <Typography weight="Body_Small_Regular" text={"*"} style={{ color: "#D01400" ,float:"inline-end" ,marginInlineStart:"4px" }} /></>} style={{ color: "#484848" }} />

            <div className={`${styles.phoneContainer} `}>
              <PhoneInput
            defaultCountry={global.config.countryCca2}
                placeholder={t("plsenterphonnumber")}
            value={""}
            onChange={(PrePhoneInput) => {handler("Individual","register","phone" , PrePhoneInput || "")} }
                className={styles.loginpersonPhoneInput}
              />  
            </div>


        </div>

<div className={styles.lastfirstContainer}>  
    <Input  size="Large" type={"text"} isRequiredText={"*"} isRequired={true} value={Individualdata.firstname} title={t("Firstname")} onChange={(e)=>{handler("Individual","register","firstname" , e.target.value || "")}} placeHolder={t("firstnnameloginplaceholder")}  />
    <Input  size="Large" type={"text"} isRequiredText={"*"} isRequired={true} value={Individualdata.lastname} title={t("Lastname")} onChange={(e)=>{handler("Individual","register","lastname" , e.target.value || "")}} placeHolder={t("Ex. Leo")}  />
</div>
    
    </div> 

     {/* <Typography className={styles.numberPhoneSentContainer} weight="Body_Middle_Regular" text={<>{t("Enter code sent to your")} <Typography weight="Body_Middle_Regular" text={"+1234567890"} style={{color:"#242424" , float:"inline-end" , marginInlineStart:"5px" , direction:"initial" ,}} /> </>} style={{color:"#808080"}} /> */}

   {/* <div className={styles.InputContainers}>

   <Input type={"text"} title={t("Code")} onChange={()=>{}} placeHolder={t("TypeHere")}  />

   </div> */}

    {/* <Typography onClick={()=>timer > 0 && null} weight="Body_Middle_Bold" text={resendCode} style={{color:"#1080FF",alignSelf :"start" ,marginTop:"16px",opacity: timer == 0 ? "1" : ".4" , cursor : timer == 0 ? "pointer" :"not-allowed"}}   />  */}

   {/* <Typography weight="Body_Small_Regular" text={<>{t("Wrong Code")}  </>} style={{color:"#D01400",alignSelf :"start" ,marginTop:"16px"}}   /> */}


  <Button onClick={registerIndi} isLoading={loading} disabled={String(Individualdata.phone).length < 2 || String(Individualdata.email).length < 2 || String(Individualdata.firstname).length < 2 || String(Individualdata.lastname).length < 2} size="Large" type="Primary" text={t("Register")} style={{marginTop:"24px" , flex:"1" , padding:"14px 16px" , width:"calc(100% - 32px)"}} />

  {/* <Typography className={styles.termsContainer} weight="Body_Small_Regular" style={{color:"#808080"}} text={<>{t("By continuing, you agree to Onro’s ")} <Typography weight="Body_Small_Regular" style={{color:"#808080" , textDecoration :"underline" ,cursor:"pointer"}} text={<>{t("Terms of Use")}</>} /> <Typography weight="Body_Small_Regular" style={{color:"#808080"}} text={<>{t("and")}</>} /> <Typography weight="Body_Small_Regular" style={{color:"#808080", textDecoration :"underline",cursor:"pointer"}} text={<>{t("Privacy Policy.")}</>} /></>} /> */}

        </div>

}

export default RegisterIndividual_Manual_start