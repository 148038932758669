import React, { useEffect, useState } from "react";
import styles from './main.module.css';
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "../../../../../design-system/lib/Typography";
import { useTranslation } from "react-i18next";
import Switch from "../../../../../design-system/lib/Switch";
import { useGoogleLogin } from '@react-oauth/google';
import Button from "../../../../../design-system/lib/Button";
import google from "../../../../../static/google.svg";
import { Email } from "../../../../../static";
import apple from "../../../../../static/apple.svg";
import Facebook from "../../../../../static/facebook.svg";
import Input from "../../../../../design-system/lib/Input";
import PhoneInput from "react-phone-number-input";
import FacebookLogin from "../../../../../innerLibrary/facebookLogin2/facebook";
import AppleLogin from "react-apple-login";
import { decodeToken } from "react-jwt";
import { AppleTokenInfo, FacebookTokenInfo, GoogleTokenInfo } from "../../../../../Redux/actions/actions";
import axios from "axios";
import GoogleLogin from "../../../../../helper/module/GoogleLogin";
import { BusinessGoogleTokenInfoApi, Bussiness_Token_Login, LoginByAppleApi, LoginByFacebookApi, LoginByGooglepi, LoginPureApi } from "../../../../../helper/api";
import loadDynamicScript from "../../../../../helper/module/loadDynamicScript";
import { UnSucceessFul_i18 } from "../../../../../helper/Notification/sendNotification";
import { get_lang } from "../../../../../helper/UserData/userdate";


function Home_Login({changeState , registerHandler , handler  , Individualdata}) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const [activeIndex, setActiveIndex] = useState(false);
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [ts, setTs] = useState(null);

  const { t, i18n } = useTranslation();


  useEffect(() => {
    requestcaptcha()
  }, [])

  const requestcaptcha = React.useCallback(() => {
    setCaptcha(null)
    setTs(null)
    // this.setState({
    //   Captcha: null ,
    //   ts:null
    // },()=>{
       loadDynamicScript(
      `https://www.google.com/recaptcha/enterprise.js?render=${global.config.grsk}`,
      "recaptchaEnterprise",
      () => {
        try {
        window.grecaptcha.enterprise.ready(() => {
          const ts = Number(String(Number(Date.now())))

          window.grecaptcha.enterprise
            .execute(global.config.grsk, {
              action: `custom_customer_login_${ts}`
            })
            .then((token) => {
              setCaptcha(token)
              setTs(ts)

            })
            .catch((error) => {
              console.log(error);
            });
        });
      } catch (error) {
        
      }
      }
    );
    // })


   
  }, []);




  const handleAutoManual = (inde) => {
      setActiveIndex(inde)
  };


  const loginIndividual = React.useCallback(() => {
    setLoading(true)
    LoginPureApi(dispatch , {state:{phone : Individualdata.phone} , header :{Captcha : captcha , ts :ts}} , (res)=>{
      if(res.error){
        setMessage(res.error)
      }else {
        if(res.res){
          handler("Individual","login","message" , res.res)
        }
        changeState("verifyNumber")
        setMessage(null)

      }
      const ts = Number(String(Number(Date.now())))

      window.grecaptcha.enterprise
        .execute(global.config.grsk, {
          action: `custom_customer_login_${ts}`
        })
        .then((token) => {
          setCaptcha(token)
          setTs(ts)

        })
        .catch((error) => {
          console.log(error);
        });

        setLoading(false)

    })
  }, [Individualdata  ,captcha ,ts]);




    const  bussinessResponseGoogle = async (res) => {
  
      console.log("responseGoogle ",res)

      try {
        const myDecodedToken = await decodeToken(res.credential);
        console.log(myDecodedToken)
        BusinessGoogleTokenInfoApi(dispatch, res.credential, (x) => {
           console.log(x)
           if (x.res && x.res.status == "registered_and_verified") {
             Bussiness_Token_Login(res.credential, dispatch, () => {

                })
              }
           if (x.res && (x.res.status == "not_registered" || x.res.status == "unverified")) {

            registerHandler("email" , myDecodedToken.email)
            registerHandler("contactName" , myDecodedToken.name)
            registerHandler("idToken" , res.credential)

            

             changeState("registerBusinessThirdP")
           }


         })
 
        // this.RegisterSetKeyValue({
        //   googleIsActive: true,
        //   facebookIsActive: false,
        //   appleIsActive: false,
        //   googleToken: res.credential,
        //   givenName: myDecodedToken.given_name,
        //   familyName: myDecodedToken.family_name,
        //   email: myDecodedToken.email,
        //   thirdPartyLogin: "googleConnected",
        // });
      } catch (error) {
        
      }
      
   
  
    };

  const  responseGoogle = async (res) => {
  
      console.log("responseGoogle ",res)

      try {
        const myDecodedToken = await decodeToken(res.credential);
        console.log(myDecodedToken)
         dispatch(GoogleTokenInfo(dispatch, res.credential, (x) => {
           console.log(x)
           if (x.res && x.res.status == "registered_and_verified") {
            LoginByGooglepi(dispatch,{idToken:res.credential},  () => {

                })
              }
           if (x.res && (x.res.status == "not_registered" || x.res.status == "unverified")) {
            handler("Individual","register","type" , "google")

            handler("Individual","register","email" , myDecodedToken.email)
            handler("Individual","register","idToken" , res.credential)

            

             changeState("registerIndividualThirdP")
           }


         }))
 
        // this.RegisterSetKeyValue({
        //   googleIsActive: true,
        //   facebookIsActive: false,
        //   appleIsActive: false,
        //   googleToken: res.credential,
        //   givenName: myDecodedToken.given_name,
        //   familyName: myDecodedToken.family_name,
        //   email: myDecodedToken.email,
        //   thirdPartyLogin: "googleConnected",
        // });
      } catch (error) {
        
      }
      
   
  
    };

  const applecallback = async (res) => {

     if (!res.error) {
 
      dispatch(AppleTokenInfo(dispatch, res.authorization.id_token, (x) => {
        console.log(x)
        if (x.res && x.res.status == "registered_and_verified") {
          LoginByAppleApi(dispatch,{idToken: res.authorization.id_token},  () => {

             })
           }
        if (x.res && (x.res.status == "not_registered" || x.res.status == "unverified")) {

          handler("Individual","register","type" , "apple")

          handler("Individual","register","email" , x.res.email)
          handler("Individual","register","idToken" , res.authorization.id_token)

         

          changeState("registerIndividualThirdP")
        }


      }))



     } else {
       UnSucceessFul_i18(
         "appletokenerror",
         "networkError",
         "close",
         () => {
           dispatch({
             type: "removeNetworkErrorSnack",
             key: "networkError",
           });
         },
         new Date().getTime() + Math.random()
       );
     }
  };

  const responseFacebook = async (res) => {

    if (res.status == "connected" || !res.status) {

      const myDecodedToken = await decodeToken(res.accessToken);
      console.log(myDecodedToken)
      dispatch(FacebookTokenInfo(dispatch, res.accessToken, (x) => {
        console.log(x)
        if (x.res && x.res.status == "registered_and_verified") {
          LoginByFacebookApi(dispatch,{idToken: res.accessToken},  () => {

             })
           }
        if (x.res && (x.res.status == "not_registered" || x.res.status == "unverified")) {
          handler("Individual","register","type" , "facebook")

         handler("Individual","register","email" , x.res.email)
         handler("Individual","register","idToken" , res.accessToken)

         

          changeState("registerIndividualThirdP")
        }


      }))


    

    }
  };

    return <div
      className={styles.Container}
    >

      <Typography weight="Heading_Small_Bold" text={t("Login to your account")} style={{color:"#000000"}} />

        <Switch type="topMenu" items={[t("Business"), t("individual") ]} className={styles.Switch} index={activeIndex} handleSelection={handleAutoManual} />
 
      {activeIndex == 0 ? <>    
        {/* {( global.config.googleLoginClientId && global.config.googleLoginClientId.length > 0) && <Button size="Large" onClick={() => login()} className={styles.google} text={t("Continue using Google")} icon={google} type="Secondary" />} */}
        {(global.config.googleLoginClientId && global.config.googleLoginClientId.length > 0) && <GoogleLogin lang={get_lang()} id={"x121"} onSuccess={(res) => bussinessResponseGoogle(res)}
          onFailure={(res) => console.log(res)} />} <Button size="Large" onClick={() => { changeState("businessLogin") }} className={`${styles.email} ${(global.config.googleLoginClientId && global.config.googleLoginClientId.length > 0) && styles.mrgbg} `} text={t("Continue using Email / Username")} icon={Email} type="Secondary" />

        <div className={styles.hr} />

        <Typography className={styles.registerLink} weight="Body_Middle_Medium" text={<>{t("Don’t have an account?")} <Typography weight="Body_Middle_Medium" text={<>{t("Register as business")}  </>} style={{ color: "var(--Secondaryonlight, #1080FF)" }} />  </>}  onClick={()=>{changeState("registerBusiness_start")}} textColor="TextLight" style={{ direction: "initial" }} />
      </> : <>

          {(global.config.googleLoginClientId && global.config.googleLoginClientId.length > 0) && <GoogleLogin  lang={get_lang()} id={"x300"}  onSuccess={(res) => responseGoogle(res)}
            onFailure={(res) => console.log(res)} />}
          <div className={`${styles.appleFacebookContainer}  ${( global.config.googleLoginClientId && global.config.googleLoginClientId.length > 0) && styles.mrgbg}`}>     
            {global.config.appleLoginClientId &&

              <AppleLogin
                clientId={ global.config.appleLoginClientId }
                redirectURI={ window.location.origin }
                state="initial"
                responseType="string"
                callback={applecallback}
                usePopup={true}
                scope="name email"
                render={(renderProps) => (
                  <Button size="Large" onClick={renderProps.onClick} disabled={renderProps.disabled} text={!global.config.facebookLoginClientId && t("Continue with Apple")} icon={apple} type="Secondary" style={{ margin: "0px" }} />
                )}
              />
            }
            {global.config.facebookLoginClientId && <FacebookLogin
              //OnroIR  id="1194837280918142"
              //Onro  id="666630160807861"
              //Pickup  id="3038885649533203"
              appId={global.config.facebookLoginClientId}
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              className="FacebookLogin"
              containerStyle={{ opacity: "0", width: "1px", height: "0px" }}
              version="9.0"
              cookie={true}
              xfbml={true}
              render={(renderProps) => (
                <Button size="Large" onClick={renderProps.onClick} className={styles.google} text={!global.config.appleLoginClientId && t("Continue with Facebook")} icon={Facebook} type="Secondary" style={{ margin: "0px" }} />
              )}
            />

            }

          </div>


      
        <div className={styles.hr}> 
          <Typography weight="Body_Small_Regular" text={t("OR")} style={{color:"#000000"}} />
        </div>

        <div
            className={styles.phoneTitleContainer}
        >

            <Typography weight="Body_Small_Regular" text={t("Phonenumber")} style={{ color: "#484848" }} />

            <div className={`${styles.phoneContainer} ${message && styles.phoneContainer_error}`}>
              <PhoneInput
            defaultCountry={global.config.countryCca2}
                placeholder={t("plsenterphonnumber")}
            value={Individualdata.phone}
            onChange={(e) => { handler("Individual","login","phone" , e || "")} }
                className={styles.loginpersonPhoneInput}
              />  
            </div>

           {message && <Typography weight="Body_Small_Regular" text={message} style={{ color: "#D01400" }} />}

        </div>

        <Button onClick={loginIndividual} isLoading={loading} disabled={String(Individualdata.phone).length < 5}  size="Large" type="Primary" text={t("login")} style={{marginTop:"24px" , flex:"1" , padding:"14px 16px" , width:"calc(100% - 32px)"}} />


        <div className={styles.hr} />


        <Typography className={styles.registerLink} weight="Body_Middle_Medium" text={<>{t("Don’t have an account?")} <Typography weight="Body_Middle_Medium" text={<>{t("Register as individual")}  </>} style={{color: `var(--Secondaryonlight, #1080FF)`}} onClick={()=>{changeState("registerIndividual_Manual_start")}}/>  </>} textColor="TextLight" style={{direction:"initial"}} />
      </>}

        </div>
}


export default Home_Login