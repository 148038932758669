import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';

import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../../../../../design-system/lib/Typography';
import { ADD_SQUARE, EDIT, Edit, ROUTE_OPTIMIZE, Routes_deactive, Status, QUESTION, pen, ic_check_circle, ic_close_red } from '../../../../../../static';
import clsx from 'clsx';
import _ from 'lodash';
import { Trans } from 'react-i18next';
import { Customer_pickupDelivery_Confirm_Order_Api, Customer_pickupDelivery_Draft_Order_Api } from '../../../../../../helper/api';
import { get_Id } from '../../../../../../helper/UserData/userdate';
import { UpdateStateOrderInList, UpdateStateViewOrder } from '../../../../../../Redux/actions/actionNames';
import IconProviderSvgModifier from '../../../../../../design-system/lib/IconProviderSvgModifier';
import { ReactComponent as Pen } from '../../../../../../static/IconProvider/pen.svg';
import { ReactComponent as Ic_check_circle } from '../../../../../../static/IconProvider/ic_check_circle.svg';



function ViewOrderEditStatusOrder({onclick}) {
  const [loading, setLoading] = useState(false);
  const statusOrder = useSelector((state) => state.ViewTravel?.pureData?.status )
  const idOrder = useSelector((state) => state.ViewTravel?.pureData?.id )

  const dispatch = useDispatch();




  const canDraft = React.useCallback(() => {
    let status = ['Confirmed', 'CustomerCanceled']
    return  status.indexOf(statusOrder) > -1;
  }, [statusOrder]);

  const canConfirmed = React.useCallback(() => {
    let status = ['CustomerCanceled']
    return status.indexOf(statusOrder) > -1;
  }, [statusOrder]);



 const DraftOrder = () => {

    Customer_pickupDelivery_Draft_Order_Api(dispatch, {
      "orderId": idOrder,
      "customerId": get_Id()
    },(res)=>{
     dispatch(UpdateStateViewOrder({
      status: res.status,
      id: res.id,
      data: res.payload
    }))
    })



  };



  
  const ConfirmOrder = () => {

    Customer_pickupDelivery_Confirm_Order_Api(dispatch, {
      "orderId": idOrder,
      "customerId": get_Id()
    },(res)=>{
     dispatch(UpdateStateViewOrder({
      status: res.status,
      id: res.id,
      data: res.payload
    }))
    })

  };



  return (
    <>
      {canDraft() && <thdotItem style={{display:"flex"}} onClick={() => {
        onclick() ;DraftOrder()
      }}>
        {" "}
        <IconProviderSvgModifier Icon={Pen} />{" "}
        <span className="spanblue">
          {" "}
          <Trans i18nKey={"Draft"}> </Trans>{" "}
        </span>
      </thdotItem>}
      {canConfirmed() && <thdotItem  style={{display:"flex"}}  onClick={() => {
        onclick() ; ConfirmOrder()
      }}>
        {" "}
        <IconProviderSvgModifier Icon={Ic_check_circle} />{" "}
        <span className="spanblue">
          {" "}
          <Trans i18nKey={"confirm"}> </Trans>{" "}
        </span>
      </thdotItem>}
      {/* {canCustomerCanceled() && <thdotItem onClick={() => {
        onclick() 
      }}>
        {" "}
        <img src={ic_close_red} />{" "}
        <span className="spanblue">
          {" "}
          <Trans i18nKey={"Cancel"}> </Trans>{" "}
        </span>
      </thdotItem>} */}



    </>

  )
}


export default ViewOrderEditStatusOrder




function ItemsList(data, selectedRoute, setSelectedRoute) {
  return (
    <div className={styles.itemsConatiner}>
      {data.map((route) => {
        return <div onClick={() => { setSelectedRoute(route) }}>

          <img src={Routes_deactive} />
          <Typography weight={selectedRoute?.id == route.id ? "Body_Middle_Bold" : 'Body_Middle_Regular'} textColor='TextNormal' text={route.name} className={styles.itemName} />
          <Typography weight='Body_Small_Regular' textColor='TextLight' text={route.driver ? route.driver?.name : "Unassigned"} className={styles.itemDriverName} />

        </div>
      })}
    </div>
  )
}


