import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import Header from "./header";
import SwipeableViews from 'react-swipeable-views';
import { connect } from "react-redux";
import Login from "./Login";
import Register from "./Register";
import ClientAppsDownloadBadge from "../clientAppsDownloadBadge";
import VerificationCode from "../../VerificationCode";
import { get_cssFlag, get_lang, set_country, set_cssFlag, set_direction, set_lang } from "../../../helper/UserData/userdate";
import Popover from "@material-ui/core/Popover";
import { getAllLanguagesWithGlobal } from "../../../helper/module";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Home_Login from "./components/Home";
import BusinessLogin from "./components/BusinessLogin";
import Verification_sms from "./components/Verification_sms";
import RegisterBusinessThirdP from "./components/RegisterBusinessThirdP";
import Verification_email from "./components/Verification_email";
import RegisterBusiness_Manual_start from "./components/RegisterBusiness_Manual_start";
import RegisterBusiness_Manual_end from "./components/RegisterBusiness_Manual_end";
import RegisterIndividualThirdP from "./components/RegisterIndividualThirdP";
import RegisterIndividual_Manual_start from "./components/RegisterIndividual_Manual_start";
import { Bussiness_Add_Addressbook_Action } from "../../../Redux/actions/actions";
import BusinessLogin_Verification from "./components/BusinessLogin_Verification";
import BusinessLogin_ForgetPassword from "./components/BusinessLogin_ForgetPassword";


const styles = {
  tabs: {
    background: '#fff',
  },
  slide: {
    padding: 15,
    minHeight: 100,
    color: '#fff',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  slide3: {
    backgroundColor: '#6AC0FF',
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stackState:["home"] ,
      state: "home", /* "home", "BusinessLogin_Verification" , "registerIndividual_Manual_start" ,  "businessLogin" , "forgetBusinessLogin" , "emailSent" , "registerIndividualThirdP" , "registerBusinessThirdP" , "registerBusiness_start" , "registerBusiness_end" , "verifyEmail" , "verifyNumber" */
      data: {
        Bussiness: {
          login: {
            email_username: "",
            password: "",
            googleToken: null,
            givenName: null,
            familyName: null,
            email: null,
            VerificationCode:"",
            errorText:"",
            thirdPartyLogin: null /* "google" */,
          },
          register: {
            idToken:"",
             username :  "",
             password :  "",
             R_password :  "",
             phone :  "",
             name :  "",
             email :  "",
             address :  "",
             contactName : ""
          }
        },
        Individual: {
          login: {
            phone :  "",
            googleToken: null,
            thirdPartyLogin: null /* "google" */,
          },
          register: {
             phone :  "",
             lastname :  "",
             email :  "",
             firstname :  "" ,
             googleToken: null,
             thirdPartyLogin: null /* "google" */,
          }
        }
      },
      LoginIsActive: false , 
      anchorEl: null,
    }
  }

  businessLoginHandler = (key,value) => {
    console.log(key,value)

    this.setState({
      data:{
        ...this.state.data,
        Bussiness : {
          ...this.state.data.Bussiness,
          login : {
            ...this.state.data.Bussiness.login,
            [key]:value
          }
        }
      }
    },()=>{    console.log(this.state.data)
    })
  }

  stageTypeHandler = (type , stage ,key,value) => {

    console.log(key,value)
    this.setState({
      data:{
        ...this.state.data,
        [type] : {
          ...this.state.data[type],
          [stage] : {
            ...this.state.data[type][stage],
            [key]:value
          }
        }
      }
    },()=>{    console.log(this.state.data)
    })
  }


  businessRegisterHandler = (key,value) => {

    console.log(key,value)
    this.setState({
      data:{
        ...this.state.data,
        Bussiness : {
          ...this.state.data.Bussiness,
          register : {
            ...this.state.data.Bussiness.register,
            [key]:value
          }
        }
      }
    },()=>{    console.log(this.state.data)
    })
  }


  changeLogin = (tab) => {
    this.setState({
      LoginIsActive: tab
    })
  }


  addState = (state) => {
    this.setState({
      stackState: [...this.state.stackState, state]
    })
  }

  removeState = () => {
    const copyArr = [...this.state.stackState];
    copyArr.splice(-1);

    this.setState({
      stackState: copyArr
    })
  }

  getLastState = () => {
    return this.state.stackState[this.state.stackState.length - 1];
}

    handleClick = (event) => {
      this.setState({
        anchorEl: event.currentTarget,
      });
    };
  
    handleClose = () => {
      this.setState({
        anchorEl: null,
      });
    };
  
    changeLanguage = (lang) => {
      this.props.dispatch({
        type: "changeDirection",
        direction: lang.direction,
        language: lang.lang,
      });
      set_lang(lang.lang);
      set_direction(lang.direction);
      set_cssFlag(lang.cssFlag)
      set_country(lang.country)
      this.props.i18n.changeLanguage(lang.lang);
      this.handleClose()
    };


  render() {
    let axis = this.props.direction == "Ltr" ? "x" : "x-reverse";
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    console.log("foptbvyu 4" , this.getLastState());


    return <div className="SecondSectionContainer">
      {this.getLastState() == "home" && <img src={global.config.logoTypeUrl} className="LoginlogoType" />}

        {this.getLastState() == "home" && <Home_Login Individualdata={this.state.data.Individual.login} handler={this.stageTypeHandler} registerHandler={this.businessRegisterHandler}  handleback={this.removeState} changeState={this.addState} />}
        {this.getLastState() == "businessLogin" && <BusinessLogin handleback={this.removeState} handler={this.businessLoginHandler} data={this.state.data.Bussiness.login}  changeState={this.addState}/>}
        {this.getLastState() == "BusinessLogin_Verification" && <BusinessLogin_Verification handleback={this.removeState}  data={this.state.data.Bussiness.login}   changeState={this.addState}/>}
        {this.getLastState() == "forgetBusinessLogin" && <BusinessLogin_ForgetPassword handleback={this.removeState}  data={this.state.data.Bussiness.login}   changeState={this.addState}/>}

        {this.getLastState() == "verifyNumber" && <Verification_sms  data={this.state.data.Individual.login} handleback={this.removeState}  changeState={this.addState}/>}
        {this.getLastState() == "registerBusinessThirdP" && <RegisterBusinessThirdP  data={this.state.data.Bussiness.register}  handler={this.stageTypeHandler}   handleback={this.removeState}  changeState={this.addState}/>}
        {this.getLastState() == "verifyEmail" && <Verification_email   data={this.state.data.Bussiness.register}  handleback={this.removeState}  changeState={this.addState}/>}
        {this.getLastState() == "registerBusiness_start" && <RegisterBusiness_Manual_start  data={this.state.data.Bussiness.register} handler={this.businessRegisterHandler} handleback={this.removeState}  changeState={this.addState}/>}
        {this.getLastState() == "registerBusiness_end" && <RegisterBusiness_Manual_end  data={this.state.data.Bussiness.register} handler={this.businessRegisterHandler}  handleback={this.removeState}  changeState={this.addState}/>}
        {this.getLastState() == "registerIndividualThirdP" && <RegisterIndividualThirdP  handler={this.stageTypeHandler}   Individualdata={this.state.data.Individual.register}  handleback={this.removeState}  changeState={this.addState}/>}
        {this.getLastState() == "registerIndividual_Manual_start" && <RegisterIndividual_Manual_start handleback={this.removeState}    handler={this.stageTypeHandler}    Individualdata={this.state.data.Individual.register}   changeState={this.addState}/>}

        
        {/* <ClientAppsDownloadBadge /> */}
        {/* <div className="loginDownloadAppContainer">
          <p className="loginDownloadAppText"> Download Onro application </p>
          <div className="loginDownloadAppBadgeContainer">  
            <a href="/"><img style={{ height: '48px',borderRadius:"10px" }} src={this.googleBadgeM()} /></a> 
            <a  href="/"><img style={{ height: '48px' , borderRadius:"10px"}} src={this.appleBadgeM()} /></a>  
          </div>
        </div> */}

        <div className="loginRegisterEndBotomContainer">

                {global.config.isPoweredByOnroActive  && <a className="PoweredBy" href="http://onro.io/" >
        {" "}
        <Trans i18nKey={"PoweredBy"}> </Trans> <span style={{ fontWeight: "bold" }}>Onro.io</span>
      </a>}


      <span
          onClick={this.handleClick}
          className={`headerLanguagelabel ${get_cssFlag()}`}
        >
          {" "}
          <Trans i18nKey={get_lang()}> </Trans>{" "}
        </span>
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List>
            {getAllLanguagesWithGlobal(global.config.availableLanguages).map((lang) => {
              return (
                <ListItem
                  onClick={() => {
                    this.changeLanguage(lang);
                  }}
                >
                  <span className={`headerLanguagelabelItem ${lang.cssFlag}`}>
                    {" "}
                    <Trans i18nKey={lang.i18Lang}> </Trans>{" "}
                  </span>
                </ListItem>
              );
            })}
          </List>
        </Popover>
        </div>


    </div>;
  }
}
const mapStateToProps = (state) => ({
  authenticated: state.SharedData.access_token,
  direction: state.SharedData.direction,
  VerificationCode_step: state.LoginRegister.VerificationCode.VerificationCode_step,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));


