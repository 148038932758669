import { Bussiness_GetVehicleTypeByService_Api, Customer_Get_AboutUs } from ".";
import {
  Bussiness_Get_OrderList_Action,
  Customer_Get_OrderList_Action,
  Bussiness_Get_OrderHistoryList_Action,
  Customer_Get_OrderHistoryList_Action,
  Bussiness_Get_AboutUs_Action,
  Customer_Get_AboutUs_Action,
  Customer_Get_Transactions_Action,
  Bussiness_Get_Transactions_Action,
  Customer_Get_AccountInformation_Action,
  Bussiness_Get_AccountInformation_Action,
  Customer_Edit_AccountInformation_Action,
  Bussiness_Edit_AccountInformation_Action,
  Customer_Get_Services_Action,
  Bussiness_Get_Services_Action,
  Customer_SearchAutoComplete_Action,
  Customer_PlaceIdDetails_Action,
  Customer_GetVehicleTypeByService_Action,
  Customer_ondemand_promotion_verify_Action,
  Customer_pickupDelivery_promotion_verify_Action,
  Customer_map_geocoding_reverse_Action,
  Customer_calculateOrderCost_Ondemand_Action,
  Customer_calculateOrderCost_PickupDelivery_Action,
  Customer_get_balance_Action,
  Customer_Create_Ondemand_Order_Action,
  Customer_Create_Schedule_Order_Action,
  Customer_Get_Web_Setting_Action,
  Customer_New_Payment_Order_Action,
  Customer_ondemand_promotion_set_Action,
  Customer_pickupDelivery_promotion_set_Action,
  Bussiness_GetVehicleTypeByService_Action,
  Bussiness_calculateOrderCost_Ondemand_Action,
  Bussiness_calculateOrderCost_PickupDelivery_Action,
  Bussiness_Create_Ondemand_Order_Action,
  Bussiness_Create_PickupDelivery_Order_Action,
  Bussiness_Get_Web_Setting_Action,
  Bussiness_map_geocoding_reverse_Action,
  Bussiness_PlaceIdDetails_Action,
  Bussiness_SearchAutoComplete_Action,
  Bussiness_New_Payment_Order_Action,
  Bussiness_get_balance_Action,
  Bussiness_pickupDelivery_promotion_set_Action,
  Bussiness_ondemand_promotion_set_Action,
  Bussiness_ondemand_promotion_verify_Action,
  Bussiness_pickupDelivery_promotion_verify_Action,
  Customer_get_Order_Info_Action,
  Bussiness_get_Order_Info_Action,
  Bussiness_upload_order_service_phooto_Action,
  Customer_Ondemand_upload_order_service_phooto_Action,
  Customer_Schedule_upload_order_service_phooto_Action,
  Bussiness_change_payment_method_Action,
  Customer_Schedule_change_payment_method_Action,
  Customer_Ondemand_change_payment_method_Action,
  Customer_Set_Feedback_Action,
  Bussiness_Set_Feedback_Action,
  Customer_Schedule_Set_hasFeedbackPageSeen_Action,
  Customer_Ondemand_Set_hasFeedbackPageSeen_Action,
  Bussiness_hasFeedbackPageSeen_Action,
  Customer_get_order_trackDriver_Action,
  Bussiness_get_order_trackDriver_Action,
  Customer_pickupDelivery_Cancel_Order_Action,
  Customer_Ondemand_Cancel_Order_Action,
  Bussiness_pickupDelivery_Cancel_Order_Action,
  Bussiness_Ondemand_Cancel_Order_Action,
  Customer_Schedule_get_estimated_distance_Action,
  Customer_Ondemand_get_estimated_distance_Action,
  Bussiness_Schedule_get_estimated_distance_Action,
  Bussiness_Ondemand_get_estimated_distance_Action,
  Customer_get_quick_message_Action,
  Bussiness_get_quick_message_Action,
  Bussiness_get_quicks_Action,
  Customer_get_quicks_Action,
  Customer_seen_message_Action,
  Bussiness_seen_message_Action,
  Customer_get_chats_Action,
  Bussiness_get_chats_Action,
  Bussiness_Send_Message_Action,
  Customer_Send_Message_Action,
  Customer_Get_Addressbook_list_Action,
  Bussiness_Get_Addressbook_list_Action,
  Customer_Add_Addressbook_Action,
  Bussiness_Add_Addressbook_Action,
  Bussiness_Delete_Addressbook_Action,
  Customer_Delete_Addressbook_Action,
  Customer_Edit_Addressbook_Action,
  Bussiness_Edit_Addressbook_Action,
  Customer_Create_PickupDelivery_Order_Action,
  Bussiness_PickupDelivery_change_payment_method_Action,
  Customer_OrderGetEventsAction,
  Bussiness_OrderGetEventsAction,
  Customer_GET_ETA_Action,
  Bussiness_GET_ETA_Action,
  Bussiness_add_Payment_Method_Action,
  Customer_add_Payment_Method_Action,
  Bussiness_Delete_Payment_Method_Action,
  Customer_Delete_Payment_Method_Action,
  Bussiness_Get_Payment_Methods_Action,
  Customer_Get_Payment_Methods_Action,
  Customer_Get_Support_Message_Action,
  Bussiness_Get_Support_Message_Action,
  Customer_Reply_Support_Message_Action,
  Bussiness_Reply_Support_Message_Action,
  Customer_Get_MessageBox_Action,
  Bussiness_Get_MessageBox_Action
} from "../../Redux/actions/actions";
import {
  get_customerPannel_type,
  get_Id,
  get_lang,
} from "../UserData/userdate";

export const Api_Get_List_AGW = (dispatch, data, call) => {

  dispatch(
    Customer_Get_OrderList_Action(dispatch, { customerId: get_Id() }, call)
  );

  // if (get_customerPannel_type() == "person") {
  //   dispatch(
  //     Customer_Get_OrderList_Action(dispatch, { customerId: get_Id() }, call)
  //   );
  // } else {
  //   dispatch(
  //     Bussiness_Get_OrderList_Action(
  //       dispatch,
  //       {
  //         page: data.page + 1,
  //         perpage: 20,
  //       },
  //       call
  //     )
  //   );
  // }
};

export const Api_Get_History_List_AGW = (dispatch, data, call) => {

  dispatch(
    Customer_Get_OrderHistoryList_Action(
      dispatch,
      { customerId: get_Id(), page: data.page + 1, perpage: 20 },
      call
    )
  );


  // if (get_customerPannel_type() == "person") {
  //   dispatch(
  //     Customer_Get_OrderHistoryList_Action(
  //       dispatch,
  //       { customerId: get_Id(), page: data.page + 1, perpage: 20 },
  //       call
  //     )
  //   );
  // } else {
  //   dispatch(
  //     Bussiness_Get_OrderHistoryList_Action(
  //       dispatch,
  //       {
  //         customerId: get_Id(),
  //         page: data.page + 1,
  //         perpage: 20,
  //       },
  //       call
  //     )
  //   );
  // }
};

export const Api_Get_Aboutus_AGW = (dispatch, data, call) => {
  dispatch(Customer_Get_AboutUs_Action(dispatch, {}, call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Get_AboutUs_Action(dispatch, {}, call));
  // } else {
  //   dispatch(Bussiness_Get_AboutUs_Action(dispatch, {}, call));
  // }
};

export const Api_Get_Transactions_AGW = (dispatch, data, call) => {

  dispatch(
    Customer_Get_Transactions_Action(
      dispatch,
      { customerId: get_Id(), page: data.page + 1, perpage: 10 },
      call
    )
  );

  // if (get_customerPannel_type() == "person") {
  //   dispatch(
  //     Customer_Get_Transactions_Action(
  //       dispatch,
  //       { customerId: get_Id(), page: data.page + 1, perpage: 10 },
  //       call
  //     )
  //   );
  // } else {
  //   dispatch(
  //     Bussiness_Get_Transactions_Action(
  //       dispatch,
  //       { customerId: get_Id(), page: data.page + 1, perpage: 10 },
  //       call
  //     )
  //   );
  // }
};

export const Api_Get_AcountInformation_AGW = (dispatch, data, call) => {

  dispatch(Customer_Get_AccountInformation_Action(dispatch, {}, call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Get_AccountInformation_Action(dispatch, {}, call));
  // } else {
  //   dispatch(Bussiness_Get_AccountInformation_Action(dispatch, {}, call));
  // }
};

export const Api_Edit_AcountInformation_AGW = (dispatch, data, call) => {

  var bodyFormData = new FormData();
  data.email && bodyFormData.append("email", data.email);
  bodyFormData.append("givenName", data.givenName);
  bodyFormData.append("familyName", data.familyName);
  data.address && bodyFormData.append("address", data.address);

  // bodyFormData.append('phone', getPhone(data.phone));
  bodyFormData.append("language", get_lang());
  bodyFormData.append("name", data.title);
  data.contactName && bodyFormData.append("contactName", data.contactName);

  dispatch(
    Customer_Edit_AccountInformation_Action(dispatch, bodyFormData, call)
  );

  
  // if (get_customerPannel_type() == "person") {
  //   var bodyFormData = new FormData();
  //   data.email && bodyFormData.append("email", data.email);
  //   bodyFormData.append("givenName", data.givenName);
  //   bodyFormData.append("familyName", data.familyName);
  //   // bodyFormData.append('phone', getPhone(data.phone));
  //   bodyFormData.append("language", get_lang());
  //   bodyFormData.append("name", data.title);

  //   dispatch(
  //     Customer_Edit_AccountInformation_Action(dispatch, bodyFormData, call)
  //   );
  // } else {
  //   dispatch(
  //     Bussiness_Edit_AccountInformation_Action(
  //       dispatch,
  //       {
  //         email: data.email,
  //         title: data.title,
  //         language: get_lang(),
  //       },
  //       call
  //     )
  //   );
  // }
};

export const getPhone = (phone) => {
  if (phone && phone.substring(0, 1) == "+") {
    return "00" + phone.substring(1, phone.length);
  }
};

export const Api_Get_Services_AGW = (dispatch, data) => {
  dispatch(Customer_Get_Services_Action(dispatch, data));

  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Get_Services_Action(dispatch, data));
  // } else {
  //   dispatch(Bussiness_Get_Services_Action(dispatch, data));
  // }
};

export const Api_GetVehicleTypeByService_AGW = (dispatch, data, call) => {

  dispatch(Customer_GetVehicleTypeByService_Action(dispatch, data, call));

  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_GetVehicleTypeByService_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_GetVehicleTypeByService_Action(dispatch, data, call));
  // }
};

export const Api_SearchAutoComplete_AGW = (dispatch, data , call) => {

  dispatch(Customer_SearchAutoComplete_Action(dispatch, data, call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_SearchAutoComplete_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_SearchAutoComplete_Action(dispatch, data, call));
  // }
};

export const Api_PlaceIdDetails_AGW = (dispatch, data, call) => {

  dispatch(Customer_PlaceIdDetails_Action(dispatch, data, call));

  
  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_PlaceIdDetails_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_PlaceIdDetails_Action(dispatch, data, call));
  // }
};

export const Api_ondemand_promotion_verify_AGW = (dispatch, data, call) => {

  dispatch(Customer_ondemand_promotion_verify_Action(dispatch, data, call));

  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_ondemand_promotion_verify_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_ondemand_promotion_verify_Action(dispatch, data, call));
  // }
};

export const Api_pickupDelivery_promotion_verify_AGW = (dispatch, data, call) => {
  dispatch(Customer_pickupDelivery_promotion_verify_Action(dispatch, data, call));

  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_pickupDelivery_promotion_verify_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_pickupDelivery_promotion_verify_Action(dispatch, data, call));
  // }
};

export const Api_ondemand_promotion_set_AGW = (dispatch, data, call) => {

  dispatch(Customer_ondemand_promotion_set_Action(dispatch, data, call));

  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_ondemand_promotion_set_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_ondemand_promotion_set_Action(dispatch, data, call));
  // }
};

export const Api_pickupDelivery_promotion_set_AGW = (dispatch, data, call) => {
  dispatch(Customer_pickupDelivery_promotion_set_Action(dispatch, data, call));

  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_pickupDelivery_promotion_set_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_pickupDelivery_promotion_set_Action(dispatch, data, call));
  // }
};

export const Api_map_geocoding_reverse_AGW = (dispatch, data, call) => {

  dispatch(Customer_map_geocoding_reverse_Action(dispatch, data, call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_map_geocoding_reverse_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_map_geocoding_reverse_Action(dispatch, data, call));
  // }
};

export const Api_calculateOrderCost_Ondemand_AGW = (dispatch, data, call) => {

  dispatch(Customer_calculateOrderCost_Ondemand_Action(dispatch, data, call));

  
  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_calculateOrderCost_Ondemand_Action(dispatch, data, call));
  // } else {
  //   dispatch(
  //     Bussiness_calculateOrderCost_Ondemand_Action(dispatch, data, call)
  //   );
  // }
};

export const Api_calculateOrderCost_PickupDelivery_AGW = (dispatch, data, call) => {

  dispatch(Customer_calculateOrderCost_PickupDelivery_Action(dispatch, data, call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_calculateOrderCost_PickupDelivery_Action(dispatch, data, call));
  // } else {
  //   dispatch(
  //     Bussiness_calculateOrderCost_PickupDelivery_Action(dispatch, data, call)
  //   );
  // }
};

export const Api_get_balance_AGW = (dispatch, data, call) => {

  dispatch(Customer_Get_AccountInformation_Action(dispatch, data, call));

  
  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Get_AccountInformation_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_Get_AccountInformation_Action(dispatch, data, call));
  // }
};

export const Api_Create_Ondemand_Order_AGW = (dispatch, data, extraData ,call) => {

  dispatch(Customer_Create_Ondemand_Order_Action(dispatch, data, extraData,call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Create_Ondemand_Order_Action(dispatch, data, extraData,call));
  // } else {
  //   dispatch(
  //     Bussiness_Create_Ondemand_Order_Action(dispatch, data, extraData,call)
  //   );
  // }
};

export const Api_Create_PickupDelivery_Order_AGW = (dispatch, data, extraData ,call) => {

  dispatch(Customer_Create_PickupDelivery_Order_Action(dispatch, data, extraData ,call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Create_PickupDelivery_Order_Action(dispatch, data, extraData ,call));
  // } else {
  //   dispatch(
  //     Bussiness_Create_PickupDelivery_Order_Action(dispatch, data, extraData ,call)
  //   );
  // }
};

export const Api_Get_Web_Setting_AGW = (dispatch, data, extraData) => {
  dispatch(Customer_Get_Web_Setting_Action(dispatch, data, extraData));

  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Get_Web_Setting_Action(dispatch, data, extraData));
  // } else {
  //   dispatch(Bussiness_Get_Web_Setting_Action(dispatch, data, extraData));
  // }
};

export const Api_New_Payment_Order_AGW = (dispatch, data, extraData) => {

  dispatch(Customer_New_Payment_Order_Action(dispatch, data, extraData));

  
  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_New_Payment_Order_Action(dispatch, data, extraData));
  // } else {
  //   dispatch(Bussiness_New_Payment_Order_Action(dispatch, data, extraData));
  // }
};

export const Api_get_Order_Info_AGW = (dispatch, data, extraData) => {

  dispatch(Customer_get_Order_Info_Action(dispatch, data, extraData));

  
  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_get_Order_Info_Action(dispatch, data, extraData));
  // } else {
  //   dispatch(Bussiness_get_Order_Info_Action(dispatch, data, extraData));
  // }
};

export const Api_upload_order_service_phooto_AGW = (dispatch, data, call) => {

      var innerdata = new FormData();
    innerdata.append("photo", data.photo);
    innerdata.append("customerId", get_Id());
    innerdata.append("orderId", data.orderId);

    dispatch(
      Customer_Ondemand_upload_order_service_phooto_Action(
        dispatch,
        innerdata,
        data.orderId,
        call
      )
    );

  // if (get_customerPannel_type() == "person") {
  //   var innerdata = new FormData();
  //   innerdata.append("photo", data.photo);
  //   innerdata.append("customerId", get_Id());
  //   innerdata.append("orderId", data.orderId);

  //   dispatch(
  //     Customer_Ondemand_upload_order_service_phooto_Action(
  //       dispatch,
  //       innerdata,
  //       data.orderId,
  //       call
  //     )
  //   );

  // } else {
  //   var innerdata = new FormData();
  //   innerdata.append("photo", data.photo);
  //   innerdata.append("customerId", get_Id());
  //   innerdata.append("orderId", data.orderId);

  //   dispatch(
  //     Bussiness_upload_order_service_phooto_Action(
  //       dispatch,
  //       innerdata,
  //       data.orderId,
  //       call
  //     )
  //   );
  // }
};

export const Api_change_payment_method_AGW = (dispatch, data, call) => {
  let puredata={
    customerId: get_Id(),
    paymentMethod: data.paymentMethod,
    paymentSide: data.paymentSide,
    "paymentMethodId": data.paymentMethodId,
    "paymentProvider": data.paymentProvider,
    "cardInfo": data.cardInfo,
    orderId : data.orderId
  }

  if (data.type == "Ondemand") {
    dispatch(
      Customer_Ondemand_change_payment_method_Action(dispatch, puredata, call)
    );
  } else {
    dispatch(
      Customer_Schedule_change_payment_method_Action(dispatch, puredata, call)
    );
  }

  // if (get_customerPannel_type() == "person") {
  //   if (data.type == "Ondemand") {
  //     dispatch(
  //       Customer_Ondemand_change_payment_method_Action(dispatch, puredata, call)
  //     );
  //   } else {
  //     dispatch(
  //       Customer_Schedule_change_payment_method_Action(dispatch, puredata, call)
  //     );
  //   }
  // } else {
  //   if (data.type == "Ondemand") {
  //     dispatch(Bussiness_change_payment_method_Action(dispatch, puredata, call));
  //   } else {
  //     dispatch(Bussiness_PickupDelivery_change_payment_method_Action(dispatch, puredata, call));

  //   }
  // }
};

export const Api_Set_Feedback_AGW = (dispatch, data, extraData) => {

  dispatch(Customer_Set_Feedback_Action(dispatch, data, extraData));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Set_Feedback_Action(dispatch, data, extraData));
  // } else {
  //   dispatch(Bussiness_Set_Feedback_Action(dispatch, data, extraData));
  // }
};

export const Api_Set_hasFeedbackPageSeen_AGW = (dispatch, data, call) => {

  dispatch(
    Customer_Ondemand_Set_hasFeedbackPageSeen_Action(dispatch, data, call)
  );

  // if (get_customerPannel_type() == "person") {
  //   dispatch(
  //     Customer_Ondemand_Set_hasFeedbackPageSeen_Action(dispatch, data, call)
  //   );
  // } else {
  //   dispatch(Bussiness_hasFeedbackPageSeen_Action(dispatch, data, call));
  // }
};

export const Api_get_order_trackDriver_AGW = (dispatch, data, extraData) => {

  dispatch(Customer_get_order_trackDriver_Action(dispatch, data, extraData));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_get_order_trackDriver_Action(dispatch, data, extraData));
  // } else {
  //   dispatch(
  //     Bussiness_get_order_trackDriver_Action(dispatch, data, extraData)
  //   );
  // }
};

export const Api_Cancel_Order_AGW = (dispatch, data, call) => {
  let puredata={
    orderId :  data.id,
    customerId:get_Id()
  }

  if (data.type == "Ondemand") {
    dispatch(Customer_Ondemand_Cancel_Order_Action(dispatch, puredata, call));
  } else {
    dispatch(Customer_pickupDelivery_Cancel_Order_Action(dispatch, puredata, call));
  }

  // if (get_customerPannel_type() == "person") {
  //   if (data.type == "Ondemand") {
  //     dispatch(Customer_Ondemand_Cancel_Order_Action(dispatch, puredata, call));
  //   } else {
  //     dispatch(Customer_pickupDelivery_Cancel_Order_Action(dispatch, puredata, call));
  //   }
  // } else {
  //   if (data.type == "Ondemand") {
  //     dispatch(Bussiness_Ondemand_Cancel_Order_Action(dispatch, puredata, call));
  //   } else {
  //     dispatch(Bussiness_pickupDelivery_Cancel_Order_Action(dispatch, puredata, call));
  //   }
  // }
};

export const Api_get_estimated_distance_AGW = (dispatch, data, call) => {


  if (data.type == "Schedule") {
    dispatch(
      Customer_Schedule_get_estimated_distance_Action(dispatch, data, call)
    );
  } else {
    dispatch(
      Customer_Ondemand_get_estimated_distance_Action(dispatch, data, call)
    );
  }

  // if (get_customerPannel_type() == "person") {
  //   if (data.type == "Schedule") {
  //     dispatch(
  //       Customer_Schedule_get_estimated_distance_Action(dispatch, data, call)
  //     );
  //   } else {
  //     dispatch(
  //       Customer_Ondemand_get_estimated_distance_Action(dispatch, data, call)
  //     );
  //   }
  // } else {
  //   if (data.type == "Schedule") {
  //     dispatch(
  //       Bussiness_Schedule_get_estimated_distance_Action(dispatch, data, call)
  //     );
  //   } else {
  //     dispatch(
  //       Bussiness_Ondemand_get_estimated_distance_Action(dispatch, data, call)
  //     );
  //   }
  // }
};




export const Api_get_quick_message_AGW = (dispatch, data, extraData) => {

  dispatch(Customer_get_quick_message_Action(dispatch, data, extraData));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_get_quick_message_Action(dispatch, data, extraData));
  // } else {
  //   dispatch(
  //     Bussiness_get_quick_message_Action(dispatch, data, extraData)
  //   );
  // }
};

export const Api_get_chats_AGW = (dispatch, data, extraData) => {
  dispatch(Customer_get_chats_Action(dispatch, data, extraData));

  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_get_chats_Action(dispatch, data, extraData));
  // } else {
  //   dispatch(
  //     Bussiness_get_chats_Action(dispatch, data, extraData)
  //   );
  // }
};


export const Api_seen_message_AGW = (dispatch, data, extraData) => {

  dispatch(Customer_seen_message_Action(dispatch, data, extraData));

  
  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_seen_message_Action(dispatch, data, extraData));
  // } else {
  //   dispatch(
  //     Bussiness_seen_message_Action(dispatch, data, extraData)
  //   );
  // }
};



export const Api_Send_Message_AGW = (dispatch, data, call) => {

  dispatch(Customer_Send_Message_Action(dispatch, data, call));



  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Send_Message_Action(dispatch, data, call));
  // } else {
  //   dispatch(
  //     Bussiness_Send_Message_Action(dispatch, data, call)
  //   );
  // }
};



export const Api_Get_Addressbook_list_AGW = (dispatch, data, call) => {

  dispatch(Customer_Get_Addressbook_list_Action(dispatch, data, call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Get_Addressbook_list_Action(dispatch, {}, call));
  // } else {
  //   dispatch(Bussiness_Get_Addressbook_list_Action(dispatch, data, call));
  // }
};



export const Api_Add_Addressbook_AGW = (dispatch, data, call) => {

  dispatch(Customer_Add_Addressbook_Action(dispatch, {...data }, call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Add_Addressbook_Action(dispatch, {...data , code:undefined}, call));
  // } else {
  //   dispatch(Bussiness_Add_Addressbook_Action(dispatch, data, call));
  // }
};


export const Api_Delete_Addressbook_AGW = (dispatch, data, call) => {

  dispatch(Customer_Delete_Addressbook_Action(dispatch, data, call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Delete_Addressbook_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_Delete_Addressbook_Action(dispatch, data, call));
  // }
};



export const Api_Set_Default_Addressbook_AGW = (dispatch, data, call) => {
  dispatch(Bussiness_Delete_Addressbook_Action(dispatch, data, call));
};



export const Api_Edit_Addressbook_AGW = (dispatch, data, call) => {

  dispatch(Customer_Edit_Addressbook_Action(dispatch, {...data }, call));

  
  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Edit_Addressbook_Action(dispatch, {...data , code:undefined}, call));
  // } else {
  //   dispatch(Bussiness_Edit_Addressbook_Action(dispatch, data, call));
  // }
};

export const Api_OrderGetEvents_AGW = (dispatch, id ) => {

  dispatch(Customer_OrderGetEventsAction(dispatch, id));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_OrderGetEventsAction(dispatch, id));
  // } else {
  //   dispatch(Bussiness_OrderGetEventsAction(dispatch, id));
  // }
};


export const Api_GET_ETA_AGW = (dispatch, id ,call) => {

  dispatch(Customer_GET_ETA_Action(dispatch, id ,call));

  
  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_GET_ETA_Action(dispatch, id ,call));
  // } else {
  //   dispatch(Bussiness_GET_ETA_Action(dispatch, id , call));
  // }
};

export const Api_Add_Payment_Method_AGW = (call , dispatch) => {

  dispatch(Customer_add_Payment_Method_Action(call , dispatch));

  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_add_Payment_Method_Action(call , dispatch));
  // } else {
  //   dispatch(Bussiness_add_Payment_Method_Action(call , dispatch));
  // }
};


export const Api_Get_Payment_Methods_AGW = (dispatch ,call) => {

  dispatch(Customer_Get_Payment_Methods_Action(dispatch ,call));


  // console.log("Api_Get_Payment_Methods_AGW ," )
  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Get_Payment_Methods_Action(dispatch ,call));
  // } else {
  //   dispatch(Bussiness_Get_Payment_Methods_Action(dispatch, call));
  // }
};

export const Api_Delete_Payment_Method_AGW = (dispatch, id ,call) => {

  dispatch(Customer_Delete_Payment_Method_Action(dispatch, id ,call));

  
  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Delete_Payment_Method_Action(dispatch, id ,call));
  // } else {
  //   dispatch(Bussiness_Delete_Payment_Method_Action(dispatch, id , call));
  // }
};


export const Api_Get_Support_Message_AGW = (dispatch ) => {

  dispatch(Customer_Get_Support_Message_Action(dispatch));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Get_Support_Message_Action(dispatch));
  // } else {
  //   dispatch(Bussiness_Get_Support_Message_Action(dispatch));
  // }
};


export const Api_Reply_Support_Message_AGW = (dispatch ,data ) => {

  dispatch(Customer_Reply_Support_Message_Action(dispatch ,data));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Reply_Support_Message_Action(dispatch ,data));
  // } else {
  //   dispatch(Bussiness_Reply_Support_Message_Action(dispatch ,data));
  // }
};


export const Api_Get_MessageBox_AGW = (dispatch, data, call) => {

  dispatch(Customer_Get_MessageBox_Action(dispatch, data, call));


  // if (get_customerPannel_type() == "person") {
  //   dispatch(Customer_Get_MessageBox_Action(dispatch, data, call));
  // } else {
  //   dispatch(Bussiness_Get_MessageBox_Action(dispatch, data, call));
  // }
};
