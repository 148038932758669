import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { getCountryWithDomain } from "../../../../helper/module";
import { get_address, get_bussiness_title, get_contactName, get_country, get_customerPannel_type, get_email, get_familyName, get_givenName, get_phone, get_username } from "../../../../helper/UserData/userdate";
import Language from "./../../Header/ProfileMenu/Language";
import ChangePassword from "../../changePassword";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone:this.getPhone(),
      Contactname : get_contactName(),
      familyName: get_familyName(),
      givenName: get_givenName() ,
      email:get_email(),
      username:get_username(),
      title : get_bussiness_title(),
      address : get_address(),
      changedInformation :false
    };
  }

  init=()=>{
    this.setState({
      phone:this.getPhone(),
      familyName: get_familyName(),
      Contactname : get_contactName(),
      givenName: get_givenName() ,
      email:get_email(),
      username:get_username(),
      title : get_bussiness_title() ,
      address : get_address(),
    })
  }
  getPhone =()=>{
    let phone = String(get_phone());
    if(phone.substring(0, 2) == "00"){
      return "+"+phone.substring(2, phone.length)
    }else{
      return phone
    }
  }

  inputOnchange = (name, value) => {
    this.setState({
      [name]:value,
      changedInformation:true
    },()=>{
          this.props.canSaveInformation(this.canSaveInformation(),{
            phone:this.state.phone,
            familyName: this.state.familyName,
            givenName: this.state.givenName ,
            email:this.state.email,
            contactName : this.state.Contactname,
            username:this.state.username,
            title :this.state.title ,
            address :this.state.address ,
          })
    })
  };

  componentWillReceiveProps(nextProps){
    if(nextProps.open != this.props.open){
      this.init()
    }
  }

  canSaveInformation=()=>{
    console.log("dwdwd556dw ",this.state)
    return this.state.changedInformation && (this.state.phone && this.state.phone.length > 6) && (get_customerPannel_type()=="individual" ? (this.state.givenName?.length > 0 && this.state.familyName?.length > 0) : (this.state.title.length > 0 && this.state.address.length > 0))
  }

  render() {
    let country = get_country();

    return (
      <div className="SettingItemsContainer">
        <p className="LP_PhoneNumberLabel" style={{opacity:".6"}}>
          {" "}
          <Trans i18nKey={"Phonenumber"}> </Trans>{" "}
        </p>
        <div
          className={`PhoneNumberContainer ${
            this.state.numberNotRegistered && "PhoneNumberContainerError"
          }`}
          style={{height:"32px",borderRadius:"8px",opacity:".6"}}
        >
          <PhoneInput
          disabled={true}
            defaultCountry={global.config.countryCca2}
            placeholder="123456789"
            value={this.state.phone}
            onChange={(e)=>{this.inputOnchange("phone",e)}}
            className="loginpersonPhoneInput olcnwti"
            id="phone"
          />
        </div>

        {(get_customerPannel_type() == "business" && String(this.state.username).length > 0 && this.state.username) &&  <div className="firstnamelastnameContainer emailcontainer" style={{height:"55px" , opacity:".6"}}>

            <p className="LP_PhoneNumberLabel">
              <Trans i18nKey={get_customerPannel_type() =="individual" ? "Firstname" :"Username"}> </Trans>
            </p>
            <div className={`PhoneNumberContainer`} style={{height:"32px",borderRadius:"8px"}}>
              <input
              className="emailinputregister"
              disabled={get_customerPannel_type() !=="individual"}
                placeholder={this.props.t("firstnnameloginplaceholder")}
                value={get_customerPannel_type() =="individual" ? this.state.givenName: this.state.username}
                onChange={(e)=>{this.inputOnchange(get_customerPannel_type() =="individual" ?"givenName":"username",e.target.value)}}
                style={{marginTop:"5px",fontSize:"12px",fontWeight:"300"}}
                />
            </div>
          </div>}

     { get_customerPannel_type() =="individual" &&  <div className="firstnamelastnameSettingContainer" style={{marginTop:'15px',height:"61px"}}>
          <div className="firstnameContainer firstnameContainerSetting">
            <p className="LP_PhoneNumberLabel">
              <Trans i18nKey={get_customerPannel_type() =="individual" ? "Firstname" :"Username"}> </Trans>
            </p>
            <div className={`PhoneNumberContainer`} style={{height:"32px",borderRadius:"8px"}}>
              <input
              disabled={get_customerPannel_type() !=="individual"}
                placeholder={this.props.t("firstnnameloginplaceholder")}
                value={get_customerPannel_type() =="individual" ? this.state.givenName: this.state.username}
                onChange={(e)=>{this.inputOnchange(get_customerPannel_type() =="individual" ?"givenName":"username",e.target.value)}}
                style={{marginTop:"5px",fontSize:"12px",fontWeight:"300"}}
                />
            </div>
          </div>

        <div className="lastnameContainer firstnameContainerSetting">
            <p className="LP_PhoneNumberLabel">
              <Trans i18nKey={get_customerPannel_type() =="individual" ? "Lastname":"registerBusinessNameLabel"}> </Trans>{" "}
            </p>
            <div className={`PhoneNumberContainer`} style={{height:"32px",borderRadius:"8px"}}>
              <input
                placeholder={this.props.t("lastnameloginplaceholder")}
                value={get_customerPannel_type() =="individual" ? this.state.familyName : this.state.title}
                onChange={(e)=>{this.inputOnchange(get_customerPannel_type() =="individual" ? "familyName":"title",e.target.value)}}
                style={{marginTop:"5px",fontSize:"12px",fontWeight:"300"}}
                />
            </div>
          </div>
        </div>}

        <div className="firstnamelastnameContainer emailcontainer" style={{height:"55px",opacity:".6"}}>
          <p className="LP_PhoneNumberLabel" >
            <Trans i18nKey={"email"}> </Trans>{" "}
          </p>
          <div className={`PhoneNumberContainer `} style={{height:"32px",borderRadius:"8px" }}>
            <input
              placeholder={this.props.t("emailPlaceholder")}
              className="emailinputregister"
              value={this.state.email}
              style={{marginTop:"5px",fontSize:"12px",fontWeight:"300"}}
              />
          </div>
        </div>

       {get_customerPannel_type() == "business" &&  <div className="firstnamelastnameContainer emailcontainer" style={{height:"55px"}}>
            <p className="LP_PhoneNumberLabel">
              <Trans i18nKey={"registerBusinessNameLabel"}> </Trans>{" "}
            </p>
            <div className={`PhoneNumberContainer`} style={{height:"32px",borderRadius:"8px"}}>
              <input
              className="emailinputregister"
                placeholder={this.props.t("lastnameloginplaceholder")}
                value={get_customerPannel_type() =="individual" ? this.state.familyName : this.state.title}
                onChange={(e)=>{this.inputOnchange("title",e.target.value)}}
                style={{marginTop:"5px",fontSize:"12px",fontWeight:"300"}}
                />
            </div>
          </div>}

          {get_customerPannel_type() == "business" &&  <div className="firstnamelastnameContainer emailcontainer" style={{height:"55px"}}>
            <p className="LP_PhoneNumberLabel">
              <Trans i18nKey={"Contact name"}> </Trans>{" "}
            </p>
            <div className={`PhoneNumberContainer`} style={{height:"32px",borderRadius:"8px"}}>
              <input
              className="emailinputregister"
                placeholder={this.props.t("lastnameloginplaceholder")}
                value={this.state.Contactname}
                onChange={(e)=>{this.inputOnchange("Contactname",e.target.value)}}
                style={{marginTop:"5px",fontSize:"12px",fontWeight:"300"}}
                />
            </div>
          </div>}

       {get_customerPannel_type() == "business" &&  <div className="firstnamelastnameContainer emailcontainer" style={{height:"55px"}}>
          <p className="LP_PhoneNumberLabel">
            <Trans i18nKey={"address"}> </Trans>{" "}
          </p>
          <div className={`PhoneNumberContainer `} style={{height:"32px",borderRadius:"8px"}}>
            <input
              placeholder={this.props.t("address")}
              className="emailinputregister"
              value={this.state.address}
              onChange={(e)=>{this.inputOnchange("address",e.target.value)}}
              style={{marginTop:"5px",fontSize:"12px",fontWeight:"300"}}
              />
          </div>
        </div>}


        <hr
          style={{
            float: "left",
            width: "100%",
            backgroundColor: "rgba(128, 128, 128, 0.2)",
            color: "rgba(128, 128, 128, 0.2)",
            opacity: "50%",
            marginTop: "15px",
          }}
        />
       {get_customerPannel_type() == "business" && <ChangePassword />}

        <div
          className={`PhoneNumberContainer ${
            this.state.numberNotRegistered && "PhoneNumberContainerError"
          }`}

          style={{height:"32px",borderRadius:"8px"}}
        >
          <Language className="yidntrd" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(App);
