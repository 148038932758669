import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import { TooltipTypes } from "./Tooltip.types";
// import { TagProps } from "./Tag.types";
import { styled } from "newmaterial-ui/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "newmaterial-ui/Tooltip";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFFFF",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 600,
        border: "unset",
        boxShadow:"0px 1px 6px 0px #80808029",
        height:"auto" ,
        borderRadius:"8px",
        padding:"0px"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color:"white"
    },
}));

const TooltipOnro: React.FC<TooltipTypes> = ({ children , title , placement , tooltipClassName}: TooltipTypes) => {

    return (


        <HtmlTooltip
            arrow
            title={title}
            placement={placement}
            className={tooltipClassName}
        >
            {children}
        </HtmlTooltip>

    );
};

export default TooltipOnro;

