import React, { useCallback } from 'react';
import styles from './index.module.css';
import passwordIcon from '../../../src/static/password.svg'
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Typography from '../../design-system/lib/Typography';
import Input from '../../design-system/lib/Input';
import Button from '../../design-system/lib/Button';
import { useQuery } from '../../helper/hook/useQuery';
import { httpCustomer } from '../../service/api/axios';
import done_circle from "../../../src/static/done_circle.svg";
import { snack } from '../../helper/module/SnackbarUtilsConfigurator';



export function  ResetPassword(props) {
  const { t, i18n } = useTranslation();
  const searchParams = useQuery();
  const token = searchParams.get("at");
  const [password, setPassword] = React.useState("");
  const [rpassword, setRpassword] = React.useState("");
  const [loading, setLoading] = React.useState("");
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);

    const changePassword = useCallback(() => {
      setLoading(true)
      setError(null)
  httpCustomer
     .post(
       `/api/v1/customer/business/auth/password-reset`,
       {
          "password": password,
          "confirmPassword": password
       },
       {
         headers: {
           "Accept-Language": "en",
           "Authorization" : `Bearer ${token}`
         },
       }
     )
     .then((res) => {
      setSuccess(true)
      setLoading(false)
      setError(null)
      })
     .catch((error) => {
      setLoading(false)
      setError(error.response.data.message)
      snack.error(error.response.data.message)
     });
    }, [ password , token ]);
  
 
  return <div className={styles.App} style={{justifyContent : success && "center"}} >

<div className={styles.Card} >
  {!success ? <>        <img src={passwordIcon} />
        <Typography weight='Heading_Small_Bold' style={{marginTop:"16px"}} text={t("changePassword")} />
        <Typography weight='Body_Tiny_Regular'  style={{marginTop:"4px"}} text={t("Enter your new password")} />
        <Input  error={(String(password).length < 6 && String(password).length > 0) && t("Password must be at least 6 characters")} onChange={(e)=>{setPassword(e.target.value)}} size='Large' ContainerStyle={{marginTop:"24px"}} type={"password"} title={t("Password")} placeHolder={t("TypeHere")} />
        <Input  error={( String(rpassword).length > 0 && String(password).trim() != String(rpassword).trim()) && t("Repeat password doesn’t match")}  onChange={(e)=>{setRpassword(e.target.value)}} size='Large'  ContainerStyle={{marginTop:"16px"}} type={"password"} title={t("Repeat password")} placeHolder={t("TypeHere")} />
        <Button isLoading={loading} disabled={String(password).length < 6 || String(rpassword).length < 6 || String(password).trim() != String(rpassword).trim() || loading} onClick={changePassword} style={{marginTop:"24px" ,width:"calc(100% - 32px)" ,maxHeight:"43px" , height:"25px"}} size='Medium' type='Primary' text={t("Change")} />
  
  </> : <>
  <img src={done_circle} />
  <Typography weight='Heading_Small_Bold' style={{marginTop:"16px" ,color :"#009B62"}} text={t("Password changed")} />
  <Typography weight='Body_Middle_Regular'  style={{marginTop:"4px" , color :"#484848"}} text={t("Return to app and login again")} />
  </>}
  </div>
    </div>

}


