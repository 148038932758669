import * as React from 'react'
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import COD from '../COD';
import ReferenceID from '../ReferenceID';
import Notes from '../Notes';
import options from '../../../../../static/IconProvider/options.svg';
import IconProvider from '../../../../../design-system/lib/IconProvider';
import Typography from '../../../../../design-system/lib/Typography';



function OtherContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className={styles.OtherContainer}>
            <div className={styles.PickupOnlyNewOrderContainerFirstRow}>
        <IconProvider Icon={options} size='16' />
        <Typography text={t("Other")} weight='Body_Middle_Bold' />
      </div>

     <COD inputContainerclassName={styles.inputContainerclassName} />
     <ReferenceID inputContainerclassName={styles.inputContainerclassName} TooltipOnro={styles.TooltipOnro} />
     <Notes />
    </div>

  )
}

export default OtherContainer