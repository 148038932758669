import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import {ReactComponent as Icaddcreditcard} from "../../../../../../../src/static/IconProvider/add_credit_card.svg";
import cancell from "../../../../../../../src/static/ic-close.svg";
import Dialog from "@material-ui/core/Dialog";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    ElementsConsumer,
    CardElement,
} from "@stripe/react-stripe-js";
import Form from "./form";
import { get_lang } from "../../../../../../helper/UserData/userdate";
import { Api_Add_Payment_Method_AGW } from "../../../../../../helper/api/ApiGateway";
import IcAddCreditCardSvg from "../../../../../../components/svg/ic-add-credit-cardSvg";
// import { Add_Payment_Method } from "../../../../../../../helper/api";
import styles from '../../../index.module.css';
import Typography from "../../../../../../design-system/lib/Typography";
import IconProviderSvgModifier from "../../../../../../design-system/lib/IconProviderSvgModifier";
import Modal from "../../../../../../design-system/lib/Modal";
import { ReactComponent as Plus } from "../../../../../../../src/static/IconProvider/add-square.svg";




class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialog: false,
            cardStatus: null,
            ready: false,
            clientSecret: null
        }
    }

    componentDidMount() {

        if (global.config.clientSecret && String(global.config.clientSecret).length > 10) {
            this.setState({
                clientSecret: global.config.clientSecret
            })
        } else {
            Api_Add_Payment_Method_AGW((data) => {
                this.setState({
                    clientSecret: data.clientSecret
                })

                global.config.clientSecret = data.clientSecret
            }, this.props.dispatch)
        
        }

    }

    getStripePromise(){
        return loadStripe(String(global.config.stripePublicApiKey));
    }


    componentWillReceiveProps(nextProps) {
            // Add_Payment_Method((data) => {
            //     this.setState({
            //         clientSecret: data.clientSecret
            //     })
            // }, nextProps.customer.id)
        
     }

    handleOpenDialoge = () => {
        this.setState({
            openDialog: !this.state.openDialog
        },()=>{
            this.props.handleOpenAddDialoge && this.props.handleOpenAddDialoge(this.state.openDialog)
            if(!this.state.openDialog){
                Api_Add_Payment_Method_AGW((data) => {
                    this.setState({
                        clientSecret: data.clientSecret
                    })
                }, this.props.dispatch)
            }
        })
        this.props.onClose()
    }


    cardOnChange = (event) => {
        console.log("cardOnChange , ", event)
        this.setState({
            cardStatus: event
        })

        // stripePromise
        // .confirmCardSetup('seti_1KkjwTLGEuIAvDMsOL9BqQZX_secret_LRdCmY8YxlyfjYtSAf31KmE5sR7pa', {
        //     payment_method: {
        //         card: card
        //     },
        // })
        // .then(function (result) {
        //     console.log(result);
        // });

    }

    cardOnReady = () => {
        this.setState({
            ready: true
        })
    }

    render() {
        const { t } = this.props;
        // const stripePromise = loadStripe(String(global.config.stripePublicApiKey));

        return (
            <React.Fragment>
                <div
                     className={styles.methodMethodsItem}
                    onClick={this.state.clientSecret && this.handleOpenDialoge}
                    style={{opacity : !this.state.clientSecret && ".5" ,cursor : !this.state.clientSecret && "not-allowed" }}
                >
                    <IconProviderSvgModifier Icon={Plus} size='16' />
                    <Typography weight="Body_Tiny_Bold" textColor="TextDark" className={styles.TopupColor} text={this.props.t("Add Card")} />
                      

                    {/* <span style={{ color: "#1080ff" }} className="AddCreditCardAddStripe">
                        {" "}
                        <Trans i18nKey={"AddCreditCard"}> </Trans>{" "}
                    </span> */}
                </div>

              { this.state.openDialog && <Elements stripe={this.getStripePromise()} options={{ locale: get_lang() }} >

                        {this.state.openDialog && <Modal  hideFooter={true} title={this.props.t("AddCreditCard")} ConfirmationText="" onclose={this.handleOpenDialoge} size="Large" bodyStyle={{backgroundColor:"rgba(244, 244, 247, 1)",borderRadius :" 0px 0px 8px 8px"}} body={<Form  clientSecret={this.state.clientSecret} onClose={this.handleOpenDialoge} />} />}
                    {/* <Dialog
                        style={{}}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        open={this.state.openDialog}
                        onClose={this.handleOpenDialoge}
                        disableAutoFocus
                        disableEnforceFocus 
                        disableRestoreFocus 
                    >
                        <div className="EditDriverContainer addCreditMobileContainer" >
                            <div className="header">
                                <img
                                    className="close"
                                    src={cancell}
                                    onClick={this.handleOpenDialoge}
                                />
                                <span className="title">
                                    {this.props.t("AddCreditCard")}
                                </span>
                            </div>
                            <Form clientSecret={this.state.clientSecret} onClose={this.handleOpenDialoge} />



                        </div>
                    </Dialog> */}
                </Elements>
}


            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    customer: state.NewOrder.newOrder.customer,

});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
