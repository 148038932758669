import React, { useEffect, useState } from "react";
import styles from './main.module.css';
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "../../../../../design-system/lib/Typography";
import { useTranslation } from "react-i18next";
import Switch from "../../../../../design-system/lib/Switch";
import { useGoogleLogin } from '@react-oauth/google';
import Button from "../../../../../design-system/lib/Button";
import google from "../../../../../static/google.svg";
import { ARROW_LEFT, Email } from "../../../../../static";
import apple from "../../../../../static/apple.svg";
import Facebook from "../../../../../static/facebook.svg";
import Input from "../../../../../design-system/lib/Input";
import PhoneInput from "react-phone-number-input";
import done_circle from "../../../../../static/done_circle.svg";
import AppleLogin from "react-apple-login";
import loadDynamicScript from "../../../../../helper/module/loadDynamicScript";
import { CodeVerification, ResendCode } from "../../../../../Redux/actions/actions";
import { Bussiness_Login_ForgetPassword, CustomerCodeVerificationApi } from "../../../../../helper/api";


function BusinessLogin_ForgetPassword({handleback , data , changeState}) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const [message, setMessage] = useState(null);
  const [vCode, setVCode] = useState("");
  const [captcha, setCaptcha] = useState(null);
  const [ts, setTs] = useState(null);
  const [captchaResend, setCaptchaResend] = useState(null);
  const [loading, setLoaing] = useState(false);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();


 

    useEffect(() => {
      requestcaptcha()
    }, [])
  
    const requestcaptcha = React.useCallback(() => {
      setCaptcha(null)
      setTs(null)
      // this.setState({
      //   Captcha: null ,
      //   ts:null
      // },()=>{
         loadDynamicScript(
        `https://www.google.com/recaptcha/enterprise.js?render=${global.config.grsk}`,
        "recaptchaEnterprise",
        () => {
          try {
          window.grecaptcha.enterprise.ready(() => {
            const ts = Number(String(Number(Date.now())))
  
            window.grecaptcha.enterprise
              .execute(global.config.grsk, {
                action: `custom_customer_login_${ts}`
              })
              .then((token) => {
                setCaptcha(token)
                setCaptchaResend(token)

              })
              .catch((error) => {
                console.log(error);
              });
          });
        } catch (error) {
          
        }
        }
      );
      // })
  
  
     
    }, []);
  




    const sendLink = React.useCallback(() => {
        setLoaing(true)
      Bussiness_Login_ForgetPassword(vCode , dispatch , (res)=>{

        if (res.res) {
          setLoaing(false)

          setMessage(res.res.message)
          console.log(res)
        } else {
          setLoaing(false)

          console.log(res)
        }


      })
     
    }, [vCode]);
    
  



    return <div
      className={styles.Container}
    >
{!message ? <>
     <div className={styles.header}>
      <Button size="Medium" type="Secondary" icon={ARROW_LEFT} onClick={handleback} />
      <Typography weight="Heading_Small_Bold" text={<>{t("Forgot password")}  </>} style={{color:"#000000"}} />
     </div>
    
   <div className={styles.InputContainers}>

   <Input size="Large" type={"text"} title={t("Email / Username")} onChange={(e)=>{setVCode(e.target.value || "")}} value={vCode} placeHolder={t("TypeHere")}  />

   </div>



  <Button isLoading={loading}  size="Large" type="Primary" disabled={vCode.length < 4} onClick={sendLink} text={t("Send link")} style={{marginTop:"24px" , flex:"1" , padding:"14px 16px" , width:"calc(100% - 32px)"}} />

</> : <>
  <img src={done_circle} />
 
 <div className={styles.linksetContainer}> 
  <Typography weight="Body_Middle_Bold" text={<>{t("Link sent")}  </>} style={{color:"#009B62"}} />
  <Typography weight="Body_Tiny_Regular" text={message} style={{color:"#484848",textAlign:"center"}} />
 </div>
 
 <Button size="Large" type="Secondary" onClick={()=>{handleback(); handleback()}} text={t("Return to login")} style={{marginTop:"16px" }} />


</>}

        </div>

}

export default BusinessLogin_ForgetPassword