import React, { useEffect, useState } from "react";
import styles from './main.module.css';
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "../../../../../design-system/lib/Typography";
import { useTranslation } from "react-i18next";
import Switch from "../../../../../design-system/lib/Switch";
import { useGoogleLogin } from '@react-oauth/google';
import Button from "../../../../../design-system/lib/Button";
import google from "../../../../../static/google.svg";
import { ARROW_LEFT, Email } from "../../../../../static";
import apple from "../../../../../static/apple.svg";
import Facebook from "../../../../../static/facebook.svg";
import Input from "../../../../../design-system/lib/Input";
import PhoneInput from "react-phone-number-input";
import FacebookLogin from "../../../../../innerLibrary/facebookLogin2/facebook";
import AppleLogin from "react-apple-login";
import { RegisterByApple, RegisterByFacebook, RegisterByGoogle } from "../../../../../Redux/actions/actions";
import { snack } from "../../../../../helper/module/SnackbarUtilsConfigurator";


function RegisterIndividualThirdP({Individualdata , handler , handleback , changeState}) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const [timer, setTimer] = useState(20);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();


      const registerIndi = React.useCallback(() => {
        setMessage(null)
        setLoading(true)
        if(Individualdata.type == "google"){
          dispatch(
            RegisterByGoogle(
              dispatch,
              {
                idToken: Individualdata.idToken,
                phone: Individualdata.phone,
                givenName: Individualdata.firstname,
                familyName: Individualdata.lastname,
                email: Individualdata.email 
              },
              (res)=>{
                if(res.error){
                  setMessage(res.error)
                  snack.error(res.error)
                }else if(res.res){
                  handler("Individual","login","message" , res.res)
                  handler("Individual","login","phone" , Individualdata.phone)
  
                  changeState("verifyNumber")
                }
  
                setLoading(false)
              }
            )
          );
        }else if (Individualdata.type == "apple"){
          dispatch(
            RegisterByApple(
              dispatch,
              {
                idToken: Individualdata.idToken,
                phone: Individualdata.phone,
                givenName: Individualdata.firstname,
                familyName: Individualdata.lastname,
                email: Individualdata.email 
              },
              (res)=>{
                if(res.error){
                  setMessage(res.error)
                  snack.error(res.error)
                }else if(res.res){
                  handler("Individual","login","message" , res.res)
                  handler("Individual","login","phone" , Individualdata.phone)
  
                  changeState("verifyNumber")
                }
  
                setLoading(false)
              }
            )
          );
        }else {
          dispatch(
            RegisterByFacebook(
              dispatch,
              {
                idToken: Individualdata.idToken,
                phone: Individualdata.phone,
                givenName: Individualdata.firstname,
                familyName: Individualdata.lastname,
                email: Individualdata.email 
              },
              (res)=>{
                if(res.error){
                  setMessage(res.error)
                  snack.error(res.error)
                }else if(res.res){
                  handler("Individual","login","message" , res.res)
                  handler("Individual","login","phone" , Individualdata.phone)
  
                  changeState("verifyNumber")
                }
  
                setLoading(false)
              }
            )
          );
        }
  
      }, [Individualdata]);

 

  useEffect(() => {

    const intervalId = setInterval(() => {
      timer > 0 && setTimer(timer - 1)
    }, 1000);


    return () => clearInterval(intervalId); //This is important
  }, [timer])



  
    const getMinSec = React.useCallback(() => {
      return `${str_pad_left(
        Math.floor(timer / 60),
        "0",
        2
      )}:${str_pad_left(timer % 60, "0", 2)}`;
    }, [timer]);
  
  
    const str_pad_left = React.useCallback((string, pad, length) => {
      return (new Array(length + 1).join(pad) + string).slice(-length);

    }, [timer]);



  let resendCode =
   timer == 0
    ? t("resendCode")
    : t("resendCodeWithTime").replace("xxx", getMinSec);


    return <div
      className={styles.Container}
    >

     <div className={styles.header}>
      <Button size="Medium" type="Secondary" icon={ARROW_LEFT} onClick={handleback} />
      <Typography weight="Heading_Small_Bold" text={<>{t("Individual account")}  </>} style={{color:"#000000"}} />
     </div>
    

    <div className={styles.thirdConnected}>
      <Typography weight="Body_Middle_Bold" style={{color:"#009B62"}} text={t(`${Individualdata.type}Connected`)} />
      <Typography weight="Body_Small_Regular" style={{color:"#484848"}} text={Individualdata.email} />

    </div>

    <div className={styles.InputContainers}>

        <div
            className={styles.phoneTitleContainer}
        >

            <Typography weight="Body_Small_Regular" text={<>{t("Phonenumber")} <Typography weight="Body_Small_Regular" text={"*"} style={{ color: "#D01400" ,float:"inline-end" ,marginInlineStart:"4px" }} /></>} style={{ color: "#484848" }} />

            <div className={`${styles.phoneContainer} ${message && styles.phoneContainer_error}`}>
              <PhoneInput
            defaultCountry={global.config.countryCca2}
                placeholder={t("plsenterphonnumber")}
            value={""}
            onChange={(PrePhoneInput) => { handler("Individual","register","phone" , PrePhoneInput || "")} }
                className={styles.loginpersonPhoneInput}
              />  
            </div>
            {message && <Typography weight="Body_Small_Regular" text={message} style={{ color: "#D01400" }} />}


        </div>

<div className={styles.lastfirstContainer}>  
    <Input  size="Large" type={"text"} isRequiredText={"*"} isRequired={true} title={t("Firstname")} onChange={(e)=>{handler("Individual","register","firstname" , e.target.value || "")}} value={Individualdata.firstname} placeHolder={t("firstnnameloginplaceholder")}  />
    <Input  size="Large" type={"text"} isRequiredText={"*"} isRequired={true} title={t("Lastname")} onChange={(e)=>{handler("Individual","register","lastname" , e.target.value || "")}} value={Individualdata.lastname}  placeHolder={t("Ex. Leo")}  />
</div>
    
    </div> 

     {/* <Typography className={styles.numberPhoneSentContainer} weight="Body_Middle_Regular" text={<>{t("Enter code sent to your")} <Typography weight="Body_Middle_Regular" text={"+1234567890"} style={{color:"#242424" , float:"inline-end" , marginInlineStart:"5px" , direction:"initial" ,}} /> </>} style={{color:"#808080"}} /> */}

   {/* <div className={styles.InputContainers}>

   <Input type={"text"} title={t("Code")} onChange={()=>{}} placeHolder={t("TypeHere")}  />

   </div> */}

    {/* <Typography onClick={()=>timer > 0 && null} weight="Body_Middle_Bold" text={resendCode} style={{color:"#1080FF",alignSelf :"start" ,marginTop:"16px",opacity: timer == 0 ? "1" : ".4" , cursor : timer == 0 ? "pointer" :"not-allowed"}}   />  */}

   {/* <Typography weight="Body_Small_Regular" text={<>{t("Wrong Code")}  </>} style={{color:"#D01400",alignSelf :"start" ,marginTop:"16px"}}   /> */}


  <Button isLoading={loading} disabled={ String(Individualdata.firstname).length < 2 ||   String(Individualdata.lastname).length < 2 ||  String(Individualdata.phone).length < 2 } onClick={registerIndi} size="Large" type="Primary" text={t("Register")} style={{marginTop:"24px" , flex:"1" , padding:"14px 16px" , width:"calc(100% - 32px)"}} />

  {/* <Typography className={styles.termsContainer} weight="Body_Small_Regular" style={{color:"#808080"}} text={<>{t("By continuing, you agree to Onro’s ")} <Typography weight="Body_Small_Regular" style={{color:"#808080" , textDecoration :"underline" ,cursor:"pointer"}} text={<>{t("Terms of Use")}</>} /> <Typography weight="Body_Small_Regular" style={{color:"#808080"}} text={<>{t("and")}</>} /> <Typography weight="Body_Small_Regular" style={{color:"#808080", textDecoration :"underline",cursor:"pointer"}} text={<>{t("Privacy Policy.")}</>} /></>} /> */}

        </div>

}

export default RegisterIndividualThirdP