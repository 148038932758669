import React from "react";
import { connect } from "react-redux";
import { ReactComponent as OriginIcon } from "../../../../static/pin-pickup.svg";
import { ReactComponent as DestinationIcon } from "../../../../static/pin_dropoff.svg";
import goBack from "../../../../static/icon-ic-chevron-left-gradient.svg";
import { ReactComponent as Iconorigin } from "../../../../static/icon-ic-pickup.svg";
import { ReactComponent as Icondestination } from "../../../../static/icon-ic-dropoff.svg";
// import { Warning_NOi18 } from "../../../../helper/Notification/sendNotification";
import { withTranslation, Trans } from "react-i18next";
import {
  ChooseoOnMapimg,
  SetSelectedAB,
  Set_DropOff_Inputs,
  Set_Pickup_address,
  SetopenAddressBookInNewOrder,
} from "../../../../Redux/actions/actionNames";
import { getCenterMap, setCenterMap } from "../../../../helper/module";
// import { GetLocationPlacenameAction } from "../../../../Redux/actions/actions";
import ReactDOMServer from "react-dom/server";
import DropOffPin from "../../../../components/shared/map-dropoff-pin/Pin";
import PickupPin from "../../../../components/shared/map-pickup-pin/Pin";
import DropOffPinWithNumber from "../../../../components/shared/map-dropoff-pin-with-number/Pin";
import Button from "../../../../design-system/lib/Button";
import { CLOSE, CLOSE_DARK } from "../../../../static";
import Typography from "../../../../design-system/lib/Typography";
import { isDesktop, isMobile, isTablet } from "react-device-detect";


class App extends React.Component {
  markerOnClick = () => {
    let center = getCenterMap(this.props.mapRef, this.props.center);
    if (!this.props.geocodingReverseـloading) {
      let originShow =
        this.props.pickupDropOffType &&
        this.props.pickupDropOffType == "Pickup";
      let destinationShow =
        this.props.pickupDropOffType &&
        this.props.pickupDropOffType == "DropOff" &&
        !this.props.pickupDropOffId;
      let othersDestinationShow =
        this.props.pickupDropOffType &&
        this.props.pickupDropOffType == "DropOff" &&
        this.props.pickupDropOffId;

      if (originShow) {
        this.props.dispatch(
          Set_Pickup_address({
            address: this.props.geocodingReverse_address,
            adresss_loc: {
              lng: this.props.mapRef.current ? center.lng : this.props.geocodingReverse_coordinates[0],
              lat: this.props.mapRef.current ? center.lat : this.props.geocodingReverse_coordinates[1],
            },
            placeId: this.props.geocodingReverse_placeId,
          })
        );

        setCenterMap(this.props.mapRef, {
          lng: this.props.mapRef.current ? center.lng : this.props.geocodingReverse_coordinates[0],
          lat: this.props.mapRef.current ? center.lat : this.props.geocodingReverse_coordinates[1],
        })
        this.goback();
      } else if (destinationShow) {
        this.props.dispatch(
          Set_DropOff_Inputs({
            name: "address",
            value: this.props.geocodingReverse_address,
            firstDropOff: true,
            id: this.props.pickupDropOffId,
          })
        );

        this.props.dispatch(
          Set_DropOff_Inputs({
            name: "adresss_loc",
            value: {
              lng: this.props.mapRef.current ? center.lng : this.props.geocodingReverse_coordinates[0],
              lat: this.props.mapRef.current ? center.lat : this.props.geocodingReverse_coordinates[1],
            },
            firstDropOff: true,
            id: this.props.pickupDropOffId,
          })
        );
        setCenterMap(this.props.mapRef, {
          lng: this.props.mapRef.current ? center.lng : this.props.geocodingReverse_coordinates[0],
          lat: this.props.mapRef.current ? center.lat : this.props.geocodingReverse_coordinates[1],
        })
        this.goback();
      } else if (othersDestinationShow) {
        this.props.dispatch(
          Set_DropOff_Inputs({
            name: "address",
            value: this.props.geocodingReverse_address,
            firstDropOff: false,
            id: this.props.pickupDropOffId,
          })
        );

        this.props.dispatch(
          Set_DropOff_Inputs({
            name: "adresss_loc",
            value: {
              lng: this.props.mapRef.current ? center.lng : this.props.geocodingReverse_coordinates[0],
              lat: this.props.mapRef.current ? center.lat : this.props.geocodingReverse_coordinates[1],
            },
            firstDropOff: false,
            id: this.props.pickupDropOffId,
          })
        );

        setCenterMap(this.props.mapRef, {
          lng: this.props.mapRef.current ? center.lng : this.props.geocodingReverse_coordinates[0],
          lat: this.props.mapRef.current ? center.lat : this.props.geocodingReverse_coordinates[1],
        })

        this.goback();
      }
      this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));


    }
  };


  getNumber = () => {


    var index = -1;
    this.props.otherDropOffs.some((entry, i) => {
      if (entry.id == this.props.pickupDropOffId) {
        index = i;
      }
    });

    return index + 2

  };


  othersDestinationShowAfterSelect = () => {
    let selectedDropoof = this.props.otherDropOffs.find((drop) => {
      return drop.id === this.props.activePickupDropOff.id;
    });
    if (selectedDropoof) {
      if (selectedDropoof.adresss_loc.lng === "") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  goback = () => {
    this.props.dispatch(
      ChooseoOnMapimg({
        showMap: false,
        pickupDropOff: {
          type: null,
          id: null,
        },
      })
    );
  };

  render() {
    let originShow =
      this.props.pickupDropOffType && this.props.pickupDropOffType == "Pickup";
    let destinationShow =
      this.props.pickupDropOffType &&
      this.props.pickupDropOffType == "DropOff" &&
      !this.props.pickupDropOffId;
    let othersDestinationShow =
      this.props.pickupDropOffType &&
      this.props.pickupDropOffType == "DropOff" &&
      this.props.pickupDropOffId;

    return (
      <div>

       {!isDesktop && <div className="newTravelPickPDHeader" style={{position:"fixed", zIndex:"6", gap :"12px" ,width:"100%" , backgroundColor:"white", height:"56px" ,display:"flex",flexDirection:"row",justifyContent:"start",alignItems:"center"}}>
          <Button icon={CLOSE} size="Medium" type="Secondary" onClick={this.goback} style={{marginInlineStart :"16px"}} />
          <Typography weight="Body_Middle_Bold" text={this.props.t(this.props.pickupDropOffType)} />
        </div>}
         {/* {((originShow || destinationShow || othersDestinationShow) && isDesktop )  ? (
          <span className="newTravelPickPDTrans">
            {" "}
            <Trans i18nKey={this.props.pickupDropOffType}> </Trans>{" "}
          </span>
        ) : null} */}

       {isDesktop && <img
          onClick={this.goback}
          src={goBack}
          className="newTravelPickPDGOback"
        /> }

        {originShow && (
          <img
            onClick={this.markerOnClick}
            src={'data:image/svg+xml;charset=utf-8,' +
              encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<PickupPin color={"#1795ff"} />))}
            alt=""
            className={`originShow originShowGoogle`}
          />
          // <OriginIcon onClick={this.markerOnClick}
          // className="originShow originShowGoogle" />

        )}

        {destinationShow && (

          this.props.otherDropOffs.length == 0 ? <img
            onClick={this.markerOnClick}
            src={'data:image/svg+xml;charset=utf-8,' +
              encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin color={"#0064d2"} bigSize={true} />))}
            alt=""
            className={`originShow originShowGoogle`}
          /> : <DropOffPinWithNumber onClick={this.markerOnClick}
            className={`originShow originShowGoogle`}
            color={"#0064d2"} style={{ position: "absolute", width: "40px", height: "53px" }} bigSize={true} number={this.getNumber()} numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "10px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} />
          //  <DestinationIcon onClick={this.markerOnClick}
          //  className="originShow destinationShow originShowGoogle" />
        )}

        {othersDestinationShow && (
          <DropOffPinWithNumber onClick={this.markerOnClick}
            className={`originShow originShowGoogle`}
            color={"#0064d2"} style={{ position: "absolute", width: "40px", height: "53px" }} bigSize={true} number={this.getNumber()} numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "10px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} />
          // <DestinationIcon onClick={this.markerOnClick}
          //   className="originShow destinationShow originShowGoogle" />
        )}
        {/* {this.props.pickupDropOffType && (
      
          <div className="bottomSheetSetPick_Drop">
             {this.props.pickupDropOffType == "Pickup" ?
              <Iconorigin className="img x896364d" />
              :
              <Icondestination className="img x896364d" />
            } 
       
             {this.props.geocodingReverseـloading ? (
              <span className="bottomSheetSetPick_DropText">
                <Trans i18nKey="fetchingdata"> </Trans>{" "}
              </span>
            ) : (
              <span className="bottomSheetSetPick_DropText">
                {this.props.geocodingReverse_address}{" "}
              </span>
            )} 
 <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
            <Button style={{flex:"1"}} size="Medium" type="Primary" text={this.props.t("Save")} onClick={this.markerOnClick} disabled={this.props.geocodingReverseـloading} />

</div> 
             <span
              className={`bottomSheetSetPick_DropButton ${this.props.geocodingReverseـloading && "grey"}`}
              onClick={this.markerOnClick}
            >
              {" "}
              <Trans i18nKey={`Set${this.props.pickupDropOffType}`}>
                {" "}
              </Trans>{" "}
            </span> 
          </div>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  pickupDropOffType: state.NewOrder.newOrder.map_Data.pickupDropOff.type,
  pickupDropOffId: state.NewOrder.newOrder.map_Data.pickupDropOff.id,
  geocodingReverseـloading: state.Map.geocodingReverse.loading,
  geocodingReverse_placeId: state.Map.geocodingReverse.placeId,
  geocodingReverse_address: state.Map.geocodingReverse.address,
  geocodingReverse_coordinates: state.Map.geocodingReverse.coordinates,
  mapRef: state.Map.mapRef,
  center: state.Map.center,
  otherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
