import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import IconClose from "../../../../static/icon-close.svg";
import { getBaseTrackByUrl } from "../../../../helper/module";
import Dialog from "@material-ui/core/Dialog";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import BottomSheet from 'bottom-sheet-react';
import {ReactComponent as Sign_pod} from "../../../../static/sign_pod.svg";
import Typography from "../../../../design-system/lib/Typography";
import styles from './style.module.css';
import ic_barcode_scanned from "../../../../static/IconProvider/ic_barcode_scanned.svg";
import no_photox from "../../../../static/IconProvider/no_photox.svg";
import IconProviderSvgModifier from "../../../../design-system/lib/IconProviderSvgModifier";


const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#00000066",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showpoper: false,
    };
  }

  handleCollapse = (e) => {
    e.stopPropagation();    this.setState({
      showpoper: !this.state.showpoper,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div
          className="addphotoService"
          onClick={ /* this.props.customerPhoto && */ this.handleCollapse}>
          <IconProviderSvgModifier Icon={Sign_pod} />
          <span>
            {/* <Trans i18nKey={"pop"}> </Trans> */}
            POP
          </span>
        </div>
        <InnerDialoge showpoper={this.state.showpoper} handleCollapse={this.handleCollapse}>

          <div style={{ width: "100%", height: "100%" }}>
            <div className="podheader" style={{borderBottomColor:"rgba(128, 128, 128, 0.2)"}}>
              <img
                src={IconClose}
                className="close"
                onClick={this.handleCollapse}
              />
              <span className="textheader"><Trans i18nKey={"pop"}> </Trans> </span>
            </div>

            <div style={{width:"calc(100% - 48px)",marginInlineStart:"24px",marginTop:"12px",float:"inline-start",display:"flex",flexDirection:"column",gap:"2px"}}>
                <Typography weight="Body_Tiny_Medium" text={this.props.t("Notes")} style={{ color: "rgba(128, 128, 128, 1)" }} />
                <Typography weight="Body_Middle_Regular" text={String(this.props.popNote).length > 0 ? this.props.popNote : this.props.t("Nonotes") } style={{ color: "rgba(72, 72, 72, 1)" }} />
            </div>

            <div style={{width:"calc(100% - 32px)",marginInlineStart:"16px",marginTop:"11px",float:"inline-start",borderBottom:"1px solid rgba(128, 128, 128, 0.2)"}}>
            </div>

            <div className={styles.profsContainer} style={{paddingBottom:"16px" , minHeight:"250px"}}>

{this.props.proofs.map((item) => {
  return <div className={styles.profsItem}>
    {["photo", "signature"].indexOf(item.type) > -1 && <img
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius:"5.14px"
      }}
      src={item.photoUrl ? item.photoUrl : item.signatureUrl}
    />}
    {['barcode-scan'].indexOf(item.type) > -1 && <div className={styles.innerProofItems}>
    <img src={ic_barcode_scanned} style={{marginBottom:"8px"}} />
    <Typography weight="Body_Tiny_Medium" text={item.barcodeDataKey == "referenceId" ? `Ref.${item.barcodeData}` : `#${item.barcodeData}`} style={{overflowWrap:"break-word" , maxWidth:"82px" , textAlign :" center"}}  />

    </div>
    }

  </div>
})}

{this.props.proofs.length == 0 && <div style={{flex:"1",gap:"4px",width:"100%",display:"flex",flexDirection:"column",justifyContent:"center" ,alignItems:"center"}}>
    <img src={no_photox} style={{marginBottom:"20px"}} />
    <Typography weight="Body_Middle_Bold" text={this.props.t("NoAttachments")} />
    <Typography weight="Body_Tiny_Regular" text={this.props.t("AllAttachmentswillbeshownhere")} />
  </div>}

</div>

            {/* <div className="podSignContainer">
              <span className="title"> {this.props.t("Photo")} </span>
              <div className="image">
                {this.props.popPhoto &&
                  this.props.popPhoto.length > 0 ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={this.props.popPhoto}
                  />
                ) : (
                  <span className="nophotosign">
                    {" "}
                    {this.props.t("Nophoto")}{" "}
                  </span>
                )}
              </div>
            </div>
            <div className="podSignContainer">
              <span className="title"> {this.props.t("Signature")} </span>
              <div className="image">
                {this.props.popSignature && this.props.popSignature.length > 0 ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={this.props.popSignature}
                  />
                ) : (
                  <span className="nophotosign">
                    {" "}
                    {this.props.t("Nosignature")}{" "}
                  </span>
                )}
              </div>
            </div> */}

          </div>
        </InnerDialoge>
      </React.Fragment>
    );
  }
}


function InnerDialoge(props) {
  if (!isDesktop && !isTablet) {
    return (
      props.showpoper ?
        <BottomSheet
          isExpandable={false}
          customHeight={328}
          onClose={props.handleCollapse}
          backdropStyle={{ background: "rgb(13 13 13 / 55%)" }}
          containerStyle={{ borderRadius: "8px" }}
        >
          {props.children}
        </BottomSheet>
        :
        null
    );
  } else {
    return (
      <Dialog open={props.showpoper}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <Paper
          style={{ width: "376px", height: "auto", borderRadius: "8px", overflow: "hidden" }}>
          <ClickAwayListener onClickAway={props.handleCollapse}>
            {props.children}
          </ClickAwayListener>
        </Paper>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
