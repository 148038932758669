export const Azerbaijani = {
  Cutomerpanel: "Customer panel",
  login: "Login",
  Register: "Register",
  en: "English",
  fa: "Persian",
  Person: "individual",
  Business: "Business",
  Phonenumber: "Phone number",
  Continue: "Continue",
  phonenumberhasnotregisterd: "Phone number hasn’t registerd",
  Username: "Username",
  title: "Title",
  Password: "Password",
  ExOnro: "Ex. company",
  Exstart: "Ex. ******",
  googleConnected: "Google connected",
  facebookConnected: "Facebook connected",
  appleConnected: "Apple Id connected",
  plsenterphonnumber: "Please enter your phone number",
  firstnnameloginplaceholder: "Ex. John",
  "businessNamePlaceholder": "Ex. ABC Company",
  "registerBusinessNameLabel":"Business Name",
  lastnameloginplaceholder: "Ex. Doe",
  Firstname: "First name",
  Lastname: "Last name",
  email: "Email",
  emailPlaceholder: "(optional) Ex. example@example.com",
  comingsoon: "Coming soon :",
  comingsoondesc: "İşinizi qeyd etmək üçün buraya vurun",
  contactUs: "Bizimlə əlaqə saxlayın",
  comingsoondesc2: "To Create Business account please contact us",
  CustomerAlreadyRegistered: "Customer has already registered, please log in",
  registerCompleted: "Enter Verification Code",
  googletokenerror: "Problem with Google Token",
  facebooktokenerror: "Problem with Facebook Token",
  appletokenerror: "Problem with Apple Token",
  CustomerNotRegistered: "Customer Not Registered",
  fr: "french",
  InvalidVerificationCode: "Invalid Verification Code",
  a: "Your information could not be found",
  welcome: "welcome",
  SmsLimitationError: "Please try again in a few moments",
  resendCodeWithTime: "resend code (xxx)",
  resendCode: "resend code",
  VerificationcodePlaceHolder: "Ex. 1234",
  Verificationcode: "Verification code",
  VerificationcodeText: "Enter the code sent to xxx",
  VerificationCodeExpired: "Verification Code Expired",
  Pending: "Pending",
  Unassigned: "Unassigned",
  Assigned: "In Progress",
  PickedUp: "In Progress",
  Started: "In Progress",
  statusInProgress: "In Progress",
  Done: "Done",
  CustomerCanceled: "Canceled",
  DriverCanceled: "Canceled",
  SupportCanceled: "Canceled",
  Arrived: "In Progress",
  new: "new",
  Historyofyourorderswillbehere: "History of your orders will be here",
  otherdropoffs: "other dropoffs",
  Orderhistory: "OnDemand Order History",
  Noorder: "No order",
  loadingList: "Be patient while receiving information",
  loadingError: "Problem on getting information",
  retry: "Try again",
  Create_your_first_requst: "Yeni bir sifariş yaradın",
  List_of_you_active_orders_will_be_here:
    "List of your active orders will be here",
  Aboutus: "About us",
  Readour: "Read our",
  privaypolicy: "privay policy",
  Transactions: "Transactions",
  NoTransaction: "You do not have a transaction",
  Historyofyourransactionswillbehere:
    "History of your transactions will be here",
  Settings: "Settings",
  Language: "Language",
  English: "English",
  Persian: "Persian",
  French: "French",
  Logout: "Log out",
  logotTitle: "Log out form account?",
  logoutDescription:
    "You will be logged out from account and you should login later",
  confirmLogout: "Yes, log out",
  skipLogout: "Cancel",
  savesettings: "Save settings",
  emailInvalid: "email is invalid",
  Neworder: "New Order",
  Service: "Service",
  poperLoading: "please wait",
  Pickup: "Pickup",
  SetPickup: "Set Pickup",
  ChooseoOnMap: "Map",
  Fornow: "For now",
  Scheduled: "Scheduled",
  before: "Before :",
  after: "After :",
  confirm: "Confirm",
  Settime: "Set time",
  fo: "Fo",
  Cancel: "Cancel",
  Shipmentlabel:"Shipment label",

  Pleasetryanotherkeyword: "Please try another keyword",
  Noinformationfound: "No information found",
  address: "Address",
  block: "Block/Building",
  Floor: "Floor",
  Unit: "Unit",
  senderfullname: "Sender Full name",
  senderphonenumber: "Sender phone number",
  notesfordriver: "Notes for driver",
  DropOff: "Drop off",
  SetDropOff: "Set DropOff",
  fetchingdata: "Receiving information...",
  Recieverfullname: "Reciever Full name",
  Recieverphonenumber: "Reciever phone number",
  undefined: "Service type",
  PromotionCode: "Promo code",
  Delete: "Delete",
  Save: "Save",
  PromoCodeValid: "Promo Code Saved",
  SenderWallet: "Wallet",
  SenderCash: "Cash",
  ReceiverCash: "Pay by Receiver",
  lowBalance: "low balance",
  Balance: "Balance",
  Methods: "Methods",
  balanceError: "Your balance must be at least equal to order price",
  SendOrder: "Send Order",
  feedback: "Feedback",
  Feedback: "Feedback",
  Orderagain: "Order again",
  Createdsuccessfully: "Created successfully",
  chooseAmount: "Choose Amount",
  orenterthepreferedamount: "Preferred amount:",
  enteramount: "your desired price",
  pay: "Pay",
  selectPaymentGateway: "Select Payment Gateway",
  Time_MachineText:
    "Do you want to recover your previously unregistered order information ?",
  yes: "Yes",
  ignore: "ignore",
  Order: "Order",
  statusPending: "Pending",
  statusUnassigned: "Unassigned",
  statusAssigned: "Assigned",
  statusStarted: "Started",
  statusPickedUp: "PickedUp",
  statusDone: "Done",
  statusCustomerCanceled: "Customer Canceled",
  statusDriverCanceled: "Driver Canceled",
  statusArrived: "Arrived",
  statusSupportCanceled: "Support Canceled",
  statusCanceled: "Canceled",
  Phone: "Phone",
  additonalservice: "additonal service",
  CallDriver: "Call Driver",
  Message: "Message",
  orderInfoError: "Error receiving information",
  support: "Support",
  map: "Map",
  receipt: "Receipt",
  photo: "photo",
  ServicePhoto: "Service Photo",
  Addphoto: "Add photo",
  Estime: "Es. time",
  Espath: "Es. path",
  pod: "Proof of delivery",
  Notes: "Notes",
  Photo: "Photo",
  Signature: "Signature",
  Nonotes: "No notes",
  Nosignature: "No signature",
  Nophoto: "No photo",
  Price: "Price",
  Receiver: "By Receiver",
  Sender: "By Sender",
  Cash: "Cash",
  Canceltravel: "Cancel Order",
  CancellText: "Order will be canceled, are you sure? ",
  Estimated: "Estimated",
  Wallet: "Wallet",
  Copylink: "Copy link",
  repeatOrder: "Repeat Order",
  Edit: "Edit",
  confirmCancel: "Yes",
  paymentmethod: "Payment method",
  SucceessFul: "done successfully",
  errorinServer: "Server problem",
  Comment: "Comment",
  Exfeedbackinput: "Ex. he delivered very fast",
  Wallett: "Wallet",
  TypeHere: "Type Here",
  Loadingmessages: "Loading messages",
  PoweredBy: "Powered By",
  newincomemessage: "You received a new message",
  newincomemessageanother: "You have a new message in another order",
  youhaveunreadmessage: "Unread message",
  Nointernetconnection: "No internet connection",
  close: "close",
  seen: "Seen",
  controlThatbeforeLoweEqualAfter:
    'Time "Before :" cannot be less than "After :"',
  controlThatbeforeAndAfterMustBiggerthanCurrentDate:
    'The "before:" and "after:" times could not be shorter than the current time',
  errorInPanelSetting: "Error in reservation settings, please contact support",
  Arabic: "Arabic",
  ar: "Arabic",
  veeDeliveryLoginText1: "Send Anything",
  veeDeliveryLoginText2: "To Anyone, NOW!",
  Schedule: "Schedule",
  Date: "Date",
  Time: "Time ",
  noDateToShow: "There is no Date to display",
  noTimeToShow: "There is no Time to display",
  Apply: "Apply",
  Increaseyourbalance: "Increase your balance",
  AddBalance: "Add Balance",
  Addressbook: "Address Book",
  AddressbookSearchbar: "Search by address, title and code",
  Removedefault: "Remove default",
  Setdefaultpickup: "Set default pickup",
  Remove: "Remove",
  newAddress: "New Address",
  NewAddressbookInfoTitle: "Info",
  Addressdetails: "Address details",
  Title: "Title",
  Code: "Kodlaşdırmaq",
  editAddress: "Edit Address",
  AddressbookDropOf: "Address book (Drop off)",
  AddressbookPickup: "Address book (Pickup)",
  hide: "Hide",
  Nosavedaddress: "No saved address",
  NosavedaddressDesc:
    "You Can Create One, by clicking the bookmark icon next to address or go to address book page",
  Romanian: "Romanian",
  ro: "Romanian",
  Vehicletype:"Vehicle Type" ,
   Pickup2:"Pickup" ,
    Dropoff2:"Dropoff" ,
    senderfullname: "Sender Name",
    block: "Block",
    senderphonenumber: "Sender number",
    Recieverfullname: "Receiver Name",
    Recieverphonenumber: "Receiver Number",


    Events: "Events",
    allEvents: "All Events",
    star :"star" ,
    Yourrate:"Your rate" ,
    Comment:"Comment" ,
    CopyOrderlink:"Copy Order link" ,
    CopyPickuplink:"Copy Pickup link" ,
    CopyDeliverylink:"Copy Delivery link" ,
    Dutch:"dutch",
    nl:"dutch",

    paymentmethod: "Payment method",
    Name :"Name",
    CashOnDelivery:"Cash on Delivery",
    EnterAmount:"Enter amount",
    COD:"Cash on Delivery",
    Surchargexxx:"Surcharge xxx",
    Pricedetails: "Price details",
    minxxx :"xxx min ",
    toPickup:"to Pickup" ,
    toDropOff: "to DropOff",
    AddCreditCard:"Add Credit/Debit Card" ,
    CustomerWallet:"Customer Wallet" ,
    Add:"Add",
    Addedsuccessfully: "Added successfully",
    paymentmethods: "Payment methods",
    NoCards:"No Cards",
    Listofyourcardswillbehere:"List of your cards will be here",
    CardInfo:"Card info",
    ExpireDate:"Expire date",
    CVC:"CVC",
    PostalCode:"Postal Code",
    RemoveCardq:"Remove Card?",
    RemoveCardA:"The card will be removed and can’t be used anymore",
    YesRemove:"Yes, Remove",
    Cardisinuse:"Card is in use",
    ok:"Ok" ,
    Pleasetypeyourwordstosearch:"Please type your keyword to search",
    spanish:"Spanish",
    es : "Spanish",
    Invoices:"Invoices",
    noInvoice:"No. xxx",
    germany: 'Germany',
    de: 'Germany',
    downloadClientApplication:"Download xxx application" ,
    "AssignedAt":"Assign till xxx",
    "StartedAt":"Start till xxx",
    "ArrivedAt":"Arrive till xxx",
    "PickedUpAt":"Pickup at xxx",
    "DoneAt":"Finish at xxx",
    Russian:"Rus",
    Azerbaijani:"Azərbaycanlı",
    rus:"Rus",
    aze:"Azərbaycanlı",
    Support:"Dəstək vermək",
    SendRequest:"Sorğu göndərmək",
    CallTheSupport:"Dəstəyini çağırın",
    Choosethesubject :"Mövzunu seçin",
    Seefrequentquestions:"Tez-tez suallara baxın",
    Details:"Təfərrüatlar",
    Send:"Göndər",
    MessageBox:"Mesaj qutusu",
    NoMessages:"Mesaj yoxdur",
    Yourmessageswillbehere:"Mesajlarınız burada olacaq",
    Portuguese:"Portuqal",
    pt:"Portuqal",
    Required:"Tələb olunan",
    changePassword : "Parol dəyişdirmək",
    changePasswordp1 : "Hesabınızı qorumaq üçün bu, hər kəsin daxil olmasına çalışan bir şeydən başqa bütün seanslardan çıxacaqsınız",
    changePasswordp2 : "Parolunuz ən azı 6 simvol olmalıdır",
    currentPassword:"Hazırkı şifrə",
    newpassword:"yeni şifrə",
    Retypenewpassword:"Yeni şifrəni yenidən yazın",
    account: "Hesab (cüzdan)",
    "Greek":"Yunan",
    "el":"Yunan",
    "registerBussinessUsername":"İstifadəçi adı (ən azı 5 simvol)",
    "registerBussinessPassword":"Şifrə (ən azı 6 simvol)",
    "ReferenceID":"İstinad ID",
    "EnterId":"Şəxsiyyət sənədi təmin etmək",
    "Orderid": "Əmrləşdirmək",
    "EnterCode":"Kodu daxil edin",
    "AustrianGerman":"Avstriya Alman",
    "deu":"Avstriya Alman",
    "pop":"Pikapın sübutu",
    "Options":"Seçimlər",
    "RequiredText":"Tələb olunan",
    "PleaseSelect":"Zəhmət olmasa seçin",
    "Optimizedropoffs":"Dropoffları optimallaşdırın",
    "Optimizedropoffs?":"Dropoffları optimallaşdırırsınız?",
    "OptimizedropoffsDesc":"Dropoffların ardıcıllığı optimallaşdırılmış ardıcıllıqla dəyişdiriləcək və qiyməti dəyişə bilər",
    "Optimize":"Optimallaşdırmaq"
    ,
    "Bulkimport":"Toplu idxal",
    "processing":"Emal etmək" ,
     "done":"Bitmiş" ,
     "failed":"Uğursuz",
     "Draft":"Layihə" ,
     "Uploading":"Yükləmək" ,
     "Draganddropyourfile":"Faylınızı sürükləyin və atın",
     "Onlycsvformatissupported":"Yalnız .CSV formatı dəstəklənir" ,
     "ORyoucan":"Ya da edə bilərsiniz",
     "Openfile":"Faylı açın" ,
     "Noimportsyet":"Hələ idxal yoxdur" ,
     "NoimportsyetDesc":"İdxalınızın siyahısını burada görə və vəziyyətlərini yoxlaya bilərsiniz",
     "nosupportdialogeTitle":"Fayl formatı dəstəklənmir",
     "nosupportdialogeText":"XXX faylı dəstəklənmir.Sifariş haqqında məlumat daxil olmaqla yalnız .csv faylını yükləyə bilərsiniz" ,
     "Import":"İdxal etmək",
     "previewTitle":"Seçimləri nəzərdən keçirin və seçin",
     "previewDesc":"Məlumatınızın düzgün quruluşda olduğunu yoxlamaq üçün bu sənədlərin ilk maddəsidir" ,
     "Upload":"Yükləmək", 
     "Remove":"Çıxarmaq" ,
     "ServiceandOrdertype":"Xidmət və sifariş növü" ,
     "Fileisproccessing":"Fayl işlənir ...",
     "proccessingDesc":"Nəticənin hazır olub olmadığını görmək üçün təzələyə bilərsiniz",
     "serverError":"Server səhvi",
     "isRequired":"XXX tələb olunur" ,
     "Result":"Nəticə",
     "ResultText":"Buradakı problemləri görə bilərsiniz və bu sətirləri ayrıca düzəltmək üçün yükləyə bilərsiniz",
     "ErrorDetails":"Səhv ilə sifarişlər",
     "ErrorDetailsText":"Səhvlərlə sifarişlər aşağıda yükləyə biləcəyiniz sənəddə verilmişdir.Hər bir sıra üçün səhv təfərrüatları ehtiva edir.",
     "DownloadErroredRows":"Download Erraised Sews" ,
     "importedsuccessfully":"XXX sifarişləri uğurla gətirilir" ,
     "ordershaserrors":"XXX Sifarişlər səhvləri var",
     "refresh":"təzələmək",
     "pleaseSellectParsel":"Xahiş edirəm Xidməti seçin",
     "Ondemand":"Tələbkarcasına",
     "PickupandDelivery":"Pikap və çatdırılma",
     "PickupDelivery":"Pikap və çatdırılma",
     "Delivery":"Çatdırılma",
     "Pickup":"Alma",
     "tab_Delivery":"Çatdırılma",
     "tab_Ondemand":"Tələbkarcasına",
     "tab_Pickup":"Alma",
     "tab_PickupDelivery":"P&D",
     "Downloadsamplecsv":"Nümunə csv yükləyin" ,
     "driverPhoto":"Driver Photo",
     "yourPhoto":"Your Photo",
     "UploadPhoto":"Upload Photo",
     "Photos":"Photos",
   
   
   
     "TrackLink":"Track Link",
     "ShipmentLabel":"Shipment Label",
     "Draft":"Draft",
     "Receipt":"Receipt",
     "Confirm":"Confirm",
     "Cancel":"Cancel",
     "BulkEditStatusSummary":"Bulk Edit Status Summary",
     "Successful":"Successful",
     "Failed":"Failed",
     "OrderID":"Order ID",
     "Details":"Details",
     "Result":"Result",
     "New":"New",
     "Import":"Import",
     "Apply":"Apply",
     "Clear":"Clear",
     "Noresultfound":"No result found",
     "Typeyourkeyword":"Type your keyword",
     "Typeintheinputtostartsearch":"Type in the input to start search",
     "OnDemand":"On Demand",
     "P_Hub_D":"P_Hub_D",
     "AddressDetails":"Address Details",
     "Map":"Map",
     "Support":"Support",
     "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
     "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
     "xxxfromyyy":"xxx from yyy",
     "xxxrows":"xxx rows",
     "xxxselected":"xxx selected",
     "xxxdropoffs":"+xxx dropoffs",
     "Status Updated at xxx":"Status Updated at xxx",
     'More':'More',
     'Summary':'Summary',
     'Prev':'Prev',
     'Next':'Next',
     'Done':'Done',
     "ClearAll":"Clear All",
     'Errorinloadingdata':'Error in loading data',
     'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
     'Tryagain':'Try again',
     'ConfigTable' :'Config Table' ,
     'ActiveColumns':'Active Columns',
     'Createyourorder':'Create your order',
     'Yourorderswillbeshownhere':'Your orders will be shown here',
     'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
   "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
     'CustomerPhoto':'Customer Photo',
     "NoAttachments":"No Attachments",
     "AllAttachmentswillbeshownhere":"All Attachments will be shown here",


     "SearchAddress":"Search Address",
     "NoResultfromLocalDatabaseD": "Please check it again or try another keyword",
     "NoResultfromMapService": "No Result from Map Service",
     "Senderinfo": "Sender info",
     "Receiverinfo": "Receiver info",
     "SchedulePickup": "Schedule Pickup ",
     "ScheduleDelivery": "Schedule Delivery",
     "Fullname": "Full name",
     "addressDetail":"Address Detail",
     "Addtoaddressbook":"Add to addressbook",
     "ExHome":"Ex. Home" ,
     "Ex123":"Ex. 123",
     "Savechanges": "Save changes",
     "Removefromaddressbook":"Remove from addressbook?",
     "RemovefromaddressbookDesc":"This address with entered details will be address to your address book.",
     "Clearfield":"Clear field",
     "CreateOrder":"Create Order" ,
     "Czech":"Czech",
     "cs":"Czech"
};
