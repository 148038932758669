import React, { useEffect, useState } from "react";
import styles from './main.module.css';
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "../../../../../design-system/lib/Typography";
import { useTranslation } from "react-i18next";
import Switch from "../../../../../design-system/lib/Switch";
import { useGoogleLogin } from '@react-oauth/google';
import Button from "../../../../../design-system/lib/Button";
import google from "../../../../../static/google.svg";
import { ARROW_LEFT, Email } from "../../../../../static";
import apple from "../../../../../static/apple.svg";
import Facebook from "../../../../../static/facebook.svg";
import Input from "../../../../../design-system/lib/Input";
import PhoneInput from "react-phone-number-input";
import FacebookLogin from "../../../../../innerLibrary/facebookLogin2/facebook";
import AppleLogin from "react-apple-login";
import { RegisterBussinessPureApi } from "../../../../../helper/api";
import loadDynamicScript from "../../../../../helper/module/loadDynamicScript";


function RegisterBusiness_Manual_end({ handler , data , changeState , handleback}) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [vCode, setVCode] = useState("");
  const [captcha, setCaptcha] = useState(null);
  const [ts, setTs] = useState(null);
  const [captchaResend, setCaptchaResend] = useState(null);
  const [tsResend, setTsResend] = useState(null);
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);

  const { t, i18n } = useTranslation();


  const handleAutoManual = (inde) => {
  };


  useEffect(() => {

    const intervalId = setInterval(() => {
      timer > 0 && setTimer(timer - 1)
    }, 1000);


    return () => clearInterval(intervalId); //This is important
  }, [timer])


   

  useEffect(() => {
    requestcaptcha()
  }, [])

  const requestcaptcha = React.useCallback(() => {
    setCaptcha(null)
    setTs(null)
    // this.setState({
    //   Captcha: null ,
    //   ts:null
    // },()=>{
       loadDynamicScript(
      `https://www.google.com/recaptcha/enterprise.js?render=${global.config.grsk}`,
      "recaptchaEnterprise",
      () => {
        try {
        window.grecaptcha.enterprise.ready(() => {
          const ts = Number(String(Number(Date.now())))

          window.grecaptcha.enterprise
            .execute(global.config.grsk, {
              action: `custom_customer_register_${ts}`
            })
            .then((token) => {
              setCaptcha(token)
              setCaptchaResend(token)

            })
            .catch((error) => {
              console.log(error);
            });
        });
      } catch (error) {
        
      }
      }
    );
    // })


   
  }, []);





  
    const requestRegister = React.useCallback(() => {
      setLoading(true)
      setMessage(null)

      if (!loading) {
           RegisterBussinessPureApi(dispatch, {header : {Captcha : captcha , ts :  ts} , state : {
             "password":  data.password,
             "phone":  data.phone,
             "name":  data.name,
             "email":  data.email,
             "address": data.address,
             "contactName": data.contactName

           }}, (res) => {
            console.log(res)
            if(res.error){
              if(res.error.message){
                setMessage(res.error.message)
              }
            }else{
              handler("message",res.res.data.message)
              changeState("verifyEmail")
            }
             setLoading(false)
           })
         }
    }, [loading , data , ts , captcha]);
  






    return <div
      className={styles.Container}
    >

     <div className={styles.header}>
      <Button size="Medium" type="Secondary" icon={ARROW_LEFT} onClick={handleback} />
      <Typography weight="Heading_Small_Bold" text={<>{t("Business account")}  </>} style={{color:"#000000"}} />
     </div>
    

  {message &&   <div className={styles.thirdConnected} style={{backgroundColor:"#D0140014"}}>
      <Typography weight="Body_Middle_Medium" style={{color:"#D01400"}} text={message} />
    </div> }

    <div className={styles.InputContainers}>

        <Input  size="Large" type={"text"} isRequiredText={"*"} isRequired={true} title={t("registerBusinessNameLabel")} value={data.name} onChange={(e)=>{handler("name",e.target.value || "")}} placeHolder={t("businessNamePlaceholder")}  />

        <Input  size="Large" type={"text"} isRequiredText={"*"} isRequired={true} title={t("address")} value={data.address} onChange={(e)=>{handler("address",e.target.value || "")}} placeHolder={t("Ex. London")}  />
        <Input  size="Large" type={"password"} isRequiredText={"*"} isRequired={true} title={t("Password")} value={data.password} onChange={(e)=>{handler("password",e.target.value || "")}} placeHolder={t("TypeHere")}  />
        <Input  size="Large" type={"password"} isRequiredText={"*"} isRequired={true} title={t("Repeat password")} value={data.R_password} onChange={(e)=>{handler("R_password",e.target.value || "")}} placeHolder={t("TypeHere")}  />

    </div> 

     {/* <Typography className={styles.numberPhoneSentContainer} weight="Body_Middle_Regular" text={<>{t("Enter code sent to your")} <Typography weight="Body_Middle_Regular" text={"+1234567890"} style={{color:"#242424" , float:"inline-end" , marginInlineStart:"5px" , direction:"initial" ,}} /> </>} style={{color:"#808080"}} /> */}

   {/* <div className={styles.InputContainers}>

   <Input type={"text"} title={t("Code")} onChange={()=>{}} placeHolder={t("TypeHere")}  />

   </div> */}

    {/* <Typography onClick={()=>timer > 0 && null} weight="Body_Middle_Bold" text={resendCode} style={{color:"#1080FF",alignSelf :"start" ,marginTop:"16px",opacity: timer == 0 ? "1" : ".4" , cursor : timer == 0 ? "pointer" :"not-allowed"}}   />  */}

   {/* <Typography weight="Body_Small_Regular" text={<>{t("Wrong Code")}  </>} style={{color:"#D01400",alignSelf :"start" ,marginTop:"16px"}}   /> */}


  <Button isLoading={loading} onClick={requestRegister} disabled={String(data.name).length < 2 || String(data.address).length < 2 || String(data.password).length < 2 ||  String(data.R_password).length < 2 || (String(data.password)  !=  String(data.R_password))} size="Large" type="Primary" text={t("Register")} style={{marginTop:"24px" , flex:"1" , padding:"14px 16px" , width:"calc(100% - 32px)"}} />

  {/* <Typography className={styles.termsContainer} weight="Body_Small_Regular" style={{color:"#808080"}} text={<>{t("By continuing, you agree to Onro’s ")} <Typography weight="Body_Small_Regular" style={{color:"#808080" , textDecoration :"underline" ,cursor:"pointer"}} text={<>{t("Terms of Use")}</>} /> <Typography weight="Body_Small_Regular" style={{color:"#808080"}} text={<>{t("and")}</>} /> <Typography weight="Body_Small_Regular" style={{color:"#808080", textDecoration :"underline",cursor:"pointer"}} text={<>{t("Privacy Policy.")}</>} /></>} />  */}

        </div>

}

export default RegisterBusiness_Manual_end