export const AustrianGermanLabel = {
  Cutomerpanel: 'Kunden Portal',
  login: 'Login',
  Register: 'Registrieren',
  en: 'Englisch',
  fa: 'Deutsch',
  Business: 'Geschäftskunde',
  Phonenumber: 'Mobil-Nummer',
  Continue: 'Weiter',
  phonenumberhasnotregisterd: 'Mobil-Nummer ist nicht registriert.',
  Username: 'Benutzername',
  title: 'Titel',
  Password: 'Passwort',
  ExOnro: 'z.B. Firmenname',
  Exstart: 'Passwort',
  googleConnected: 'Google connected',
  facebookConnected: 'Facebook connected',
  appleConnected: 'Apple ID connected',
  plsenterphonnumber: 'Bitte füge Deine Mobil-Nummer ein.',
  businessNamePlaceholder: 'z.B. Max',
  registerBusinessNameLabel: 'Namen',
  firstnnameloginplaceholder: 'z.B. Max',
  lastnameloginplaceholder: 'z.B. Mustermann',
  Firstname: 'Vorname',
  Lastname: 'Nachname',
  email: 'E-Mail-Adresse',
  emailPlaceholder: 'E-Mail-Adresse',
  comingsoon: 'kommt bald:',
  comingsoondesc: 'Kontaktiere uns, um ein Firmenkonto zu eröffnen.',
  CustomerAlreadyRegistered: 'Kunde ist schon registriert - bitte zum Login',
  registerCompleted: 'Verifikationscode eingeben',
  googletokenerror: 'Problem with Google Token',
  facebooktokenerror: 'Problem mit dem Facebook Tokken',
  appletokenerror: 'Problem mit den Apple Tokken',
  CustomerNotRegistered: 'Kunde ist noch nicht registriert',
  fr: 'Französisch',
  de: 'Deutschland',
  InvalidVerificationCode: 'Ungültiger Verifikationscode',
  a: 'Your information could not be found',
  welcome: 'Willkommen',
  SmsLimitationError: 'Please try again in a few moments',
  resendCodeWithTime: 'resend code (xxx)',
  resendCode: 'Code erneut schicken',
  VerificationcodePlaceHolder: 'z.B. 1234',
  Verificationcode: 'Verifikationscode',
  VerificationcodeText: 'Bitte den gesendeten Code eingeben (xxx).',
  VerificationCodeExpired: 'Verifikationscode abgelaufen',
  Pending: 'Ausstehend',
  Unassigned: 'Nicht zugewiesen',
  Assigned: 'Zugewiesen',
   PickedUp: 'Abgeholt',
  Started: 'Gestartet',
  statusInProgress: 'Im Laufen',
  Done: 'Erledigt',
  CustomerCanceled: 'Vom Kunden storniert',
  DriverCanceled: 'Vom Fahrer storniert',
  SupportCanceled: 'Vom Support storniert',
  Arrived: 'Zugestellt',
  new: 'Neu',
  Historyofyourorderswillbehere: 'Die Auftragshistorie wird hier angezeigt.',
  otherdropoffs: 'Weitere Adressen',
  Orderhistory: 'Auftragshistorie',
  Noorder: 'Kein Auftrag',
  loadingList: 'Bitte Geduld, wir laden die Information.',
  loadingError: 'Problem on getting information',
  retry: 'Versuche es nocheinmal',
  Create_your_first_requst: 'Create a new order',
  List_of_you_active_orders_will_be_here: 'Die Liste der aktiven Aufträge wird hier gezeigt.',
  Aboutus: 'Über uns',
  Readour: 'Lese unsere',
  privaypolicy: 'Datenschutzerklärung',
  Transactions: 'Transaktionen',
  NoTransaction: 'Es gibt keine Transaktionen',
  Historyofyourransactionswillbehere: 'Die Transaktionshistorie wird hier gezeigt.',
  Settings: 'Einstellungen',
  Language: 'Sprachen',
  English: 'Englisch',
  Gereman: 'Deutsch',
  French: 'Französisch',
  Logout: 'Ausloggen',
  logotTitle: 'Vom Konto ausloggen?',
  logoutDescription: 'Du wirst vom Konto ausgeloggt und kannst später wieder einloggen.',
  confirmLogout: 'Ja, ausloggen',
  skipLogout: 'Schliessen',
  savesettings: 'Einstellungen speichern',
  emailInvalid: 'E-Mail-Adresse ungültig',
  Neworder: 'Neuer Auftrag',
  Service: 'Service',
  poperLoading: 'Bitte warten',
  Pickup: 'Abholadresse',
  SetPickup: 'Abholadresse anklicken',
  ChooseoOnMap: 'Auf der Karte auswählen',
  Fornow: 'Absofort',
  Scheduled: 'Geplant',
  before: 'Vor:',
  after: 'Nach:',
  confirm: 'Bestätigen',
  Settime: 'Set time',
  fo: 'für',
  Cancel: 'Cancel',
  Pleasetryanotherkeyword: 'Versuche ein anderes Keyword',
  Noinformationfound: 'Keine Information gefunden',
  address: 'Adresse',
  block: 'Stiege',
  Floor: 'Stockwerk',
  Unit: 'Türnummer',
  senderfullname: 'Firmenname, Person',
  senderphonenumber: 'Mobilnummer',
  notesfordriver: 'Notiz für den Fahrer',
  DropOff: 'Zustelladresse',
  SetDropOff: 'Zustelladresse anklicken',
  fetchingdata: 'Empfange Daten',
  Recieverfullname: 'Empfängername',
  Recieverphonenumber: 'Mobilnummer',
  PromotionCode: 'Promotionscode',
  Delete: 'Löschen',
  Save: 'Speichern',
  PromoCodeValid: 'Promotionscode gespeichert',
  SenderWallet: 'Konto',
  SenderCash: 'Bar (Vom Versender)',
  ReceiverCash: 'Bar (Vom Empfänger)',
  lowBalance: 'Guthaben zu klein',
  Balance: 'Konto',
  Methods: 'Zahlungsmethode',
  balanceError: 'Ihr Guthaben muss mindestens dem Auftrag entsprechen',
  SendOrder: 'Auftrag absenden',
  feedback: 'Bewertung',
  Feedback: 'Bewertung',
  Orderagain: 'Wiederholen',
  Createdsuccessfully: 'Erfolgreich erfasst',
  chooseAmount: 'Betrag auswählen',
  orenterthepreferedamount: 'Bevorzugter Betrag:',
  enteramount: 'Gewünschter Betrag',
  pay: 'Bezahlen',
  selectPaymentGateway: 'Wähle den Zahlungsanbieter',
  Time_MachineText: 'Do you want to recover your previously unregistered order information ?',
  yes: 'Ja',
  ignore: 'Ignorieren',
  Order: 'Auftrag',
  statusPending: 'Ausstehend',
  statusUnassigned: 'Nicht zugewiesen',
  statusAssigned: 'Zugewiesen',
  statusStarted: 'Gestartet',
  statusPickedUp: 'Abgeholt',
  statusDone: 'Erledigt',
  statusCustomerCanceled: 'Storniert vom Kunden',
  statusDriverCanceled: 'Storniert vom Fahrer',
  statusArrived: 'Angekommen',
  statusSupportCanceled: 'Storniert vom Support',
  statusCanceled: 'Storniert',
  Phone: 'Mobilnummer',
  additonalservice: 'Zusätzliches Service',
  CallDriver: 'Fahrer anrufen',
  Message: 'Nachricht',
  orderInfoError: 'Error receiving information',
  support: 'Support',
  map: 'Karte',
  receipt: 'Beleg',
  photo: 'Foto',
  ServicePhoto: 'Foto',
  Addphoto: 'Foto hinzufügen',
  Estime: 'Geschätzte Zeit',
  Espath: 'Vorgeschlagene Route',
  pod: 'Zustellnachweis',
  Notes: 'Notizen',
  Photo: 'Foto',
  Signature: 'Unterschrift',
  Nonotes: 'Keine Notiz',
  Nosignature: 'Keine Unterschrift',
  Nophoto: 'Kein Foto',
  Price: 'Preis',
  Receiver: 'Vom Empfänger',
  Sender: 'Vom Versender',
  Cash: 'Bar',
  Canceltravel: 'Anfrage stornieren',
  CancellText: 'Nach dem Stornieren wird auch der Auftrag beim Fahrer gelöscht',
  Estimated: 'Geschätzt',
  Wallet: 'Saldo',
  Copylink: 'Link kopieren',
  repeatOrder: 'Auftrag wiederholen',
  Edit: 'Ändern',
  confirmCancel: 'Ja',
  paymentmethod: 'Zahlungsmethode',
  SucceessFul: 'Erfolgreich abgeschlossen',
  errorinServer: 'Serverproblem',
  Comment: 'Kommentar',
  Exfeedbackinput: 'z.B. bin top zufrieden, verlässlich ausgeliefert',
  Wallett: 'Konto',
  TypeHere: 'Hier eingeben',
  Loadingmessages: 'Loading messages',
  PoweredBy: 'Powered by PiNK.Express',
  newincomemessage: 'Eine neue Nachricht erhalten',
  newincomemessageanother: 'Eine neue Nachricht in einem anderen Auftrag erhalten',
  youhaveunreadmessage: 'Unread message',
  Vehicletype: 'Fahrzeugtyp',
  Pickup2: 'Abholadresse',
  Dropoff2: 'Zustelladresse',
  Events: 'Ereignis',
  allEvents: 'Alle Ereignisse',
  star: 'Sterne',
  Yourrate: 'Bewertung',
  CopyOrderlink: 'Auftragslink kopieren',
  CopyPickuplink: 'Abhollink kopieren',
  CopyDeliverylink: 'Zustelllink kopieren',
  Nointernetconnection: 'Keine Internetverbindung',
  close: 'Schliessen',
  seen: 'Gesehen',
  controlThatbeforeLoweEqualAfter: 'Die Zeit ""Vor"" kann nicht kleiner als ""Nach"" sein',
  controlThatbeforeAndAfterMustBiggerthanCurrentDate: 'Die ""Vor"" und ""Nach"" Zeiten können nicht kleiner als die aktuelle Zeit sein',
  errorInPanelSetting: 'Error in reservation settings, please contact support',
  Arabic: 'Arabisch',
  ar: 'Arabisch',
  veeDeliveryLoginText1: 'Send Anything',
  veeDeliveryLoginText2: 'To Anyone, NOW!',
  Schedule: 'Geplant / Terminiert',
  Date: 'Datum',
  Time: 'Zeit',
  noDateToShow: 'Kein Datum zum Anzeigen',
  noTimeToShow: 'Keine Zeit zum Anzeigen',
  Apply: 'Anwenden',
  Increaseyourbalance: 'Guthaben erhöhen',
  AddBalance: 'Guthaben hinzufügen',
  Addressbook: 'Adressbuch',
  AddressbookSearchbar: 'Suchen (nach Adresse oder Bezeichnung)',
  Removedefault: 'Remove default',
  Setdefaultpickup: 'Set default pickup',
  Remove: 'Löschen',
  newAddress: 'Neue Adresse',
  NewAddressbookInfoTitle: 'Info',
  Addressdetails: 'Adressdetails',
  Title: 'z.B. Firmenname, Empfänger',
  Code: 'Code',
  editAddress: 'Adresse ändern',
  AddressbookDropOf: 'Adressbuch (Zustelladressen)',
  AddressbookPickup: 'Adressbuch (Abholadressen)',
  hide: 'Verbergen',
  Nosavedaddress: 'Keine gespeicherten Adressen',
  NosavedaddressDesc: 'Auftragserfassung durch Icon-Klick im Adressbuch',
  Romanian: 'Romanisch',
  ro: 'Romanisch',
  Dutch: 'Holländisch',
  Shipmentlabel: 'Versandlabel',
  Name: 'Namen',
  CashOnDelivery: 'Per  Nachnahme',
  EnterAmount: 'Betrag eingeben',
  COD: 'Nachnahme',
  Surchargexxx: 'Zuschläge xxx',
  Pricedetails: 'Kostenaufstellung',
  minxxx: 'xxx min',
  toPickup: 'zur Abholadresse',
  toDropOff: 'zur Zustelladresse',
  AddCreditCard: 'Karte hinzufügen',
  CustomerWallet: 'Kundenkonto',
  Add: 'Hinzufügen',
  Addedsuccessfully: 'Erfolgreich hinzugefügt',
  paymentmethods: 'Zahlungsmethode',
  NoCards: 'Keine Karten',
  Listofyourcardswillbehere: 'Die Liste der Karten wird hier angezeigt',
  CardInfo: 'Card Info',
  ExpireDate: 'Expire date',
  CVC: 'CVC',
  PostalCode: 'PLZ',
  RemoveCardq: 'Karte entfernen?',
  RemoveCardA: 'The card will be removed and can’t be used anymore',
  YesRemove: 'Ja, entfernen',
  Cardisinuse: 'Karte ist in Verwendung',
  ok: 'Ok',
  Pleasetypeyourwordstosearch: 'Bitte tippe dein Keyword zum suchen ein',
  spanish: 'Spanisch',
  es: 'Spanisch',
  Invoices: 'Rechnung',
  noInvoice: 'Rechnungsnr. Xxx',
  germany: 'Deutschland',
  nl: 'Niederländisch',
  downloadClientApplication: 'Download xxx app',
  AssignedAt: 'Zugeteilt seit xxx',
  StartedAt: 'Gestartet seit xxx',
  ArrivedAt: 'Zugestellt seit xxx',
  PickedUpAt: 'Abgeholt seit xxx',
  DoneAt: 'Erledigt seit xxx',
  Russian: 'Russisch',
  Azerbaijani: 'Aserbaidschanisch',
  rus: 'Russisch',
  aze: 'Aserbaidschanisch',
  Support: 'Unterstützung',
  SendRequest: 'Anfrage senden',
  CallTheSupport: 'Support anrufen',
  Choosethesubject: 'Wählen Sie das Thema',
  Seefrequentquestions: 'FAQ',
  Details: 'Details',
  Send: 'Senden',
  MessageBox: 'Nachrichten',
  NoMessages: 'Keine Nachrichten',
  Yourmessageswillbehere: 'Ihre Nachrichten werden hier sein',
  Portuguese: 'Portugiesisch',
  pt: 'Portugiesisch',
  Greek: 'griechisch',
  el: 'griechisch',
  individual: 'Person',
  comingsoondesc2: 'Um ein Geschäftskonto zu erstellen, kontaktieren Sie uns bitte',
  contactUs: 'Kontaktieren Sie uns',
  changePassword: 'Kennwort ändern',
  changePasswordp1: 'Mit dieser Ausnahme verlassen Sie alle Sitzungen, um Ihr Konto vor Zugriffsversuchen zu schützen',
  changePasswordp2: 'Ihr Passwort sollte mindestens 6 Zeichen lang sein',
  currentPassword: 'Aktuelles Passwort',
  newpassword: 'Neues Passwort',
  Retypenewpassword: 'Geben Sie erneut ein neues Passwort ein',
  account: 'Konto (Wallet)',
  Required: 'Notwendig',
  registerBussinessUsername: 'Benutzername (mindestens 5 Zeichen)',
  registerBussinessPassword: 'Passwort (mindestens 6 Zeichen)',
  ReferenceID: 'Referenz ID',
  EnterId: 'Lieferschein Nr, Kundenname',
  Orderid: 'Bestellnummer',
  EnterCode: 'Code eingeben',
  AustrianGerman: 'Deutsch AT',
  deu: 'Deutsch AT',
  pop: 'Abholnachweis',
  Options: 'Optionen',
  RequiredText: 'Erforderlich',
  PleaseSelect: 'Bitte auswählen',
  Optimizedropoffs: 'Abgaben optimieren',
  'Optimizedropoffs?': 'Abgaben optimieren?',
  OptimizedropoffsDesc: 'Die Reihenfolge der Abgaben wird in eine optimierte Reihenfolge geändert und es kann zu Preisänderungen kommen',
  Optimize: 'Optimieren',
  zh: 'Chinesisch vereinfacht',
  ChineseSimplified: 'Chinesisch vereinfacht',
  Bulkimport: 'Massenimport',
  processing: 'Verarbeitung',
  done: 'Fertig',
  failed: 'Fehlgeschlagen',
  Draft: 'Entwurf',
  Uploading: 'Hochladen',
  Draganddropyourfile: 'Ziehen Sie Ihre Datei per Drag & Drop',
  Onlycsvformatissupported: 'Es wird nur das CSV-Format unterstützt',
  ORyoucan: 'ODER du kannst',
  Openfile: 'Datei öffnen',
  Noimportsyet: 'Noch keine Importe',
  NoimportsyetDesc: 'Hier können Sie die Liste Ihrer Importe einsehen und deren Status überprüfen',
  nosupportdialogeTitle: 'Dateiformat wird nicht unterstützt',
  nosupportdialogeText: 'xxx-Datei wird nicht unterstützt. Sie können nur eine CSV-Datei mit Bestellinformationen hochladen',
  Import: 'Import',
  previewTitle: 'Vorschau und wählen Sie Optionen',
  previewDesc: 'Dies ist das erste Element Ihrer Dateien, bei dem überprüft wird, ob Ihre Informationen die richtige Struktur haben',
  Upload: 'Hochladen',
  ServiceandOrdertype: 'Service- und Auftragstyp',
  Fileisproccessing: 'Datei wird verarbeitet ...',
  proccessingDesc: 'Sie können eine Aktualisierung durchführen, um zu sehen, ob das Ergebnis fertig ist',
  serverError: 'Serverfehler',
  isRequired: 'xxx ist erforderlich',
  Result: 'Ergebnis',
  ResultText: 'Sie können die Probleme hier sehen und diese Zeilen herunterladen, um sie separat zu beheben',
  ErrorDetails: 'Bestellungen mit Fehler',
  ErrorDetailsText: 'Bestellungen mit Fehlern sind in der Datei aufgeführt, die Sie unten herunterladen können. Es enthält Fehlerdetails für jede Zeile.',
  DownloadErroredRows: 'Laden Sie fehlerhafte Zeilen herunter',
  importedsuccessfully: 'xxx Bestellungen wurden erfolgreich importiert',
  ordershaserrors: 'xxx Bestellungen sind fehlerhaft',
  refresh: 'Aktualisieren',
  pleaseSellectParsel: 'Bitte wählen Sie Service aus',
  Ondemand: 'Auf Anfrage',
  PickupandDelivery: 'Abholung und Lieferung',
  PickupDelivery: 'Abholung und Lieferung',
  Delivery: 'Lieferung',
  tab_Delivery: 'Lieferung',
  tab_Ondemand: 'Auf Anfrage',
  tab_Pickup: 'Abholen',
  tab_PickupDelivery: 'P&D',
  Downloadsamplecsv: 'Laden Sie eine Beispiel-CSV-Datei herunter',
  Distance: 'Distanz',
  Duration: 'Dauer',
  driverPhoto: 'Fahrerfoto',
  yourPhoto: 'Dein Foto',
  No: 'NEIN',
  confirmCancel2: 'Ja, Bestellung stornieren',
  UploadPhoto: 'Foto hochladen',
  Photos: 'Fotos',
  ParcelPhoto: 'Paketfoto',
  ProofofPickup: 'Abholnachweis',
  EditPhoto: 'Foto bearbeiten',
  TrackLink: 'Track-Link',
  ShipmentLabel: 'Versandetikett',
  Receipt: 'Quittung',
  Confirm: 'Bestätigen',
  BulkEditStatusSummary: 'Zusammenfassung des Massenbearbeitungsstatus',
  Successful: 'Erfolgreich',
  Failed: 'Fehlgeschlagen',
  OrderID: 'Bestell-ID',
  New: 'Neu',
  Clear: 'Klar',
  Noresultfound: 'Kein Ergebnis gefunden',
  Typeyourkeyword: 'Geben Sie Ihr Schlüsselwort ein',
  Typeintheinputtostartsearch: 'Geben Sie die Eingabe ein, um die Suche zu starten',
  OnDemand: 'Auf Anfrage',
  P_Hub_D: 'P_Hub_D',
  AddressDetails: 'Adressdetails',
  Map: 'Karte',
  'BulkEditStatusxxx/yyy': 'Massenbearbeitungsstatus xxx/JJJJ',
  'xxxSuccessfull,yyyFailed': 'xxx Erfolgreich, yyy Fehlgeschlagen',
  xxxfromyyy: 'xxx von jjj',
  xxxrows: 'xxx Zeilen',
  xxxselected: 'xxx ausgewählt',
  xxxdropoffs: 'xxx Abgaben',
  'Status Updated at xxx': 'Status aktualisiert um xxx',
  More: 'Mehr',
  Summary: 'Zusammenfassung',
  Prev: 'Vorher',
  Next: 'Nächste',
  ClearAll: 'Alles löschen',
  Errorinloadingdata: 'Fehler beim Laden der Daten',
  Therewasaproblemloadingdatapleasetryagainorcontactsupport: 'Beim Laden der Daten ist ein Problem aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support',
  Tryagain: 'Versuchen Sie es erneut',
  ConfigTable: 'Konfigurationstabelle',
  ActiveColumns: 'Aktive Spalten',
  Createyourorder: 'Erstellen Sie Ihre Bestellung',
  Yourorderswillbeshownhere: 'Ihre Bestellungen werden hier angezeigt',
  Pleasecheckyourfiltersorkeyword: 'Bitte überprüfen Sie Ihre Filter oder Ihr Schlüsselwort',
  CustomerPhoto: 'Kundenfoto',
  NoAttachments: 'Keine Anhänge',
  AllAttachmentswillbeshownhere: 'Alle Anhänge werden hier angezeigt',
  Scheduleoverlap: 'Die geplante Abholzeit muss vor der geplanten Lieferzeit liegen und darf sich nicht überschneiden',
  SearchAddress: 'Adresse suchen',
  NoResultfromLocalDatabaseD: 'Bitte überprüfen Sie es noch einmal oder versuchen Sie es mit einem anderen Schlüsselwort',
  NoResultfromMapService: 'Kein Ergebnis vom Kartendienst',
  Senderinfo: 'Absenderinformationen',
  Receiverinfo: 'Informationen zum Empfänger',
  SchedulePickup: 'Abholung vereinbaren',
  ScheduleDelivery: 'Lieferung planen',
  Fullname: 'Vollständiger Name',
  addressDetail: 'Adressdetails',
  Addtoaddressbook: 'Zum Adressbuch hinzufügen',
  ExHome: 'z.B. Haus',
  Ex123: 'z.B 123',
  Savechanges: 'Änderungen speichern',
  Removefromaddressbook: 'Aus Adressbuch entfernen?',
  RemovefromaddressbookDesc: 'Diese Adresse mit den eingegebenen Daten wird in Ihr Adressbuch übernommen.',
  Clearfield: 'Leeres Feld',
  CreateOrder: 'Auftrag erstellen',
  Walletbalanceislow: 'Der Kontostand ist niedrig',
  Walletbalanceislowd: 'Um das Wallet nutzen zu können, muss Ihr Guthaben mindestens dem Bestellpreis entsprechen.',
  'Selected date and time': 'Ausgewähltes Datum und Uhrzeit',
  Vehicle: 'Fahrzeug',
  Other: 'Referenz Nr.',
  'Fill in or Select': 'Ausfüllen oder auswählen',
  Fields: 'Felder',
  OrderType: 'Auftragstyp',
  Stage: 'Bühne',
  Status: 'Status',
  CreatedAt: 'Erstellt am',
  StatusUpdatedAt: 'Status aktualisiert',
  Note: 'Notiz',
  ReferenceId: 'Auftragsnummer und Warenart',
  PickupAddress: 'Abholadresse',
  DeliveryAddress: 'Lieferadresse',
  EstimatedDistanceInMeters: 'Geschätzte Entfernung in Metern',
  EstimatedDuration: 'Geschätzte Dauer',
  'Payment Method': 'Zahlungsmethode',
  PickupAddressDetail: 'Details zur Abholadresse',
  PickupCompleteAfter: 'Abholung abgeschlossen nach',
  PickupcompleteBefore: 'Abholung abgeschlossen vor',
  PickupFullName: 'Abholname',
  PickupPhone: 'Abhol Telefon',
  PickupEmail: 'Abhol Email',
  PickupPodNote: 'Abholhinweis',
  PickupPodPhoto: 'Abholfoto',
  PickupPodSignature: 'Abholunterschrift',
  DeliveryAddressDetail: 'Details zur Lieferadresse',
  DeliveryCompleteAfter: 'Lieferung abgeschlossen nach',
  DeliverycompleteBefore: 'Lieferung abgeschlossen vor',
  DeliveryFullName: 'Liefername',
  DeliveryPhone: 'Liefer Telefon',
  DeliveryEmail: 'Liefer Email',
  DeliveryPodNote: 'Lieferhinweis',
  DeliveryPodPhoto: 'Lieferfoto',
  DeliveryPodSignature: 'Lieferunterschrift',
  'Customer Email': 'Kunden-E-Mail',
  PaymentProvider: 'Zahlungsanbieter',
  'Shipment Label': 'Versandetikett',
  'Track Order': 'Bestellung verfolgen',
  Stages: 'Stufen',
  CodAmount: 'Nachname Betrag',
  selected: 'ausgewählt',
  from: 'aus',
  rows: 'Reihe',
  'Select Status': 'Wählen Sie Status aus',
  'Select Reason': 'Wählen Sie Grund aus',
  'Edit Status': 'Status bearbeiten',
  Czech: 'Tschechisch',
  cs: 'CZ',
  Persian: 'Persisch',
  'Pickup Address': 'Abholadresse',
  'You must assign driver to select this route': 'Sie müssen den Fahrer zuweisen, um diese Route auszuwählen',
  'You must choose an unassigned route or unassign selected route': 'Sie müssen eine nicht zugewiesene Route auswählen oder die Zuweisung einer ausgewählten Route aufheben',
  'Collection from': 'Abholung von',
  'Added to address book': 'Zum Adressbuch hinzugefügt',
  'Add to address book': 'Zum Adressbuch hinzufügen',
  ASAP: 'Absofort',
  'Time slot': 'Zeitfenster',
  'Set time slot': 'Zeitfenster festlegen',
  'Deliver to': 'Lieferung an',
  'Add dropoff': 'Lieferadresse hinzufügen',
  refid_tooltip: 'Verwende dieses Feld, um die Auftragsnummer und die Warenart der Lieferung hinzuzufügen.',
  'Pay By Card': 'Bezahlen Sie mit Karte',
  'Add Card': 'Karte hinzufügen',
  'On Account': 'Auf Rechnung',
  'No card added': 'Keine Karte hinzugefügt',
  Schedulepickup: 'Abholung vereinbaren',
  Scheduledelivery: 'Planen Sie die Abgabe',
  'Business account': 'Geschäftskonto',
  'Email/Username': 'E-Mail/Benutzername',
  'Forgot your password?': 'Passwort vergessen?',
  'Send link': 'Link senden',
  'Link sent': 'Link gesendet',
  'Return to login': 'Zurück zur Anmeldung',
  Verify: 'Überprüfen',
  'Login to your account': 'Melden Sie sich bei Ihrem Konto an',
  'Register as business': 'Als Geschäft anmelden',
  'Continue using Email / Username': 'Weiter mit E-Mail / Benutzername',
  'Don’t have an account?': 'Haben Sie kein Konto?',
  OR: 'ODER',
  'Register as individual': 'Als Einzelperson registrieren',
  'Repeat password': 'Passwort wiederholen',
  'Ex. London': 'Z. B. London',
  'Privacy Policy.': 'Datenschutzrichtlinie.',
  and: 'und',
  'Terms of Use': 'Nutzungsbedingungen',
  'By continuing, you agree to Onro’s': 'Durch die Fortsetzung stimmen Sie Onros',
  'Business Email': 'Geschäftliche E-Mail',
  'Ex. example@example.com': 'Z. B. beispiel@beispiel.com',
  'Contact name': 'Kontaktname',
  'Ex. John Leo': 'Z. B. John Leo',
  'Ex. Leo': 'Z. B. Leo',
  'Enter your new password': 'Geben Sie Ihr neues Passwort ein',
  'Password must be at least 6 characters': 'Passwort muss mindestens 6 Zeichen lang sein',
  'Repeat password doesn’t match': 'Passwortwiederholung stimmt nicht überein',
  'Password changed': 'Passwort geändert',
  'Return to app and login again': 'Zur App zurückkehren und erneut anmelden',
  Change: 'Ändern',
  'Continue with Apple': 'Mit Apple fortfahren',
  'Continue with Facebook': 'Mit Facebook fortfahren',
  'Change password': 'Passwort ändern',
   'Phone number': 'Phone number'
};
