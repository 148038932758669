import { Component } from "react";
import { connect } from "react-redux";
import { SetCanSaveTravel } from "../../../../Redux/actions/actionNames";
import {
  get_customerPannel_type, Get_Permisssion
} from "../../../../helper/UserData/userdate";
import { isEmpty } from "lodash";
class App extends Component {
  componentDidUpdate() {
    if (this.props.price.done && ["green", "grey"].indexOf(this.WalletCheck(this.props)) > -1 && this.props.paymentMethod && this.serviceRequiredCheck(this.props) && this.vehicleRequiredCheck(this.props) && this.pickupRequiredCheck(this.props) && this.firstDropOffRequiredCheck(this.props) && this.otherDropOffRequiredCheck(this.props)) {
      this.props.dispatch(
        SetCanSaveTravel({ canSaveOrder: true, loading: false })
      );
    } else {
      this.props.dispatch(
        SetCanSaveTravel({ canSaveOrder: false, loading: false })
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceivePropsxxx ",nextProps.price.done || ["green", "grey"].indexOf(this.WalletCheck(nextProps)) > -1  ,  nextProps.paymentMethod  ,  this.serviceRequiredCheck(nextProps) ,  this.vehicleRequiredCheck(nextProps)  ,  this.pickupRequiredCheck(nextProps)  ,  this.firstDropOffRequiredCheck(nextProps)  ,  this.otherDropOffRequiredCheck(nextProps))
  
  
    if (nextProps.price.done && ["green", "grey"].indexOf(this.WalletCheck(nextProps)) > -1 && nextProps.paymentMethod && this.serviceRequiredCheck(nextProps) && this.vehicleRequiredCheck(nextProps) && this.pickupRequiredCheck(nextProps) && this.firstDropOffRequiredCheck(nextProps) && this.otherDropOffRequiredCheck(nextProps)) {
      nextProps.dispatch(
        SetCanSaveTravel({ canSaveOrder: true, loading: false })
      );
    } else {
      nextProps.dispatch(
        SetCanSaveTravel({ canSaveOrder: false, loading: false })
      );
    }
  }

  WalletCheck = (props) => {
    if (props.paymentSide + props.paymentMethod == "SenderWallet") {
      if (props.price.price && props.price.done) {
        if (this.WalletBalance(props)) {
          return "green";
        } else {
          return "red";
        }
      } else {
        return "grey";
      }
    }else if (props.paymentSide + props.paymentMethod == "SenderCard") {
      if (props.cardInfo) {
        return "green"
      } else {
        return "red"
      }
    } else {
      return "grey";
    }
  };


  serviceRequiredCheck = (props) => {

    if (props.serviceactiveVehicleType) {
      if (props.serviceactiveVehicleType.options.length == 0) {
        return true
      } else {
        let isNotExist = false;
        let arrFiltered = props.serviceactiveVehicleType.options.filter((item) => item.isRequired);
        arrFiltered.forEach(element => {
          if (!this.optionisSaved(props, element.id)) {
            isNotExist = true;
          }
        });
        return isNotExist == false
      }
    } else {
      return false
    }
  };


  optionisSaved(props, id) {
    let booll = false;
    booll = props.serviceoptions.find((item) => {
      return item.id == id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }


  pickupRequiredCheck = (props) => {
    if (props.serviceactiveVehicleType) {
      let name = false, email = false, phone = false;

      if (props.serviceactiveVehicleType.setting.isPickupAddressNameRequired) {
        if (props.pickup.senderFullName && String(props.pickup.senderFullName).trim().length > 0) {
          name = true
        } else {
          name = false
        }
      } else {
        name = true
      }

      if (props.serviceactiveVehicleType.setting.isPickupAddressPhoneRequired) {
        if (props.pickup.senderPhoneNumber && String(props.pickup.senderPhoneNumber).trim().length > 0) {
          phone = true
        } else {
          phone = false
        }
      } else {
        phone = true
      }


      if (props.serviceactiveVehicleType.setting.isPickupAddressEmailRequired) {
        if (props.pickup.email && String(props.pickup.email).length > 0 && this.emailValidate(String(props.pickup.email))) {
          email = true
        } else {
          email = false
        }
      } else {
        email = true
      }



      return (name && phone && email);

    } else {
      return false
    }

  }

  firstDropOffRequiredCheck = (props) => {
    if (props.serviceactiveVehicleType) {
      let name = false, email = false, phone = false;

      if (props.serviceactiveVehicleType.setting.isDropoffAddressNameRequired) {
        if (props.firstDropOff.recieverFullName && String(props.firstDropOff.recieverFullName).trim().length > 0) {
          name = true
        } else {
          name = false
        }
      } else {
        name = true
      }

      if (props.serviceactiveVehicleType.setting.isDropoffAddressPhoneRequired) {
        if (props.firstDropOff.recieverPhoneNumber && String(props.firstDropOff.recieverPhoneNumber).trim().length > 0) {
          phone = true
        } else {
          phone = false
        }
      } else {
        phone = true
      }


      if (props.serviceactiveVehicleType.setting.isDropoffAddressEmailRequired) {
        if (props.firstDropOff.email && String(props.firstDropOff.email).length > 0 && this.emailValidate(String(props.firstDropOff.email))) {
          email = true
        } else {
          email = false
        }
      } else {
        email = true
      }



      return (name && phone && email);

    } else {
      return false
    }

  }


  otherDropOffRequiredCheck = (props) => {
    if (props.serviceactiveVehicleType) {
      if (!isEmpty(props.otherdropOffs)) {
        let status = true;    
  
        props.otherdropOffs.forEach((dropOff, index) => {    
          let name = false, email = false, phone = false;
    
          if (props.serviceactiveVehicleType.setting.isDropoffAddressNameRequired) {
            if (dropOff.recieverFullName && String(dropOff.recieverFullName).trim().length > 0) {
              console.log("xcop ", 61)

              name = true
            } else {
              name = false
              status = false
            }
          } else {
            name = true
          }
    
          if (props.serviceactiveVehicleType.setting.isDropoffAddressPhoneRequired) {
            if (dropOff.recieverPhoneNumber && String(dropOff.recieverPhoneNumber).trim().length > 0) {
              console.log("xcop ", 71)

              phone = true
            } else {
              phone = false
              status = false
            }
          } else {
            phone = true
          }
    
    
          if (props.serviceactiveVehicleType.setting.isDropoffAddressEmailRequired) {
            if (dropOff.email && String(dropOff.email).length > 0 && this.emailValidate(String(dropOff.email))) {
              console.log("xcop ", 81)

              email = true
            } else {
              email = false
              status = false
            }
          } else {
            email = true
          }


    
          // if (index == props.otherdropOffs.length - 1) {
          //   console.log("xcop ", 10 , name , phone , email , status)
          //   if(name && phone && email && index == 0){
          //     return true
          //   }else  if (name && phone && email && status) {
          //     console.log("xcop ", 11 , name , phone , email , status)
    
          //     return true
          //   } else {
          //     return false
          //   }
    
          // } else {
          //   console.log("xcop ", 12 , name , phone , email , status)
          //   if (name && phone && email) {
    
          //   } else {
          //     status = false
          //   }
          // }
    
        });

        if (status) {
          return true
        } else {
          return false
        }
    
      } else {
        console.log("xcop ", 3)
        return true
      }
    } else {
      console.log("xcop ", 4)
      return false
    }

  }


  DropOffsCheck =  (otherdropOffs, props) => {
  

  }



  vehicleRequiredCheck = (props) => {

    if(this.props.serviceactiveVehicleType?.orderTypes.indexOf("PickupDelivery") > -1){
      return true
    }else{
      
    if (props.auto_activeVehicleType) {
      if (props.auto_activeVehicleType.options.length == 0) {
        return true
      } else {
        let isNotExist = false;
        let arrFiltered = props.auto_activeVehicleType.options.filter((item) => item.isRequired);
        arrFiltered.forEach(element => {
          if (!this.VehicleOptionisSaved(props, element.id)) {
            isNotExist = true;
          }
        });
        return isNotExist == false
      }
    } else {
      if(!props.isSelectVehicleTypeActive){
        return true
      }else{
        return false
      }
    }
    }

  };





  VehicleOptionisSaved(props, id) {
    let booll = false;
    booll = props.VehicleOptions.find((item) => {
      return item.id == id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }


  emailValidate(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }




  WalletBalance = (props) => {

    if (this.props.paymentSide + this.props.paymentMethod == "SenderWallet") {
      if ((props.balance - props.price.price.price + (props.price.price.promoAmount? props.price.price.promoAmount : 0)) >= props.minimumBalance) {
        return true
      } else {
        return Number(props.price.price.price + (props.price.price.promoAmount ? props.price.price.promoAmount :0)) <=
          Number(props.balance)
      }
    } else {
      return Number(props.price.price.price + (props.price.price.promoAmount ? props.price.price.promoAmount : 0)) <=
        Number(props.balance)
    }


  };
  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  price: state.NewOrder.newOrder.price,
  customer: state.NewOrder.newOrder.customer,
  code: state.NewOrder.newOrder.promotionCode.code,
  valid: state.NewOrder.newOrder.promotionCode.valid,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  VehicleOptions: state.NewOrder.newOrder.driver.options,
  pickup: state.NewOrder.newOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
  balance: state.NewOrder.newOrder.balance.balance,
  minimumBalance: state.NewOrder.newOrder.balance.minimumBalance,
  isSelectVehicleTypeActive: state.NewOrder.newOrder.service.activeVehicleType
    ? state.NewOrder.newOrder.service.activeVehicleType.setting &&
    state.NewOrder.newOrder.service.activeVehicleType.setting.isVehicleTypeSelectionActive
    : false,
  cardInfo: state.NewOrder.newOrder.paymentMethod.cardInfo,

});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
